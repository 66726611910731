<template>
  <div class="content resize">
    <e-breadcrumbs />
    <win-layer-pop class="rmk_pop">
      <component
        v-if="rmkComponent"
        :is="rmkComponent"
        @close="closePopup"
        :parent-info="parentInfo"
      />
    </win-layer-pop>
    <h1 class="page_title">
      나의 할인쿠폰 <a class="button" href="#" @click.prevent="pdfDownload('쿠폰 사용 FAQ_241118.pdf')"> [할인쿠폰 FAQ 다운로드 하기 (CLICK)]</a>
    </h1><!-- 나의 할인쿠폰 -->
    <form id="frm2">
      <div class="content_box beforetab">
        <table class="tbl_search">
          <colgroup>
            <col :width="[serviceCtrCd === 'KR' ? '60px' : '80px']">
            <col width="150px">
            <col width="324px">
            <col width="50px">
            <col width="150px">
            <col width="180px">
            <col width="50px">
            <col width="120px">
          </colgroup>
          <tbody>
            <tr>
              <th>{{ $t('msg.FARE020T010.001') }}</th><!-- 출발 -->
              <td colspan="2">
                <e-auto-complete-place
                  id="polPol"
                  @change="changePol"
                  :ctr-cd="params.porCtrCd"
                  :plc-cd="params.porPlcCd"
                  :is-char="true"
                  :is-char-alert="true"
                />
              </td>
              <th>{{ $t('msg.FARE020T010.002') }}</th><!-- 도착 -->
              <td colspan="4">
                <e-auto-complete-place
                  id="podPod"
                  @change="changePod"
                  :ctr-cd="params.dlyCtrCd"
                  :plc-cd="params.dlyPlcCd"
                  :is-char="true"
                  :is-char-alert="true"
                />
              </td>
              <td class="text_right">
              </td>
            </tr>
            <tr>
              <th>{{ $t('msg.FARE020T010.003') }}</th><!-- 기간 -->
              <td>
                <select v-model="params.kind" class="wid150">
                  <option value="R">ETD 기준</option><!-- ETD 기준 -->
                </select>
              </td>
              <td colspan="3">
                <e-date-range-picker
                  id="date_picker"
                  :sdate="params.fromDt"
                  :edate="params.toDt"
                  :is-debug="true"
                  @change="changeDateRange"
                />
              </td>
              <th style="text-align: right; padding-right: 15px;">{{ $t('msg.COUP100.014') }}</th><!-- TYPE -->
              <td colspan="2">
                <select v-model="params.cntrTyp" class="wid150">
                  <option value="">선택</option>
                  <option value="GP">GP, HC</option>
                  <option value="TK">TK</option>
                  <option value="RF">RF</option>
                </select>
              </td>
              <td class="text_right">
                <a class="button blue sh" href="#" @click.prevent="searchMyCoupon(true)">{{ $t('msg.FARE020T010.013') }}</a><!-- 검색 -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <span style="font-size: 18px; font-weight: bolder; color: red;">※ 시작일~만료일 이내에 출항한 건들에 대하여 INVIOCE 발행시 쿠폰 적용 가능합니다.</span>
      <div class="content_box">
        <div id="realgrid" class="mt5" style="width: 100%; height: 650px;" />
      </div>
    </form>
  </div>
</template>

<script>

import EAutoCompletePlace from '@/components/common/EAutoCompleteCtrPlace'
import schedule from '@/api/rest/schedule/schedule'

import { rootComputed } from '@/store/helpers'

import { GridView, LocalDataProvider } from 'realgrid'
import JSZip from 'jszip'
import moment from 'moment'
import EBreadcrumbs from '@/components/common/EBreadcrumbs.vue'
import intergis from '@/api/rest/common/intergis'

let gridView = GridView
let provider = LocalDataProvider

const fields = [
  { fieldName: 'porCtrCd', dataType: 'text' }, // 출항지 국가 코드
  { fieldName: 'porCtrNm', dataType: 'text' }, // 출항지 국가 명
  { fieldName: 'porPlcCd', dataType: 'text' }, // 출항지 지역
  { fieldName: 'dlyCtrCd', dataType: 'text' }, // 도착지 국가 코드
  { fieldName: 'dlyCtrNm', dataType: 'text' }, // 도착지 국가 명
  { fieldName: 'dlyPlcCd', dataType: 'text' }, // 도착지 지역
  { fieldName: 'strDt', dataType: 'datetime', datetimeFormat: 'yyyyMMdd' }, // 시작일
  { fieldName: 'endDt', dataType: 'datetime', datetimeFormat: 'yyyyMMdd' }, // 만료일
  { fieldName: 'cntrTypCd', dataType: 'text' }, // CNTR TYPE
  { fieldName: 'of20Rate', dataType: 'text' }, // 20 DC 금액
  { fieldName: 'of40Rate', dataType: 'text' }, // 40 DC 금액
  { fieldName: 'rmk', dataType: 'text' }, //비고
  { fieldName: 'bookingBtn', dataType: 'text' }
]

const columns = [
  { name: 'porCtrNm', fieldName: 'porCtrNm', header: { text: app.$t('msg.COUP100.005') }, editable: false, width: 15 },
  { name: 'porPlcCd', fieldName: 'porPlcCd', header: { text: app.$t('msg.COUP100.006') }, editable: false, width: 10 },
  { name: 'dlyCtrNm', fieldName: 'dlyCtrNm', header: { text: app.$t('msg.COUP100.005') }, editable: false, width: 15 },
  { name: 'dlyPlcCd', fieldName: 'dlyPlcCd', header: { text: app.$t('msg.COUP100.006') }, editable: false, width: 10 },
  { name: 'strDt', fieldName: 'strDt', header: { text: app.$t('msg.COUP100.007') }, editable: false, width: 15 },
  { name: 'endDt', fieldName: 'endDt', header: { text: app.$t('msg.COUP100.008') }, editable: false, width: 15 },
  { name: 'cntrTypCd', fieldName: 'cntrTypCd', header: { text: app.$t('msg.COUP100.009') }, editable: false, width: 10 },
  { name: 'of20Rate', fieldName: 'of20Rate', header: { text: app.$t('msg.COUP100.011') }, editable: false, width: 12 },
  { name: 'of40Rate', fieldName: 'of40Rate', header: { text: app.$t('msg.COUP100.012') }, editable: false, width: 12 },
  {
    name: 'rmk',
    fieldName: 'rmk',
    header: { text: app.$t('msg.COUP100.013') },
    editable: false,
    width: 46,
    renderer: {
      showTooltip: true,
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const rmk = grid.getValue(idx, 'rmk')

        return `<a href="#" onclick="vmAppEx.openPopup('KmtcCouponRmkPop', '${rmk}')">${rmk}</a>`
      }
    }
  },
  {
    name: 'bookingBtn',
    fieldName: 'bookingBtn',
    type: 'text',
    width: '10',
    header: { text: '선택' },
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const porCtrCd = grid.getValue(idx, 'porCtrCd')
        const porPlcCd = grid.getValue(idx, 'porPlcCd')
        const dlyCtrCd = grid.getValue(idx, 'dlyCtrCd')
        const dlyPlcCd = grid.getValue(idx, 'dlyPlcCd')
        const startDt = grid.getValue(idx, 'strDt')

        return `<span class="yes"><a class="button sm" onclick="vmAppWpx.goSchedule('${porCtrCd}', '${porPlcCd}', '${dlyCtrCd}', '${dlyPlcCd}', '${startDt}');">부킹</a></span>`
      }
    }
  }
]

const layout = [
    {
      name: app.$t('msg.COUP100.003'),
      items: [
        { column: 'porCtrNm' },
        { column: 'porPlcCd' }
      ]
    },
    {
      name: app.$t('msg.COUP100.004'),
      items: [
        { column: 'dlyCtrNm' },
        { column: 'dlyPlcCd' }
      ]
    },
    'strDt',
    'endDt',
    'cntrTypCd',
    {
      name: app.$t('msg.COUP100.010'),
      items: [
        { column: 'of20Rate' },
        { column: 'of40Rate' }
      ]
    },
    'rmk',
    'bookingBtn'
  ]

export default {
  name: 'KmtcCoupon',
  components: {
    EBreadcrumbs,
    EAutoCompletePlace,
    'e-date-range-picker': () => import('@/components/common/EDateRangePickerMulti'),
    'win-layer-pop': () => import('@/pages/trans/popup/WinLayerPop'),
    KmtcCouponRmkPop: () => import('@/pages/schedule/popup/KmtcCouponRmkPop')
  },
  data () {
    return {
      gridHeight: 510,
      rmkComponent: null,
      parentInfo: {
        rmk: ''
      },
      params: {
        porCtrCd: '',
        porPlcCd: '',
        dlyCtrCd: '',
        dlyPlcCd: '',
        kind: 'R',
        fromDt: '',
        toDt: '',
        cntrTyp: ''
      },
      isSearch: false,
      format: 'YYYY.MM.DD',
      myOceanFreightList: [],
      // 운임 팝업을 띄우기 위해 필요한 데이터
      surChargeParams: {},
      dtChk: true,
      serviceCtrCd: '',
      relYn: true, // JamSin
      raNo: '',
      scNo: '',
      porCtrNm: '',
      porPlcNm: '',
      dlyCtrNm: '',
      dlyPlcNm: '',
      freExpDate: '',
      actShpNm: '',
      req20Ft: '', // 20FT 요청 금액
      req40Ft: '', // 40FT 요청 금액
      apv20FtList: [], // 20FT 승인 금액 리스트
      apv40FtList: [], // 40FT 승인 금액 리스트
      apv20Ft: '',
      apv40Ft: '',
      dlyCtrCd: '', // 도착지 국가 코드
      dlyCtrLssYn: '', // 도착지 국가 LSS 대상 여부
      lssYn: 'Y', // LSS 포함 여부
      negoAfterText: ''
    }
  },
  computed: {
    ...rootComputed
  },
  created () {
    // 비로그인 kmtc on 진입 금지
    if (!this.auth.userId) {
      this.$router.push('/main')
    } else { // 로그인 후 미설정 화주 kmtc on 진입금지
      // 가로배너 클릭, kmtc on 아이콘 클릭 구분하기 위해 로그 전달
      schedule.getKmtcSpotTarget('01').then((response) => {
        if (response.data[0].kmtcSpotCoupon !== 'Y') {
          this.$router.push('/main')
        }
      })
    }
  },
  destroyed () {
    window.removeEventListener('keydown', this.handleKeydown)
  },
  mounted () {
    window.vmAppWpx = this
    window.vmAppEx = this

    //엑셀 다운로드용 JSZip 셋팅
    window.JSZip = window.JSZip || JSZip

    //리얼그리드 셋팅
    provider = new LocalDataProvider(true)
    gridView = new GridView('realgrid')
    gridView.setDataSource(provider)
    provider.setFields(fields)
    gridView.setColumns(columns)
    gridView.setColumnLayout(layout)

    gridView.displayOptions.fitStyle = 'even'

    // [s] 그리드 크기 자동조절을 위한 설정
    gridView.header.height = 50
    gridView.displayOptions.rowHeight = 60
    // [e] 그리드 크기 자동조절을 위한 설정

    gridView.setEditOptions({
      editable: false,
      updatable: false
    })

    //인디케이터 없애기
    gridView.setRowIndicator({
      visible: false
    })
    //상태바 없애기
    gridView.setStateBar({
      visible: false
    })
    //체크바 없애기
    gridView.setCheckBar({
      visible: false
    })
    gridView.setFooter({
      visible: false
    })

    gridView.displayOptions.columnMovable = false

    this.$nextTick(function () {
      window.addEventListener('keydown', this.handleKeydown)
    })

    gridView.onShowTooltip = function (grid, idx, value) {
        const columns = ['rmk']
        if (columns.includes(idx.column)) {
          let breakLine = ''
          let oneLine = ''
          let oneLineByteLength = 0

          for (const token of value) {
            oneLine += token

            // 한글과 한글 외 텍스트의 너비 계산
            const unicode = token.charCodeAt(0)
            if (unicode > 127 || (unicode > 64 && unicode < 91)) {
              oneLineByteLength += 2
            } else {
              oneLineByteLength += 1
            }

            // 조건에 따른 개행
            if (token === '\n' || (token === ' ' && oneLineByteLength > 70)) {
              breakLine += oneLine + (token === '\n' ? '' : ' \n')
              oneLine = ''
              oneLineByteLength = 0
            }
          }
          breakLine += oneLine
          return breakLine
        }
      }

    // JamSin 날짜 초기값 셋팅 -1w ~ +1w
    const arrMonthInfo1 = this.$ekmtcCommon.getDefaultStEnDt({ startCode: 'M', startGap: 0, endCode: 'M', endGap: 1 })
    this.params.fromDt = arrMonthInfo1.stDt
    this.params.toDt = arrMonthInfo1.enDt

    this.searchMyCoupon(true) // 검색 실행
  },
  methods: {
    handleKeydown (e) {
      // JamSin
      if (this.relYn) {
        if (e.key === 'Enter') {
          this.searchMyCoupon(true)
        }
      }
    },
    changePol (vo) {
      this.params.porCtrCd = vo.ctrCd
      this.params.porPlcCd = vo.plcCd
      const frm = document.querySelector('#frm2')
      const elem = frm.querySelector('#polPol')
      this.$ekmtcCommon.hideErrorTooltip(elem)
    },
    changePod (vo) {
      this.params.dlyCtrCd = vo.ctrCd
      this.params.dlyPlcCd = vo.plcCd
      const frm = document.querySelector('#frm2')
      const elem = frm.querySelector('#podPod')
      this.$ekmtcCommon.hideErrorTooltip(elem)
    },
    async searchMyCoupon (searchBtnClick) {
      // 조회
      if (this.fnValidationAll()) {
        // '검색'버튼을 누른 경우
        if (searchBtnClick !== undefined && searchBtnClick) {
          this.isSearch = true
        }

        const formData = JSON.parse(JSON.stringify(this.params))

        if (this.$ekmtcCommon.isNotEmpty(formData.fromDt)) {
          formData.fromDt = formData.fromDt.replaceAll('.', '')
        }
        if (this.$ekmtcCommon.isNotEmpty(formData.toDt)) {
          formData.toDt = formData.toDt.replaceAll('.', '')
        }

        await schedule.getMyCouponList(this.params).then(response => {
          this.list = response.data

          provider.setRows(this.list)
          gridView.setCurrent({ itemIndex: 0 })

          if (this.list.length === 0) {
            provider.setRows(this.list)
            gridView.setCurrent({ itemIndex: 0 })
            this.$ekmtcCommon.alertCallback(this.$t('msg.ONEX010T010.073'), () => {
              return false
            })
          }
        }).catch(err => {
          console.log(err)
        })
      }
    },
    openPopup (compNm, rmk) {
      if (this.rmkComponent !== null) {
        this.rmkComponent = null
        this.$ekmtcCommon.layerClose('.rmk_pop')
      }
      this.rmkComponent = compNm
      this.parentInfo.rmk = rmk

      this.$ekmtcCommon.layerOpen('.rmk_pop')
    },
    closePopup () {
      this.rmkComponent = null
      this.$ekmtcCommon.layerClose('.rmk_pop')
    },
    // '검색'버튼 클릭시 검색조건 전체 validation check
    fnValidationAll () {
      const frm = document.querySelector('#frm2')
      let isAllOk = true

      // 출발지 validation check
      if (!this.fnValidationPor(frm)) {
        isAllOk = false
      }
      // 도착지 validation check
      if (!this.fnValidationDly(frm)) {
        isAllOk = false
      }

      return isAllOk && this.dtChk
    },
    // 출발지 validation check
    fnValidationPor (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm2')
      }

      const elem = frm.querySelector('#polPol')
      const inputElem = elem.querySelector('input')
      const inputValue = inputElem.value
      let isOk = true
      let msg = ''

      if (this.$ekmtcCommon.isNotEmpty(inputValue) && (this.$ekmtcCommon.isEmpty(this.params.porCtrCd) || this.$ekmtcCommon.isEmpty(this.params.porPlcCd))) {
        msg = this.$t('msg.FARE010T010.013') // 출발지를 입력해 주세요.
        isOk = false
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }

      return isOk
    },
    // 도착지 validation check
    fnValidationDly (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm2')
      }

      const elem = frm.querySelector('#podPod')
      const inputElem = elem.querySelector('input')
      const inputValue = inputElem.value
      let isOk = true
      let msg = ''

      if (this.$ekmtcCommon.isNotEmpty(inputValue) && (this.$ekmtcCommon.isEmpty(this.params.dlyCtrCd) || this.$ekmtcCommon.isEmpty(this.params.dlyPlcCd))) {
        msg = this.$t('msg.FARE010T010.014') // 도착지를 입력해 주세요.
        isOk = false
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }

      return isOk
    },
    // 시작일 validation check
    fnValidationFromDt (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm2')
      }

      const elem = frm.querySelector('#date_picker')
      let isOk = true
      let msg = ''

      if (this.$ekmtcCommon.isEmpty(this.params.fromDt)) {
        msg = '시작일을 선택해 주세요.' // 시작일을 선택해 주세요.
        isOk = false
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }

      return isOk
    },
    // 종료일 validation check
    fnValidationToDt (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm2')
      }

      const elem = frm.querySelector('#date_picker')
      let isOk = true
      let msg = ''

      if (this.$ekmtcCommon.isEmpty(this.params.toDt)) {
        msg = '종료일을 선택해 주세요.' // 종료일을 선택해 주세요.
        isOk = false
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }

      return isOk
    },
    changeDateRange (sdt, edt) {
      const frm = document.querySelector('#frm2')
      const selector = frm.querySelector('#date_picker')

      this.params.fromDt = sdt
      this.params.toDt = edt

      this.$ekmtcCommon.hideErrorTooltip(selector)
      this.dtChk = true
    },
    async goSchedule (porCtrCd, porPlcCd, dlyCtrCd, dlyPlcCd, startDt) {
      var js_date = new Date(startDt)
      var year = js_date.getFullYear()
      var month = js_date.getMonth() + 1
      if (month < 10) {
        month = '0' + month
      }

      if (porCtrCd === 'undefined') {
        porCtrCd = ''
      }
      if (porPlcCd === 'undefined') {
        porPlcCd = ''
      }
      if (dlyCtrCd === 'undefined') {
        dlyCtrCd = ''
      }
      if (dlyPlcCd === 'undefined') {
        dlyPlcCd = ''
      }

      const params = {
        porCtrCd: porCtrCd,
        porPlcCd: porPlcCd,
        dlyCtrCd: dlyCtrCd,
        dlyPlcCd: dlyPlcCd,
        yyyymm: year + '' + month
      }
      this.$router.push({ name: 'leg', query: params }).catch(() => {})
    },
    async pdfDownload (fileName) {
      const result = await intergis.excelDownload(fileName)
      console.log(result)
      //blob 생성
      const blob = new Blob([result.data], { type: result.headers['content-type'] })

      if (window.navigator.msSaveOrOpenBlob) { //browser Check - IE
        window.navigator.msSaveOrOpenBlob(blob, fileName)
      } else { //browser Check - 그 외
        //<a>태그에 URL화 시킨 blob객체를 연결시킨 후 다운로드
        const link = document.createElement('a')
        const url = window.URL.createObjectURL(blob)
        link.href = url
        link.target = '_self'
        link.download = fileName
        link.click()

        //url 사용 후 메모리에서 제거
        window.URL.revokeObjectURL(url)
      }
    }
  }
}
</script>
