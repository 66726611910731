<template>
  <div class="booking_new_area capture_area">
    <form id="frm" @submit.prevent>
      <span class="booking_info" v-if="saveFlag === 'ADD'">
        <ul>
          <li>
            <strong>{{ $t("msg.CSBK100.046") }}</strong>
            <!-- 담당 -->
            <span>{{ auth.userName }}</span>
          </li>
          <li>
            <strong>TEL</strong>
            <span>{{ usrInfo.usrTel }}</span>
          </li>
          <li>
            <strong>FAX</strong>
            <span>{{ usrInfo.usrFax }}</span>
          </li>
        </ul>
      </span>
      <div class="content_box t2" v-if="saveFlag === 'ADD'">
        <div class="booking_call_wrap">
          <!-- 불러오기 -->
          <div class="call_search">
            <input v-show="vueMode"
                   type="radio"
                   id="templateCd_1"
                   v-model="regParams.templateCd"
                   value="A"
            />
            <label for="templateCd_1">{{ $t("msg.CSBK100.504") }}</label>
            <!-- 불러오기 -->
            <input
              v-model="templateNo"
              type="text"
              placeholder="Booking No."
              @keypress.enter="templateEnter"
              @input="templateNo = templateNo.toUpperCase()"
              @click="regParams.templateCd ='A'"
              :class="urlOrNot ? 'dsm_info' : 'dsm_info'"
            />
            <button
              type="button"
              class="search_btn01"
              @click.prevent="templateSearch()"
              @click="regParams.templateCd ='A'"
            >
              <span class="offscreen">{{ $t("msg.CSBK100.121") }}</span>
              <!-- 부킹템플릿 불러오기 -->
            </button>
            <input v-show="vueMode"
                   type="radio"
                   id="templateCd_2"
                   v-model="regParams.templateCd"
                   value="B"
            />
            <label v-show="vueMode" for="templateCd_2" style="margin-left:20px;">Template</label>
            <select v-show="vueMode"
                    id="templateList"
                    style="width: 200px; margin-left:20px;"
                    v-model="templateNo_2"
                    @click="regParams.templateCd ='B'"
                    :class="urlOrNot ? 'dsm_info' : 'dsm_info'"
            >
              <option value="">
                {{ this.$t("msg.CSBL200.078") }}
              </option>
              <option
                v-for="vo in templateList"
                :key="vo.tplRno"
                :value="vo.tplRno"
              >
                {{ vo.tplNm }}
              </option>
            </select>
            <a class="button blue sh ml15" @click.prevent="setTemplateInfo()">{{
              $t("btn.CMBTK010")
            }}</a>
            <!-- 적용 -->
            <a v-show="vueMode" class="button blue sh" style="margin-left:5px;" @click.prevent="deleteTemplate()">{{
              $t("art.CMBA660.007")
            }}</a>
            <!-- 삭제 -->
          </div>
        </div>
        <!-- 불러오기 end -->
      </div>
      <!-- end content_box -->
      <div class="content_box beforetab" v-if="saveFlag === 'EDIT'">
        <table class="tbl_search">
          <colgroup>
            <col style="width: 130px" />
            <col style="width: 20%" />
            <col style="width: auto" />
          </colgroup>
          <tbody>
            <tr>
              <th scope="row">Booking No</th>
              <td class="text_left">
                <select
                  id="bkgNoList"
                  style="width: 85%"
                  v-model="regParams.bkgNo"
                  :class="urlOrNot ? 'dsm_info' : 'dsm_info'"
                >
                  <option :value="regParams.bkgNo">
                    {{ regParams.bkgNo }}
                  </option>
                </select>
                <a class="btn_copy ml3" @click.prevent="fnCopy()">copy</a>
              </td>
              <td>
                <a class="button sm mr10" @click="fnCommonBtnAction('LOG')">Log {{ $t("btn.CMBTK011") }}</a>
                <span
                  v-if="$ekmtcCommon.isNotEmpty(statusMsg)"
                  :class="'box_info ' + statusClass"
                >
                  <span class="note">{{ statusMsg }}</span>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="content_box mt10 booking_content_area">
        <div class="lybox ly_btn">
          <div class="ly_column left_col col_5">
            <!-- left -->
            <div class="ly_col_cont">
              <h2 class="content_title">
                {{ this.$t("msg.CSBK100.124") }}
                <!-- 부킹 담당자 -->
              </h2>
              <table class="tbl_row tbl_bkg_charger">
                <colgroup>
                  <col style="width: 130px" />
                  <col style="width: auto" />
                </colgroup>
                <tbody>
                  <tr>
                    <th>{{ this.$t("msg.CSBK100.126") }}</th>
                    <!-- 성함 -->
                    <td class="position_relative">
                      <select
                        v-model="picNmh"
                        id="picNmh"
                        @change="changePicNm"
                        class="col_3"
                      >
                        <option value="">
                          {{ this.$t("msg.CSBK100.050") }}
                        </option>
                        <!-- 직접입력 -->
                        <option
                          v-for="vo in cstInfoList"
                          :key="vo.picNo"
                          :value="vo.picNo"
                        >
                          {{ vo.picNm }}
                        </option>
                      </select>
                      <input
                        id="bizPicNm"
                        v-model="regParams.bizPicNm"
                        type="text"
                        maxlength="30"
                        class="inp_space70"
                        @keyup="checkValidationCharger('bizPicNm', 'KEYUP')"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>{{ $t("msg.CSBK100.148") }}</th>
                    <!-- 전화번호 -->
                    <td class="position_relative">
                      <e-input-number
                        id="bizPicTelPlcNo"
                        v-model="regParams.bizPicTelPlcNo"
                        :is-phone="true"
                        maxlength="4"
                        :disabled="userHkSg === 'Y'"
                        class="col_3"
                        @input="
                          checkValidationCharger('bizPicTelPlcNo', 'KEYUP')
                        "
                      >
                      </e-input-number>
                      <e-input-number
                        id="bizPicTelOfcNo"
                        v-model="regParams.bizPicTelOfcNo"
                        :is-phone="true"
                        maxlength="12"
                        class="inp_space70"
                        @input="
                          checkValidationCharger('bizPicTelOfcNo', 'KEYUP')
                        "
                      >
                      </e-input-number>
                    </td>
                  </tr>
                  <tr>
                    <th>FAX</th>
                    <td class="position_relative">
                      <e-input-number
                        id="bizPicFaxPlcNo"
                        v-model="regParams.bizPicFaxPlcNo"
                        :is-phone="true"
                        maxlength="4"
                        :disabled="userHkSg === 'Y'"
                        class="col_3"
                        @input="
                          checkValidationCharger('bizPicFaxPlcNo', 'KEYUP')
                        "
                      >
                      </e-input-number>
                      <e-input-number
                        id="bizPicFaxOfcNo"
                        v-model="regParams.bizPicFaxOfcNo"
                        :is-phone="true"
                        maxlength="12"
                        class="inp_space70"
                        @input="
                          checkValidationCharger('bizPicFaxOfcNo', 'KEYUP')
                        "
                      >
                      </e-input-number>
                    </td>
                  </tr>
                  <tr>
                    <th>{{ $t("msg.CSBK100.149") }}</th>
                    <!-- 업무담당자 email 수신처에 2인 이상 입력 시 콤마(,)... -->
                    <td class="position_relative">
                      <e-input-number
                        id="bizPicMbpBsnNo"
                        v-model="regParams.bizPicMbpBsnNo"
                        :is-phone="true"
                        maxlength="4"
                        :disabled="userHkSg === 'Y'"
                        class="col_3"
                        @input="
                          checkValidationCharger('bizPicMbpBsnNo', 'KEYUP')
                        "
                      >
                      </e-input-number>
                      <e-input-number
                        id="bizPicMbpOfcNo"
                        class="inp_space70"
                        v-model="regParams.bizPicMbpOfcNo"
                        :is-phone="true"
                        maxlength="12"
                        @input="
                          checkValidationCharger('bizPicMbpOfcNo', 'KEYUP')
                        "
                      >
                      </e-input-number>
                    </td>
                  </tr>
                  <tr>
                    <th>email</th>
                    <td>
                      <input
                        id="bizPicEmlAddr"
                        v-model="regParams.bizPicEmlAddr"
                        type="text"
                        maxlength="100"
                        :placeholder="$t('msg.CSBK100.152')"
                        class="inp_98"
                        @blur="checkValidationCharger('bizPicEmlAddr', 'KEYUP')"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- left // -->

          <div class="btn_col">
            <button class="tbl_icon arrow" @click="changeDocInfo">arrow</button>
          </div>

          <div class="ly_column right_col col_5">
            <!-- right -->
            <div class="ly_col_cont">
              <h2 class="content_title">
                {{ $t("msg.CSBK100.125") }}
                <!-- 서류 담당자 -->
              </h2>
              <table class="tbl_row tbl_bkg_charger">
                <colgroup>
                  <col style="width: 130px" />
                  <col style="width: auto" />
                </colgroup>
                <tbody>
                  <tr>
                    <th>{{ $t("msg.CSBK100.126") }}</th>
                    <!-- 성함 -->
                    <td class="position_relative">
                      <select
                        v-model="docPicNmh"
                        id="docPicNmh"
                        class="col_3"
                        @change="changeDocPicNmh"
                      >
                        <option value="">
                          {{ this.$t("msg.CSBK100.050") }}
                        </option>
                        <option
                          v-for="vo in cstInfoList"
                          :key="vo.picNo"
                          :value="vo.picNo"
                        >
                          {{ vo.picNm }}
                        </option>
                      </select>

                      <input
                        id="docPicNm"
                        class="inp_space70"
                        v-model="regParams.docPicNm"
                        type="text"
                        maxlength="30"
                        @keyup="checkValidationCharger('docPicNm', 'KEYUP')"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>{{ $t("msg.CSBK100.148") }}</th>
                    <!-- 전화번호 -->
                    <td class="position_relative">
                      <e-input-number
                        id="docPicTelPlcNo"
                        class="col_3"
                        v-model="regParams.docPicTelPlcNo"
                        :is-phone="true"
                        maxlength="4"
                        :disabled="userHkSg === 'Y'"
                        @input="
                          checkValidationCharger('docPicTelPlcNo', 'KEYUP')
                        "
                      >
                      </e-input-number>

                      <e-input-number
                        id="docPicTelNo"
                        class="inp_space70"
                        v-model="regParams.docPicTelNo"
                        :is-phone="true"
                        maxlength="12"
                        @input="checkValidationCharger('docPicTelNo', 'KEYUP')"
                      >
                      </e-input-number>
                    </td>
                  </tr>
                  <tr>
                    <th>FAX</th>
                    <td class="position_relative">
                      <e-input-number
                        id="docPicFaxPlcNo"
                        class="col_3"
                        v-model="regParams.docPicFaxPlcNo"
                        :is-phone="true"
                        maxlength="4"
                        :disabled="userHkSg === 'Y'"
                        @input="
                          checkValidationCharger('docPicFaxPlcNo', 'KEYUP')
                        "
                      >
                      </e-input-number>

                      <e-input-number
                        id="docPicFaxNo"
                        class="inp_space70"
                        v-model="regParams.docPicFaxNo"
                        :is-phone="true"
                        maxlength="12"
                        @input="checkValidationCharger('docPicFaxNo', 'KEYUP')"
                      >
                      </e-input-number>
                    </td>
                  </tr>
                  <tr>
                    <th>{{ $t("msg.CSBK100.149") }}</th>
                    <!-- 휴대전화번호 -->
                    <td>
                      <e-input-number
                        id="docPicMbpBsnNo"
                        class="col_3"
                        v-model="regParams.docPicMbpBsnNo"
                        :is-phone="true"
                        maxlength="4"
                        :disabled="userHkSg === 'Y'"
                        @input="
                          checkValidationCharger('docPicMbpBsnNo', 'KEYUP')
                        "
                      >
                      </e-input-number>

                      <e-input-number
                        id="docPicMbpNo"
                        class="inp_space70"
                        v-model="regParams.docPicMbpNo"
                        :is-phone="true"
                        maxlength="12"
                        @input="checkValidationCharger('docPicMbpNo', 'KEYUP')"
                      >
                      </e-input-number>
                    </td>
                  </tr>
                  <tr>
                    <th>email</th>
                    <td>
                      <input
                        id="docPicEmlAddr"
                        v-model="regParams.docPicEmlAddr"
                        type="text"
                        :placeholder="$t('msg.CSBK100.152')"
                        maxlength="100"
                        class="inp_98"
                        @blur="checkValidationCharger('docPicEmlAddr', 'KEYUP')"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- right // -->
        </div>
        <!-- 좌우 분할 중앙버튼 // -->
        <p class="txt_desc">
          {{ $t("msg.CSBK100.150") }} <br>
          <span v-if="memberDetail.userCtrCd === 'JP'">
            着信通知メールはプロフィール設定を参照してください。(https://www.ekmtc.com/index.html#/my-info/setting/profile)
          </span>
        </p>
        <div class="flex_box mt20">
          <h2 class="content_title" v-if="regParams.mtyPkupUsagYn !== 'Y'">
            {{ $t("msg.CSBK100.151") }}
            <!-- 스케줄 -->
          </h2>
          <div class="sc_box scd_btn_area">
            <span v-show="isPkupUsagYn">
              <input
                id="mtyPkupUsagYn"
                v-model="regParams.mtyPkupUsagYn"
                type="checkbox"
                true-value="Y"
                false-value="N"
                @click="checkMtyPkupUsagYn"
                :disabled="saveFlag === 'EDIT'"
              /><label for="mtyPkupUsagYn">Pick Up Booking</label>
            </span>
            <span
              class="ml_auto"
              v-if="regParams.mtyPkupUsagYn !== 'Y' && schdBtnYn !== 'N'"
            >
              <!--이미 선택된 스케줄이 있는 경우 : 버튼이 [스케줄 변경] 버튼으로 바뀌어서 노출됨(클래스 blue를 gray로 변경)-->
              <a
                :class="setScheduleBtnClass()"
                @click.prevent="searchSchedule"
              >{{
                $ekmtcCommon.isEmpty(regParams.hidCS008I)
                  ? $t("msg.CSBK100.153")
                  : $t("msg.CSBK100.154")
              }}</a>
            </span>
          </div>
        </div>

        <booking-schedule-info
          v-if="regParams.mtyPkupUsagYn !== 'Y'"
          ref="schdInfo"
          :leg-trml-fixd-yn="regParams.legTrmlFixdYn"
          :leg-fix-show="isLegFixShow"
          :bkg-no="regParams.bkgNo"
          :disabled-yn="disabledYn"
          :is-url="urlOrNot"
        />

        <div class="mt20" v-if="regParams.mtyPkupUsagYn !== 'Y'">
          <h2 class="content_title">
            {{ $t("msg.CSBK100.158") }}
          </h2>
          <table class="tbl_row">
            <colgroup>
              <col width="130" />
              <col />
              <col width="130" />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <th>{{ $t("msg.CSBK100.011") }}</th>
                <!-- 실화주 -->
                <td>
                  <e-auto-complete-cst-enm2
                    :ctr-cd="usrCtrCd"
                    :cd-nm="regParams.actShprCstEnm"
                    class="col_5 auto-area position_relative"
                    id="actShprCstEnm"
                    :disabled="regParams.consYn === 'Y'"
                    @change="changeActShprCstENm"
                    @input="checkValidationCargoInfo('actShprCstEnm')"
                    @blur="consReuseChk()"
                  />
                  <!-- <e-auto-complete-cst-enm
                    v-if="lang !== 'KOR'"
                    :ctr-cd="usrCtrCd"
                    :cd-nm="regParams.actShprCstEnm"
                    class="col_6 auto-area position_relative"
                    id="actShprCstEnm"
                    :disabled="regParams.consYn === 'Y'"
                    @change="changeActShprCstENm"
                    @input="checkValidationCargoInfo('actShprCstEnm')"
                  /> -->
                  <span v-if="lang === 'KOR'" class="float_right">
                    <input
                      id="c2"
                      type="checkbox"
                      v-model="regParams.reuseYn"
                      true-value="Y"
                      false-value="N"
                      @click="changeReuseYn"
                    />&nbsp;&nbsp;
                    <label for="c2"><span />복화사용</label>
                  </span>
                  <span v-if="lang === 'KOR'" class="float_right">
                    <input
                      id="c1"
                      type="checkbox"
                      v-model="regParams.consYn"
                      true-value="Y"
                      false-value="N"
                      @click="changeConsYn"
                    />
                    <label for="c1"><span />{{ $t("msg.CSBK100.159") }}</label>
                  </span>
                </td>
                <th>Cargo Weight</th>
                <td>
                  <span class="wg">
                    <e-input-number
                      id="grsWtTon"
                      v-model="grsWtTon"
                      :is-comma="true"
                      :input-class="'wid80'"
                      :disabled="isKg"
                      :point="3"
                      maxlength="23"
                      @input="changeGrsWt($event, 'TON')"
                    >
                    </e-input-number>
                    ton
                  </span>
                  <span class="color_gray span_grs">/</span>
                  <span class="wg">
                    <e-input-number
                      v-model="grsWtKg"
                      :is-comma="true"
                      id="grsWtKg"
                      :input-class="'wid80'"
                      :disabled="isTon"
                      maxlength="30"
                      @input="changeGrsWt($event, 'KG')"
                    >
                    </e-input-number>
                    kg
                  </span>
                </td>
              </tr>
              <tr>
                <th scope="row">Ocean Freight</th>
                <td>
                  <input
                    type="radio"
                    id="frtPncCd_1"
                    v-model="regParams.frtPncCd"
                    value="P"
                    @click="changeFrtPncCd($event)"
                  />
                  <label for="frtPncCd_1">PREPAID</label>
                  <input
                    type="radio"
                    id="frtPncCd_2"
                    v-model="regParams.frtPncCd"
                    value="C"
                    @click="changeFrtPncCd($event)"
                  />
                  <label for="frtPncCd_2">COLLECT</label>
                </td>
                <th scope="row">SC No.</th>
                <td>
                  <input
                    type="text"
                    maxlength="30"
                    id="cneCstEnm"
                    v-model="regParams.cneCstEnm"
                    :disabled="regParams.frtPncCd !== 'C'"
                    :placeholder="
                      regParams.frtPncCd !== 'C' ? 'For COLLECT term only' : ''
                    "
                    @keyup="checkValidationCargoInfo('cneCstEnm')"
                    @input="
                      regParams.cneCstEnm = regParams.cneCstEnm.toUpperCase()
                    "
                    @blur="findIotCntrYn('S')"
                  />
                </td>
              </tr>
              <tr>
                <th>Commodity</th>
                <td>
                  <e-auto-complete-commodity
                    :ctr-cd="usrCtrCd"
                    :cd-nm="regParams.cmdtDsc"
                    :disabled="regParams.emptyFlag === 'E'"
                    class="col_5 auto-area position_relative"
                    id="cmdtDsc"
                    @change="changeCmdtDsc"
                    @input="checkValidationCargoInfo('cmdtDsc')"
                    :focus-force="true"
                  />
                  <span class="float_right">
                    <input
                      v-if="['SG','MY'].includes(memberDetail.userCtrCd)"
                      id="udab"
                      v-model="regParams.udab"
                      type="checkbox"
                      true-value="Y"
                      false-value="N"
                    />
                    <label
                      for="udab"
                      v-if="['SG','MY'].includes(memberDetail.userCtrCd)"
                    >
                      UDAB
                    </label>
                    <input
                      id="emptyFlag"
                      v-model="regParams.emptyFlag"
                      type="checkbox"
                      true-value="E"
                      false-value="F"
                      @click="changeEmptyFlag"
                      :disabled="isDisabledEmptyFlag()"
                    /><label for="emptyFlag">{{ $t("msg.CSBK100.072") }}</label>
                  </span>
                </td>
                <th>Term</th>
                <td v-if="cstCatCd !== 'Y'">
                  <!-- SOC 화주 아닐때  -->
                  <select
                    v-model="regParams.polShtmCd"
                    id="polShtmCd"
                    @click="clickPolShtm"
                    @change="changePolShtm"
                    :disabled="usrCtrCd === 'VN'"
                    class="wid120"
                  >
                    <option value="01">CY</option>
                    <option value="02">CFS</option>
                  </select>
                  <select
                    id="podShtmCd"
                    v-model="regParams.podShtmCd"
                    class="wid120 d_inline_block"
                    disabled
                  >
                    <option value="01">CY</option>
                    <option value="02">CFS</option>
                    <option value="03">{{ "CY&CFS" }}</option>
                  </select>
                </td>
                <td v-if="cstCatCd === 'Y'">
                  <!-- SOC 화주  -->
                  <select
                    v-model="regParams.polShtmCd"
                    id="polShtmCd"
                    :disabled="usrCtrCd === 'VN'"
                    class="wid120"
                  >
                    <option value="01">CY</option>
                    <option value="05">Tackle</option>
                    <option value="06">FI</option>
                  </select>
                  <select
                    id="podShtmCd"
                    v-model="regParams.podShtmCd"
                    class="wid120 d_inline_block"
                    disabled
                  >
                    <option value="01">CY</option>
                    <option value="05">Tackle</option>
                    <option value="07">FO</option>
                    <option value="09">T/S Zone</option>
                  </select>
                </td>
              </tr>
              <tr v-show="regParams.chemicalUploadYn === 'Y'">
                <th class="bor-left" scope="row">{{ $t('msg.NEWB020G010.022') }}<!-- Chemical or Not --></th>
                <td colspan="3">
                  <input type="radio" id="chemicalY" v-model="regParams.chemicalYn" name="chemicalY" value="Y" true-value="Y" false-value="N" @click="toolTipHide('chemicalY', 'Y')" />
                  <label for="chemicalY" class="mr10"><span></span>Y</label>
                  <input type="radio" id="chemicalN" v-model="regParams.chemicalYn" name="chemicalN" value="N" true-value="N" false-value="Y" @click="toolTipHide('chemicalY', 'N')" />
                  <label for="chemicalN" class="mr10"><span></span>N</label>
                  &nbsp;
                  <button v-show="regParams.chemicalYn === 'Y'" type="button" :class="isChemicalFileUpload() ? 'blue' : 'gray'" class="button sm" @click="openUploadPopup('chemical')">
                    {{ $t('msg.CSBK100.205') }} <!-- 파일첨부/확인 -->
                  </button>
                </td>
              </tr>
              <tr v-if="usrCtrCd === 'PH'">
                <th>Haulier</th>
                <td>
                  <input
                    v-model="regParams.tfcoCstNm"
                    type="text"
                    class="col_8"
                    maxlength="50"
                  />
                </td>
              </tr>
              <tr v-if="usrCtrCd === 'IN'">
                <th>PDA</th>
                <td>
                  <input
                    type="radio"
                    id="dpstAcCd_1"
                    v-model="regParams.dpstAcCd"
                    value="01"
                  />
                  <label for="dpstAcCd_1">shipper's</label>
                  <input
                    type="radio"
                    id="dpstAcCd_2"
                    v-model="regParams.dpstAcCd"
                    value="02"
                  />
                  <label for="dpstAcCd_2">carrier's</label>
                </td>
                <th>place of stuffing</th>
                <td>
                  <input
                    type="radio"
                    id="stuffPlceCd_1"
                    v-model="regParams.stuffPlceCd"
                    value="01"
                  />
                  <label for="stuffPlceCd_1">factroy</label>
                  <input
                    type="radio"
                    id="stuffPlceCd_2"
                    v-model="regParams.stuffPlceCd"
                    value="02"
                  />
                  <label for="stuffPlceCd_2">cfs(ICD)</label>
                </td>
              </tr>
              <tr v-if="lang === 'JPN' && fwdrCstCd === '01'">
                <th>Booking Shipper</th>
                <td>
                  <select
                    v-model="regParams.bkgShprCstNo"
                    @change="changeBkgShprCstNo"
                  >
                    <option value="">{{ $t("msg.CSBK100.050") }}</option>
                    <option
                      v-for="vo in jpBkgShprList"
                      :key="vo.bkgShprCstNo"
                      :value="vo.bkgShprCstNo"
                    >
                      {{ vo.bkgShprCstEnm }}
                    </option>
                  </select>
                  <input
                    v-model="regParams.bkgShprCstEnm"
                    type="text"
                    class="txt_jp"
                    maxlength="50"
                  />
                  <span class="jp_notice">{{ $t("msg.CSBK100.503") }}</span>
                </td>
                <th>通関業者</th>
                <td class="vat">
                  <select
                    v-model="regParams.fwdrCstNo"
                    @change="changeFwdrCstNo"
                  >
                    <option value="">{{ $t("msg.CSBK100.050") }}</option>
                    <option
                      v-for="vo in jpFwdrList"
                      :key="vo.fwdrCstNo"
                      :value="vo.fwdrCstNo"
                    >
                      {{ vo.fwdrCstEnm }}
                    </option>
                  </select>
                  <input
                    v-model="regParams.fwdrCstEnm"
                    type="text"
                    class="txt_jp"
                    maxlength="50"
                  />
                </td>
              </tr>
              <tr v-if="lang === 'JPN' && fwdrCstCd !== '01'">
                <th>通関業者</th>
                <td colspan="3">
                  <select
                    v-model="regParams.fwdrCstNo"
                    @change="changeFwdrCstNo"
                    class="col_3"
                  >
                    <option value="">{{ $t("msg.CSBK100.050") }}</option>
                    <option
                      v-for="vo in jpFwdrList"
                      :key="vo.fwdrCstNo"
                      :value="vo.fwdrCstNo"
                    >
                      {{ vo.fwdrCstEnm }}
                    </option>
                  </select>
                  <input
                    v-model="regParams.fwdrCstEnm"
                    type="text"
                    class="col_3"
                    maxlength="50"
                  />
                </td>
              </tr>
              <template v-if="regParams.polCtrCd == 'MY'">
                <tr>
                  <th scope="row">HS CODE</th>
                  <td>
                    <div class="tbl_form">
                      <input
                        type="text"
                        v-model="regParams.hsCd"
                        id="my_hsCd"
                        maxlength="10"
                        style="width:272px;"
                        @blur="myHsCdInputCheck()"
                        @input="checkMyValidation()"
                      />
                    </div>
                  </td>
                  <th>
                    Bidding No.<span class="txt">({{ $t("msg.CSBK100.062") }})</span>
                  </th>
                  <td>
                    <input
                      v-model="regParams.cstBidgMgrNo"
                      type="text"
                      maxlength="10"
                      @change="checkBidgNo"
                    />
                  </td>
                </tr>
                <tr>
                  <td class="border_left_none">
                    <input
                      id="chk4"
                      v-model="regParams.rtnCgoYn"
                      type="checkbox"
                      true-value="Y"
                      false-value="N"
                      @click="checkRtnCgoYn()"
                    />
                    <label for="chk4">{{ $t("msg.CSBK100.160") }}</label>
                  </td>
                  <td class="border_left_none">
                    <input
                      type="text"
                      v-model="regParams.impBlNo"
                      :disabled="regParams.rtnCgoYn !== 'Y'"
                      :placeholder="this.$t('msg.CSBK100.123')"
                    />
                  </td>
                  <td colspan="2"></td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td class="border_left_none">
                    <input
                      id="chk4"
                      v-model="regParams.rtnCgoYn"
                      type="checkbox"
                      true-value="Y"
                      false-value="N"
                      @click="checkRtnCgoYn()"
                    />
                    <label for="chk4">{{ $t("msg.CSBK100.160") }}</label>
                  </td>
                  <td class="border_left_none">
                    <input
                      type="text"
                      v-model="regParams.impBlNo"
                      :disabled="regParams.rtnCgoYn !== 'Y'"
                      :placeholder="this.$t('msg.CSBK100.123')"
                    />
                  </td>
                  <th>
                    Bidding No.<span class="txt">({{ $t("msg.CSBK100.062") }})</span>
                  </th>
                  <td>
                    <input
                      v-model="regParams.cstBidgMgrNo"
                      type="text"
                      maxlength="10"
                      @change="checkBidgNo"
                    />
                  </td>
                </tr>
              </template>
              <tr>
                <th scope="row">Etc File Upload</th>
                <td colspan="3">
                  <div class="filebox">
                    <a
                      @click="etcDocUploadPopup()"
                      :class="
                        etcDeleteAllYn === 'N'
                          ? 'button sm gray file'
                          : 'button sm blue'
                      "
                    >
                      {{ $t("msg.CSBK100.205") }}
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <table v-if="regParams.polPortCd == 'PGU'" class="tbl_row mt10">
            <colgroup>
              <col width="130px" />
              <col width="*" />
            </colgroup>
            <tbody>
              <tr>
                <th>Forwading Code</th>
                <td>
                  <input
                    id="fwdEdiCd"
                    v-model="regParams.fwdEdiCd"
                    type="text"
                    class="col_5"
                    maxlength="4"
                    @keyup="checkValidationCargoInfo('fwdEdiCd')"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <!-- <div class="desc_list" v-show="serviceLang !== 'JPN'">
            <ul>
              <li>{{ this.$t("msg.CSBK100.127") }}</li>
            </ul>
          </div> -->
        </div>
        <div
          class="mt20"
          v-if="
            regParams.polShtmCd === '01' &&
              lang === 'KOR' &&
              cstCatCd !== 'Y' &&
              regParams.mtyPkupUsagYn !== 'Y'
          "
        >
          <h2 class="content_title">
            {{ $t("msg.CSBK100.042") }}
          </h2>
          <table class="tbl_col tbl_owtr_info">
            <colgroup>
              <col style="width: 8%" />
              <col style="width: 12%" />
              <col style="width: 15%" />
              <col style="width: 20%" />
              <col style="width: auto" />
            </colgroup>
            <tbody>
              <tr>
                <th class="text_center">{{ $t("msg.CSBK100.043") }}</th>
                <!-- 운송구분  -->
                <th class="text_center">{{ $t("msg.CSBK100.044") }}</th>
                <!-- 운송사 -->
                <th class="text_center">{{ $t("msg.CSBK100.066") }}</th>
                <!-- 공장지역 -->
                <th class="text_center">{{ $t("msg.CSBK100.067") }}</th>
                <!-- 공장명 -->
                <th class="text_center">
                  {{ $t("msg.CSBK100.046") }}/{{ $t("msg.CSBK100.161") }}/email
                </th>
                <!-- 담당/연락처/email -->
              </tr>
              <tr>
                <td class="border_left_none full">
                  <select
                    v-model="regParams.owtrYn"
                    @click="checkOwtrYn"
                    @change="changeOwtrYn"
                  >
                    <option value="N">{{ $t("msg.CSBK100.047") }}</option>
                    <!-- Line -->
                    <option value="Y">{{ $t("msg.CSBK100.048") }}</option>
                    <!-- 자가 -->
                  </select>
                </td>
                <td>
                  <div class="search_btn text_left">
                    <input
                      v-model="regParams.tfcoCstNm"
                      id="tfcoCstNm"
                      type="text"
                      class="inp_responsive_space7"
                      readonly
                    />
                    <!--자가시에만 노출-->
                    <button
                      v-if="regParams.owtrYn === 'Y'"
                      id="btn_tfcoCstNmSrch"
                      class="search_btn01 ml2"
                      @click="transCompanySearch"
                    />
                    <!--자가시에만 노출-->
                  </div>
                </td>
                <td>
                  <select
                    v-if="regParams.owtrYn === 'N'"
                    v-model="regParams.zip1"
                    id="zip1"
                    class="inp_responsive_space1"
                    @change="changeZip1"
                    @click="checkZip1"
                  >
                    <option value="" />
                    <option
                      v-for="zvo in zip1List"
                      :key="zvo.region"
                      :value="zvo.region"
                    >
                      {{ zvo.region }}
                    </option>
                  </select>
                  <input
                    v-if="regParams.owtrYn === 'N'"
                    v-model="regParams.zip2"
                    type="text"
                    maxlength="30"
                    class="inp_responsive_space1"
                  />
                </td>
                <td class="position_relative">
                  <select
                    v-if="regParams.owtrYn === 'N'"
                    v-model="cboFactory"
                    class="inp_responsive_space1"
                    @change="changeFactoryInfo"
                  >
                    <option value="">{{ this.$t("msg.CSBK100.050") }}</option>
                    <!-- 직접입력 -->
                    <option
                      v-for="(vo, i) in factoryInfoList"
                      :key="i"
                      :value="vo.facPicNm + '$' + vo.facCtfcNo + '$' + vo.facNm"
                    >
                      {{ vo.facNm }}
                    </option>
                  </select>
                  <span class="position_relative ml2">
                    <input
                      v-if="regParams.owtrYn === 'N'"
                      v-model="regParams.facNm"
                      type="text"
                      maxlength="20"
                      class="inp_responsive_space1"
                    />
                  </span>
                </td>
                <td class="text_left">
                  <div class="d_inline_block">
                    <input
                      v-if="regParams.owtrYn === 'N'"
                      id="facPicNm"
                      v-model="regParams.facPicNm"
                      type="text"
                      :placeholder="$t('msg.CSBK100.126')"
                      class="wid50"
                      maxlength="20"
                      @keyup="checkValidationTrans('facPicNm')"
                    />
                    <input
                      v-if="regParams.owtrYn === 'N'"
                      id="facCtfcNo"
                      v-model="regParams.facCtfcNo"
                      type="text"
                      :placeholder="$t('msg.CSBK100.161')"
                      class="wid130"
                      maxlength="20"
                      @keyup="checkValidationTrans('facCtfcNo')"
                    />
                    <input
                      v-if="regParams.owtrYn === 'N'"
                      id="facPicEmlAddr"
                      v-model="regParams.facPicEmlAddr"
                      type="text"
                      placeholder="email"
                      maxlength="20"
                      class="wid130 ml2"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th colspan="2" class="text_left">
                  <span class="span_rmk">{{ $t("msg.CSBK100.162") }}</span>
                  <!-- 운송 Remark -->
                </th>
                <td colspan="3">
                  <div class="position_relative">
                    <div style="margin-right: 150px">
                      <input
                        id="docmRmk"
                        v-model="regParams.dorOdrRmk"
                        type="text"
                        maxlength="100"
                        :placeholder="this.$t('msg.CSBK100.122')"
                        :readonly="'IN|PK|BD|RU|IR|LK|AE'.indexOf(regParams.podCtrCd) > -1 ? true:false"
                      />
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <a
          class="button sm mt5"
          v-if="memberDetail.userCtrCd === 'PH' || regParams.polCtrCd === 'PH'"
          @click.prevent="templateDownloadForPH"
        >
          {{ $t('msg.ONEX050G020.021') }}
        </a>
        <div class="flex_box mt20">
          <h2 class="content_title">
            {{ $t("msg.CSBK100.040") }}
            <!-- Container Information -->
          </h2>
          <!-- UO640-->
          <!-- DEM/DET 계산기 -->
          <span class="ml_auto">
            <a
              class="button sm"
              v-show="usrCtrCd === 'JP' && dgCargoYn === 'Y'"
              href="/pdf/eKMTC_DG_Application-JP.pdf"
              download
            >How to input DG application
            </a>
            <a
              class="button sm"
              :class="{ disabled: isDetCombined }"
              @click="demDetInfoPop"
            >
              <span class="btn_icon calculator" />{{ $t("msg.CSBK100.163") }}
            </a>
            <!-- DEM/DET 계산기 -->
          </span>
        </div>
        <table class="tbl_col">
          <colgroup>
            <col style="width: 10%" />
            <col style="width: 10%" />
            <col style="width: 10%" />
            <col style="width: 10.5%" />
            <col style="width: 16%" />
            <col style="width: 10%" />
            <col style="width: auto" />
            <col style="width: 9.5%" />
          </colgroup>
          <thead id="containerHeader">
            <tr>
              <th colspan="4">CONTAINER</th>
              <th rowspan="2">{{ $t("msg.CSBK100.068") }}</th>
              <!-- 특수화물 구분 -->
              <th v-if="regParams.polShtmCd !== '02'" rowspan="2">
                {{ $t("msg.CSBK100.069") }}
              </th>
              <!-- pick up 일시 -->
              <th v-if="regParams.polShtmCd !== '02'" rowspan="2">
                {{ $t("msg.CSBK100.070") }}
              </th>
              <!-- pick up 장소 -->
              <th v-if="regParams.polShtmCd === '02'" rowspan="2">
                {{ $t("msg.CSBK100.073") }}
              </th>
              <th
                v-if="regParams.polShtmCd === '02' && lang === 'KOR'"
                rowspan="2"
              >
                {{ $t("msg.CSBK100.164") }}<br />{{ $t("msg.CSBK100.164_1") }}
              </th>
              <!-- 쇼링 업체<br>(쇼링업체명 기재) -->
              <th
                v-if="regParams.polShtmCd === '02' && lang !== 'KOR'"
                rowspan="2"
              ></th>
              <th rowspan="2">
                <button class="tbl_icon plus" @click="addContainer">
                  plus
                </button>
              </th>
            </tr>
            <tr>
              <th>Size</th>
              <th>
                Type
                <button
                  class="tbl_icon help ml5"
                  type="button"
                  @click="openPopup('container-pop')"
                >
                  <span class="offscreen">도움말</span>
                </button>
              </th>
              <th>Q'ty</th>
              <th>{{ $t("msg.CSBK100.071") }}</th>
              <!-- 화주<br>컨테이너 -->
            </tr>
          </thead>
          <tbody
            v-for="(vo, idx) in _containerList"
            :key="'tr_container_' + idx"
          >
            <tr>
              <td>
                <select
                  :value="vo.cntrSzCd"
                  :id="'cntrSzCd_' + idx"
                  @change="changeCntrSz(vo, idx, $event)"
                >
                  <option
                    v-for="option in cntSzList"
                    :key="option.cd"
                    :value="option.cd"
                  >
                    {{ option.cdNm }}
                  </option>
                </select>
              </td>
              <td>
                <select
                  :value="vo.cntrTypCd"
                  :id="'cntrTypCd_' + idx"
                  @change="changeCntrTyp(vo, idx, $event)"
                  @click="checkCntrTyp"
                >
                  <option value="">---------------------</option>
                  <option
                    v-for="option in vo.subCntrTypList"
                    :key="option.cd"
                    :value="option.cd"
                  >
                    {{ option.cdNm }}
                  </option>
                </select>
              </td>
              <td>
                <e-input-number
                  :value="vo.cntrQty"
                  :id="'cntrQty_' + idx"
                  @input="checkValidationCntr('cntrQty', idx, 'KEYUP', ...arguments)"
                  maxlength="3"
                >
                </e-input-number>
              </td>
              <td>
                <input
                  :checked="vo.shprVanYn === 'Y' ? true : false"
                  :id="'shprVanYn_' + idx"
                  type="checkbox"
                  :disabled="regParams.polShtmCd === '02'"
                  true-value="Y"
                  false-value="N"
                  @click="checkShprVanYn($event, vo, idx)"
                />
                <label :for="'shprVanYn_' + idx"><span class="offscreen">화주 컨테이너 체크</span></label>
              </td>
              <td>
                <div>
                  <select
                    :value="vo.cgoTypCd"
                    :id="'cgoTypCd_' + idx"
                    :class="isRfDg(vo) ? 'col_85' : ''"
                    @change="changeCgoTyp(vo, idx, $event)"
                  >
                    <option v-if="isDefaultCgoValue(vo)" value="">
                      ---------------------------------------
                    </option>
                    <option
                      v-for="option in vo.subCgoTypList"
                      :key="option.cd"
                      :value="option.cd"
                    >
                      {{ option.cdNm }}
                    </option>
                  </select>
                  <a
                    v-if="isRfDg(vo)"
                    class="btn_rf_entry float_right"
                    @click="rfDgRequestPop(idx)"
                  >
                    {{ $t("msg.CSBL200.258") }}
                  </a>
                </div>
                <div
                  v-if="$ekmtcCommon.isNotEmpty(vo.apvNo)"
                  class="danger_num"
                >
                  <div>{{ $t("msg.CSBK100.165") }}</div>
                  <div class="color_red">
                    {{ vo.apvNo }}
                  </div>
                </div>
              </td>
              <td>
                <div class="input_calendar">
                  <e-date-picker
                    v-if="cstCatCd !== 'Y'"
                    :value="vo.pcupReqDt"
                    :id="'pcupReqDt_' + idx"
                    @input="
                      checkValidationCntr(
                        'pcupReqDt',
                        idx,
                        'KEYUP',
                        ...arguments
                      )
                    "
                    :disabled="
                      (vo.shprVanYn === 'Y' && regParams.owtrYn === 'Y') ||
                        usrCtrCd === 'PH' ||
                        pcupDtDisabled
                    "
                  >
                  </e-date-picker>
                  <select
                    class="mt5 col_8"
                    v-if="lang !== 'KOR' && cstCatCd !== 'Y'"
                    :id="'pcupReqTm_' + idx"
                    v-model="vo.pcupReqTm"
                    :disabled="vo.shprVanYn === 'Y' && regParams.owtrYn === 'Y'"
                  >
                    <option value="09">09:00</option>
                    <option value="10">10:00</option>
                    <option value="11">11:00</option>
                    <option value="12">12:00</option>
                    <option value="13">13:00</option>
                    <option value="14">14:00</option>
                    <option value="15">15:00</option>
                    <option value="16">16:00</option>
                    <option value="17">17:00</option>
                  </select>
                </div>
              </td>
              <td>
                <div v-if="cstCatCd !== 'Y'">
                  <!-- SOC 화주가 아닐때 -->
                  <div v-if="lang !== 'KOR' && regParams.polShtmCd === '01'">
                    <p>
                      <input
                        :value="vo.pickUpPlace"
                        :id="'pcupCyCd_' + idx"
                        type="text"
                        :placeholder="$t('msg.CSBK100.381')"
                        readonly
                        :disabled="
                          vo.shprVanYn === 'Y' && regParams.owtrYn === 'Y'
                        "
                        @click="getPickupPlace(idx)"
                      />
                    </p>
                  </div>
                  <div
                    v-if="lang === 'KOR' && regParams.polShtmCd === '01'"
                    class="position_relative"
                  >
                    <p>
                      <input
                        :value="vo.pickUpPlace"
                        :id="'pcupCyCd_' + idx"
                        type="text"
                        :placeholder="$t('msg.CSBK100.381')"
                        readonly
                        :disabled="
                          vo.shprVanYn === 'Y' && regParams.owtrYn === 'Y'
                        "
                        @click="getPickupPlace(idx)"
                      />
                    </p>
                    <p style="margin: 5px 0">
                      <input
                        v-model="vo.pickUpMan"
                        :id="'pickUpMan_' + idx"
                        type="text"
                        readonly
                        :disabled="
                          vo.shprVanYn === 'Y' && regParams.owtrYn === 'Y'
                        "
                        :placeholder="$t('msg.CSBK100.129')"
                      />
                    </p>
                    <p>
                      <input
                        v-model="vo.pickUpTel"
                        :id="'pickUpTel_' + idx"
                        type="text"
                        readonly
                        :disabled="
                          vo.shprVanYn === 'Y' && regParams.owtrYn === 'Y'
                        "
                        :placeholder="$t('msg.CSBK100.161')"
                      />
                    </p>
                  </div>
                  <div
                    v-if="
                      lang === 'KOR' &&
                        regParams.polShtmCd !== '01' &&
                        idx === 0
                    "
                  >
                    <select
                      v-model="regParams.cfsFixdTypCd"
                      :id="'cfsFixdTypCd_' + idx"
                      class="col_4"
                      @change="changeCfsFixdType"
                    >
                      <option value="01">{{ $t("msg.CSBK100.047") }}</option>
                      <!-- Line -->
                      <option value="02">{{ $t("msg.CSBK100.048") }}</option>
                      <!-- 자가 -->
                      <option value="03">{{ $t("msg.CSBK100.166") }}</option>
                      <!-- 쇼링없음 -->
                    </select>
                    <input
                      v-model="regParams.cfsFixdEntrNm"
                      :id="'cfsFixdEntrNm_' + idx"
                      type="text"
                      class="col_5"
                      :readonly="regParams.cfsFixdTypCd !== '02'"
                      @keyup="
                        checkValidationCntr('cfsFixdEntrNm', idx, 'KEYUP')
                      "
                    />
                  </div>
                </div>
                <div v-if="cstCatCd === 'Y'">
                  <!-- SOC 화주일때 -->
                  <p>
                    <input
                      v-model="vo.pickUpPlace"
                      type="text"
                      :placeholder="$t('msg.CSBK100.381')"
                      readonly
                      @click="getPickupPlace(idx)"
                    />
                  </p>
                </div>
              </td>
              <td>
                <button
                  v-if="
                    (saveFlag === 'EDIT' && regParams.mtyPkupUsagYn !== 'Y') ||
                      $ekmtcCommon.isEmpty(vo.pcupReqDtOld)
                  "
                  class="tbl_icon minus"
                  @click="removeContainer(idx)"
                >
                  minus
                </button>
              </td>
            </tr>
            <tr v-if="vo.cntrTypCd === 'RF' || vo.cntrTypCd === 'RH'">
              <td class="text_left btn_responsive" colspan="2">
                <input
                  :id="'chk12_' + idx"
                  type="checkbox"
                  :checked="vo.chkNOR === 'Y' ? true : false"
                  :value="vo.chkNOR"
                  true-value="Y"
                  false-value="N"
                  @click="resetRfSetup($event, idx)"
                  @change="
                    setContainer(
                      'chkNOR',
                      $event.target.checked ? 'Y' : 'N',
                      idx
                    )
                  "
                /><label :for="'chk12_' + idx">NOR(Non Operating Reefer)</label>&nbsp;
                <span class="position_relative span_tooltip">
                  <button
                    class="tbl_icon help"
                    @click="$ekmtcCommon.showTooltip($event)"
                  />
                  <div class="tooltip_wrap short position_absolute">
                    <!-- tooltip_wrap   -->
                    <div class="cont">
                      <!-- cont -->
                      <button
                        type="button"
                        class="close"
                        @click="$ekmtcCommon.hideTooltip($event)"
                      >
                        close
                      </button>
                      <ul class="bul_list_sm t2">
                        <li>
                          {{ $t("msg.CSBK100.143") }}
                        </li>
                      </ul>
                    </div>
                    <!-- cont -->
                  </div>
                  <!-- tooltip_wrap // -->
                </span>
              </td>
              <th scope="row">{{ $t("msg.CMBK100_M1.025") }}</th>
              <td>
                <e-input-number
                  :id="'rfSetupTmpr_' + idx"
                  :value="vo.rfSetupTmpr"
                  :class="'col_4'"
                  :is-minus="true"
                  :disabled="vo.chkNOR === 'Y' ? true : false"
                  @input="checkValidationRF('rfSetupTmpr', idx, ...arguments)"
                >
                </e-input-number>
                <select
                  v-if="usrCtrCd !== 'KR' && vo.chkNOR !== 'Y'"
                  v-model="vo.rfTmprUnitCd"
                  class="col_5"
                  :disabled="vo.chkNOR === 'Y' ? true : false"
                  :id="'rfTmprUnitCd_' + idx"
                >
                  <option value="C">℃</option>
                  <option value="F">℉</option>
                </select>
                <span
                  v-if="usrCtrCd === 'KR' || vo.chkNOR === 'Y'"
                  class="d_inline"
                >℃</span>
              </td>
              <th scope="row">VENT</th>
              <td>
                <select
                  :value="vo.cntrRfStsCd"
                  :id="'cntrRfStsCd_' + idx"
                  :disabled="vo.chkNOR === 'Y' ? true : false"
                  @change="
                    checkValidationRF('cntrRfStsCd', idx, $event.target.value)
                  "
                >
                  <template v-if="regParams.polCtrCd === 'VN' && cntrRfStsYn === 'Y'">
                    <option
                      v-for="cvo in cntrRfStsListVN"
                      :key="cvo.cd"
                      :value="cvo.cd"
                    >
                      {{ cvo.cdNm }}
                    </option>
                  </template>
                  <template v-else>
                    <option
                      v-for="cvo in cntrRfStsList"
                      :key="cvo.cd"
                      :value="cvo.cd"
                    >
                      {{ cvo.cdNm }}
                    </option>
                  </template>
                </select>
              </td>
              <th scope="row">
                <span v-if="vo.iotCntrYn === 'Y' || vo.iotEqpmYn === 'Y'">
                  IoT Container&nbsp;
                  <button
                    class="tbl_icon help ml5"
                    type="button"
                    @click="openPopup('container-iot-pop')"
                  >
                  </button>
                </span>
              </th>
              <td>
                <input v-if="vo.iotCntrYn === 'Y' || vo. iotEqpmYn === 'Y'"
                       :id="'iotEqpmYn_' + idx"
                       type="checkbox"
                       :disabled="vo.iotCntrYn === 'Y' || vo. iotEqpmYn === 'Y' ? false : true"
                       :checked="vo.iotEqpmYn === 'Y' ? true : false"
                       true-value="Y"
                       false-value="N"
                       @change="
                         setContainer(
                           'iotEqpmYn',
                           $event.target.checked ? 'Y' : 'N',
                           idx
                         )"
                       @click="checkIotYn($event, vo, idx)"
                />
                <label :for="'iotEqpmYn_' + idx"></label>
              </td>
            </tr>
            <!-- 추가-->
            <tr v-if="(vo.cntrTypCd === 'RF' || vo.cntrTypCd === 'RH' ) && ['SG'].includes(memberDetail.userCtrCd)">
              <th>PRE-SET/COOL</th>
              <td>
                <select v-model="vo.rfPreSetCd">
                  <option value="">---------</option>
                  <option
                    v-for="cvo in preSetList"
                    :key="cvo.cd"
                    :value="cvo.cd"
                  >
                    {{ cvo.cdNm }}
                  </option>
                </select>
              </td>
              <th>Slot</th>
              <td>{{ Number(vo.pcupReqTm) > 11 ? 'PM' : 'AM' }}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr v-if="
              (vo.cntrTypCd === 'RF' || vo.cntrTypCd === 'RH') &&
                usrCtrCd === 'KR'
            "
            >
              <td colspan="6">
                <div class="select_opt_area">
                  <div class="desc_list">
                    <ul>
                      <li>{{ $t("msg.CSBK100.168_1") }}</li>
                      <li>{{ $t("msg.CSBK100.168_2") }}</li>
                      <li>{{ $t("msg.CSBK100.168_3") }}</li>
                      <li>
                        {{ $t("msg.CSBK100.168_4") }}
                        <a
                          class="text_decoration"
                          style="cursor: pointer"
                          href="/#/common/pds?messageIdSeq=CKR362&groupId=362"
                          target="_blank"
                        >{{ $t("msg.CSBK100.168_5") }}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </td>
              <td colspan="2">
                <a
                  v-if="lang === 'KOR'"
                  class="button sm gray"
                  @click="openRfHpmgSite(vo.rfHmpgUrl)"
                >{{ $t("msg.CSBK100.167") }}</a>
                <!-- 냉동 Pick Up 정보 확인 (Terminal Site) -->
              </td>
            </tr>
            <tr
              v-if="
                vo.cgoTypCd !== '' && '01|02|03|06'.indexOf(vo.cgoTypCd) > -1
              "
            >
              <td colspan="8" style="text-align: left">
                <!-- dg s -->
                <booking-table-dg
                  v-if="vo.cgoTypCd === '01'"
                  ref="dg"
                  :index="idx"
                  :parent-info="vo"
                  :parent-dg-list="vo.containerDGList"
                  @vanningChk="vanningChk"
                  :cas-no-port-yn="casNoPortYn"
                  :tel-ctr-no="usrInfo.telCtrNo"
                  :disabled-yn="disabledYn"
                  :unno-disabled-yn="unnoDisabledYn"
                  :lang="lang"
                  :save-flag="saveFlag"
                  :bkg-sts-cd="regParams.bkgStsCd"
                  :bkg-vsl-doc-yn="bkgVslDocYn"
                  :status-class="statusClass"
                />
                <!-- dg e -->

                <!-- oog s -->
                <booking-table-oog
                  v-if="
                    vo.cgoTypCd === '02' || vo.cgoTypCd === '03' ||
                      (vo.cgoTypCd === '03' &&
                        vo.cntrTypCd === 'HC' &&
                        vo.cntrSzCd === '45')
                  "
                  ref="oog"
                  :index="idx"
                  :cgo-typ-cd="vo.cgoTypCd"
                  :parent-info="vo"
                  :kmd-vsl-check="kmdVslCheck"
                  :empty-flag="regParams.emptyFlag"
                  :lang="lang"
                  :save-flag="saveFlag"
                  :cntr-typ-cd="vo.cntrTypCd"
                  :sts-and-app-no-yn="stsAndAppNoYn"
                  :grs-wt-ton="grsWtTon"
                />
                <!-- oog e -->

                <!-- flexi s -->
                <booking-table-flexi
                  v-if="vo.cgoTypCd === '06'"
                  ref="flexi"
                  :index="idx"
                  :parent-info="vo"
                  :lang="lang"
                  :save-flag="saveFlag"
                  :loi-down-yn="loiDownYn"
                  @fbCatInfo="callFbCatInfo"
                />
                <!-- flexi e -->
              </td>
            </tr>
            <tr v-if="vo.cgoTypCd === '01'">
              <td colspan="8">
                <div class="select_opt_area">
                  <div class="desc_list">
                    <ul v-if="lang === 'KOR'">
                      <li>
                        UNNO 미부착 또는 수기(Handwritten) 시, 기항지에서 큰
                        액수의 벌금이 부과될 수 있으므로 선적 과정에서 발견될
                        경우, 통보없이 선적 거절될 수 있습니다. (FAQ 참조)
                      </li>
                      <li class="color_red">
                        CAS No. 제약이 있는 항만으로 수출입할 경우 MSDS에 기재된
                        모든 CAS No.를 누락없이 입력하셔야 하며 부정확한 정보
                        입력으로 인하여 발생하는 모든 책임은 신고자(송하인)에게
                        있음을 알려 드립니다.
                      </li>
                      <li class="color_red" v-show="false">
                        MSDS상 Secret CAS No. (MSDS상 CAS No.미공개로 표시됨)가
                        있는 경우, 별도로 확인하여 알려주시거나 제공이 불가한
                        경우, 책임이 신고자(송하인)에게 있음을 승인하는 LOI 제출
                        바랍니다. * 당사 공지사항 참조
                      </li>
                    </ul>
                    <ul v-if="lang !== 'KOR'">
                      <li>
                        In case of missing UNNO or handwritten UNNO on the DG
                        container can result in large amount of penalty at
                        calling ports. If it is found before loading, it could
                        be rejected without pre-notice.
                      </li>
                      <li>{{ $t("msg.CSBK100.094") }}</li>
                      <li>{{ $t("msg.CSBK100.095") }}</li>
                      <li>{{ $t("msg.CSBK100.096") }}</li>
                      <li class="color_red">
                        All of CAS numbers shown on Chinese MSDS have to be
                        entered on DG cargo details without omission When DG
                        cargo is shipped to the restricted ports which force to
                        provide CAS numbers.<br />Shipper (Reporter) should take
                        all of the responsibilities caused by inaccurate
                        information provided.
                      </li>
                      <li class="color_red" v-show="false">
                        If there are 'secret CAS numbers' which are unwritten
                        CAS numbers, Please let us know directly. If It isn't
                        possible to provide it, Please submit an LOI, confirming
                        that all of the responsibilities rests with the shipper
                        (Reporter).
                      </li>
                    </ul>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div v-if="usrCtrCd === 'TH'" class="mt20">
          <table class="tbl_col">
            <colgroup>
              <col width="20%" />
              <col width="80%" />
            </colgroup>
            <tbody>
              <tr>
                <th>Return Date</th>
                <td class="text_left">
                  <e-date-picker v-model="regParams.returnDate">
                  </e-date-picker>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div v-if="vanningYn === 'Y'" class="mt20">
          <h2 class="content_title">Charcoal Info.</h2>
          <table class="tbl_row mt10">
            <colgroup>
              <col style="width: 25%" />
              <col style="width: 25%" />
              <col style="width: 25%" />
              <col style="width: 25%" />
            </colgroup>
            <tbody>
              <tr>
                <th scope="col">B/L Shipper</th>
                <td>
                  <select
                    id="chclActShprCstNo"
                    v-model="regParams.chclActShprCstNo"
                    @change="checkValidationVanningInfo('chclActShprCstNo')"
                  >
                    <option value="">Select</option>
                    <option
                      v-for="cvo in chclActList"
                      :key="cvo.actShprCstNo"
                      :value="cvo.actShprCstNo"
                    >
                      {{ cvo.actShprCstNm }}
                    </option>
                  </select>
                </td>
                <th scope="col">Vanning Surveyor.</th>
                <td>
                  <select
                    id="vaniSurvCstNo"
                    v-model="regParams.vaniSurvCstNo"
                    :disabled="vanningSurvYn === 'N'"
                    @change="checkValidationVanningInfo('vaniSurvCstNo')"
                  >
                    <option value="">Select</option>
                    <option
                      v-for="cvo in chclActList"
                      :key="cvo.vaniSurvCstNo"
                      :value="cvo.vaniSurvCstNo"
                    >
                      {{ cvo.vaniSurvCstNm }}
                    </option>
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          v-if="msdsCheck === 'Y' && regParams.podPortCd === 'SHA'"
          class="desc_list mt20"
        >
          <ul>
            <li class="color_red font_bold">
              To Shanghai, a A MSDS is required. Please upload the file.
            </li>
          </ul>
        </div>
        <div
          v-if="msdsCheck === 'Y' && regParams.podPortCd !== 'SHA'"
          class="desc_list mt20"
        >
          <ul>
            <li class="color_red font_bold">
              To S.China or Ningbo, a A MSDS in CHINESE is required. Please
              upload the file.
            </li>
          </ul>
        </div>
        <div v-if="consigneeYn === 'Y'" class="mt20">
          <h2 class="content_title text_left">{{ $t("msg.CSBK100.169") }}</h2>
          <!-- Consignee 연락처 -->
          <table class="tbl_col t2">
            <colgroup>
              <col style="width: 20%" />
              <col style="width: 20%" />
              <col style="width: 20%" />
              <col style="width: 40%" />
            </colgroup>
            <tbody>
              <tr>
                <th scope="col">{{ $t("msg.CSBK100.129") }}</th>
                <!-- 담당자 -->
                <th scope="col">{{ $t("msg.CSBK100.148") }}</th>
                <!-- 전화번호 -->
                <th scope="col">
                  {{ $t("msg.CSBK100.170")
                  }}<span class="txt">({{ $t("msg.CSBK100.162") }})</span>
                </th>
                <!-- FAX번호(선택) -->
                <th scope="col">
                  email<span class="txt">({{ $t("msg.CSBK100.162") }})</span>
                </th>
                <!-- email(선택) -->
              </tr>
              <tr>
                <td>
                  <input
                    type="text"
                    v-model="regParams.cnePicNm"
                    id="cnePicNm"
                    maxlength="100"
                    @input="inputConsignee('cnePicNm', 'ESSENTIAL')"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    v-model="regParams.cnePicTelNo"
                    id="cnePicTelNo"
                    @input="inputConsignee('cnePicTelNo', 'ESSENTIAL')"
                    maxlength="30"
                  />
                </td>
                <!-- <td>
                  <e-input-number
                    v-model="regParams.cnePicTelNo"
                    id="cnePicTelNo"
                    @input="inputConsignee('cnePicTelNo', 'ESSENTIAL')"
                    maxlength="30"
                    :is-c-phone="true"
                  >
                  </e-input-number>
                </td> -->
                <td>
                  <e-input-number
                    v-model="regParams.cnePicFaxNo"
                    id="cnePicTelNo"
                    @input="inputConsignee('cnePicFaxNo', 'ESSENTIAL')"
                    maxlength="30"
                    :is-phone="true"
                  >
                  </e-input-number>
                </td>
                <td>
                  <input
                    type="text"
                    id="cnePicEmlAddr"
                    v-model="regParams.cnePicEmlAddr"
                    maxlength="100"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <close-info
          v-if="regParams.mtyPkupUsagYn !== 'Y' && usrCtrCd !== 'VN'"
          :parent-info="bkgCloseInfo"
          :vessel-info="bkgCloseVslInfo"
        >
        </close-info>

        <div class="desc_list" v-if="lang === 'KOR'">
          <ul>
            <li class="color_blue">
              {{ $t("msg.CSBK100.175") }}<br />{{ $t("msg.CSBK100.176") }}
            </li>
          </ul>
        </div>

        <div class="desc_list" style="margin-top:20px; margin-bottom:-15px;" v-if="(regParams.podCtrCd === 'IR' || regParams.podCtrCd === 'RU') && lang === 'KOR'">
          <ul>
            <li class="color_red">
              <p>Sanction 대상 화물이 선적 되는 위험을 예방하기 위하여 선적하는 모든 화물에 대하여 제3자 기관을 통하여 Sanction 대상 여부의 검증을 실시하고 있습니다.</p>
              <p>원활한 검증을 위해 서류 제출시 하기 사항의 추가 입력을 요청드리오며, 허위 정보 입력시 Sanction 대상으로 확인될 위험성이 높으므로 정확한 정보가 입력될 수 있도록 협조를 요청드립니다. </p>
            </li>
          </ul>
        </div>
        <table class="tbl_row mt20" v-if="regParams.podCtrCd === 'IR' || regParams.podCtrCd === 'RU'">
          <!-- 도착지 이란일 경우 생성 -->
          <colgroup>
            <col style="width: 25%" />
            <col style="width: 10%" />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <th scope="row" rowspan="2">B/L SHIPPER</th>
              <th width="150">NAME</th>
              <td>
                <input
                  v-model="regParams.iranBookingInfo.actShprCstNm"
                  id="ir_actShprCstNm"
                  type="text"
                  maxlength="100"
                  @blur="iranInputCheck('actShprCstNm')"
                  @keyup="checkIranValidation('actShprCstNm')"
                  @input="changeActShprCstNm"
                />
                <span
                  :class="
                    $ekmtcCommon.isNotEmpty(
                      regParams.iranBookingInfo.actShprCstNmC
                    ) && regParams.iranBookingInfo.actShprCstNmC !== 'N'
                      ? ''
                      : 'd_none'
                  "
                  id="ir_actShprCstNmC"
                >
                  {{ $t("msg.CSBK100.115") }}
                </span>
              </td>
            </tr>
            <tr>
              <th scope="row" style="border-left: 1px solid #e1e3eb;">ADDRESS</th>
              <td>
                <input
                  v-model="regParams.iranBookingInfo.actShprAddr"
                  id="ir_actShprAddr"
                  type="text"
                  maxlength="300"
                  @keyup="checkIranValidation('actShprAddr')"
                  @input="changeActShprAddr"
                />
              </td>
            </tr>
            <tr>
              <th scope="row" rowspan="2">B/L CONSIGNEE</th>
              <th scope="row">NAME</th>
              <td>
                <input
                  v-model="regParams.iranBookingInfo.cneCstNm"
                  id="ir_cneCstNm"
                  type="text"
                  maxlength="100"
                  @blur="iranInputCheck('cneCstNm')"
                  @keyup="checkIranValidation('cneCstNm')"
                  @input="
                    changeOnlyUpperEng(
                      $event,
                      regParams.iranBookingInfo,
                      'cneCstNm'
                    )
                  "
                />
                <span
                  :class="
                    $ekmtcCommon.isNotEmpty(
                      regParams.iranBookingInfo.cneCstNmC
                    ) && regParams.iranBookingInfo.cneCstNmC !== 'N'
                      ? ''
                      : 'd_none'
                  "
                  id="ir_cneCstNmC"
                >
                  {{ $t("msg.CSBK100.115") }}
                </span>
              </td>
            </tr>
            <tr>
              <th scope="row" style="border-left: 1px solid #e1e3eb;">ADDRESS</th>
              <td>
                <input
                  v-model="regParams.iranBookingInfo.cneCstAddr"
                  id="ir_cneCstAddr"
                  type="text"
                  maxlength="300"
                  @keyup="checkIranValidation('cneCstAddr')"
                  @input="changCneCstAddr"
                />
              </td>
            </tr>
            <tr>
              <th scope="row" rowspan="2">B/L NOTIFY</th>
              <th scope="row">NAME</th>
              <td>
                <input
                  v-model="regParams.iranBookingInfo.ntifCstNm"
                  id="ir_ntifCstNm"
                  type="text"
                  maxlength="100"
                  @blur="iranInputCheck('ntifCstNm')"
                  @keyup="checkIranValidation('ntifCstNm')"
                  @input="changNtifCstNm"
                />
                <span
                  :class="
                    $ekmtcCommon.isNotEmpty(
                      regParams.iranBookingInfo.ntifCstNmC
                    ) && regParams.iranBookingInfo.ntifCstNmC !== 'N'
                      ? ''
                      : 'd_none'
                  "
                  id="ir_ntifCstNmC"
                >
                  {{ $t("msg.CSBK100.115") }}
                </span>
              </td>
            </tr>
            <tr>
              <th scope="row" style="border-left: 1px solid #e1e3eb;">ADDRESS</th>
              <td>
                <input
                  v-model="regParams.iranBookingInfo.ntifCstAddr"
                  id="ir_ntifCstAddr"
                  type="text"
                  maxlength="300"
                  @keyup="checkIranValidation('ntifCstAddr')"
                  @input="changNtifCstAddr"
                />
              </td>
            </tr>
            <tr>
              <th colspan="2" scope="row">END USER</th>
              <td>
                <div class="">
                  <table class="tbl_col">
                    <colgroup>
                      <col width="10%">
                      <col width="20%">
                      <col width="60%">
                      <col width="10%">
                    </colgroup>
                    <thead>
                      <tr>
                        <th rowspan="2">{{ $t('msg.CSBK100.287') }}</th> <!-- SEQ -->
                        <th>NAME</th> <!-- Name -->
                        <th>ADDRESS</th> <!-- ADDRESS -->
                        <th rowspan="2"></th>
                      </tr>
                      <tr>
                        <th style="border-left: 1px solid #e1e3eb;">TEL</th> <!-- Name -->
                        <th>EMAIL</th> <!-- EMAIL -->
                      </tr>
                    </thead>
                    <tbody v-for="(vo, idx) in endUserList" :key="'trEndUser_' + idx">
                      <tr>
                        <td rowspan="2">{{ idx + 1 }}</td>
                        <td>
                          <input
                            v-model="vo.endUsrNm"
                            :id="'ir_endUsrNm_' + idx"
                            maxlength="100"
                            type="text"
                            placeholder="NAME"
                            @blur="iranInputCheck('endUsrNm', idx)"
                            @keyup="checkIranValidation('endUsrNm', idx)"
                            @input="
                              changeOnlyUpperEng(
                                $event,
                                regParams.iranBookingInfo,
                                'endUsrNm'
                              )
                            "
                          >
                          <span
                            :class="
                              regParams.iranBookingInfo.endUsrNmCList &&
                                $ekmtcCommon.isNotEmpty(
                                  regParams.iranBookingInfo.endUsrNmCList[idx]
                                ) && regParams.iranBookingInfo.endUsrNmCList[idx] !== 'N'
                                ? ''
                                : 'd_none'
                            "
                            :id="'ir_endUsrNmC_' + idx"
                          >
                            {{ $t("msg.CSBK100.115") }}
                          </span>
                        </td>
                        <td>
                          <input
                            v-model="vo.endUsrAddr"
                            :id="'ir_endUsrAddr_' + idx"
                            maxlength="300"
                            type="text"
                            placeholder="ADDRESS"
                            @keyup="checkIranValidation('endUsrAddr', idx)"
                          >
                        </td>
                        <td rowspan="2">
                          <button
                            v-if="idx === 0"
                            type="button"
                            class="tbl_icon plus"
                            @click="addEndUser()"
                          >
                            plus
                          </button>
                          <button
                            v-if="idx !== 0"
                            type="button"
                            class="tbl_icon minus"
                            @click="removeEndUser(idx)"
                          >
                            minus
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td style="border-left: 1px solid #e1e3eb;">
                          <input
                            v-model="vo.endUsrTelNo"
                            :id="'ir_endUsrTelNo_' + idx"
                            maxlength="25"
                            type="text"
                            placeholder="TEL"
                            @input="
                              changeOnlyUpperEng(
                                $event,
                                regParams.iranBookingInfo,
                                'endUsrTelNo'
                              )
                            "
                          >
                        </td>
                        <td>
                          <input
                            v-model="vo.endUsrEmlAddr"
                            :id="'ir_endUsrEmlAddr_' + idx"
                            maxlength="100"
                            type="text"
                            placeholder="EMAIL"
                            @input="
                              changeOnlyUpperEng(
                                $event,
                                regParams.iranBookingInfo,
                                'endUsrEmlAddr'
                              )
                            "
                            @blur="checkValidationEmail(`ir_endUsrEmlAddr_${idx}`)"
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
            <tr></tr>
            <!-- <tr>
              <th colspan="2" scope="row">B/L SHIPPER(ENGLISH ONLY)</th>
              <td>
                <input
                  v-model="regParams.iranBookingInfo.actShprCstNm"
                  id="ir_actShprCstNm"
                  type="text"
                  maxlength="100"
                  @blur="iranInputCheck('actShprCstNm')"
                  @keyup="checkIranValidation('actShprCstNm')"
                  @input="changeActShprCstNm"
                />
                <span
                  :class="
                    $ekmtcCommon.isNotEmpty(
                      regParams.iranBookingInfo.actShprCstNmC
                    ) && regParams.iranBookingInfo.actShprCstNmC !== 'N'
                      ? ''
                      : 'd_none'
                  "
                  id="ir_actShprCstNmC"
                >
                  {{ $t("msg.CSBK100.115") }}
                </span>
              </td>
            </tr> -->
            <!-- <tr>
              <th colspan="2" scope="row">NAME OF CONSIGNEE</th>
              <td>
                <input
                  v-model="regParams.iranBookingInfo.cneCstNm"
                  id="ir_cneCstNm"
                  type="text"
                  maxlength="100"
                  @blur="iranInputCheck('cneCstNm')"
                  @keyup="checkIranValidation('cneCstNm')"
                  @input="
                    changeOnlyUpperEng(
                      $event,
                      regParams.iranBookingInfo,
                      'cneCstNm'
                    )
                  "
                />
                <span
                  :class="
                    $ekmtcCommon.isNotEmpty(
                      regParams.iranBookingInfo.cneCstNmC
                    ) && regParams.iranBookingInfo.cneCstNmC !== 'N'
                      ? ''
                      : 'd_none'
                  "
                  id="ir_cneCstNmC"
                >
                  {{ $t("msg.CSBK100.115") }}
                </span>
              </td>
            </tr> -->
            <!-- <tr>
              <th colspan="2" scope="row">END USER</th>
              <td>
                <div class="tbl_form position_relative">
                  <span class="dv col_5">
                    <input
                      v-model="regParams.iranBookingInfo.endUsrNm"
                      id="ir_endUsrNm"
                      type="text"
                      maxlength="100"
                      @blur="iranInputCheck('endUsrNm')"
                      @keyup="checkIranValidation('endUsrNm')"
                      @input="
                        changeOnlyUpperEng(
                          $event,
                          regParams.iranBookingInfo,
                          'endUsrNm'
                        )
                      "
                    />
                    <span
                      :class="
                        $ekmtcCommon.isNotEmpty(
                          regParams.iranBookingInfo.endUsrNmC
                        ) && regParams.iranBookingInfo.endUsrNmC !== 'N'
                          ? ''
                          : 'd_none'
                      "
                      id="ir_endUsrNmC"
                    >
                      {{ $t("msg.CSBK100.115") }}
                    </span>
                  </span>
                  <span class="dv">
                    <input
                      type="checkbox"
                      id="sameas"
                      class="ml20"
                      @click="sameConsigneeName"
                    /><label for="sameas">SAME AS CONSIGNEE</label>
                  </span>
                </div>
              </td>
            </tr> -->
            <!-- <tr>
              <th scope="row">END USER INFORMATION</th>
              <th scope="row"></th>
              <td>
                <div class="tbl_form">
                  <span class="dv col_2">
                    <input
                      v-model="regParams.iranBookingInfo.endUsrTelNo"
                      id="ir_endUsrTelNo"
                      type="text"
                      placeholder="phone no"
                      maxlength="25"
                    />
                  </span>
                  <span class="dv col_3">
                    <input
                      v-model="regParams.iranBookingInfo.endUsrEmlAddr"
                      id="ir_endUsrEmlAddr"
                      type="text"
                      placeholder="email"
                      maxlength="100"
                    />
                  </span>
                  <span class="dv">
                    <input
                      v-model="regParams.iranBookingInfo.endUsrAddr"
                      id="ir_endUsrAddr"
                      type="text"
                      placeholder="address"
                      maxlength="100"
                    />
                  </span>
                </div>
              </td>
            </tr> -->
            <tr>
              <th colspan="2" scope="row">NAME OF COMMODITY</th>
              <td>
                <input
                  v-model="regParams.iranBookingInfo.cmdtNm"
                  id="ir_cmdtNm"
                  type="text"
                  maxlength="100"
                  @blur="iranInputCheck('cmdtNm')"
                  @keyup="checkIranValidation('cmdtNm')"
                  @input="
                    changeOnlyUpperEng(
                      $event,
                      regParams.iranBookingInfo,
                      'cmdtNm'
                    )
                  "
                />
                <span
                  :class="
                    $ekmtcCommon.isNotEmpty(cmdtNmC) && cmdtNmC !== 'N'
                      ? ''
                      : 'd_none'
                  "
                  id="ir_cmdtNmC"
                >
                  {{ $t("msg.CSBK100.116") }}
                </span>
              </td>
            </tr>
            <tr>
              <th colspan="2" scope="row">HS CODE</th>
              <td>
                <div class="tbl_form">
                  <span class="dv col_5">
                    <e-input-number
                      v-model="regParams.iranBookingInfo.hsCd"
                      id="ir_hsCd"
                      :is-phone="true"
                      maxlength="10"
                      @blur="iranInputCheck('hsCd')"
                      @input="checkIranValidation('hsCd')"
                    >
                    </e-input-number>
                  </span>
                  <span class="dv">
                    <button
                      v-if="
                        saveFlag === 'EDIT' &&
                          $ekmtcCommon.isNotEmpty(regParams.bkgNo)
                      "
                      type="button"
                      class="tbl_icon plus"
                      @click="fnIranHsCdMulti"
                    >
                      plus
                    </button>
                  </span>
                </div>
                <span
                  :class="
                    $ekmtcCommon.isNotEmpty(hsCdC) && hsCdC !== 'N'
                      ? ''
                      : 'd_none'
                  "
                  id="ir_hsCdC"
                >
                  {{ $t("msg.CSBK100.117") }}
                </span>
              </td>
            </tr>
            <tr>
              <th colspan="2" scope="row">PURPOSE OF USE</th>
              <td>
                <input
                  v-model="regParams.iranBookingInfo.useDestCont"
                  id="ir_useDestCont"
                  type="text"
                  @keyup="checkIranValidation('useDestCont')"
                />
              </td>
            </tr>
            <tr>
              <th colspan="2" scope="row">SHIPPER REMARK</th>
              <td>
                <textarea
                  v-model="regParams.iranBookingInfo.reqCont"
                  id="reqCont"
                >
                </textarea>
              </td>
            </tr>
            <tr>
              <th colspan="2" scope="row">KMTC REMARK</th>
              <td>
                {{ regParams.iranBookingInfo.apvCont }}
              </td>
            </tr>
          </tbody>
        </table>

        <div
          class="mt20 div_req_area"
          v-if="cstNo === 'PKE005' || lang !== 'KOR'"
        >
          <h2 class="content_title">Request</h2>
          <table class="tbl_row">
            <colgroup>
              <col width="15%" />
              <col width="85%" />
            </colgroup>
            <tbody>
              <tr>
                <th>CY(Depot) Remark</th>
                <td>
                  <div class="position_relative">
                    <textarea
                      id="dorOdrRmk"
                      v-model="regParams.dorOdrRmk"
                      @keyup="checkValidationRemark($event, 2000)"
                      :readonly="'IN|PK|BD|RU|IR|LK|AE'.indexOf(regParams.podCtrCd) > -1 ? true:false"
                    ></textarea>
                    <div class="div_req_byte">
                      <span class="color_black font_medium" id="byte_dorOdrRmk">0</span>
                      <span class="color_lightgray">/</span>
                      <span class="color_lightgray">2,000</span>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th>Document Remark</th>
                <td>
                  <textarea
                    v-if="usrCtrCd !== 'TH'"
                    v-model="regParams.returnDate"
                  >
                  </textarea>
                  <textarea
                    v-if="usrCtrCd === 'TH'"
                    v-model="returnDateTH"
                    readonly
                  >
                  </textarea>
                </td>
              </tr>
              <tr>
                <th>Operation Remark</th>
                <td>
                  <div class="position_relative">
                    <textarea
                      id="oprRmk"
                      v-model="regParams.oprRmk"
                      @keyup="checkValidationRemark($event, 2000)"
                    >
                    </textarea>
                    <div class="div_req_byte">
                      <span class="color_black font_medium" id="byte_oprRmk">0</span>
                      <span class="color_lightgray">/</span>
                      <span class="color_lightgray">2,000</span>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- end content_box -->

      <div v-if="saveFlag === 'ADD'">
        <div class="mt10 flex_box">
          <a class="button lg mr5" @click="goList()">{{
            $t("msg.ADMN030G020.017")
          }}</a>
          <a
            v-if="['SG'].includes(memberDetail.userCtrCd)"
            class="button lg mr5"
            href="/template/LOI for Pre-set Reefer.docx"
            download
          >
            {{ $t("msg.CSBK100.553") }}
          </a>
          <div class="ml_auto text_right">
            <a class="button blue lg mr5" @click="saveBooking()">{{
              $t("msg.CSBK100.178")
            }}</a>
            <!-- 저장 -->
            <a class="button gray lg" @click="resetBooking()">
              {{ $t("msg.CSBK100.179") }}
            </a>
            <!-- 취소 -->
          </div>
        </div>
      </div>
      <div v-if="saveFlag === 'EDIT'">
        <div class="mt10 flex_box">
          <a class="button lg mr5" @click="goList()">{{
            $t("msg.ADMN030G020.017")
          }}</a>
          <a
            v-if="['SG'].includes(memberDetail.userCtrCd)"
            class="button lg mr5"
            href="/template/LOI for Pre-set Reefer.docx"
            download
          >
            {{ $t("msg.CSBK100.553") }}
          </a>
          <a
            class="button lg mr5"
            v-if="dgDclrBtnYn === 'Y'"
            @click="dgDeclarationPop()"
          ><span class="btn_icon deadline"></span>DG Declaration Download</a>
          <div v-if="lang === 'KOR'">
            <a
              v-if="regParams.bkgStsCd === '01'"
              class="button lg mr5"
              @click="shipmentFinishPop()"
            >
              <span class="btn_icon deadline"></span>{{ $t("msg.CSBK100.228") }}
            </a>
            <!-- 선적마감 안내문 -->
            <a
              v-if="delayYn === 'Y'"
              class="button lg mr5"
              @click="delayNoticePop()"
            >
              <span class="btn_icon delay"></span>{{ $t("msg.CSBK100.229") }}
            </a>
            <!-- Delay Notice -->
            <a
              v-if="vslRollOverYn === 'Y'"
              class="button lg"
              @click="vesselChangePop()"
            >
              <span class="btn_icon change"></span>{{ $t("msg.CSBK100.230") }}
            </a>
            <!-- Vessel Change Notice -->
          </div>
          <a
            v-show="vueMode"
            class="button lg mr5"
            @click="templateSavePop()"
          >
            Template Save
          </a>
          <!-- Template Save -->
          <div
            v-if="regParams.bkgStsCd !== '03'"
            class="ml_auto"
          >
            <a
              v-if="btnAreaVisible"
              class="button blue lg mr5"
              @click="saveBooking()"
            >{{ $t("msg.CSBK100.178") }}</a>
            <!--  ml_auto 부터 우측 정렬 됨!-->
            <a
              v-if="isSplitCombine('SPLIT')"
              class="button blue lg mr5"
              @click="fbBkgSplitCombine('SPLIT')"
            >
              {{ $t("msg.CSBK100.231") }}
            </a>
            <!-- Split -->
            <a
              v-if="isSplitCombine('COMBINE')"
              class="button blue lg mr5"
              @click="fbBkgSplitCombine('COMBINE')"
            >
              {{ $t("msg.CSBK100.232") }}
            </a>
            <!-- Combine -->
            <a
              v-if=" (usrCtrCd !== 'VN' && usrCtrCd !== 'JP' && usrCtrCd !== 'ID' && usrCtrCd !== 'HK' && usrCtrCd !== 'IN' ) &&
                btnAreaVisible &&
                showCancelBtn() &&
                !vslCancelReqYn() &&
                srRest !== 'C'
              "
              class="button blue lg mr5"
              @click="cancelBooking()"
            >
              {{ $t("msg.CSBK100.233") }}
            </a>
            <!-- 부킹 취소 -->
            <a
              v-if="
                (usrCtrCd === 'VN' || usrCtrCd === 'JP' || usrCtrCd === 'ID' || usrCtrCd === 'HK' || usrCtrCd === 'IN' ) &&
                  bookingCancelBtnVisible &&
                  showCancelBtn() &&
                  !vslCancelReqYn() &&
                  srRest !== 'C'
              "
              class="button blue lg mr5"
              @click="cancelBooking()"
            >
              {{ $t("msg.CSBK100.233") }}
            </a>
            <!-- 부킹 취소 -->
            <a
              v-if="btnAreaVisible && showCancelBtn() && vslCancelReqYn()"
              class="button blue lg mr5"
              @click="cancelBooking()"
            >
              {{ $t("msg.CSBK100.345") }}
            </a>
            <!-- 선적 취소 요청 -->
            <a
              v-if="cancelReqYn === 'Y' || invoiceReqYn === 'Y'"
              class="button blue lg mr5"
              @click="cancelReqDocCancel()"
            >
              {{ $t("msg.CSBK100.253") }}
            </a>
            <a
              v-if="regParams.bkgOversEditYn === 'Y' && !btnAreaVisible"
              class="button blue lg mr5"
              @click="saveBooking()"
            >{{ $t("msg.CSBK100.178") }}</a>
            <a
              v-if="regParams.mtyPkupUsagYn !== 'Y'"
              class="button blue lg"
              @click="createSR()"
            >{{ $t("msg.CSBK100.234") }}</a>
            <!-- S/R Create -->
          </div>
          <div
            v-if="regParams.bkgStsCd === '03'"
            class="ml_auto"
          >
            <!-- 취소한 경우 -->
            <span class="color_error font_bold">{{
              $t("msg.CMBA100.0127")
            }}</span>
          </div>
        </div>
      </div>
    </form>
    <win-layer-pop>
      <component
        :is="customComponent"
        v-if="customComponent"
        :parent-info="popParams"
        @close="closePopup"
        @selectFunc="selectFunc"
      />
    </win-layer-pop>
    <div class="doc_req_pop">
      <win-layer-pop>
        <component
          v-if="uploadComponent"
          :is="uploadComponent"
          @close="closeUploadPopup"
          :parent-info="uploadParam"
          @set-dext-file-info="uploadCallbackFunc"
        />
      </win-layer-pop>
    </div>

    <div class="etc_doc_pop">
      <win-layer-pop>
        <component
          v-if="etcUploadComponent"
          :is="etcUploadComponent"
          @close="closeEtcUploadPopup"
          :parent-info="etcUploadParam"
          @selectFunc="selectFunc"
        />
      </win-layer-pop>
    </div>

    <component
      v-if="commonBLComponent"
      :is="commonBLComponent"
      :parent-info="commonBLParams"
      ref="commonBL"
      @callback="commonBLCallback"
    />
  </div>
</template>

<script>
import { rootComputed } from '@/store/helpers'
import commons from '@/api/services/commons'
import trans from '@/api/rest/trans/trans'
import bookings from '@/api/rest/trans/bookings'
import transCommon from '@/api/rest/trans/transCommon'
import auth from '@/api/services/auth'
import schedule from '@/api/rest/schedule/schedule'
import docs from '@/api/rest/trans/docs'
import cp from '@/api/rest/cp/cp'
import DgOogCerti from '@/api/rest/trans/dgOogCerti'

import EInputNumber from '@/components/common/EInputNumber'
import EDatePicker from '@/components/common/EDatePicker'
import BookingScheduleInfo from '@/components/trans/BookingScheduleInfo'

export default {
  name: 'BookingNew',
  components: {
    EInputNumber,
    EDatePicker,
    BookingScheduleInfo,
    'booking-table-dg': () => import('@/components/trans/BookingTableDg.vue'),
    'booking-table-oog': () => import('@/components/trans/BookingTableOog.vue'),
    'booking-table-flexi': () =>
      import('@/components/trans/BookingTableFlexi.vue'),
    'win-layer-pop': () => import('@/pages/trans/popup/WinLayerPop'),
    'pick-up-search-pop': () => import('@/pages/trans/popup/PickUpSearchPop'),
    'trans-company-pop': () => import('@/pages/trans/popup/TransCompanyPop'),
    'oog-loi-pop': () => import('@/pages/trans/popup/OogLoiPop'),
    'booking-call-pop': () => import('@/pages/trans/popup/BookingCallPop'),
    'e-auto-complete-commodity': () =>
      import('@/components/common/EAutoCompleteCommodity'),
    'e-auto-complete-cst-enm2': () =>
      import('@/components/common/EAutoCompleteCstEnmV2'),
    // 'e-auto-complete-cst-enm': () => import('@/components/common/EAutoCompleteCstEnmV2'),
    'rf-dg-request-pop': () => import('@/pages/trans/popup/RfDgRequestPop'),
    'schedule-leg-pop': () => import('@/pages/schedule/popup/ScheduleLegPop'),
    'dem-det-calculator-pop': () =>
      import('@/pages/trans/popup/DemDetCalculatorPop'),
    'booking-cancel-pop': () => import('@/pages/trans/popup/BookingCancelPop'),
    'booking-cancel-request-pop': () =>
      import('@/pages/trans/popup/BookingCancelRequestPop'),
    'container-pop': () => import('@/pages/trans/popup/ContainerPop'),
    'booking-combine-detail-pop': () =>
      import('@/pages/trans/popup/BookingCombineDetailPop'),
    'booking-split-detail-pop': () =>
      import('@/pages/trans/popup/BookingSplitDetailPop'),
    'hs-code-ir-pop': () => import('@/pages/trans/popup/HsCodeIRPop'),
    'booking-cancel-fee-pop': () =>
      import('@/pages/trans/popup/BookingCancelFeePop'),
    'container-shuttle-pop': () =>
      import('@/pages/trans/popup/ContainerShuttlePop'),
    'close-info': () => import('@/components/trans/CloseInfo.vue'),
    'dg-itext-pop': () => import('@/pages/pdf/popup/DgItextPop'),
    'shipment-finish-pop': () => import('@/pages/pdf/popup/ShipmentFinishPop'),
    'delay-notice-pop': () => import('@/pages/pdf/popup/DelayNoticePop'),
    'vessel-change-pop': () => import('@/pages/pdf/popup/VesselChangePop'),
    'common-bl': () => import('@/pages/trans/CommonBL'),
    'iran-upload-pop': () => import('@/pages/trans/popup/IranUploadPop'),
    'template-save-pop': () => import('@/pages/trans/popup/TemplateSavePop'),
    'doc-upload-pop': () => import('@/pages/trans/popup/DocUploadPop'),
    'container-iot-pop': () => import('@/pages/trans/popup/ContainerIotPop'),
    'reuse-yn-pop': () => import('@/pages/trans/popup/ReuseYnPop'),
    'etc-doc-upload-pop': () => import('@/pages/trans/popup/EtcDocUploadPop')
  },
  props: {
    detailBkgNo: {
      type: String,
      default: ''
    },
    scheduleObj: {
      type: Object,
      default: null
    },
    mtyPkupUsagYn: {
      type: String,
      default: 'N'
    },
    srCnclYn: {
      type: String,
      default: ''
    }
  },
  data: function () {
    return {
      customComponent: null,
      usrCtrCd: 'KR',
      lang: 'KOR',
      usrInfo: {
        usrTel: '',
        usrFax: '',
        telCtrNo: ''
      },
      userHkSg: 'N',
      statusMsg: '',
      statusClass: 'cancel',
      schdBtnYn: 'Y', // 스케줄 선택 버튼 노출 여부
      saveFlag: '',
      cstCatCd: 'N',
      fwdrCstCd: '',
      cstNo: '',
      commonCodes: {},
      cntSzList: [],
      cntrRfStsYn: 'N',
      cntrRfStsList: [],
      cntrRfStsListVN: [],
      zip1List: [],
      customerList: [],
      chclActList: [],
      cstInfoList: [],
      factoryInfoList: [],
      cn4List: [],
      popParams: {},
      vanningYn: 'N',
      vanningSurvYn: '',
      selectFunc: null,
      commonBLComponent: 'common-bl',
      commonBLParams: {},
      commonBLCallback: '',
      bkgCloseInfo: null,
      bkgCloseVslInfo: null,
      whCodeInfo: '',
      srRest: '',
      localDtm: '',
      vslRest: '',
      cgoRest: '', // 반입마감일
      eta: '',
      etd: '',
      cfsCls: '',
      cgoCls: '',
      bkgCloseYn: '',
      rfTeuCount: '',
      cfsRmkYn: '',
      cfsRmkMsg: '',
      kmdVslCheck: '',
      pickDateSgMy: '',
      pickPlaceNm: '',
      pickPlaceCd: '',
      legTermHPNT: '',
      legTermHKM: '',
      templateNo: '',
      templateNo_2: '',
      msdsCheck: '',
      dgOogFileYn: '', // 모든 파일 올렸을때 Y로 바뀜.. MSDS 올렸는지 체크되지않음
      flexiFileYn: '',
      rfPlugOverYn: 'N',
      bkgDt: '',
      serverYear: '',
      serverMonth: '',
      serverDay: '',
      serverHour: '',
      serverMin: '',
      serverSecond: '',
      consigneeYn: '',
      cmdtNmC: '',
      hsCdC: '',
      schdDetail: null,
      bkgVslDocYn: '',
      vslCheck: '',
      disabledYn: '', // 수정화면 항목 disabled 여부
      unnoDisabledYn: '',
      cntrCpEditYn: '', // 컨테이너 최종 수정 가능 여부 (CP 증량 관련 추가)
      bookingSpecial: '', // 특수화물 부킹 여부
      bkgDocOver: 'N',
      oldContainerList: [],
      cntrEditYn: 'N',
      bkgPlcCd: '',
      jpFwdrList: [],
      jpBkgShprList: [],
      vaniEmpty: '',
      vocYn: '',
      fbCat01: '',
      fbCat02: '',
      fbCat03: '',
      fbCat04: '',
      fbCat05: '',
      delayYn: '', // Delay Notice 버튼 노출 여부
      vslRollOverYn: '', // Vessel Change Notice 버튼 노출 여부
      dgDclrBtnYn: '', // DG Declaration download 버튼 노출 여부 (HK, SG, PH, TH 위험물 존재시)
      cancelReqYn: '', // 선적취소 요청서 요청 여부
      invoiceReqYn: '', // 인보이스 생성 여부
      btnAreaVisible: true, // 버튼 영역 사용여부
      btnAreaVisibleJpn: true,
      isLegFixShow: true,
      isPkupUsagYn: false,
      pcupDtDisabled: false,
      cntrTypeList: [
        { cdNm: 'Dry', cd: 'GP', rmk: '20/40' },
        { cdNm: 'HIGH CUBE', cd: 'HC', rmk: '40/45' },
        { cdNm: 'REEFER', cd: 'RF', rmk: '20' },
        { cdNm: 'REEFER HIGH CUBE', cd: 'RH', rmk: '40' },
        { cdNm: 'TANK', cd: 'TK', rmk: '20/40' },
        { cdNm: 'OPEN TOP', cd: 'OT', rmk: '20/40' },
        { cdNm: 'FLAT RACK', cd: 'FR', rmk: '20/40' },
        { cdNm: 'SUPER RACK', cd: 'SR', rmk: '40' }
      ],
      cgoTypCdList: [
        { cdNm: '(OOG)Out of Gauge', cd: '02', rmk: 'OT/FR/SR' },
        { cdNm: 'In Gauge', cd: '03', rmk: 'OT/FR/SR/HC' },
        { cdNm: this.$t('js.booking.010'), cd: '01', rmk: 'GP/RF/RH/OT/TK/HC' },
        { cdNm: 'Flexi Bag', cd: '06', rmk: 'GP' }
      ],
      picNmh: '',
      docPicNmh: '',
      cboFactory: '',
      casNoPortYn: 'N',
      isKg: false,
      isTon: false,
      rfDisabled: false,
      grsWtTon: '',
      grsWtKg: '',
      returnDateTH: '',
      shuttlePopYn: 'N', // 셔틀 요청서
      oldPodTrmlCd: '', // 저장된 양하항 터미널
      oldPolTrmlCd: '', // 저장된 선적항 터미널
      jpCntrEditable: true,
      originParams: {},
      regParams: {
        bkgNo: '',
        sumValue: '',
        mtyPkupUsagYn: 'N',
        cstBidgMgrNo: '',
        bizPicNm: '',
        bizPicEmlAddr: '',
        bizPicTelPlcNo: '',
        bizPicTelOfcNo: '',
        bizPicFaxPlcNo: '',
        bizPicFaxOfcNo: '',
        bizPicMbpBsnNo: '',
        bizPicMbpOfcNo: '',
        docPicNm: '',
        docPicTelPlcNo: '',
        docPicTelNo: '',
        docPicFaxPlcNo: '',
        docPicFaxNo: '',
        docPicMbpBsnNo: '',
        docPicMbpNo: '',
        docPicEmlAddr: '',
        etxblEmlAddr: '',
        grsWt: '',
        wtCvsCd: '',
        polPortCd: '',
        polCtrCd: '',
        polTrmlCd: '',
        podCtrCd: '',
        podPortCd: '',
        podTrmlCd: '',
        polShtmCd: '01',
        podShtmCd: '01',
        frtPncCd: 'P',
        cneCstEnm: '',
        cmdtDsc: '',
        owtrYn: 'N',
        tfcoCstNm: '',
        tfcoCstNo: '',
        emptyFlag: 'F',
        zip1: '',
        zip2: '',
        facNm: '',
        facPicNm: '',
        facCtfcNo: '',
        facPicEmlAddr: '',
        cnePicNm: '',
        cnePicTelNo: '',
        cnePicFaxNo: '',
        cnePicEmlAddr: '',
        cfsFixdTypCd: '',
        cfsFixdEntrNm: '',
        actShprCstEnm: '',
        consYn: 'N',
        reuseYn: 'N',
        repsCont: '',
        docmRmk: '',
        rtnCgoYn: 'N',
        impBlNo: '',
        chclActShprCstNo: '',
        vaniSurvCstNo: '',
        expcYn: '',
        cfsCy: '',
        schFrtAppNo: '',
        schLogDtm: '',
        casNoPortYn: '',
        dpstAcCd: '',
        stuffPlceCd: '',
        bkgShprCstNo: '',
        bkgShprCstEnm: '',
        fwdrCstNo: '',
        fwdrCstEnm: '',
        fwdEdiCd: '',
        dorOdrRmk: '',
        oprRmk: '',
        returnDate: '',
        polEtd: '',
        polEta: '',
        podEta: '',
        megNumYn: '',
        railData: '',
        legTrmlFixdYn: '',
        pickupYn: '',
        apoTcnt: '',
        containerList: [],
        tplCatCd: '01',
        templateCd: 'A',
        chemicalYn: 'N',
        chemicalFileList: [],
        chemicalUploadYn: 'N',
        shaTsYn: '',
        kmtcSpotYn: 'N',
        iranBookingInfo: {
          actShprCstNm: '',
          actShprCstNmC: '',
          cneCstNm: '',
          cneCstNmC: '',
          endUsrNm: '',
          endUsrNmC: '',
          endUsrTelNo: '',
          endUsrEmlAddr: '',
          cmdtNm: '',
          hsCd: '',
          useDestCont: '',
          reqCont: '',
          apvCont: '',
          actShprAddr: '',
          cneCstAddr: '',
          ntifCstNm: '',
          ntifCstNmC: '',
          ntifCstAddr: '',
          endUsrAddr: '',
          endUserList: [],
          endUsrNmCList: []
        },
        bkgOversEditYn: 'N',
        hsCd: '',
        udab: 'N'
      },
      endUserList: [],
      cpParam: {},
      stsAndAppNoYn: null,
      isPickupError: false,
      isDetCombined: false,
      urlOrNot: false, // JamSin DSM URL 로 넘어온 경로 구분
      urlParam: {}, // JamSin DSM URL DATA 저장
      dsmYn: true, //process.env.VUE_APP_MODE !== 'PRD', // JamSin DSM
      vueMode: true, //process.env.VUE_APP_MODE !== 'PRD'
      templateList: [],
      promotionChk: 'N',
      uploadCallbackFunc: null,
      uploadParam: [],
      uploadType: '',
      uploadComponent: null,
      podShaYn: true,
      iotYn: false,
      myHsCdYn: 'Y',
      loiDownYn: 'Y', // flexi bag loi 다운로드 버튼 노출 여부
      bookingCancelBtnVisible: true, // 부킹 cancel 버튼
      etcUploadCallbackFunc: null,
      etcUploadParam: {},
      etcUploadType: '',
      etcUploadComponent: null,
      etcDeleteAllYn: 'N',
      dgCargoYn: 'N',
      uploadFileList: [],
      preSetList: []
    }
  },
  computed: {
    ...rootComputed,
    _containerList () {
      console.log('@@@@ change _containerList')
      return this.$store.state.schedule
        ? this.$store.state.schedule.containerList
        : []
    }
  },
  created () {
    this._ = require('lodash')
    if (this.$ekmtcCommon.isNotEmpty(this.detailBkgNo)) {
      this.saveFlag = 'EDIT'

      if (this.dsmYn) {
        if (Object.keys(this.$route.query).length > 0) { // JamSin DSM 대안항로 url로 연결된 경우 체크
          const queryVo = this.$route.query
          if (queryVo !== null && queryVo !== undefined) {
            if (queryVo.dsmType === '020') {
              this.urlOrNot = true
              this.urlParam = queryVo
            }
          }
        }
      }
    } else {
      this.saveFlag = 'ADD'
      if (this.$route.name === 'booking-edit') {
        // 진행중 업무 진입시 ADD 이면 부킹 뉴로 이동
        this.$router.push({ name: 'booking-new', params: {} }).catch(() => {})
      }
    }

    this.init()
  },
  mounted () {
    this.$nextTick(function () {
      window.addEventListener('keydown', this.handleKeydown)
    })
  },
  destroyed () {
    window.removeEventListener('keydown', this.handleKeydown)

    this.$store.commit('schedule/defaultAssign', {
      key: 'containerList',
      value: []
    })
  },
  methods: {
    async setBookingCancelBtnVisible () {
      const case1 = ['VN', 'JP', 'ID', 'HK', 'IN']
      if (case1.includes(this.usrCtrCd)) {
        await bookings.getBookingCancelBtnYn({ bkgNo: this.regParams.bkgNo }).then((res) => {
          const data = res.data

          if (data === 'Y') this.bookingCancelBtnVisible = true
          else this.bookingCancelBtnVisible = false
        })
      }
    },
    isDisabledEmptyFlag () {
      console.log('@@@@ enter isDisabledEmptyFlag')
      let cnt = 0
      this._containerList.forEach((item) => {
        if (item.shprVanYn === 'Y') cnt++
      })

      return !(cnt > 0)
    },
    handleKeydown (e) {
      console.log('@@@@ keydown')
      console.log(e)
      //if (e.target.localName === 'input') return
      // if (e.key === '+') {
      //   this.addContainer()
      // }

      // if (e.key === '-' && this._containerList.length > 0) {
      //   this.removeContainer(this._containerList.length - 1)
      // }
    },
    getRandomKey () {
      return Math.random().toString(36).substr(2, 11)
    },
    async init () {
      if (this.auth !== undefined) {
        this.lang = this.$ekmtcCommon.isNotEmpty(this.auth.serviceLang)
          ? this.auth.serviceLang
          : 'KOR'
        this.cstCatCd = this.auth.cstCatCd

        if (this.usrCtrCd === 'SG' || this.usrCtrCd === 'HK') {
          this.userHkSg = 'Y'
        }
      }

      this.initParams()
      await this.initCommCodes()

      if (this.memberDetail !== undefined) {
        this.fwdrCstCd = this.memberDetail.fwdrCstCd
        this.cstNo = this.memberDetail.cstCd
        this.bkgPlcCd = this.memberDetail.bkgPlcCd
        this.usrCtrCd = this.memberDetail.userCtrCd
      }

      if (this.$ekmtcCommon.isNotEmpty(this.detailBkgNo)) {
        this.initEdit(this.detailBkgNo)
      } else {
        console.log('@@@@ init addContainer')
        this.addContainer()

        if (this.usrCtrCd === 'KR') {
          this.regParams.tfcoCstNm = 'KCTC'
          this.regParams.tfcoCstNo = 'TRK001'
        }

        if ('SG|MY'.indexOf(this.usrCtrCd) > -1) {
          this.pcupDtDisabled = true
        }
        await trans.getTemplateList(this.regParams).then((res) => {
          this.templateList = res.data
          console.log('@@@@@@@@@@@@@@@@@@', this.templateList)
        })
      }

      if (this.lang === 'JPN') {
        this.initFwdrInfo()
      }

      // if (this.scheduleObj !== null && this.scheduleObj !== undefined) {
      //   this.callbackSearchSchedule(this.scheduleObj)schedule
      // }

      let obj

      if (Object.keys(this.$route.query).length > 0) {
        const vo = this.$route.query
        if (vo !== undefined && vo !== null) {
          const tempVo = {
            params: JSON.parse(vo.params),
            schedule: JSON.parse(vo.schedule),
            detail: JSON.parse(vo.detail)
          }
          obj = tempVo
        }
      } else {
        if (this.scheduleObj !== undefined && this.scheduleObj !== null) {
          this.promotionChk = this.scheduleObj.promotionChk // 프로모션 유입 값 설정

          // 스케줄 메뉴에서 KMTC SPOT 유입 체크 || 스케줄 메뉴에서 KMTC ON PREMIUM 구간 선택 체크
          if (this.scheduleObj.detail.kmtcSpotClosYn !== 'Y') {
            if (this.scheduleObj.schedule.kmtcPremiumLineYn === 'Y' && this.scheduleObj.detail.kmtcSpotLineYn === 'Y') {
              this.regParams.kmtcSpotYn = 'Y'
            }
          }

          obj = this.scheduleObj

          // JamSin DSM 020 대안항로 URL로 들어오진 않았지만 선호항로, 선복보장 url로 진입 후 Booking진입 시 트래킹을 위해 url 값은 체크한다.
          this.urlOrNot = this.$ekmtcCommon.isNotEmpty(this.scheduleObj.urlOrNot) ? this.scheduleObj.urlOrNot : false
          // JamSin DSM 020 대안항로 URL로 들어오진 않았지만 선호항로, 선복보장 url로 진입한 경우를 체크해서 url param을 저장
          if (this.urlOrNot) {
            this.urlParam = this.scheduleObj.urlParam
            console.log('this.scheduleObj.urlOrNot>>>>>>>>>>>>TRUE :: ', this.urlParam)
          }
        }

        // 진행중인 업무 메뉴에서 유입시 KMTC ON 체크
        if (this.$route.params.spcShiprYn === 'Y') {
          this.regParams.kmtcSpotYn = 'Y'
        }
      }

      if (obj !== undefined) {
        this.callbackSearchSchedule(obj)
      }

      if (this.mtyPkupUsagYn === 'Y') {
        this.regParams.mtyPkupUsagYn = 'Y'

        this.$set(
          this,
          'cntSzList',
          this.commonCodes['01031'].filter((vo) => vo.cd !== '45')
        )
        this.setCntrTypList(this.regParams.containerList[0])
      }

      this.$nextTick(() => {
        console.log('this.regParams.podCtrCd @@@@ ', this.regParams.podCtrCd)

        if (this.endUserList.length === 0) {
          this.addEndUser()
        }
      })
    },
    initParams () {
      const regParams = this.regParams
      if (this.usrCtrCd === 'IN') {
        regParams.dpstAcCd = '01'
        regParams.stuffPlceCd = '01'
      }
    },
    async initCommCodes () {
      const arrCdId = ['01031', '01237', '01238', '01291', '01043', 'CS103', 'CS108']
      const params = {
        keyword: this.$ekmtcCommon.isNotEmpty(this.detailBkgNo) ? this.detailBkgNo : ''
      }

      await Promise.all([
        auth.info(this.auth.jwt).then((res) => {
          const result = res.data
          if (this.saveFlag === 'ADD') {
            this.initUserInfo(result)
          }
        }),

        cp
          .getCallActionHandler({ actionCd: 'Q101' })
          .then((res) => {
            const result = res.data

            if (this.$ekmtcCommon.isNotEmpty(result)) {
              const flag = result.result
              if (flag === 'Y') {
                this.isPkupUsagYn = true
              } else {
                this.isPkupUsagYn = false
              }
            }
          })
          .catch(() => {}),

        commons.getCommonsCodesCommon(arrCdId).then((res) => {
          this.commonCodes = res
          this.cntSzList = this.commonCodes['01031']
          this.cntrRfStsList = this.commonCodes['01291']
          // eslint-disable-next-line dot-notation
          this.cntrRfStsListVN = this.commonCodes['CS103']
          // 오름차순 정렬
          this.cntrRfStsList = this.cntrRfStsList.sort((a, b) => a.inqOrd - b.inqOrd)
          this.cntrRfStsListVN = this.cntrRfStsListVN.sort((a, b) => a.inqOrd - b.inqOrd)
          // eslint-disable-next-line dot-notation
          this.preSetList = this.commonCodes['CS108']

          if (this.usrCtrCd === 'TH') {
            const cntrRfStsList = this.cntrRfStsList
            this.cntrRfStsList = cntrRfStsList.filter(
              (vo) => vo.cd >= 20 || vo.cd === '01'
            )
          }

          if (this.lang === 'KOR') {
            this.cntrTypeList = this.cntrTypeList.filter(
              (vo) => vo.cd !== 'SR'
            )
          }
          const regParams = this.regParams
          const containerList = regParams.containerList

          containerList.forEach((c, index) => {
            this.setCntrTypList(c)
            this.setCgoTypList(c, index)
          })
        }),
        commons.getPlcList('CN4').then((res) => {
          this.cn4List = res.data
        }),
        commons.getRegionList().then((res) => {
          this.zip1List = res
        }),
        commons.getServerTime().then((res) => {
          this.serverYear = res.data.substring(0, 4)
          this.serverMonth = res.data.substring(4, 6)
          this.serverDay = res.data.substring(6, 8)
          this.serverHour = res.data.substring(8, 10)
          this.serverMin = res.data.substring(10, 12)
          this.serverSecond = res.data.substring(12, 14)
        }),
        commons.getCstInfo().then((res) => {
          this.cstInfoList = res
        }),
        commons.getFactoryInfo().then((res) => {
          this.factoryInfoList = res
        }),
        transCommon.findUserVocYn().then((res) => {
          this.vocYn = res.data
        }),
        commons.getServerTime().then((res) => {
          this.serverYear = res.data.substring(0, 4)
          this.serverMonth = res.data.substring(4, 6)
          this.serverDay = res.data.substring(6, 8)
          this.serverHour = res.data.substring(8, 10)
          this.serverMin = res.data.substring(10, 12)
          this.serverSecond = res.data.substring(12, 14)
        }),
        transCommon.checkCntrRfStsCodeYn(params).then((res) => {
          this.cntrRfStsYn = res.data
        })
      ])
    },
    initUserInfo (obj) {
      const regParams = this.regParams
      if (obj !== undefined) {
        let usrTel = ''
        let usrFax = ''

        if (this.$ekmtcCommon.isNotEmpty(obj.tel1)) {
          usrTel += obj.tel1 + ')'
        }
        if (this.$ekmtcCommon.isNotEmpty(obj.tel2)) {
          usrTel += obj.tel2
        }
        if (
          this.$ekmtcCommon.isNotEmpty(obj.tel3) &&
          this.$ekmtcCommon.isNotEmpty(obj.tel4)
        ) {
          let changeUsrTel = ''
          changeUsrTel = obj.tel3 + obj.tel4

          // 프로필에서 저장된 전화번호 형식 맞추기
          if (changeUsrTel.length < 8) {
            usrTel += '-' + changeUsrTel.substring(0, 3)
            usrTel += '-' + changeUsrTel.substring(3)
          } else {
            usrTel += '-' + obj.tel3
            usrTel += '-' + obj.tel4
          }
        }

        if (this.$ekmtcCommon.isNotEmpty(obj.fax1)) {
          usrFax += obj.fax1 + ')'
        }
        if (this.$ekmtcCommon.isNotEmpty(obj.fax2)) {
          usrFax += obj.fax2
        }
        if (
          this.$ekmtcCommon.isNotEmpty(obj.fax3) &&
          this.$ekmtcCommon.isNotEmpty(obj.fax4)
        ) {
          let changeUsrFax = ''
          changeUsrFax = obj.fax3 + obj.fax4

          // 프로필에서 저장된 전화번호 형식 맞추기
          if (changeUsrFax.length < 8) {
            usrFax += '-' + changeUsrFax.substring(0, 3)
            usrFax += '-' + changeUsrFax.substring(3)
          } else {
            usrFax += '-' + obj.fax3
            usrFax += '-' + obj.fax4
          }
        }

        this.usrInfo.usrTel = usrTel
        this.usrInfo.usrFax = usrFax
        this.usrInfo.telCtrNo = obj.tel1

        regParams.bizPicNm = this.auth.userName
        // regParams.bizPicTelPlcNo = this.selectedProfile.telPlcNo
        // regParams.bizPicTelOfcNo = this.selectedProfile.telOfcNo
        // regParams.bizPicFaxPlcNo = this.selectedProfile.faxPlcNo
        // regParams.bizPicFaxOfcNo = this.selectedProfile.faxOfcNo
        // regParams.bizPicMbpBsnNo = this.selectedProfile.mbpBsnNo
        // regParams.bizPicMbpOfcNo = this.selectedProfile.mbpOfcNo
        regParams.bizPicEmlAddr = obj.userEmail

        regParams.bizPicTelPlcNo = obj.tel2 || ''
        regParams.bizPicTelOfcNo = (obj.tel3 || '') + (obj.tel4 || '')
        regParams.bizPicFaxPlcNo = obj.fax2 || ''
        regParams.bizPicFaxOfcNo = (obj.fax3 || '') + (obj.fax4 || '')
        regParams.bizPicMbpBsnNo = this.userHkSg !== 'Y' ? obj.cel1 || '' : ''
        regParams.bizPicMbpOfcNo = (obj.cel2 || '') + (obj.cel3 || '')
      }

      /* as-is 로직 제외 요청
      if (this.auth !== undefined && this.auth.userId === 'CJCJGLS' && this.saveFlag === 'ADD') {
        regParams.bizPicTelPlcNo = ''
        regParams.bizPicTelOfcNo = ''
        regParams.bizPicMbpBsnNo = ''
        regParams.bizPicMbpOfcNo = ''
        regParams.bizPicEmlAddr = ''
      }
      */
    },
    initFwdrInfo () {
      const regParams = this.regParams
      regParams.fwdrCstNo = this.cstNo
      regParams.bkgShprCstNo = this.cstNo

      trans.getFwdrInfoList().then((res) => {
        const result = res.data

        if (result !== undefined) {
          const fwdrList = result.fwdrList
          const bkgShprList = result.bkgShprList

          if (fwdrList !== undefined) {
            this.jpFwdrList = fwdrList

            const fwdrInfo = fwdrList.filter(
              (vo) => vo.fwdrCstNo === this.cstNo
            )
            if (
              fwdrInfo !== undefined &&
              fwdrInfo.length > 0 &&
              this.$ekmtcCommon.isNotEmpty(fwdrInfo[0].fwdrCstEnm)
            ) {
              regParams.fwdrCstEnm = fwdrInfo[0].fwdrCstEnm
            }
          }

          if (bkgShprList !== undefined) {
            this.jpBkgShprList = bkgShprList

            const bkgShprInfo = bkgShprList.filter(
              (vo) => vo.bkgShprCstNo === this.cstNo
            )
            if (
              bkgShprInfo !== undefined &&
              bkgShprInfo.length > 0 &&
              this.$ekmtcCommon.isNotEmpty(bkgShprInfo[0].bkgShprCstEnm)
            ) {
              regParams.bkgShprCstEnm = bkgShprInfo[0].bkgShprCstEnm
            }
          }
        }
      })
    },
    async initEdit (bkgNo) {
      console.log('@@@@ enter initEdit')
      let result = null
      const TH = this

      if (this.$ekmtcCommon.isEmpty(bkgNo)) {
        bkgNo = this.regParams.bkgNo
      }

      await bookings.getBooking(bkgNo).then(async (res) => {
          console.log('@@@@ res: ', res)
          result = res.data

          // 부킹정보 재조회 후 KMTC ON 부킹여부 세팅
          this.regParams.kmtcSpotYn = result.spcShiprYn

          const response = await trans.isDetCombined({
            polCtrCd: result.polCtrCd,
            polPortCd: result.polPortCd
          })
          // Y 로 오면 DEM/DET 계산기 비활성화
          this.isDetCombined = response.data === 'Y'
          console.log('@@@@ isDetCombined: ', this.isDetCombined)

         Array.isArray(result.containerList) &&
          await result.containerList.forEach((item, index) => {
              // await console.log
              console.log('@@@@ item: ', item)
              Array.isArray(item.containerDGList) &&
                item.containerDGList.forEach((_item) => {
                  transCommon.dgFPointCheck(_item.imdunNo).then((_res) => {
                    const _result = _res.data
                    if (
                      _result !== undefined &&
                      _result !== 'N' &&
                      _result.indexOf('@') > -1
                    ) {
                      _item.strFlsTmpr = Number(_result.split('@')[0])
                      _item.endFlsTmpr = Number(_result.split('@')[1])
                    }
                  })
                })
            })
          TH.regParams.shaTsYn = result.shaTsYn
          TH.setDetailInfo(result)
          TH.regParams.hidCS008I = result.makecs008i
          TH.regParams.schFrtAppNo = result.frtAppNo
          TH.regParams.vsrBkgStsCd = result.bkgStsCd
          TH.localDtm = result.localDtm
          TH.kmdVslCheck = result.kmdYn
          TH.oldPodTrmlCd = result.podTrmlCd
          TH.oldPolTrmlCd = result.polTrmlCd
          if (TH.usrCtrCd !== 'KR' || this.cstNo === 'PKE005') {
            if (TH.usrCtrCd !== 'TH') {
              TH.regParams.returnDate = result.docmRmk
            } else {
              TH.regParams.returnDate = result.docmRmk.replace('Return: ', '')
              TH.returnDateTH = result.docmRmk
            }
          } else {
            if (TH.usrCtrCd === 'KR') {
              TH.regParams.returnDate = result.docmRmk
            }
          }

          if (TH.regParams.mtyPkupUsagYn !== 'Y') {
            const params = {
              asBkgNo: TH.regParams.bkgNo,
              asVoyNo: TH.regParams.voyNo,
              asVslCd: TH.regParams.vslCd,
              asPolPortCd: TH.regParams.polPortCd,
              asPodPortCd: TH.regParams.podPortCd
            }

            let searchFlag = true
            let cancelInfoLoad = true

            this.isPkupUsagYn = false // pick up 부킹 가림처리

            await Promise.all([
              schedule
                .checkBkgRestriction(params)
                .then(async (res1) => {
                  // 부킹 제약사항 별도 조회
                  console.log('@@@@ return schedule.checkBkgRestriction')
                  const restriction = res1.data
                  if (restriction !== undefined && restriction.msg === 'Y') {
                    result.srRest = restriction.srRest
                    result.localDtm = restriction.localDtm
                    result.cgoRest = restriction.cgoRest
                    result.vslRest = restriction.vslRest
                    this.scheduleCloseInfo(result, 'EDIT')

                    if (TH.regParams.polShtmCd === '02') {
                      await this.setCfsCodeInfo() // CFS 반입지 담당자 정보를 다시 가져온다
                    }

                    if (TH.bookingSpecial === 'Y' || TH.srRest === 'C') {
                      TH.initStatus(result)
                    } else {
                      TH.initStatusDG(result)
                    }

                    let docCloseTime = TH.cgoRest // 서류마감일시
                    const localDate = TH.$ekmtcCommon.getStrToDate(TH.localDtm) //현재시간
                    let docDate = ''

                    if (this.$ekmtcCommon.isEmpty(docCloseTime)) {
                      docCloseTime = TH.regParams.polEta

                      //입항일에서 -12시간을 한다.
                      docDate = new Date(
                        Number(docCloseTime.substring(0, 4)),
                        Number(docCloseTime.substring(4, 6)) - 1,
                        Number(docCloseTime.substring(6, 8)),
                        Number(docCloseTime.substring(8, 10) - 12),
                        Number(docCloseTime.substring(10, 12))
                      )

                      /******************************************************
                       * 입항시간이 17~24시 사이에 있을 경우 : 서류마감시간 = 입항일-1일 17시
                       * 입항시간이 24~09시 사이에 있을 경우 : 서류마감시간 = 입항일 17시
                       * 그외 서류마감시간 = 입항시간
                       ******************************************************/
                      if (docDate.getHours() > 17 && docDate.getHours() < 24) {
                        docDate = new Date(
                          Number(docCloseTime.substring(0, 4)),
                          Number(docCloseTime.substring(4, 6)) - 1,
                          Number(docCloseTime.substring(6, 8)) - 1,
                          17,
                          Number(docCloseTime.substring(10, 12))
                        )
                      } else if (
                        docDate.getHours() <= 24 &&
                        docDate.getHours() < 9
                      ) {
                        docDate = new Date(
                          Number(docCloseTime.substring(0, 4)),
                          Number(docCloseTime.substring(4, 6)) - 1,
                          Number(docCloseTime.substring(6, 8)),
                          17,
                          Number(docCloseTime.substring(10, 12))
                        )
                      }
                    } else {
                      docDate = new Date(
                        Number(docCloseTime.substring(0, 4)),
                        Number(docCloseTime.substring(4, 6)) - 1,
                        Number(docCloseTime.substring(6, 8)),
                        Number(docCloseTime.substring(8, 10)),
                        Number(docCloseTime.substring(10, 12))
                      ) //서류마감시한
                    }

                    if (TH.$ekmtcCommon.isNotEmpty(docCloseTime)) {
                      if (docDate <= localDate) {
                        // 서류마감 이전 : CP 증량 가능 여부
                        TH.bkgDocOver = 'Y'
                      }
                    }

                    if (TH.usrCtrCd === 'JP') {
                      if (
                        docDate <= localDate ||
                        TH.regParams.vslClosed === 'A'
                      ) {
                        TH.jpCntrEditable = false // 컨테이너 수량 변경 못하도록 처리
                        const frm = document.querySelector('#frm')
                        TH.$nextTick(() => {
                          TH.regParams.containerList.forEach((c, idx) => {
                            frm.querySelector(
                              '#cntrQty_' + idx
                            ).disabled = true
                          })
                        })
                      }
                    }
                  } else {
                    searchFlag = false
                  }
                })
                .catch((err) => {
                  console.log(err)
                  searchFlag = false
                })
                .finally(() => {
                  if (!searchFlag) {
                    TH.$ekmtcCommon.alertDefault(TH.$t('js.common.005'))
                  }
                }),
              trans
                .fnCancelRequestInfo(TH.regParams.bkgNo)
                .then((res2) => {
                  if (res2.headers.respcode === 'C0000') {
                    const cancelReqInfo = res2.data

                    TH.cancelReqYn = cancelReqInfo.cancelReqYn
                    TH.invoiceReqYn = cancelReqInfo.invoiceReqYn
                  } else {
                    cancelInfoLoad = false
                  }
                })
                .catch(() => {
                  cancelInfoLoad = false
                })
                .finally(() => {
                  if (!cancelInfoLoad) {
                    TH.cancelReqYn = 'N'
                    TH.invoiceReqYn = 'N'
                  }
                })
            ])

            if (TH.$ekmtcCommon.isNotEmpty(TH.regParams.rfTeuCount)) {
              TH.rfTeuCount = TH.regParams.rfTeuCount
            }
          }

          let returnVal = await this.callBkgSaveCp('A201')
          if (returnVal === 'Y' && this.bkgDocOver !== 'Y') {
            this.initCntrInput()
          }

          this.originParams = this._.cloneDeep(this.regParams)
          this.pickDateSgMy = this.regParams.pickDateSgMy

          this.$nextTick(() => {
            if (
              'SG|MY'.indexOf(this.usrCtrCd) > -1 &&
              this.regParams.polShtmCd === '01'
            ) {
              const frm = document.querySelector('#frm')
              this.regParams.containerList.forEach((c, i) => {
                this.pcupDtDisabled = true
              })
            }
          })
        })
        .catch((err) => {
          console.log(err)
          this.$ekmtcCommon.alertDefault(this.$t('js.common.005')) // 조회에 실패했습니다. 관리자에게 문의해주세요.
        })

        await this.oldFileCheck()

      const params = {
        polCtrCd: this.regParams.polCtrCd,
        polPortCd: this.regParams.polPortCd
      }
      await trans.getPickUpSet(params).then((res) => {
        const data = res.data
        this.pickPlaceCd = data.cyCd
        this.pickPlaceNm = data.cyNm
      })
      if (this.regParams.casPortCd === 'Y') {
        this.casNoPortYn = 'Y'
      }

      if (result.chemicalYn === 'Y' && result.chemicalFileList) {
        result.chemicalFileList.map(obj => {
          obj.size = obj.fileSize
          obj.fileId = 'http://trans.ekmtc:9000/trans/trans/file-download?bkgNo=' + obj.bkgNo + '&atchFileSeq=' + obj.fileSeq + '&type=BOOKING_SR'
          })
      }
      //KR이 아니고, 해외부킹 승인 시점에 수정화면에 들어 오는 경우 컨테이너 사이즈 45 가림 처리.
      if (this.memberDetail.userCtrCd !== 'KR' && this.memberDetail.userCtrCd !== 'JP' && this.regParams.bkgOversEditYn === 'Y') {
          this.cntSzList = this.cntSzList.filter(
            (vo) => vo.cd !== '45'
           )
      } else {
        this.cntSzList = this.commonCodes['01031']
      }
      // this.oldContainerList = this.regParams.containerList
      this.oldContainerList = [...this._containerList]
      //this.findIotCntrYn('S')

      this.setBookingCancelBtnVisible()
    },
    initStatusEng () {
      console.log('@@@@ enter initStatusEng')
      console.log('@@@@ bkgOversEditYn=============>', this.regParams.bkgOversEditYn)
      const regParams = this.regParams
      const srRest = this.srRest
      const frm = document.querySelector('#frm')

      if (this.lang === 'JPN') {
        let cgoFlag = false

        regParams.containerList.forEach((c) => {
          if (
            this.$ekmtcCommon.isNotEmpty(c.cntrTypCd) &&
            'RF|RH|OT|SR|FR'.indexOf(c.cntrTypCd) > -1
          ) {
            cgoFlag = true
          }
        })

        if (regParams.bkgStsCd === '01' || regParams.bkgStsCd === '02') {
          this.btnAreaVisibleJpn = false
        }

        if (srRest !== 'C' && !cgoFlag) {
          return
        }
      } else if (this.usrCtrCd === 'XXX') {
        if (srRest !== 'C' && this.bkgDocOver !== 'Y') {
          return
        }
      }
      this.btnAreaVisible = false

      if (regParams.bkgOversEditYn === 'Y' && this.regParams.pickupYn === 'Y') {
        this.schdBtnYn = 'N'
      }
      if (regParams.bkgOversEditYn === 'Y') {
        return
      }
      const inputEl = frm.getElementsByTagName('input')
      for (const el of inputEl) {
        el.disabled = true
      }

      const selectEl = frm.getElementsByTagName('select')
      for (const el of selectEl) {
        el.disabled = true
      }

      const textEl = frm.getElementsByTagName('textarea')
      for (const el of textEl) {
        el.disabled = true
      }

      this.schdBtnYn = 'N'
      this.disabledYn = 'Y'
      this.unnoDisabledYn = 'Y'
    },
    initStatus (result) {
      console.log('@@@@ enter initStatus')
      const regParams = this.regParams
      const frm = document.querySelector('#frm')

      this.statusMsg = ''
      this.statusClass = ''

      if (this.usrCtrCd !== 'KR') {
        if (regParams.bkgStsCd === '01' && this.vaniEmpty !== 'Y') {
          this.initStatusEng()
        }
        return
      }

      let docCloseTime = this.cgoRest // 서류마감일시
      const localTime = this.localDtm // 현재일시
      const localDate = this.$ekmtcCommon.getStrToDate(this.localDtm) //현재시간
      let docDate = ''

      if (this.$ekmtcCommon.isEmpty(docCloseTime)) {
        docCloseTime = regParams.polEta

        //입항일에서 -12시간을 한다.
        docDate = new Date(
          Number(docCloseTime.substring(0, 4)),
          Number(docCloseTime.substring(4, 6)) - 1,
          Number(docCloseTime.substring(6, 8)),
          Number(docCloseTime.substring(8, 10) - 12),
          Number(docCloseTime.substring(10, 12))
        )

        /******************************************************
         * 입항시간이 17~24시 사이에 있을 경우 : 서류마감시간 = 입항일-1일 17시
         * 입항시간이 24~09시 사이에 있을 경우 : 서류마감시간 = 입항일 17시
         * 그외 서류마감시간 = 입항시간
         ******************************************************/
        if (docDate.getHours() > 17 && docDate.getHours() < 24) {
          docDate = new Date(
            Number(docCloseTime.substring(0, 4)),
            Number(docCloseTime.substring(4, 6)) - 1,
            Number(docCloseTime.substring(6, 8)) - 1,
            17,
            Number(docCloseTime.substring(10, 12))
          )
        } else if (docDate.getHours() <= 24 && docDate.getHours() < 9) {
          docDate = new Date(
            Number(docCloseTime.substring(0, 4)),
            Number(docCloseTime.substring(4, 6)) - 1,
            Number(docCloseTime.substring(6, 8)),
            17,
            Number(docCloseTime.substring(10, 12))
          )
        }
      } else {
        docDate = new Date(
          Number(docCloseTime.substring(0, 4)),
          Number(docCloseTime.substring(4, 6)) - 1,
          Number(docCloseTime.substring(6, 8)),
          Number(docCloseTime.substring(8, 10)),
          Number(docCloseTime.substring(10, 12))
        ) //서류마감시한
      }

      const localDate1 = new Date(
        Number(localTime.substring(0, 4)),
        Number(localTime.substring(4, 6)) - 1,
        Number(localTime.substring(6, 8)) + 2,
        0,
        0
      ) // 현재일자 +2일
      const localDate2 = new Date(
        Number(localTime.substring(0, 4)),
        Number(localTime.substring(4, 6)) - 1,
        Number(localTime.substring(6, 8)),
        0,
        0
      ) // 현재일자
      const etaTime = regParams.polEta
      const etaDate = new Date(
        Number(etaTime.substring(0, 4)),
        Number(etaTime.substring(4, 6)) - 1,
        Number(etaTime.substring(6, 8)),
        0,
        0
      ) // 입항일

      const etdTime = this.etd
      const etdDate = new Date(
        Number(etdTime.substring(0, 4)),
        Number(etdTime.substring(4, 6)) - 1,
        Number(etdTime.substring(6, 8)),
        0,
        0
      ) // 출항일
console.log('etdDate@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@', etdDate)
      const vslRest = this.vslRest // 선박 close 여부
      const srRest = this.srRest // S/R 확정여부

      /******************************************************
       * Term 변경
       * 1.현재일자+2 > 선박입항일일때, Term변경불가
       * 2.같은 조건에서 CFS Term인 booking을 조회한 경우, 모든 항목 변경 불가(SOC화주포함)
       ******************************************************/
      let termChange = 0
      if (localDate1 > etaDate) {
        if (regParams.polShtmCd === '02' || this.cstCatCd === 'Y') {
          termChange++
        } else {
          frm.querySelector('#polShtmCd').disabled = true
        }
      }

      let vslCheck = 'N'
      let transChange = 0
      let disabledCntrType = false
      regParams.containerList.forEach((c) => {
        if (
          regParams.polShtmCd === '01' &&
          this.cstCatCd !== 'Y' &&
          regParams.owtrYn !== 'Y'
        ) {
          // CY
          const pcupReqDt = this.$ekmtcCommon.isNotEmpty(c.pcupReqDt)
            ? c.pcupReqDt
            : ''
          const pickupDate = new Date(
            Number(pcupReqDt.substring(0, 4)),
            Number(pcupReqDt.substring(4, 6)) - 1,
            Number(pcupReqDt.substring(6, 8)) - 1,
            0,
            0
          )
          if (this.$ekmtcCommon.isNotEmpty(pcupReqDt)) {
            if (localDate.getHours() >= 15) {
              if (localDate2 >= pickupDate) {
                transChange++
              }
            } else if (localDate2 > pickupDate) {
              transChange++
            }
          }
        }

        if (c.cntrTypCd === 'RF' || c.cntrTypCd === 'RH') {
          if (this.$ekmtcCommon.isNotEmpty(c.pcupReqDt)) {
            const pickupDate1 = new Date(
              Number(c.pcupReqDt.substring(0, 4)),
              Number(c.pcupReqDt.substring(4, 6)) - 1,
              Number(c.pcupReqDt.substring(6, 8)) - 2,
              0,
              0
            )
            if (localDate2 > pickupDate1) {
              console.log('@@@@ 1')
              disabledCntrType = true
            }
          } else {
            console.log('@@@@ 2')
            disabledCntrType = true
          }
        }
      })

      console.log('@@@@ docDate: ', docDate)
      console.log('@@@@ localDate: ', localDate)
      console.log('@@@@ srRest: ', srRest)
      console.log('@@@@ regParams.bkgStsCd: ', regParams.bkgStsCd)
      console.log('@@@@ termChange: ', termChange)

      let disabledType = false
      if (
        docDate <= localDate ||
        srRest === 'C' ||
        regParams.bkgStsCd === '03' ||
        termChange > 0
      ) {
        disabledType = true
      }

      if (regParams.bkgStsCd === '03') {
        this.statusMsg = this.$t('msg.CSBK100.220')
      } else if (srRest === 'C') {
        this.statusMsg = this.$t('msg.CSBK100.221')
        this.statusClass = 'decide'
      } else if (docDate <= localDate) {
        this.statusMsg = this.$t('msg.CSBK100.222')
        this.statusClass = 'past'
        this.bkgVslDocYn = 'Y'
      } else if (Number(vslRest) > 0) {
        this.statusMsg = this.$t('msg.CSBK100.223')
        this.bkgVslDocYn = 'Y'

        if (Number(vslRest) >= 1) vslCheck = 'Y'
      } else if (termChange > 0) {
        this.statusMsg = this.$t('msg.CSBK100.224')
      } else if (transChange > 0) {
        this.statusMsg = this.$t('msg.CSBK100.225')
      } else if (disabledCntrType) {
        this.statusMsg = this.$t('msg.CSBK100.226')
      } else if (localDate1 > etaDate) {
        this.statusMsg = this.$t('msg.CSBK100.227')
      }

      console.log('@@@@ disabledType: ', disabledType)
      console.log('@@@@ transChange: ', transChange)
      console.log('@@@@ disabledCntrType: ', disabledCntrType)
      console.log('@@@@ vslRest: ', vslRest)

      if (disabledType || transChange > 0 || disabledCntrType) {
        if (
          this.polShtmCd === '02' ||
          result.cllYn === 'Y' ||
          localDate2 >= etdDate ||
          srRest === 'C' ||
          regParams.bkgStsCd === '03'
        ) {
          this.schdBtnYn = 'N'
        }

        const inputEl = frm.getElementsByTagName('input')
        for (const el of inputEl) {
          const id = el.getAttribute('id') || ''
          console.log('@@@@ input id: ', id)
          if (
            id !== 'grsWtTon' &&
            id !== 'grsWtKg' &&
            id !== 'autocomplete-form-input' &&
            id.indexOf('imdunNo_') < 0 &&
            id.indexOf('imoClasCd_') < 0 &&
            id.indexOf('casNo_') < 0
          ) {
            el.disabled = true
            this.pcupDtDisabled = true
          } else {
            if (this.bkgVslDocYn === 'Y') {
              el.disabled = true
              this.pcupDtDisabled = true
            }
          }

          if (id !== null) {
            const idNm = id.indexOf('_') > -1 ? id.split('_')[0] : id
            let checkNm =
              'dorOdrRmk|ir|cntrQty|pcupReqDt|pcupCyCd|pickUpMan|pickUpTel|sameas' // ir: 이란 입력 사항 전체
            if (
              vslCheck === 'Y' &&
              (checkNm.indexOf(idNm) > -1 ||
                idNm.indexOf('bizPic') > -1 ||
                idNm.indexOf('docPic') > -1)
            ) {
              el.disabled = false
              this.pcupDtDisabled = false
            }
          }
        }
        // 운송사 비활성화시 운송사 검색버튼 같이 비활성화
        if (frm.querySelector('#tfcoCstNm') && frm.querySelector('#tfcoCstNm').disabled) {
          if (frm.querySelector('#btn_tfcoCstNmSrch')) {
            frm.querySelector('#btn_tfcoCstNmSrch').disabled = true
          }
        }

        const selectEl = frm.getElementsByTagName('select')
        for (const el of selectEl) {
          const id = el.getAttribute('id') || ''
          console.log('@@@@ select id: ', id)

          if (id.indexOf('tmprUnitCd_') < 0) {
            el.disabled = true
          }

          if (id !== null) {
            const idNm = id.indexOf('_') > -1 ? id.split('_')[0] : id
            const checkNm =
              'dgMatlCd|dgCatCd|underDckCd|bulkYn|oogCmdtNm|cmdtCd|dgPkgGrpCd|tmprUnitCd|frtPncCd|cntrTypCd|cntrSzCd'
            if (vslCheck === 'Y' && checkNm.indexOf(idNm) > -1) {
              el.disabled = false
            }
          }
        }

        const textEl = frm.getElementsByTagName('textarea')
        for (const el of textEl) {
          el.readonly = true
        }

        if (vslCheck === 'Y') {
          const arrEl = ['bkgNoList', 'reqCont', 'sameas']
          arrEl.forEach((v) => {
            const el = frm.querySelector('#' + v)
            if (el !== null) {
              el.disabled = false
              el.readonly = false
            }
          })
        }

        if (!disabledType && transChange > 0) {
          frm
            .querySelector('#actShprCstEnm')
            .querySelector('#autocomplete-form-input').disabled = false
        }

        if (regParams.bkgStsCd !== '03' && regParams.cllYn !== 'Y') {
          regParams.containerList.forEach((c, idx) => {
            this.$nextTick(() => {
              if (this.cstCatCd !== 'Y') {
                if (frm.querySelector('#pcupCyCd_' + idx) !== null) {
                  frm.querySelector('#pcupCyCd_' + idx).disabled = false
                }
                if (frm.querySelector('#pcupCyCd_' + idx) !== null) {
                  frm.querySelector('#pickUpMan_' + idx).disabled = false
                }

                if (frm.querySelector('#pickUpTel_' + idx) !== null) {
                  frm.querySelector('#pickUpTel_' + idx).disabled = false
                }

                if (frm.querySelector('#pcupReqDt_' + idx) !== null) {
                  //frm.querySelector('#pcupReqDt_' + idx).disabled = false
                  this.pcupDtDisabled = false
                }
              }

              if (regParams.owtrYn === 'N') {
                if (frm.querySelector('#pcupCyCd_' + idx) !== null) {
                  frm.querySelector('#pcupCyCd_' + idx).readonly = true
                }
                if (frm.querySelector('#pcupCyCd_' + idx) !== null) {
                  frm.querySelector('#pickUpMan_' + idx).readonly = true
                }

                if (frm.querySelector('#pickUpTel_' + idx) !== null) {
                  frm.querySelector('#pickUpTel_' + idx).readonly = true
                }
              }
            })
          })
        }

        console.log('@@@@ disabledType2: ', disabledType)

        if (disabledType) {
          return
        }

        if (regParams.polShtmCd === '01' || regParams.polShtmCd === '03') {
          let checkNm =
            'cntrSzCd|cntrTypCd|cgoTypCd|cntrRfStsCd|pcupReqTm|rfTmprUnitCd|'
          checkNm +=
            'cntrQty|pcupReqDt|flsTmpr|rfSetupTmpr|emrTelNo|emrTelCtrNo|emrPicNm|'
          checkNm +=
            'orgLength|orgWidth|orgHeight|itemQty|shprVanYn|emptyFlag|dgPkgQty|'
          checkNm += 'isdDgPkgQty|frgtWt|saptTmpr'
          regParams.containerList.forEach((c) => {
            let cntrDisabled = false
            if (this.$ekmtcCommon.isNotEmpty(c.pcupReqDt)) {
              const pickupDate = new Date(
                Number(c.pcupReqDt.substring(0, 4)),
                Number(c.pcupReqDt.substring(4, 6)) - 1,
                Number(c.pcupReqDt.substring(6, 8)) - 1,
                0,
                0
              )
              const pickupDate1 = new Date(
                Number(c.pcupReqDt.substring(0, 4)),
                Number(c.pcupReqDt.substring(4, 6)) - 1,
                Number(c.pcupReqDt.substring(6, 8)) - 2,
                0,
                0
              )
              if (c.cntrTypCd === 'RF' || c.cntrTypCd === 'RH') {
                if (localDate2 > pickupDate1) {
                  cntrDisabled = true
                }
              }

              if (regParams.owtrYn === 'N') {
                if (localDate.getHours() >= 15) {
                  if (localDate2 >= pickupDate) {
                    cntrDisabled = true
                  }
                } else if (localDate2 > pickupDate) {
                  cntrDisabled = true
                }
              }
            } else {
              cntrDisabled = true
            }
            console.log('@@@@ cntrDisabled: ', cntrDisabled)

            if (!cntrDisabled) {
              const inputEl = frm.getElementsByTagName('input')
              for (const el of inputEl) {
                const id = el.getAttribute('id') || ''

                if (id !== null) {
                  const idNm = id.indexOf('_') > -1 ? id.split('_')[0] : id
                  if (
                    checkNm.indexOf(idNm) > -1 ||
                    id.indexOf('grsWt_') > -1 ||
                    id.indexOf('netWt_') > -1
                  ) {
                    el.disabled = false
                    this.pcupDtDisabled = false
                  }

                  if (Number(vslRest) > 0) {
                    if (
                      idNm === 'shprVanYn' ||
                      (idNm === 'emptyFlag' && this.cstCatCd === 'Y')
                    ) {
                      el.disabled = true
                    }
                  }
                }
              }

              const selectEl = frm.getElementsByTagName('select')
              for (const el of selectEl) {
                const id = el.getAttribute('id') || ''

                if (
                  vslCheck === 'Y' &&
                  (id.indexOf('tmprUnitCd_') > -1 ||
                    id.indexOf('dgMatlCd_') > -1 ||
                    id.indexOf('dgCatCd_') > -1)
                ) {
                  el.disabled = false
                } else {
                  el.disabled = true
                }

                if (id !== null) {
                  const idNm = id.indexOf('_') > -1 ? id.split('_')[0] : id
                  if (checkNm.indexOf(idNm) > -1) {
                    el.disabled = false
                  }
                }
              }

              this.rfDisabled = true
            }
          })
        }
      }

      this.vslCheck = vslCheck
      this.disabledYn = disabledType ? 'Y' : 'N'
      this.unnoDisabledYn = disabledType && vslCheck !== 'Y' ? 'Y' : 'N' // unno 검색 버튼에서는 조건이 달라지므로 값을 분리함
    },
    initStatusDG (result) {
      console.log('@@@@ enter initStatusDG')
      const regParams = this.regParams
      if (this.usrCtrCd !== 'KR') {
        if (regParams.bkgStsCd === '01' && this.vaniEmpty !== 'Y') {
          this.initStatusEng()
        }
        if (this.lang === 'JPN') {
          // 일본일 경우 미승인 건도 컴바인 안보이도록
          if (regParams.bkgStsCd === '02') {
            this.btnAreaVisibleJpn = false
          }
        }
        return
      }

      let docCloseTime = this.cgoRest // 서류마감일시
      const localTime = this.localDtm // 현재일시
      const localDate = this.$ekmtcCommon.getStrToDate(this.localDtm) //현재시간
      let docDate = ''

      if (this.$ekmtcCommon.isEmpty(docCloseTime)) {
        docCloseTime = this.regParams.polEta

        //입항일에서 -12시간을 한다.
        docDate = new Date(
          Number(docCloseTime.substring(0, 4)),
          Number(docCloseTime.substring(4, 6)) - 1,
          Number(docCloseTime.substring(6, 8)),
          Number(docCloseTime.substring(8, 10) - 12),
          Number(docCloseTime.substring(10, 12))
        )

        /******************************************************
         * 입항시간이 17~24시 사이에 있을 경우 : 서류마감시간 = 입항일-1일 17시
         * 입항시간이 24~09시 사이에 있을 경우 : 서류마감시간 = 입항일 17시
         * 그외 서류마감시간 = 입항시간
         ******************************************************/
        if (docDate.getHours() > 17 && docDate.getHours() < 24) {
          docDate = new Date(
            Number(docCloseTime.substring(0, 4)),
            Number(docCloseTime.substring(4, 6)) - 1,
            Number(docCloseTime.substring(6, 8)) - 1,
            17,
            Number(docCloseTime.substring(10, 12))
          )
        } else if (docDate.getHours() <= 24 && docDate.getHours() < 9) {
          docDate = new Date(
            Number(docCloseTime.substring(0, 4)),
            Number(docCloseTime.substring(4, 6)) - 1,
            Number(docCloseTime.substring(6, 8)),
            17,
            Number(docCloseTime.substring(10, 12))
          )
        }
      } else {
        docDate = new Date(
          Number(docCloseTime.substring(0, 4)),
          Number(docCloseTime.substring(4, 6)) - 1,
          Number(docCloseTime.substring(6, 8)),
          Number(docCloseTime.substring(8, 10)),
          Number(docCloseTime.substring(10, 12))
        ) //서류마감시한
      }

      const localDate1 = new Date(
        Number(localTime.substring(0, 4)),
        Number(localTime.substring(4, 6)) - 1,
        Number(localTime.substring(6, 8)) + 2,
        0,
        0
      ) // 현재일자 +2일
      const localDate2 = new Date(
        Number(localTime.substring(0, 4)),
        Number(localTime.substring(4, 6)) - 1,
        Number(localTime.substring(6, 8)),
        0,
        0
      ) // 현재일자

      const etaTime = this.regParams.polEta
      const etaDate = new Date(
        Number(etaTime.substring(0, 4)),
        Number(etaTime.substring(4, 6)) - 1,
        Number(etaTime.substring(6, 8)),
        0,
        0
      ) // 입항일

      const etdTime = this.etd
      const etdDate = new Date(
        Number(etdTime.substring(0, 4)),
        Number(etdTime.substring(4, 6)) - 1,
        Number(etdTime.substring(6, 8)),
        0,
        0
      ) // 출항일

      const vslRest = this.vslRest // 선박 close 여부
      const srRest = this.srRest // S/R 확정여부

      let transChange = 0
      let disabledType = false
      let vsrRestType = 'N'
      if (regParams.polShtmCd === '01' && this.cstCatCd !== 'Y') {
        // CY
        regParams.containerList.forEach((c) => {
          const pcupReqDt = this.$ekmtcCommon.isNotEmpty(c.pcupReqDt)
            ? c.pcupReqDt
            : ''
          const pickupDate = new Date(
            Number(pcupReqDt.substring(0, 4)),
            Number(pcupReqDt.substring(4, 6)) - 1,
            Number(pcupReqDt.substring(6, 8)) - 1,
            0,
            0
          )
          if (localDate.getHours() >= 15) {
            if (localDate2 >= pickupDate) {
              transChange++
            }
          } else if (localDate2 > pickupDate) {
            transChange++
          }
        })
      }

      if (
        docDate <= localDate ||
        srRest === 'C' ||
        regParams.bkgStsCd === '03'
      ) {
        disabledType = true
      }

      if (regParams.bkgStsCd === '03') {
        this.statusMsg = this.$t('msg.CSBK100.220')
      } else if (srRest === 'C') {
        this.statusMsg = this.$t('msg.CSBK100.221')
      } else if (docDate <= localDate) {
        this.statusMsg = this.$t('msg.CSBK100.222')
        this.statusClass = 'past'
        this.bkgVslDocYn = 'Y'
      } else if (Number(vslRest) > 0) {
        this.statusMsg = this.$t('msg.CSBK100.223')
        this.bkgVslDocYn = 'Y'
        vsrRestType = 'Y'
      }
      console.log('@@@@ disabledType:', disabledType)
      console.log('@@@@ vsrRestType: ', vsrRestType)
      if (disabledType) {
        if (
          regParams.polShtmCd === '02' ||
          regParams.cllYn === 'Y' ||
          localDate2 >= etdDate ||
          srRest === 'C'
        ) {
          this.schdBtnYn = 'N'
        }

        const inputEl = frm.getElementsByTagName('input')
        for (const el of inputEl) {
          const type = el.getAttribute('type')
          const id = el.getAttribute('id')
          console.log('@@@@ id: ', id)
          console.log('@@@@ type: ', type)
          if (disabledType) {
            if (type === 'checkbox') {
              if (vsrRestType !== 'Y') {
                el.disabled = true
              }
            } else if (type === 'text') {
              if (
                id !== 'grsWtTon' &&
                id !== 'grsWtKg' &&
                id !== 'autocomplete-form-input'
              ) {
                el.disabled = true
                this.pcupDtDisabled = true
              }
            }
          }

          if (id !== null) {
            const idNm = id.indexOf('_') > -1 ? id.split('_')[0] : id
            let checkNm =
              'dorOdrRmk|ir|cntrQty|pcupReqDt|pcupCyCd|pickUpMan|pickUpTel' // ir: 이란 입력 사항 전체
            if (
              vsrRestType === 'Y' &&
              (checkNm.indexOf(idNm) > -1 ||
                idNm.indexOf('bizPic') > -1 ||
                idNm.indexOf('docPic') > -1)
            ) {
              el.disabled = false
              this.pcupDtDisabled = false
            }
          }
        }

        const selectEl = frm.getElementsByTagName('select')
        for (const el of selectEl) {
          if (disabledType) {
            el.disabled = true
          }

          const id = el.getAttribute('id')

          if (id !== null) {
            const idNm = id.indexOf('_') > -1 ? id.split('_')[0] : id
            const checkNm =
              'cntrSzCd|cntrTypCd|cgoTypCd|pcupReqTm|picNmh|docPicNmh'
            if (vsrRestType === 'Y' && checkNm.indexOf(idNm) > -1) {
              el.disabled = false
            }
          }
        }

        this.$nextTick(() => {
          const frm = document.querySelector('#frm')
          frm
            .querySelector('#actShprCstEnm')
            .querySelector('#autocomplete-form-input').disabled = false
        })
      }

      const textEl = frm.getElementsByTagName('textarea')
      for (const el of textEl) {
        el.readonly = true
      }

      this.$nextTick(() => {
        const frm = document.querySelector('#frm')
        if (vsrRestType === 'Y') {
          frm.querySelector('#bkgNoList').disabled = false
          if (this.regParams.podCtrCd === 'IR') {
            frm.querySelector('#reqCont').disabled = false // 이란 요청사항
            frm.querySelector('#sameas').disabled = false
          }
        }
      })

      this.disabledYn = disabledType && Number(vslRest) > 0 ? 'Y' : 'N'
      this.unnoDisabledYn = disabledType ? 'Y' : 'N'
    },
    checkMtyPkupUsagYn (e) {
      console.log('@@@@ enter checkMtyPkupUsagYn')
      const regParams = this.regParams

      const cntSzList = this.commonCodes['01031']
      const containerList = regParams.containerList
      if (e.target.checked) {
        regParams.owtrYn = 'Y'
        regParams.mtyPkupUsagYn = 'Y'

        containerList.forEach((c) => {
          c.cgoTypCd = ''
          c.containerDGList = []
        })

        this.consigneeYn = 'N'
        this.msdsCheck = 'N'

        this.$set(
          this,
          'cntSzList',
          cntSzList.filter((vo) => vo.cd !== '45')
        )
      } else {
        regParams.mtyPkupUsagYn = 'N'
        this.$set(this, 'cntSzList', cntSzList)
      }

      containerList.forEach((c) => {
        this.setCntrTypList(c)
        this.setCgoTypList(c)
      })
    },
    changePicNm (e) {
      const TH = this
      const picNo = e.target.value
      const regParams = TH.regParams

      if (picNo !== '') {
        const cstInfo = TH.cstInfoList.filter((vo) => vo.picNo === picNo)[0]

        if (cstInfo !== undefined) {
          regParams.bizPicNm = cstInfo.picNm
          regParams.bizPicEmlAddr = cstInfo.emlAddr
          regParams.bizPicTelPlcNo = cstInfo.telPlcNo
          regParams.bizPicTelOfcNo = cstInfo.telOfcNo
          regParams.bizPicFaxPlcNo = cstInfo.faxPlcNo
          regParams.bizPicFaxOfcNo = cstInfo.faxOfcNo
          regParams.bizPicMbpBsnNo = cstInfo.mbpBsnNo
          regParams.bizPicMbpOfcNo = cstInfo.mbpOfcNo

          if (TH.lang === 'KOR') {
            if (
              TH.$ekmtcCommon.isNotEmpty(regParams.bizPicTelPlcNo) &&
              regParams.bizPicTelPlcNo.substring(0, 1) !== '0'
            ) {
              regParams.bizPicTelPlcNo = '0' + regParams.bizPicTelPlcNo
            }

            if (
              TH.$ekmtcCommon.isNotEmpty(regParams.bizPicFaxPlcNo) &&
              regParams.bizPicFaxPlcNo.substring(0, 1) !== '0'
            ) {
              regParams.bizPicFaxPlcNo = '0' + regParams.bizPicFaxPlcNo
            }

            if (
              TH.$ekmtcCommon.isNotEmpty(regParams.bizPicMbpBsnNo) &&
              regParams.bizPicMbpBsnNo.substring(0, 1) !== '0'
            ) {
              regParams.bizPicMbpBsnNo = '0' + regParams.bizPicMbpBsnNo
            }
          }
        }

        this.$nextTick(() => {
          const arrChargeEl = [
            'bizPicNm',
            'bizPicTelPlcNo',
            'bizPicTelOfcNo',
            'bizPicFaxPlcNo',
            'bizPicFaxOfcNo',
            'bizPicMbpBsnNo',
            'bizPicMbpOfcNo',
            'bizPicEmlAddr'
          ]

          if (regParams.polCtrCd !== 'VN') {
            arrChargeEl.forEach((el) => {
              TH.checkValidationCharger(el, 'KEYUP')
            })
          }
        })
      } else {
        regParams.bizPicNm = ''
        regParams.bizPicEmlAddr = ''
        regParams.bizPicTelPlcNo = ''
        regParams.bizPicTelOfcNo = ''
        regParams.bizPicFaxPlcNo = ''
        regParams.bizPicFaxOfcNo = ''
        regParams.bizPicMbpBsnNo = ''
        regParams.bizPicMbpOfcNo = ''
      }
    },
    changeDocPicNmh (e) {
      const picNo = e.target.value
      const regParams = this.regParams

      if (picNo !== '') {
        const cstInfo = this.cstInfoList.filter((vo) => vo.picNo === picNo)[0]

        if (cstInfo !== undefined) {
          regParams.docPicNm = cstInfo.picNm
          regParams.docPicEmlAddr = cstInfo.emlAddr
          regParams.docPicTelPlcNo = cstInfo.telPlcNo
          regParams.docPicTelNo = cstInfo.telOfcNo
          regParams.docPicFaxPlcNo = cstInfo.faxPlcNo
          regParams.docPicFaxNo = cstInfo.faxOfcNo
          regParams.docPicMbpBsnNo = cstInfo.mbpBsnNo
          regParams.docPicMbpNo = cstInfo.mbpOfcNo

          if (this.lang === 'KOR') {
            if (
              this.$ekmtcCommon.isNotEmpty(regParams.docPicTelPlcNo) &&
              regParams.docPicTelPlcNo.substring(0, 1) !== '0'
            ) {
              regParams.docPicTelPlcNo = '0' + regParams.docPicTelPlcNo
            }

            if (
              this.$ekmtcCommon.isNotEmpty(regParams.docPicFaxPlcNo) &&
              regParams.docPicFaxPlcNo.substring(0, 1) !== '0'
            ) {
              regParams.docPicFaxPlcNo = '0' + regParams.docPicFaxPlcNo
            }

            if (
              this.$ekmtcCommon.isNotEmpty(regParams.docPicMbpBsnNo) &&
              regParams.docPicMbpBsnNo.substring(0, 1) !== '0'
            ) {
              regParams.docPicMbpBsnNo = '0' + regParams.docPicMbpBsnNo
            }
          }
        }
      } else {
        regParams.docPicNm = ''
        regParams.docPicEmlAddr = ''
        regParams.docPicTelPlcNo = ''
        regParams.docPicTelNo = ''
        regParams.docPicFaxPlcNo = ''
        regParams.docPicFaxNo = ''
        regParams.docPicMbpBsnNo = ''
        regParams.docPicMbpNo = ''
      }
    },
    checkValidationVanningInfo (id) {
      let isOk = true
      const frm = document.querySelector('#frm')
      const selector = frm.querySelector('#' + id)

      this.$ekmtcCommon.hideErrorTooltip(selector)
      if (
        selector.disabled !== true &&
        this.$ekmtcCommon.isEmpty(selector.value)
      ) {
        this.$ekmtcCommon.showErrorTooltip(
          selector,
          this.$t('msg.CSBK100.147')
        )
        isOk = false
      }

      return isOk
    },
    changeFactoryInfo (e) {
      const value = e.target.value
      const regParams = this.regParams

      if (value !== '') {
        const arrValues = value.split('$')

        if (arrValues.length > 0) {
          regParams.facPicNm = arrValues[0]
          regParams.facCtfcNo = arrValues[1]
          regParams.facNm = arrValues[2]

          this.$ekmtcCommon.hideErrorTooltip('#facPicNm')
          this.$ekmtcCommon.hideErrorTooltip('#facCtfcNo')
        }
      } else {
        regParams.facPicNm = ''
        regParams.facCtfcNo = ''
        regParams.facNm = ''
      }
    },
    templateEnter () {
      this.templateSearch()
    },
    templateSearch () {
      // KMTC ON 부킹시 불러오기 적용은 추후 개발 예정임
      if (this.regParams.kmtcSpotYn === 'Y') {
        this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.511'))
        return
      }

      this.popParams = {
        templateNo: this.templateNo,
        podCtrCd: this.regParams.podCtrCd,
        podPortCd:
          this.regParams.podPortCd === 'PNC' ? 'PUS' : this.regParams.podPortCd,
        polCtrCd: this.regParams.polCtrCd,
        polPortCd:
          this.regParams.polPortCd === 'PNC' ? 'PUS' : this.regParams.polPortCd
      }

      this.selectFunc = this.getTemplateInfo
      this.openPopup('booking-call-pop')
    },
    getTemplateInfo (obj) {
      const bkgNo = obj.bkgNo
      this.templateNo = bkgNo

      this.setTemplateInfo()
    },
    async setTemplateInfo () {
      // KMTC ON 부킹시 불러오기 적용은 추후 개발 예정임
      if (this.regParams.kmtcSpotYn === 'Y') {
        this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.511'))
        return
      }

      const bkgNo = this.regParams.templateCd === 'A' ? this.templateNo : this.templateNo_2
      if (this.$ekmtcCommon.isNotEmpty(bkgNo)) {
        const TH = this
        await trans.getTemplateInfo(bkgNo).then((res) => {
          const result = res.data

          if (this.$ekmtcCommon.isNotEmpty(result)) {
            this.regParams.containerList = []
            this.$store.commit('schedule/defaultAssign', {
              key: 'containerList',
              value: [],
              test: '1'
            })

            // DG 컨테이너 삭제된 내용 Ui 반영 후 처리
            this.$nextTick(() => {
              // 컨테이너의 DG에 VS_UNNO FLASH POINT RANGE 정보 반영
              Array.isArray(result.containerList) &&
                result.containerList.forEach((item, index) => {
                  // await console.log
                  console.log('@@@@ item: ', item)
                  Array.isArray(item.containerDGList) &&
                    item.containerDGList.forEach((_item) => {
                      transCommon.dgFPointCheck(_item.imdunNo).then((_res) => {
                        const _result = _res.data
                        if (
                          _result !== undefined &&
                          _result !== 'N' &&
                          _result.indexOf('@') > -1
                        ) {
                          _item.strFlsTmpr = Number(_result.split('@')[0])
                          _item.endFlsTmpr = Number(_result.split('@')[1])
                        }
                      })
                    })
                })
              result.templateCd = this.regParams.templateCd
              TH.setDetailInfo(result)
              TH.clearSchedule()
              this.$ekmtcCommon.alertDefault(this.$t('tem.CMAT015'))
              if (result.consYn === 'Y' && this.lang === 'KOR') { //콘솔 부킹 불러올 경우 메시지 안내
                this.consMsg()
              }
              if (this.regParams.podCtrCd === 'IR' || this.regParams.podCtrCd === 'RU') {
                if (this.regParams.iranBookingInfo.endUserList && this.regParams.iranBookingInfo.endUserList.length > 0) {
                  this.endUserList = this.regParams.iranBookingInfo.endUserList
                }
                  this.regParams.iranBookingInfo.endUsrNmCList = this.regParams.iranBookingInfo.endUsrNmCList || []
               }
            })
            this.regParams.chemicalUploadYn = 'N'
            this.regParams.chemicalYn = 'N'
          } else {
            this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.235'))
          }
        })
      } else {
        if (this.regParams.templateCd === 'A') {
          this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.235'))
        } else {
          this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.078'))
        }
      }
    },
    setDetailInfo (result) {
      console.log('@@@@ enter setDetailInfo')
      const mstDefault = {
        mtyPkupUsagYn: 'N',
        cstBidgMgrNo: '',
        docPicNm: '',
        docPicTelPlcNo: '',
        docPicTelNo: '',
        docPicFaxPlcNo: '',
        docPicFaxNo: '',
        docPicMbpBsnNo: '',
        docPicMbpNo: '',
        docPicEmlAddr: '',
        etxblEmlAddr: '',
        grsWt: '',
        polPortCd: '',
        polCtrCd: '',
        polTrmlCd: '',
        podCtrCd: '',
        podPortCd: '',
        podTrmlCd: '',
        polShtmCd: '01',
        podShtmCd: '01',
        frtPncCd: 'P',
        cneCstEnm: '',
        cmdtDsc: '',
        owtrYn: '',
        tfcoCstNm: '',
        tfcoCstNo: '',
        emptyFlag: 'F',
        zip1: '',
        zip2: '',
        facNm: '',
        facPicNm: '',
        facCtfcNo: '',
        facPicEmlAddr: '',
        cnePicNm: '',
        cnePicTelNo: '',
        cnePicFaxNo: '',
        cnePicEmlAddr: '',
        cfsFixdTypCd: '',
        cfsFixdEntrNm: '',
        actShprCstEnm: '',
        consYn: 'N',
        reuseYn: 'N',
        repsCont: '',
        docmRmk: '',
        rtnCgoYn: 'N',
        impBlNo: '',
        chclActShprCstNo: '',
        vaniSurvCstNo: '',
        expcYn: '',
        cfsCy: '',
        schFrtAppNo: '',
        schLogDtm: '',
        casNoPortYn: '',
        dpstAcCd: '',
        stuffPlceCd: '',
        bkgShprCstNo: '',
        fwdrCstNo: '',
        fwdEdiCd: '',
        dorOdrRmk: '',
        oprRmk: '',
        returnDate: '',
        containerList: [],
        iranBookingInfo: {},
        templateCd: '',
        kmtcSpotYn: 'N',
        bkgOversEditYn: 'N'
      }

      if (result !== undefined && result !== null) {
        const frm = document.querySelector('#frm')
        this.$ekmtcCommon.hideErrorTooltipAll(frm)
        this.regParams = {}
        this.regParams = { ...mstDefault, ...result }

        // 진행중인 업무 메뉴에서 유입 및 db조회 데이터에서 kmtc on 체크
        if (this.$route.params.spcShiprYn === 'Y' || this.regParams.spcShiprYn === 'Y') {
          this.regParams.kmtcSpotYn = 'Y'
        }

        if (
          this.lang === 'KOR' &&
          (result.polShtmCd === '01' || result.polShtmCd === '03')
        ) {
          if (
            result.dorOdrRmk !== undefined &&
            result.dorOdrRmk.indexOf('||') > -1
          ) {
            const rmk = result.dorOdrRmk.split('||')
            if (rmk !== undefined) {
              const arrZip = rmk[0].split(',')
              if (arrZip !== undefined && arrZip.length > 0) {
                this.regParams.zip1 = arrZip[0]
                if (arrZip.length > 1) {
                  this.regParams.zip2 = arrZip[1]
                }
              }

              // RF,RH일때 &&로 구분되어 정보가 추가됨으로 잘라준다.
              if (rmk[1].indexOf(' && ') > -1) {
                this.regParams.dorOdrRmk = rmk[1].split(' && ')[0]
              } else {
                this.regParams.dorOdrRmk = rmk[1]
              }
            }
          }

          if (
            this.$ekmtcCommon.isNotEmpty(result.dorOdrRmk) &&
            result.dorOdrRmk.indexOf('return B/L No. / ') > -1
          ) {
            const rmk = result.dorOdrRmk.split('return B/L No. / ')

            if (rmk !== undefined && rmk.length > 1) {
              this.regParams.impBlNo = rmk[1]
            }
          }
        }

        if (this.regParams.frtPncCd === 'P') {
          this.regParams.cneCstEnm = ''
        }

        const apvStsList = this.commonCodes['01043']
        let dgYn = 'N'
        let fbYn = 'N'
        let emptyFlag = 'F'
        this.regParams.containerList.forEach((info, idx) => {
          console.log('@@@@ info: ', info)
          this.setCntrTypList(info, idx)
          this.setCgoTypList(info, idx)
          if (info.cgoTypCd === '05') {
            info.chkNOR = 'Y'
            info.cgoTypCd = ''
            info.cntrRfStsCd = this.$ekmtcCommon.isNotEmpty(info.cntrRfStsCd) ? info.cntrRfStsCd : '01'
          }
          info.pcupReqDtOld = ''
          info.apvNo = ''

          if (this.$ekmtcCommon.isEmpty(info.cgoTypCd)) {
            info.cgoTypCd = ''
          }

          if (info.cgoTypCd === '01') {
            dgYn = 'Y'

            if (Array.isArray(info.containerDGList)) {
              const includeCAS = info.containerDGList.filter(
                (vo) => vo.casNoList !== undefined && vo.casNoList.length > 0
              )
              console.log('@@@@ includeCAS: ', includeCAS)
              // api 에서만 this.casNoPortYn 값 건드리는 것으로 변경
              if (includeCAS !== undefined && includeCAS.length > 0) {
                // this.casNoPortYn = 'Y'
              } else {
                info.containerDGList.forEach((item) => {
                  console.log('@@@@ item.casNoList: ', item.casNoList)
                  if (item.casNoList === undefined) {
                    item.casNoList = [
                      {
                        casNo: '',
                        rto: ''
                      }
                    ]
                  }
                })
                // this.casNoPortYn = 'Y'
              }
            } else {
              info.containerDGList = [
                {
                  dgSeq: 1,
                  imdunNo: '',
                  imdgSeq: '',
                  imoClasCd: '',
                  sbRiskVal1: '',
                  sbRiskVal2: '',
                  sbRiskVal3: '',
                  flsTmpr: '',
                  tmprUnitCd: 'C',
                  txtPackage: '',
                  grsWt: '',
                  netWt: '',
                  dgPkgGrpCd: '',
                  emrPicTelNo: '',
                  emrTelCtrNo: '',
                  tchlNm: '',
                  tchlNmMdtyYn: '',
                  dgPkgQty: '',
                  dgMatlCd: '',
                  isdDgPkgQty: '',
                  dgCatCd: '',
                  mpltCd: '',
                  emsFeCd: '',
                  emsSeCd: '',
                  lqYn: '',
                  imdgGrpCd: '',
                  imdgGrpYn: '',
                  dgRsdCd: '',
                  saptTmprUnitCd: '' /* SAPT 단위 */,
                  sp386Yn: '',
                  saptTmpr: '',
                  casNoList: [{ casNo: '', rto: '' }],
                  addInfoMsg: '',
                  addInfoMsgList: [],
                  repsCont: ''
                }
              ]
            }
          } else if (info.cgoTypCd === '06') {
            fbYn = 'Y'
          }

          if (this.$ekmtcCommon.isNotEmpty(info.pcupCyNm)) {
            info.pickUpPlace = info.pcupCyNm
          }

          if (this.$ekmtcCommon.isNotEmpty(info.pcupPic)) {
            if (this.regParams.owtrYn === 'Y') {
              info.pickUpMan = ''
              info.pickUpTel = ''
            } else if (this.regParams.owtrYn === 'N') {
              info.pickUpPlace = 'KCTC'
              info.pickUpMan = ''
              info.pickUpTel = ''
            }
          }

          if (this.regParams.owtrYn !== 'N' && this.saveFlag === 'EDIT') {
            if (this.$ekmtcCommon.isNotEmpty(info.pcupPic)) {
              info.pickUpMan = info.pcupPic.split(',')[0]
              info.pickUpTel = info.pcupPic.split(',')[1]
            }
          }

          if (this.$ekmtcCommon.isNotEmpty(info.pcupReqDt)) {
            info.pcupReqDtBak = info.pcupReqDt
            info.pcupReqDtOld = info.pcupReqDt
          }

          if (
            this.$ekmtcCommon.isNotEmpty(info.pcupReqTm) &&
            info.pcupReqTm.length > 2
          ) {
            info.pcupReqTm = info.pcupReqTm.substring(0, 2)
          }

          if (
            this.$ekmtcCommon.isNotEmpty(info.cntrTypCd) &&
            'RF|RH'.indexOf(info.cntrTypCd) > -1 &&
            // this.$ekmtcCommon.isNotEmpty(info.reqConts) &&
            info.reqConts &&
            info.reqConts.indexOf('\n') > -1
          ) {
            const splitInfo = info.reqConts.split('\n')
            const apvNo = splitInfo[0]
            const reqConts = splitInfo.join('\n')

            info.apvNo = apvNo
            info.reqConts = reqConts
          }

          // 불러오기 적용때는 status, Ref No. 표시안함 -> 2022.05.18 15:08 원복
          if (this.saveFlag === 'ADD') {
            info.scgAppNo = ''
            info.apvStsCd = ''
            this.stsAndAppNoYn = 'N'
            info.reqRno = ''
            info.scgRno = ''
          }

          if (this.$ekmtcCommon.isNotEmpty(info.apvStsCd)) {
            const apvStsInfo = apvStsList.filter(
              (vo) => vo.cd === info.apvStsCd
            )
            if (apvStsInfo !== undefined && apvStsInfo.length > 0) {
              info.apvStsNm = apvStsInfo[0].cdNm
            }
          }

          if (
            (this.$ekmtcCommon.isNotEmpty(info.cntrTypCd) &&
              'RF|RH|OT|FR|SR|TK'.indexOf(info.cntrTypCd) > -1) ||
            this.$ekmtcCommon.isNotEmpty(info.cgoTypCd) ||
            this.regParams.owtrYn === 'N' ||
            this.regParams.bkgStsCd === '03'
          ) {
            this.bookingSpecial = 'Y'
          }

          if (
            this.saveFlag === 'EDIT' &&
            this.$ekmtcCommon.isNotEmpty(info.vaniYn) &&
            info.vaniYn === 'Y' &&
            this.$ekmtcCommon.isEmpty(info.vaniDt) &&
            this.$ekmtcCommon.isEmpty(info.reqRno)
          ) {
            this.vaniEmpty = 'Y'
          }

          if (info.feCatCd === 'E') {
            emptyFlag = 'E'
          }
        })

        this.$store.commit('schedule/defaultAssign', {
          key: 'containerList',
          value: [...this.regParams.containerList]
        })

        this.regParams.emptyFlag = emptyFlag

        if (fbYn === 'Y') {
          trans.findFbCatInfo(this.regParams.bkgNo).then((res) => {
            if (res.headers.respcode === 'C0000') {
              const result = res.data
              this.fbCat01 = result.fbCat01
              this.fbCat02 = result.fbCat02
              this.fbCat03 = result.fbCat03
              this.fbCat04 = result.fbCat04
              this.fbCat05 = result.fbCat05
            }
          })
        }

        if (this.$ekmtcCommon.isNotEmpty(result.grsWt)) {
          const type = result.wtCvsCd === 'K' ? 'KG' : 'TON'
          if (type === 'TON') {
            this.grsWtTon = result.grsWt
          } else {
            this.grsWtKg = result.grsWt
          }

          this.changeGrsWt(result.grsWt, type)
        }

        if (this.$ekmtcCommon.isNotEmpty(this.regParams.actShprCstEnm)) {
          this.regParams.actShprCstEnmYn = 'Y'
        }

        if (this.saveFlag === 'EDIT') {
          if (this.regParams.mtyPkupUsagYn === 'Y') {
            this.$nextTick(() => {
              this.regParams.containerList.forEach((c, idx) => {
                frm.querySelector('#cntrSzCd_' + idx).disabled = true
                frm.querySelector('#cntrTypCd_' + idx).disabled = true
                frm.querySelector('#cntrQty_' + idx).disabled = true
                frm.querySelector('#shprVanYn_' + idx).disabled = true
                frm.querySelector('#cgoTypCd_' + idx).disabled = true
                //frm.querySelector('#pcupReqDt_' + idx).disabled = true
                this.pcupDtDisabled = true
              })
            })
          }

          if (this.lang === 'KOR') {
            trans.findBkgNoticeInfo(this.regParams.bkgNo).then((res) => {
              if (res.headers.respcode === 'C0000' && res.data !== undefined) {
                this.vslRollOverYn = res.data.vslRollOverYn
                this.delayYn = res.data.delayYn
              }
            })
          }

          if (
            (this.usrCtrCd === 'HK' ||
              this.usrCtrCd === 'SG' ||
              this.usrCtrCd === 'PH' ||
              this.usrCtrCd === 'TH' ||
              this.bkgPlcCd === 'CAN'
              ) &&
            dgYn === 'Y'
          ) {
            this.dgDclrBtnYn = 'Y'
          }

          this.$nextTick(() => {
            const iranBookingInfo = this.regParams.iranBookingInfo
            if (
              iranBookingInfo !== undefined &&
              this.$ekmtcCommon.isNotEmpty(iranBookingInfo.hsCd)
            ) {
              frm.querySelector('#ir_hsCd').disabled = false // HS CODE 존재시 수정 불가처리
            }
          })

          this.setVslCloseInfo(this.regParams, this.regParams.rteCd)

          this.fnDgShipCheck()

          this.chemicalChk()
          // 이란, 러시아 부킹일때 END_USER 가져오기
          if (this.regParams.podCtrCd === 'IR' || this.regParams.podCtrCd === 'RU') {
            if (this.regParams.iranBookingInfo.endUserList && this.regParams.iranBookingInfo.endUserList.length > 0) {
              this.endUserList = this.regParams.iranBookingInfo.endUserList
            }
            this.regParams.iranBookingInfo.endUsrNmCList = this.regParams.iranBookingInfo.endUsrNmCList || []
          }
        }
      }
      this.btnAreaVisible = true
    },
    vslCancelReqYn () {
      const cgoRest = this.cgoRest
      let serverDt =
        this.serverYear +
        this.serverMonth +
        this.serverDay +
        this.serverHour +
        this.serverMin

      if (
        this.$ekmtcCommon.isNotEmpty(cgoRest) &&
        Number(cgoRest) < Number(serverDt)
      ) {
        return true
      } else {
        return false
      }
    },
    transCompanySearch () {
      this.selectFunc = this.transCompanySelect

      this.openPopup('trans-company-pop')
    },
    transCompanySelect (obj) {
      this.regParams.tfcoCstNm = obj.tfcoCstNm
      this.regParams.tfcoCstNo = obj.tfcoCstNo
    },
    customerSearch (e) {
      const keyword = e
      const TH = this
      commons.getCommonsCodesCustomer(keyword, this.usrCtrCd).then((data) => {
        const list = data.data
        if (list === undefined || list === null || list.length === 0) {
          TH.customerList = []
        } else {
          TH.customerList = list.map((vo) => vo.cdNm)
        }
      })
    },
    changeDocInfo () {
      if (this.disabledYn === 'Y' || this.schdBtnYn === 'N') {
        return
      }

      const regParams = this.regParams

      this.docPicNmh = this.picNmh
      regParams.docPicNm = regParams.bizPicNm
      regParams.docPicTelPlcNo = regParams.bizPicTelPlcNo
      regParams.docPicTelNo = regParams.bizPicTelOfcNo
      regParams.docPicFaxPlcNo = regParams.bizPicFaxPlcNo
      regParams.docPicFaxNo = regParams.bizPicFaxOfcNo
      regParams.docPicMbpBsnNo = regParams.bizPicMbpBsnNo
      regParams.docPicMbpNo = regParams.bizPicMbpOfcNo
      regParams.docPicEmlAddr = regParams.bizPicEmlAddr
    },
    changeCmdtDsc (vo) {
      this.regParams.cmdtDsc = vo.cdNm
      if (this.$ekmtcCommon.isNotEmpty(vo.cdNm)) {
        const frm = document.querySelector('#frm')
        const selector = frm.querySelector('#cmdtDsc')
        this.$ekmtcCommon.hideErrorTooltip(selector)
      }
    },
    changeActShprCstENm (vo) {
      this.regParams.actShprCstEnm = vo.cdNm
      this.regParams.fnlActShprCstCd = vo.cd
      this.regParams.actShprCstEnmYn = vo.isSelected ? 'Y' : 'N'

      if (this.$ekmtcCommon.isNotEmpty(vo.cdNm)) {
        const frm = document.querySelector('#frm')
        const selector = frm.querySelector('#actShprCstEnm')
        this.$ekmtcCommon.hideErrorTooltip(selector)
      }
      if (this.regParams.consYn !== 'Y') {
        this.regParams.reuseYn = 'N'
      }
    },
    async changeCntrSz (cvo, idx, e) {
      console.log('@@@@ cvo: ', cvo)
      console.log('@@@@ e: ', e.target.value)

      if (this.regParams.kmtcSpotYn === 'Y' && e.target.value === '45') {
        e.target.value = cvo.cntrSzCd
        this.$ekmtcCommon.alertDefault(this.$t('KMTC ON 부킹은 45피트 선택이 불가합니다.'))
        return false
      }

      let _cvo = { ...cvo }
      _cvo.cntrTypCd = ''
      _cvo.cgoTypCd = ''
      _cvo.cntrSzCd = e.target.value

      if (_cvo.cntrSzCd === '45') {
        _cvo.cntrTypCd = 'HC'
        _cvo.cgoTypCd = '03'
        _cvo.pcupReqDt = ''
        _cvo.pcupCyCd = ''
        _cvo.pickUpPlace = ''
        _cvo.pickUpMan = ''
        _cvo.pickUpTel = ''
        _cvo.pcupReqDtBak = ''
        _cvo.rfHmpgUrl = ''

        await this.setCntrTypList(_cvo, idx)
        this.setCntrPickInfo(_cvo, idx)
      } else {
        this.setCntrTypList(_cvo, idx)
      }

      this.setCgoTypList(_cvo, idx)
    },
    changeCntrTyp (cvo, idx, e) {
      console.log('@@@@ cvo: ', cvo)
      console.log('@@@@ cvo.pickUpPlace: ', cvo.pickUpPlace)
      console.log('@@@@ idx: ', idx)
      console.log('@@@@ e: ', e.target.value)
      let oldCvo = this.oldContainerList[idx]
      console.log('@@@@ oldCvo: ', oldCvo)

      if (Number(this.vslRest) > 0 && this.saveFlag === 'EDIT' && oldCvo) {
        if (
          e.target.value === 'RF' ||
          e.target.value === 'RH' ||
          e.target.value === 'TK' ||
          e.target.value === 'OT' ||
          e.target.value === 'FR' ||
          e.target.value === 'SR'
        ) {
          //
          if (oldCvo.cntrTypCd !== 'RF') {
            this.$ekmtcCommon.alertDefault(
              this.$t(
                '선박이 close 되면 일반 Dry, HIGH CUBE 컨테이너에 한해서 수정 가능합니다.'
              )
            )
            e.target.value = cvo.cntrTypCd
            return false
          } else {
            if (e.target.value !== 'RF' && e.target.value !== 'RH') {
              this.$ekmtcCommon.alertDefault(
                this.$t(
                  '선박이 close 되면 일반 Dry, HIGH CUBE 컨테이너에 한해서 수정 가능합니다.'
                )
              )
              e.target.value = cvo.cntrTypCd
              return false
            }
          }
        }
      }

      let _cvo = {
        cntrSzCd: cvo.cntrSzCd
      }
      // let _cvo = [...this.$store.state.schedule.containerList]
      // let _cvo = { ...this._containerList[idx] }
      console.log('@@@@ before _cvo: ', _cvo)
      _cvo.cntrTypCd = e.target.value
      const regParams = this.regParams
      const polShtmCd = regParams.polShtmCd

      if (_cvo.cntrTypCd === 'RF' || _cvo.cntrTypCd === 'RH') {
        _cvo.cntrRfStsCd = '01'
        _cvo.rfTmprUnitCd = 'C'

        if (polShtmCd === '02') {
          // CFS는 RF 부킹 불가
          this.$ekmtcCommon.alertDefault(this.$t('js.booking.023'))
          _cvo.cntrTypCd = ''
          return
        }

        this.setRfTeuCount()
      } else {
        _cvo.rfTmprUnitCd = ''
        _cvo.cntrRfStsCd = ''
        _cvo.chkNOR = ''
        _cvo.apvNo = ''
        _cvo.iotEqpmYn = ''
        _cvo.iotCntrYn = ''
      }

      var dgYn = 'N'

      // 위험물 등록되어있을때, 컨테이너 타입 변경시 초기화 되지 않도록함
      if ((_cvo.cntrTypCd === 'OT' ||
         _cvo.cntrTypCd === 'GP' ||
         _cvo.cntrTypCd === 'RF' ||
         _cvo.cntrTypCd === 'TK' ||
         _cvo.cntrTypCd === 'HC' ||
         _cvo.cntrTypCd === 'RH') && cvo.cgoTypCd === '01' && !this.$ekmtcCommon.isEmpty(cvo.containerDGList)) {
          dgYn = 'Y'
      }

      if (
        (_cvo.cntrTypCd === 'OT' && dgYn === 'N') ||
        _cvo.cntrTypCd === 'FR' ||
        _cvo.cntrTypCd === 'SR'
      ) {
          _cvo.cgoTypCd = '02'
          if (this.lang === 'KOR' && this.$ekmtcCommon.isEmpty(cvo.underDckCd)) {
            _cvo.underDckCd = this.kmdVslCheck === 'Y' ? '02' : '01'
          } else if (this.$ekmtcCommon.isEmpty(cvo.underDckCd)) {
            _cvo.underDckCd = '01'
          }

          if (this.$ekmtcCommon.isEmpty(cvo.bulkYn)) {
            _cvo.bulkYn = 'N'
          }
      } else if (dgYn === 'N') {
        _cvo.cgoTypCd = ''
        // _cvo.containerDGList = []
      }

      this.setCgoTypList(_cvo, idx)
      console.log('@@@@ _cvo: ', _cvo)

      if (regParams.mtyPkupUsagYn !== 'Y') {
        let _containerList = [...this.$store.state.schedule.containerList]
        _containerList = [
          ..._containerList.slice(0, idx),
          {
            ..._cvo
          },
          ..._containerList.slice(idx + 1)
        ]

        this.fnDgShipCheck(_containerList)

        if (this.cstCatCd !== 'Y') {
          let o = {
            ...cvo,
            ..._cvo
          }
          console.log('@@@@ 여기 오니?')
          this.setCntrPickInfo(o, idx)
        }

        if (_cvo.cntrTypCd === 'TK') {
          // TK 타입은 화주 컨테이너 디폴트 체크
          _cvo.shprVanYn = 'Y'
        } else if (regParams.emptyFlag !== 'E') {
          _cvo.shprVanYn = 'N'
        }

        if (polShtmCd === '02') {
          this.setCfsCodeInfo(_containerList) // CFS 반입지 담당자 정보를 다시 가져온다
        }

        if (_cvo.cntrTypCd === 'OT' || _cvo.cntrTypCd === 'FR') {
          this.oogLoiPop(this.kmdVslCheck)
        }
      }

      // let _containerList = [...this.$store.state.schedule.containerList]
      // this.$store.commit('schedule/defaultAssign', {
      //   key: 'containerList',
      //   value: [
      //     ..._containerList.slice(0, idx),
      //     {
      //       ..._cvo
      //     },
      //     ..._containerList.slice(idx + 1)
      //   ],
      //   test: 'changeCntrType'
      // })

      // Line 일 경우 픽업일만 초기화
      if (this.regParams.owtrYn === 'N') {
        //
        _cvo.pcupReqDt = ''
      } else {
        _cvo.pcupReqDt = ''
        //_cvo.pickUpPlace = ''
        _cvo.pickUpMan = ''
        _cvo.pickUpTel = ''
        //_cvo.pcupCyCd = ''
        _cvo.pcupReqDtBak = ''
        _cvo.rfHmpgUrl = ''

        // 수정시 인도네시아, 인도, 베트남인 경우 픽업장소 Clear
        if (this.saveFlag === 'EDIT') {
          // 수정
          if (['KR', 'IN', 'ID', 'VN'].indexOf(this.usrCtrCd) > -1) {
            // 한국, 인도네시아, 인도, 베트남인 경우 픽업장소 Clear
            _cvo.pcupCyCd = ''
            _cvo.pickUpPlace = ''
          }
        } else {
          // 신규
          _cvo.pcupCyCd = ''
          _cvo.pickUpPlace = ''
        }
      }

      if (['SG', 'MY', 'JP'].indexOf(this.usrCtrCd) > -1) {
        this.setCntrPcupDtAndPlace(_cvo) // 픽업일자 및 픽업장소 설정
      }

      console.log('@@@@ after _cvo: ', _cvo)

      if (_cvo.cntrSzCd === '45' && _cvo.cntrTypCd === 'HC') {
        _cvo.cgoTypCd =
          _cvo.subCgoTypList.length > 0 ? _cvo.subCgoTypList[0].cd : ''
      }
      this.$store.commit('schedule/setContainerListOnlySent', {
        index: idx,
        value: _cvo,
        test: 'changeCntrTyp'
      })

      this.checkValidationCntr('cntrTypCd', idx, 'KEYUP', e.target.value)
      if (e.target.value === 'RF' || e.target.value === 'RH') {
        this.findIotCntrYn('C', idx, _cvo)
      }
    },
    setCntrTypList (cvo, idx) {
      console.log('@@@@ enter setCntrTypList')
      console.log('@@@@ cvo: ', cvo)
      console.log('@@@@ idx: ', idx)
      const TH = this
      let subCntrTypList = []

      const rfYn = this.regParams.schRfYn

      if (rfYn !== 'Y') {
        if (TH.regParams.mtyPkupUsagYn !== 'Y') {
          subCntrTypList = this.cntrTypeList.filter(
            (vo) => vo.rmk.indexOf(cvo.cntrSzCd) > -1
          )
        } else {
          subCntrTypList = this.cntrTypeList.filter(
            (vo) =>
              vo.rmk.indexOf(cvo.cntrSzCd) > -1 &&
              (vo.cd === 'GP' || vo.cd === 'HC')
          )
        }
      } else {
        subCntrTypList = this.cntrTypeList.filter(
          (vo) =>
            vo.rmk.indexOf(cvo.cntrSzCd) > -1 &&
            (vo.cd === 'RF' || vo.cd === 'RH')
        )
      }
      //KR이 아니고, 해외부킹 승인 시점에 수정 되는 경우 컨테이너 타입 가림처리
      if (this.memberDetail.userCtrCd !== 'KR' && this.memberDetail.userCtrCd !== 'JP' && this.regParams.bkgOversEditYn === 'Y') {
        subCntrTypList = this.cntrTypeList.filter(
        (vo) =>
          vo.rmk.indexOf(cvo.cntrSzCd) > -1 &&
          (vo.cd === 'GP' || vo.cd === 'HC' || vo.cd === 'TK')
        )
      }
      TH.$set(cvo, 'subCntrTypList', subCntrTypList)

      let _containerList = [...this.$store.state.schedule.containerList]
      console.log('@@@@ _containerList.length: ', _containerList.length)

      if (idx === undefined || idx === null) {
        this.$store.commit('schedule/defaultAssign', {
          key: 'containerList',
          value: [
            {
              ...cvo
            }
          ],
          test: '1'
        })
      } else {
        this.$store.commit('schedule/defaultAssign', {
          key: 'containerList',
          value: [
            ..._containerList.slice(0, idx),
            {
              ...cvo
            },
            ..._containerList.slice(idx + 1)
          ],
          test: '1'
        })
      }
    },
    setCgoTypList (cvo, idx) {
      console.log('@@@@ enter setCgoTypList')
      console.log('@@@@ setCgoTypList cvo: ', cvo)
      console.log('@@@@ idx: ', idx)
      const regParams = this.regParams
      const polShtmCd = regParams.polShtmCd

      let subCgoTypList = []

      if (cvo.cntrTypCd !== '') {
        if (regParams.mtyPkupUsagYn !== 'Y') {
          subCgoTypList = this.cgoTypCdList.filter(
            (vo) => vo.rmk.indexOf(cvo.cntrTypCd) > -1
          )
          if (polShtmCd === '02' && regParams.polPortCd !== 'USN') {
            // 출발지가 울산인 경우 CFS booking도 위험물 선적 가능
            // 이외 CFS는 위험물 선택 불가
            subCgoTypList = this.cgoTypCdList.filter(
              (vo) => vo.rmk.indexOf(cvo.cntrTypCd) > -1 && vo.cd !== '01'
            )
          }

          if (cvo.cntrSzCd === '40' && cvo.cntrTypCd === 'GP') {
            subCgoTypList = this.cgoTypCdList.filter(
              (vo) => vo.rmk.indexOf(cvo.cntrTypCd) > -1 && vo.cd !== '06'
            )
          }

          if (this.lang !== 'KOR') {
            if (cvo.cntrSzCd === '40' && cvo.cntrTypCd === 'HC') {
              subCgoTypList = this.cgoTypCdList.filter(
                (vo) => vo.rmk.indexOf(cvo.cntrTypCd) > -1 && vo.cd !== '03'
              )
            }
          } else {
            if (cvo.cntrSzCd === '45') {
              subCgoTypList = this.cgoTypCdList.filter(
                (vo) => vo.rmk.indexOf(cvo.cntrTypCd) > -1 && vo.cd !== '01'
              )
              console.log('@@@@ subCgoTypList: ', subCgoTypList)
            }
          }
        }
        if (cvo.cntrSzCd === '40' && cvo.cntrTypCd === 'HC') {
          console.log('@@@@ subCgoTypList: ', subCgoTypList)
          subCgoTypList = subCgoTypList.filter((item) => item.cd !== '03')
        }
        this.$set(cvo, 'subCgoTypList', subCgoTypList)
         //KR이 아니고, 해외부킹 승인 시점에 수정 되는 경우 컨테이너 Special Cago Type 가림
        if (this.memberDetail.userCtrCd !== 'KR' && this.memberDetail.userCtrCd !== 'JP' && this.regParams.bkgOversEditYn === 'Y') {
          this.$set(cvo, 'subCgoTypList', [])
        }
      } else {
        this.$set(cvo, 'subCgoTypList', [])
      }

      this.$store.commit('schedule/setContainerList', {
        key: 'subCgoTypList',
        value: subCgoTypList,
        index: idx
      })
    },
    async changeCgoTyp (vo, idx, e) {
      // KMTC SPOT인 경우 특수화물 구분 자체 변경이 불가능해야 함
      if (this.regParams.kmtcSpotYn === 'Y') {
        if (e.target.value !== '') {
          this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.281'))
        }
        vo.cgoTypCd = ''
        e.target.value = ''
        return
      }

      console.log('@@@ enter changeCgoTyp')
      let _vo = { ...vo }
      console.log('@@@@ _vo: ', _vo)
      _vo.cgoTypCd = e.target.value
      // _vo.containerDGList = []

      _vo.dgYn = 'N'
      _vo.mpltYn = 'N'
      _vo.newFbYn = 'Y'

      this.dgCargoYn = 'N'
      if (_vo.cgoTypCd === '01') {
        this.dgCargoYn = 'Y'
      } else {
        this._containerList.forEach((c, i) => {
          if (c.cgoTypCd === '01') {
            this.dgCargoYn = 'Y'
          }
        })
      }

      // DG cargo 일때, 팝업
      if (_vo.cgoTypCd === '01' && this.casNoPortYn === 'Y') {
        this.$ekmtcCommon.alertDefault(`Please include compulsory data for CAS No.
                                        For cargoes destined for or passing through Shanghai, both MSDS and Chinese MSDS
                                        with compositions reflecting 100% are required.`)
      }

      if (_vo.cgoTypCd === '01' && this.$ekmtcCommon.isEmpty(_vo.containerDGList)) {
      _vo.containerDGList = [
        {
          dgSeq: '1',
          imdunNo: '',
          imdgSeq: '',
          imoClasCd: '',
          sbRiskVal1: '',
          sbRiskVal2: '',
          sbRiskVal3: '',
          flsTmpr: '',
          tmprUnitCd: 'C',
          txtPackage: '',
          grsWt: '',
          netWt: '',
          dgPkgGrpCd: '',
          emrTelCtrNo: this.usrInfo.telCtrNo,
          emrPicTelNo: '',
          emrTelNo: '',
          emrPicNm: '',
          tchlNm: '',
          dgPkgQty: '',
          dgMatlCd: '',
          isdDgPkgQty: '',
          dgCatCd: '',
          mpltYn: '',
          emsFeCd: '',
          emsSeCd: '',
          lqYn: '',
          imdgGrpCd: '',
          imdgGrpYn: '',
          dgRsdCd: '',
          saptTmprUnitCd: '' /* SAPT 단위 */,
          sp386Yn: '',
          saptTmpr: '',
          casNoList: [
            {
              casNo: '',
              rto: ''
            }
          ],
          addInfoMsg: '',
          addInfoMsgList: [],
          repsCont: ''
        }
      ]
      }

      if (_vo.cntrTypCd === 'RF' || _vo.cntrTypCd === 'RH') {
        console.log('@@@@ changeCgoTyp 1')
        let _containerList = [...this.$store.state.schedule.containerList]
        _containerList = [
          ..._containerList.slice(0, idx),
          {
            ..._vo
          },
          ..._containerList.slice(idx + 1)
        ]

        const isOk = await this.checkBookingRF(_containerList)
        if (!isOk) {
          _vo.cgoTypCd = ''

          let _containerList = [...this.$store.state.schedule.containerList]
          this.$store.commit('schedule/defaultAssign', {
            key: 'containerList',
            value: [
              ..._containerList.slice(0, idx),
              {
                ..._vo
              },
              ..._containerList.slice(idx + 1)
            ]
          })

          return
        }

        if (_vo.cgoTypCd === '01') {
          this.rfDgRequestPop(idx)
        } else {
          _vo.apvNo = ''
        }
       this.findIotCntrYn('C', idx, _vo)
      } else {
        console.log('@@@@ changeCgoTyp 2')
        _vo.apvNo = ''

        let _containerList = [...this.$store.state.schedule.containerList]
        _containerList = [
          ..._containerList.slice(0, idx),
          {
            ..._vo
          },
          ..._containerList.slice(idx + 1)
        ]

        this.fnDgShipCheck(_containerList)
      }

      _vo.apvCont = ''
      _vo.apvStsCd = ''
      _vo.itemCont = ''
      _vo.apvStsNm = ''
      _vo.scgAppNo = ''
      _vo.reqConts = ''
      _vo.reqRno = ''

      if (_vo.cgoTypCd === '') {
        // flexi init
        _vo.cmdtDsc = ''
        _vo.dgYn = 'N'
        _vo.fbCapa = '24000'
        _vo.fbGrsWt = ''
        _vo.flsTmpr = ''
        _vo.mfcoNm = ''
        _vo.mpltYn = 'N'
        _vo.newFbYn = 'Y'
        _vo.perMax = ''
        _vo.insrCertStrDt = ''
        _vo.insrCertEndDt = ''
        _vo.coaCertStrDt = ''
        _vo.coaCertEndDt = ''
        _vo.railTestDt = ''
        _vo.spcfcGrvt = ''
      }

      let _containerList = [...this.$store.state.schedule.containerList]
      this.$store.commit('schedule/defaultAssign', {
        key: 'containerList',
        value: [
          ..._containerList.slice(0, idx),
          {
            ..._vo
          },
          ..._containerList.slice(idx + 1)
        ]
      })
    },
    rfDgRequestPop (idx) {
      this.popParams = {
        selectedRow: idx,
        podPortCd:
          this.regParams.podPortCd === 'PNC' ? 'PUS' : this.regParams.podPortCd,
        polPortCd:
          this.regParams.polPortCd === 'PNC' ? 'PUS' : this.regParams.polPortCd
      }

      this.selectFunc = this.setApvNo
      this.openPopup('rf-dg-request-pop')
    },
    setApvNo (obj) {
      const regParams = this.regParams
      const containerVo = this._containerList[obj.selectedRow]

      if (obj !== undefined) {
        containerVo.apvNo = obj.apvNo
        containerVo.reqConts = ''
        this.fnDgShipCheck()
      }
    },
    checkCntrTyp (e) {
      const TH = this
      const regParams = TH.regParams
      const zip1 = regParams.zip1

      if (regParams.mtyPkupUsagYn !== 'Y') {
        if (TH.lang === 'KOR') {
          // CY 부킹, line 운송, 공장지역 없을 경우
          if (
            (regParams.polShtmCd === '01' || regParams.polShtmCd === '03') &&
            regParams.owtrYn === 'N' &&
            this.cstCatCd !== 'Y' &&
            this.$ekmtcCommon.isEmpty(zip1)
          ) {
            e.target.blur()
            this.$ekmtcCommon.alertDefault(this.$t('js.booking.008'))
            e.target.value = ''
            return
          }
        }

        // 선명/항차 없는 경우
        if (this.$ekmtcCommon.isEmpty(regParams.polPortCd)) {
          this.$ekmtcCommon.alertCallback(
            this.$t('js.booking.003'),
            function () {
              TH.searchSchedule()
            }
          )
          e.target.value = ''
        }
      }
    },
    clickPolShtm (e) {
      // 선명/항차 없는 경우
      const TH = this
      if (this.$ekmtcCommon.isEmpty(this.regParams.polPortCd)) {
        e.target.blur()
        this.$ekmtcCommon.alertCallback(this.$t('js.booking.003'), function () {
          TH.searchSchedule()
        })
      }
    },
    async changePolShtm (e) {
      console.log('@@@@ changePolShtm:', e)
      // this.regParams.containerList = []
      await this.$store.commit('schedule/defaultAssign', {
        key: 'containerList',
        value: []
      })
      // 일본 컨테이너 수량 변경 as-is 동일 처리 : term 변경시 수량 입력 가능해짐
      if (this.usrCtrCd === 'JP' && !this.jpCntrEditable) {
        const frm = document.querySelector('#frm')
        this.$nextTick(() => {
          this.regParams.containerList.forEach((c, idx) => {
            frm.querySelector('#cntrQty_' + idx).disabled = false
          })
        })

        this.jpCntrEditable = true
      }

      this.addContainer()

      if (e.target.value !== '02') {
        this.regParams.cfsFixdTypCd = ''
        this.regParams.cfsFixdEntrNm = ''
      } else {
        this.regParams.cfsFixdTypCd = '01'
        this.regParams.cfsFixdEntrNm = this.$t('js.booking.029')
        this.setCfsCodeInfo() // CFS 반입지 담당자 정보를 다시 가져온다

        if ('SG|MY|JP'.indexOf(this.usrCtrCd) > -1) {
          const frm = document.querySelector('#frm')
          this.regParams.containerList.forEach((c, i) => {
            this.pcupDtDisabled = false
          })
        }

        this.regParams.tfcoCstNm = ''
        this.regParams.tfcoCstNo = ''
        this.regParams.zip1 = ''
        this.regParams.zip2 = ''
        this.regParams.facPicNm = ''
        this.regParams.facCtfcNo = ''
        this.regParams.facPicEmlAddr = ''
        this.regParams.facNm = ''
      }

      // 마감정보에 반입지/담당자 정보 반영
      if (this.$ekmtcCommon.isNotEmpty(this.bkgCloseInfo)) {
        this.bkgCloseInfo.polShtmCd = this.regParams.polShtmCd
      }

      //출항일 기준. 8월 28일 ~ 10월 1일
      //스케쥴 KR -> JP 제외한 국가 HBGT 터미널로 접안
      //CFS Term 선택 시, 메시지 안내 후 부킹 불가
      const date = new Date('2024.10.01 23:59:59')
      //출항일
      let etd = this.regParams.polEtd

      if (etd !== null && etd !== '') {
        etd = new Date(etd.substring(0, 4) + '.' + etd.substring(4, 6) + '.' + etd.substring(6, 8))

        //10월 1일 이전
        if (etd < date) {
          //터미널이 HBGT
          if (this.regParams.polCtrCd === 'KR' && this.regParams.polTrmlCd === 'HBGT') {
            if (this.regParams.podCtrCd !== 'JP' && this.regParams.polShtmCd === '02') {
              let msg = '허치슨 신감만 이전으로 선사 CFS 서비스 일시중단 합니다.\n' +
                'CFS 업체에 다이렉트로 문의바랍니다.'
              this.$ekmtcCommon.alertDefault(msg)
              this.regParams.polShtmCd = '01'
            }
          }
        }
      }
    },
    changeZip1 () {
      console.log('@@@@ enter changeZip1')
      const TH = this
      const regParams = TH.regParams
      let _containerList = [...this._containerList]

      // 선명/항차 없는 경우
      if (this.$ekmtcCommon.isEmpty(regParams.polPortCd)) {
        regParams.zip1 = ''
        this.$ekmtcCommon.alertCallback(this.$t('js.booking.003'), function () {
          TH.searchSchedule()
        })
      }

      if (regParams.polShtmCd === '02') {
        return
      }

      if (regParams.polShtmCd === '01' || regParams.polShtmCd === '03') {
        console.log('@@@@ here1')
        _containerList.forEach((c, i) => {
          console.log('@@@@ c: ', c)
          if (regParams.owtrYn === 'N') {
            this.setCntrPickInfo(c, i)
          } else {
            c.pcupCyCd = ''
            c.pickUpMan = ''
            c.pickUpTel = ''
            c.pickUpPlace = ''
            c.pcupReqDt = ''

            this.removePcupErrTooltip(i)
          }
        })
      }

      // commit 필요
      this.checkValidationTrans('zip1')
    },
    getPickupPlace (idx) {
      const TH = this
      const regParams = TH.regParams
      if (
        regParams.owtrYn === 'N' &&
        regParams.mtyPkupUsagYn !== 'Y' &&
        this.lang === 'KOR'
      ) {
        console.log('@@@@ 1 return')
        return
      }

      if (
        TH.usrCtrCd !== 'ID' &&
        TH.usrCtrCd !== 'IN' &&
        TH.usrCtrCd !== 'VN' &&
        this.lang !== 'KOR'
      ) {
        console.log('@@@@ 2 return')
        return
      }

      if (this.cstCatCd === 'Y') {
        console.log('@@@@ 3 return')
        return
      }

      const cvo = this._containerList[idx]
      // 픽업 부킹 등록 -> 수정일 때 기존 등록 내용 변경 못하도록 처리
      if (
        this.$ekmtcCommon.isNotEmpty(cvo.pcupReqDtOld) &&
        this.$ekmtcCommon.isNotEmpty(cvo.pcupCyCd) &&
        regParams.mtyPkupUsagYn === 'Y'
      ) {
        console.log('@@@@ 4 return')
        return
      }

      if (this.lang === 'KOR' && cvo.shprVanYn === 'Y') {
        console.log('@@@@ 5 return')
        return
      }

      if (this.$ekmtcCommon.isEmpty(cvo.cntrSzCd)) {
        console.log('@@@@ 6 return')
        this.$ekmtcCommon.alertDefault(this.$t('js.booking.009'))
        return
      }

      if (this.$ekmtcCommon.isEmpty(cvo.cntrTypCd)) {
        console.log('@@@@ 7 return')
        this.$ekmtcCommon.alertDefault(this.$t('js.bookingValidation.006'))
        return
      }

      if (this.$ekmtcCommon.isEmpty(cvo.pcupReqDt)) {
        console.log('@@@@ 8 return')
        this.$ekmtcCommon.alertDefault(this.$t('js.booking.021'))
        return
      }

      if (cvo.pcupReqDt.length !== 8) {
        console.log('@@@@ 9 return')
        this.$ekmtcCommon.alertDefault(this.$t('js.bookingValidation.022'))
        return
      }

      const polPortCd = this.$ekmtcCommon.isNotEmpty(this.regParams.polPortCd)
        ? this.regParams.polPortCd
        : 'USN'

      this.popParams = {
        cntrSize: cvo.cntrSzCd,
        cntrType: cvo.cntrTypCd,
        polPortCd: polPortCd,
        pickupDate: cvo.pcupReqDt,
        selectRow: idx,
        mtyPkupUsagYn: this.regParams.mtyPkupUsagYn,
        iotCntrYn: cvo.iotEqpmYn,
        polCtrCd: this.$ekmtcCommon.isNotEmpty(this.regParams.polCtrCd) ? this.regParams.polCtrCd : ''
      }

      this.selectFunc = this.pickUpSelect

      this.openPopup('pick-up-search-pop')
    },
    pickUpSelect (obj) {
      // const containerVo = this.regParams.containerList[obj.selectIdx]
      console.log('@@@@ obj: ', obj)
      const containerVo = { ...this._containerList[obj.selectIdx] }

      containerVo.pickUpPlace = obj.cyNm
      containerVo.pickUpMan = obj.picNm
      containerVo.pickUpTel = obj.picTel
      containerVo.pcupCyCd = obj.cyCd
      containerVo.pcupReqDtBak = containerVo.pcupReqDt
      containerVo.rfHmpgUrl = obj.rfHmpgUrl
      console.log('@@@@ containerVo: ', containerVo)

      this.checkValidationCntr('pcupCyCd', obj.selectIdx, 'KEYUP', containerVo)
    },
    setCntrPickInfo (cvo, index) {
      console.log('@@@@ enter setCntrPickInfo')
      const regParams = this.regParams
      const polPortCd = regParams.polPortCd
      const zip1 = regParams.zip1
      console.log('@@@@ zip1: ', zip1)
      console.log('@@@@ cvo.cntrTypCd:', cvo.cntrTypCd)
      let _cvo = {}

      _cvo.pickUpPlace = ''
      _cvo.pickUpMan = ''
      _cvo.pickUpTel = ''
      _cvo.pcupCyCd = ''

      if (
        (regParams.polShtmCd === '01' || regParams.polShtmCd === '03') &&
        regParams.owtrYn === 'N' &&
        this.lang === 'KOR'
      ) {
        console.log('@@@@ in pickinfo')
        _cvo.pickUpPlace = 'KCTC'
        if (
          this.$ekmtcCommon.isNotEmpty(zip1) &&
          this.$ekmtcCommon.isNotEmpty(cvo.cntrTypCd)
        ) {
          if (polPortCd === 'PUS' || polPortCd === 'PNC') {
            if (
              zip1 === this.$t('js.booking.004') ||
              zip1 === this.$t('js.booking.005') ||
              zip1 === this.$t('js.booking.006') ||
              zip1 === this.$t('js.booking.007')
            ) {
              if (cvo.cntrTypCd === 'GP' || cvo.cntrTypCd === 'HC') {
                _cvo.pcupCyCd = 'KBGK'
              } else {
                _cvo.pcupCyCd = 'ICT:HBGT:UAM'
              }
            } else {
              if (cvo.cntrTypCd === 'GP' || cvo.cntrTypCd === 'HC') {
                _cvo.pcupCyCd = 'UAM'
              } else {
                _cvo.pcupCyCd = 'HBGT:UAM'
              }
            }

            if (_cvo.pcupCyCd === 'UAM') {
              if (cvo.cntrTypCd === 'HC' && cvo.cntrSzCd === '40') {
                _cvo.pcupCyCd = 'HBGT'
              }

              if (cvo.cntrTypCd === 'GP') {
                _cvo.pcupCyCd = 'HBGT'
              }
            }
          } else if (polPortCd === 'INC') {
            if (
              zip1 === this.$t('js.booking.004') ||
              zip1 === this.$t('js.booking.005') ||
              zip1 === this.$t('js.booking.006') ||
              zip1 === this.$t('js.booking.007')
            ) {
              if (cvo.cntrTypCd === 'GP' || cvo.cntrTypCd === 'HC') {
                _cvo.pcupCyCd = 'KINC'
              } else {
                _cvo.pcupCyCd = 'ICT:IKEC'
              }
            } else {
              if (cvo.cntrTypCd === 'GP' || cvo.cntrTypCd === 'HC') {
                _cvo.pcupCyCd = 'HBGT'
              } else {
                _cvo.pcupCyCd = 'HBGT'
              }
            }
          } else if (polPortCd === 'KAN') {
            if (
              zip1 === this.$t('js.booking.004') ||
              zip1 === this.$t('js.booking.005') ||
              zip1 === this.$t('js.booking.006') ||
              zip1 === this.$t('js.booking.007')
            ) {
              if (cvo.cntrTypCd === 'GP' || cvo.cntrTypCd === 'HC') {
                _cvo.pcupCyCd = 'KBGK'
              } else {
                _cvo.pcupCyCd = 'KIT'
              }
            } else {
              _cvo.pcupCyCd = 'KIT'
            }
          } else {
            if (
              zip1 === this.$t('js.booking.004') ||
              zip1 === this.$t('js.booking.005') ||
              zip1 === this.$t('js.booking.006') ||
              zip1 === this.$t('js.booking.007')
            ) {
              if (cvo.cntrTypCd === 'GP' || cvo.cntrTypCd === 'HC') {
                _cvo.pcupCyCd = 'KBGK'
              } else {
                _cvo.pcupCyCd = 'UNCT'
              }
            } else {
              _cvo.pcupCyCd = 'UNCT'
            }
          }

          if (
            cvo.cntrTypCd === 'RF' ||
            cvo.cntrTypCd === 'RH' ||
            cvo.cntrTypCd === 'OT' ||
            cvo.cntrTypCd === 'FR'
          ) {
            if (polPortCd === 'INC') {
              _cvo.pcupCyCd = 'ICT'
            } else {
              _cvo.pcupCyCd = 'HBGT'
            }
          }
          console.log('@@@@ _cvo: ', _cvo)

          // let sumcvo = {
          //   ...cvo,
          //   ..._cvo
          // }

          const params = {
            pcupCyCd: _cvo.pcupCyCd,
            cntrSzCd: cvo.cntrSzCd,
            cntrTypCd: cvo.cntrTypCd,
            polPortCd: polPortCd
          }
          console.log('@@@@ before trans.getPickupOne')
          trans.getPickupOne(params).then((res) => {
            const result = res.data
            if (result !== null && result !== undefined) {
              result.forEach((c) => {
                if (
                  Number(c.rstChk) === 0 &&
                  this.$ekmtcCommon.isNotEmpty(c.cyCd)
                ) {
                  _cvo.pcupCyCd = c.cyCd
                } else if (Number(c.rstChk) === 1 && cvo.cntrTypCd === 'SR') {
                  this.$ekmtcCommon.alertDefault(this.$t('js.booking.032'))
                  _cvo.cntrTypCd = ''
                }

                this.$store.commit('schedule/setContainerListOnlySent', {
                  index: index,
                  value: _cvo,
                  test: 'setCntrPickInfo if1'
                })
              })
            } else {
              this.$store.commit('schedule/setContainerListOnlySent', {
                index: index,
                value: _cvo,
                test: 'setCntrPickInfo if2'
              })
            }
          })
        } else {
          //
          console.log('@@@@ else')
          this.$store.commit('schedule/setContainerListOnlySent', {
            index: index,
            value: _cvo,
            test: 'setCntrPickInfo else'
          })
        }

        this.$ekmtcCommon.hideErrorTooltip('#pcupCyCd_' + index)
      }

      // let _containerList = [...this.$store.state.schedule.containerList]
      // this.$store.commit('schedule/defaultAssign', {
      //   key: 'containerList',
      //   value: [
      //     ..._containerList.slice(0, index),
      //     {
      //       ...cvo
      //     },
      //     ..._containerList.slice(index + 1)
      //   ]
      // })

      if (this.lang !== 'KOR') {
        this.setPickPlaceInfo()
      }
    },
    checkZip1 (e) {
      const TH = this
      const regParams = TH.regParams

      // 선명/항차 없는 경우
      if (this.$ekmtcCommon.isEmpty(regParams.polPortCd)) {
        e.target.blur()
        e.target.value = ''
        this.$ekmtcCommon.alertCallback(this.$t('js.booking.003'), function () {
          TH.searchSchedule()
        })
      }
    },
    changeGrsWt (value, type) {
      console.log('@@@@ enter changeGrsWt')
      console.log('@@@@ value: ', value)
      console.log('@@@@ type: ', type)
      if (this.$ekmtcCommon.isEmpty(value)) {
        this.isKg = false
        this.isTon = false
        this.regParams.grsWt = ''
        this.regParams.wtCvsCd = ''
        this.grsWtKg = ''
        this.grsWtTon = ''

        this.checkValidationWeight()
      } else {
        if (type === 'TON') {
          const ton = this.grsWtTon
          const kg = Number(ton) * 1000

          this.grsWtKg = kg
          this.regParams.grsWt = ton
          this.regParams.wtCvsCd = 'T'
          this.isKg = false
          this.isTon = true
        } else {
          console.log('@@@@ changeGrsWt else')
          const kg = this.grsWtKg
          const ton = (Number(kg) * 1000) / 1000000
          this.grsWtTon = ton

          this.regParams.grsWt = kg
          this.regParams.wtCvsCd = 'K'

          this.isKg = true
          this.isTon = false
        }

        this.checkValidationWeight()
      }
    },
    async checkShprVanYn (e, vo, idx) {
      console.log('@@@@ e: ', e.target.value)
      // let _vo = { ...vo }
      // let _vo = { ...this._containerList[idx] }
      let _cvo = {}
      _cvo.shprVanYn = e.target.checked ? 'Y' : 'N'
      if (e.target.checked && this.lang === 'KOR') {
        await this.$ekmtcCommon.asyncAlertMessage({
          message: this.$t('js.booking.014')
        })
      }

      if (e.target.checked === false) {
        this.regParams.emptyFlag = 'F'
      } else {
        _cvo.pcupReqDt = ''
        _cvo.pickUpPlace = ''
        _cvo.pickUpMan = ''
        _cvo.pickUpTel = ''
        _cvo.pcupCyCd = ''
        _cvo.pcupReqDtBak = ''
        _cvo.rfHmpgUrl = ''
      }

      this.$store.commit('schedule/setContainerListOnlySent', {
        index: idx,
        value: _cvo,
        test: 'checkShprVanYn'
      })
      // const frm = document.querySelector('#frm')
      // if (e.target.checked && (this.regParams.owtrYn === 'Y' || this.lang !== 'KOR')) {
      //   _vo.pcupReqDt = ''
      //   _vo.pcupReqTm = ''
      //   _vo.pcupCyCd = ''
      //   _vo.pickUpPlace = ''
      //   _vo.shprVanYn = 'Y'

      //   this.removePcupErrTooltip(idx)
      // } else {
      //   if (this.regParams.emptyFlag === 'E') {
      //     this.$ekmtcCommon.alertDefault(this.$t('js.booking.030'))
      //     e.target.checked = true
      //     _vo.shprVanYn = 'Y'
      //     return
      //   } else {
      //     _vo.shprVanYn = 'N'
      //   }

      // }
      // this.setCntrPickInfo(_vo, idx)

      /*
      if (this.cstCatCd === 'Y') {
        this.setPickPlaceInfo()
      }
      */
    },
    changeEmptyFlag (e) {
      const frm = document.querySelector('#frm')
      const containerList = this.regParams.containerList
      if (e.target.checked) {
        if (this.regParams.polShtmCd !== '02') {
          containerList.forEach((c, idx) => {
            // frm.querySelector('#shprVanYn_' + idx).checked = true
            // c.shprVanYn = 'Y'

            if (this.regParams.owtrYn === 'N') {
              this.setCntrPickInfo(c, idx)
            } else {
              c.pcupCyCd = ''
              c.pickUpMan = ''
              c.pickUpTel = ''
              c.pickUpPlace = ''
              c.pcupReqDt = ''

              this.removePcupErrTooltip(idx)
            }
          })
        }

        // this.regParams.cmdtDsc = ''
      } else {
        containerList.forEach((c, idx) => {
          // frm.querySelector('#shprVanYn_' + idx).checked = false
          // c.shprVanYn = 'N'

          if (this.regParams.owtrYn === 'N') {
            this.setCntrPickInfo(c, idx)
          } else {
            c.pcupCyCd = ''
            c.pickUpMan = ''
            c.pickUpTel = ''
            c.pickUpPlace = ''
            c.pcupReqDt = ''

            this.removePcupErrTooltip(idx)
          }
        })
      }
    },
    changeConsYn (e) {
      if (e.target.checked && this.regParams.reuseYn === 'Y') {
        this.regParams.consYn = 'Y'
        this.regParams.actShprCstEnm = ''
        this.regParams.fnlActShprCstEnm = ''
        this.regParams.fnlActShprCstCd = ''
        this.$ekmtcCommon.hideErrorTooltip('#actShprCstEnm')
        this.consMsg() //콘솔 체크 시 메시지 안내
      } else if (e.target.checked) {
        this.regParams.consYn = 'Y'
        this.regParams.actShprCstEnm = ''
        this.regParams.fnlActShprCstEnm = ''
        this.regParams.fnlActShprCstCd = ''
        this.regParams.reuseYn = 'N'
        this.consMsg() //콘솔 체크 시 메시지 안내
      } else {
        this.regParams.consYn = 'N'
        this.$ekmtcCommon.hideErrorTooltip('#actShprCstEnm')
      }
    },
    async changeReuseYn (e) {
      if (e.target.checked) {
        if (this.regParams.consYn === 'Y') {
          this.regParams.reuseYn = 'Y'
          this.selectFunc = this.setReuseYnSet
          if (await this.fnCheckLoiReuse() !== 'Y') { //연간 LOI 제출 업체는 동의 팝업 안 뛰우도록.
            //this.openPopup('reuse-yn-pop')
          }
        } else if (this.regParams.fnlActShprCstCd === '' || (this.$ekmtcCommon.isEmpty(this.regParams.fnlActShprCstCd))) {
          this.$ekmtcCommon.alertDefault('실화주를 확인하세요.')
          this.regParams.reuseYn = 'N'
          e.target.checked = false
        } else {
          this.regParams.reuseYn = 'Y'
          this.selectFunc = this.setReuseYnSet
          if (await this.fnCheckLoiReuse() !== 'Y') { //연간 LOI 제출 업체는 동의 팝업 안 뛰우도록.
            //this.openPopup('reuse-yn-pop')
          }
        }
      } else {
        this.regParams.reuseYn = 'N'
      }
    },
    setReuseYnSet (e) {
      if (e !== 'Y') {
        this.regParams.reuseYn = 'N'
      }
      this.closePopup()
    },
    consReuseChk () {
      if (this.regParams.fnlActShprCstCd === '' || (this.$ekmtcCommon.isEmpty(this.regParams.fnlActShprCstCd))) {
        this.regParams.actShprCstEnm = ''
        this.regParams.fnlActShprCstEnm = ''
        this.regParams.fnlActShprCstCd = ''
      }
    },
    consMsg () {
      this.$ekmtcCommon.alertDefault('부킹 저장하시기 전 콘솔부킹 여부를 정확히 확인 부탁드립니다.\n(콘솔부킹 : 1개 컨테이너에 여러 실화주의 화물이\n동시에 작업하는 경우 해당됩니다.)\n\n- 콘솔부킹으로 진행 시 추후 FCL로 변경이 어려울 수 있습니다.\n- 콘솔부킹으로 진행 시 FCL 운임과 다른 운임이 적용될 수 있습니다.')
    },
    changeCfsFixdType (e) {
      const val = e.target.value

      if (val === '01') {
        this.regParams.cfsFixdEntrNm = this.$t('js.booking.029')
      } else {
        this.regParams.cfsFixdEntrNm = ''
      }
    },
    checkBidgNo (e) {
      const value = e.target.value
      if (this.$ekmtcCommon.isNotEmpty(value)) {
        trans.getBidgChk(e.target.value).then((res) => {
          const result = res.data.result

          if (result === 'N') {
            this.$ekmtcCommon.alertDefault(this.$t('js.booking.031'))
            this.regParams.cstBidgMgrNo = ''
          }
        })
      }
    },
    removePcupErrTooltip (idx) {
      const frm = document.querySelector('#frm')
      this.$ekmtcCommon.hideErrorTooltip(frm.querySelector('#pcupCyCd_' + idx))
      this.$ekmtcCommon.hideErrorTooltip(
        frm.querySelector('#pcupReqDt_' + idx)
      )
    },
    checkOwtrYn (e) {
      const regParams = this.regParams
      const TH = this
      // 선명/항차 없는 경우
      if (this.$ekmtcCommon.isEmpty(regParams.polPortCd)) {
        e.target.blur()
        this.$ekmtcCommon.alertCallback(this.$t('js.booking.003'), function () {
          TH.searchSchedule()
        })
      }
    },
    changeOwtrYn (e) {
      console.log('@@@@ enter changeOwtrYn')
      const val = e.target.value
      const regParams = this.regParams
      // const containerList = regParams.containerList
      let containerList = [...this._containerList]
      containerList = containerList.map((b, idx) =>
        Object.assign({ index: idx }, b)
      )
      const TH = this

      // 선명/항차 없는 경우
      if (this.$ekmtcCommon.isEmpty(regParams.polPortCd)) {
        this.$ekmtcCommon.alertCallback(this.$t('js.booking.003'), function () {
          TH.searchSchedule()
        })
        return
      }

      containerList.forEach((c, i) => {
        console.log('@@@@ changeOwtrYn c: ', c)
        if (regParams.owtrYn === 'N') {
          this.setCntrPickInfo(c, i)
        } else {
          c.pcupCyCd = ''
          c.pickUpMan = ''
          c.pickUpTel = ''
          c.pickUpPlace = ''
          c.pcupReqDt = ''

          this.$store.commit('schedule/defaultAssign', {
            key: 'containerList',
            value: [...containerList]
          })

          this.removePcupErrTooltip(i)
        }
      })

      const tbl = document.querySelector('.tbl_owtr_info')
      this.$ekmtcCommon.hideErrorTooltipAll(tbl)

      if (val === 'N') {
        if (this.usrCtrCd === 'KR') {
          regParams.tfcoCstNm = 'KCTC'
          regParams.tfcoCstNo = 'TRK001'
        }
        regParams.zip1 = ''
      } else {
        regParams.tfcoCstNm = ''
        regParams.tfcoCstNo = ''
        regParams.zip1 = ''
        regParams.zip2 = ''
        regParams.facPicNm = ''
        regParams.facCtfcNo = ''
        regParams.facPicEmlAddr = ''
      }
    },
    addContainer () {
      console.log('@@@@ addcontainer')

      // KMTC ON 부킹은 컨테이너 증량 불가함
      if (this.regParams.kmtcSpotYn === 'Y' && this.saveFlag === 'EDIT') {
        this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.513'))
        return
      }

      if (this.statusClass === 'past') return false
      const regParams = this.regParams
      const containerList = regParams.containerList
      const cntrSeq =
        containerList.length === 0
          ? 1
          : Number(containerList[containerList.length - 1].cntrSeq) + 1
      let shprVanYn = 'N'
      // if (this.disabledYn === 'Y' && this.cntrCpEditYn !== 'Y') {
      //   this.$ekmtcCommon.alertDefault(this.$t('js.booking.035'))
      //   return
      // }

      if (!this.jpCntrEditable && this.regParams.polShtmCd === '01') {
        return
      }

      if (regParams.polShtmCd !== '02' && regParams.emptyFlag === 'E') {
        shprVanYn = 'Y'
      }

      containerList.push({
        cntrSeq: cntrSeq,
        cntrSzCd: '20',
        cntrQty: '',
        pcupReqDt: '',
        pcupReqDtBak: '',
        pcupReqDtOld: '',
        rfHmpgUrl: '',
        pickUpPlace: '',
        pcupCyCd: '',
        pcupReqTm: '',
        pickUpMan: '',
        pickUpTel: '',
        shprVanYn: shprVanYn,
        feCatCd: 'F',
        cgoTypCd: '',
        cntrTypCd: '',
        rfSetupTmpr: '',
        rfTmprUnitCd: '',
        cntrRfStsCd: '',
        containerDGList: [],
        chkNOR: '',
        subCntrTypList: [],
        subCgoTypList: [],
        apvNo: '',
        //
        underDckCd: '', // JamSin
        bulkYn: 'N',
        iotEqpmYn: 'N'
      })

      let _containerList = [...this.$store.state.schedule.containerList]

      _containerList.push({
        bkgNo: this.$ekmtcCommon.isEmpty(this.templateNo) ? this.regParams.bkgNo : this.templateNo, //24.03.06 하위 컴포넌트에 부킹번호 변수 전달을 위해 추가함.
        cntrSeq: cntrSeq,
        cntrSzCd: '20',
        cntrQty: '',
        pcupReqDt: '',
        pcupReqDtBak: '',
        pcupReqDtOld: '',
        rfHmpgUrl: '',
        pickUpPlace: '',
        pcupCyCd: '',
        pcupReqTm: '',
        pickUpMan: '',
        pickUpTel: '',
        shprVanYn: shprVanYn,
        feCatCd: 'F',
        cgoTypCd: '',
        cntrTypCd: '',
        rfSetupTmpr: '',
        rfTmprUnitCd: '',
        cntrRfStsCd: '',
        containerDGList: [],
        chkNOR: '',
        subCntrTypList: [],
        subCgoTypList: [],
        apvNo: '',
        //
        underDckCd: '', // JamSin
        bulkYn: 'N',
        // flexi default value
        dgYn: 'N',
        mpltYn: 'N',
        newFbYn: 'Y',
        fbCapa: '24000',
        iotEqpmYn: 'N'
      })

      const containerLen = containerList.length
      const last = containerList[containerLen - 1]

      const _last = _containerList[_containerList.length - 1]

      if ('SG|MY|JP'.indexOf(this.usrCtrCd) > -1) {
        this.setCntrPcupDtAndPlace(_last) // 픽업일자 및 픽업장소 설정
      }

      if (this.lang !== 'KOR') {
        last.pcupReqTm = '09'
        _last.pcupReqTm = '09'
      }

      this.setCntrTypList(last)

      // 여기 문제 아닌듯
      let subCntrTypList = []

      const rfYn = this.regParams.schRfYn

      if (rfYn !== 'Y') {
        if (this.regParams.mtyPkupUsagYn !== 'Y') {
          subCntrTypList = this.cntrTypeList.filter(
            (vo) => vo.rmk.indexOf(_last.cntrSzCd) > -1
          )
        } else {
          subCntrTypList = this.cntrTypeList.filter(
            (vo) =>
              vo.rmk.indexOf(_last.cntrSzCd) > -1 &&
              (vo.cd === 'GP' || vo.cd === 'HC')
          )
        }
      } else {
        subCntrTypList = this.cntrTypeList.filter(
          (vo) =>
            vo.rmk.indexOf(_last.cntrSzCd) > -1 &&
            (vo.cd === 'RF' || vo.cd === 'RH')
        )
      }
      //KR이 아니고, 해외부킹 승인 시점에 수정 되는 경우 컨테이너 타입 일반 GP, HC외에 가림처리
      if (this.memberDetail.userCtrCd !== 'KR' && this.memberDetail.userCtrCd !== 'JP' && this.regParams.bkgOversEditYn === 'Y') {
        subCntrTypList = this.cntrTypeList.filter(
        (vo) =>
          vo.rmk.indexOf(_last.cntrSzCd) > -1 &&
          (vo.cd === 'GP' || vo.cd === 'HC' || vo.cd === 'TK')
        )
      }
      _last.subCntrTypList = subCntrTypList

      this.$store.commit('schedule/defaultAssign', {
        key: 'containerList',
        value: [..._containerList]
      })
    },
    removeContainer (idx) {
      // 출항일 기준 날짜 체크
      let cntrMinusChk = this.chkKmtcOnCntrInfoMinus() // false : 출항일 7일 이내, true : 출항일 7일 이전

      if (this.regParams.kmtcSpotYn === 'Y' && this.saveFlag === 'EDIT' && !cntrMinusChk) {
        this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.514'))
        return
      }

      if (this.statusClass === 'past') return false
      let _containerList = [...this.$store.state.schedule.containerList]

      this.$store.commit('schedule/defaultAssign', {
        key: 'containerList',
        value: [
          ..._containerList.slice(0, idx),
          ..._containerList.slice(idx + 1)
        ]
      })
      // if (this.disabledYn === 'Y' && this.cntrCpEditYn !== 'Y') {
      //   this.$ekmtcCommon.alertDefault(this.$t('js.booking.002'))
      //   return
      // }

      if (
        !this.jpCntrEditable &&
        this.regParams.polShtmCd === '01' &&
        idx === 0
      ) {
        // CY일때 수량 변경 불가 처리 : 첫번째 컨테이너 삭제할 수 없도록
        return
      }

      if (document.querySelector('#cntrSzCd_' + idx).disabled) {
        //
      }

      const containerList = [...this.regParams.containerList]
      containerList.splice(idx, 1)
      console.log('@@@@ containerList: ', containerList)

      const containerLen = containerList.length

      if (containerLen === 0) {
        this.addContainer()
      }

      // containerList.forEach((c, idx) => {
      //   c.cntrSeq = idx + 1
      // })

      this.regParams.containerList = containerList
      // for (let i = 0; i < containerList.length; i++) {
      //   containerList[i].cntrSeq = i + 1
      // }
    },
    checkValidationCharger (id, saveFlag) {
      const frm = document.querySelector('#frm')
      let isOk = true
      const userHkSg = this.userHkSg
      // essentialYn: 필수입력사항, telFirstYn: 지역번호 첫째자리 0체크
      const selectors = {
        bizPicNm: { essentialYn: 'Y' },
        bizPicTelPlcNo: { essentialYn: 'Y', telFirstYn: 'Y' },
        bizPicTelOfcNo: { essentialYn: 'Y' },
        bizPicFaxPlcNo: { essentialYn: 'Y', telFirstYn: 'Y' },
        bizPicFaxOfcNo: { essentialYn: 'Y' },
        bizPicMbpBsnNo: { essentialYn: 'Y', telFirstYn: 'Y' },
        bizPicMbpOfcNo: { essentialYn: 'Y' },
        bizPicEmlAddr: { essentialYn: 'Y', emailYn: 'Y' },
        docPicNm: {},
        docPicTelPlcNo: { telFirstYn: 'Y' },
        docPicTelNo: {},
        docPicFaxPlcNo: { telFirstYn: 'Y' },
        docPicFaxNo: {},
        docPicMbpBsnNo: { telFirstYn: 'Y' },
        docPicMbpNo: {},
        docPicEmlAddr: { emailYn: 'Y' }
      }

      if (userHkSg === 'Y') {
        selectors.bizPicTelPlcNo.essentialYn = 'N'
        selectors.bizPicFaxPlcNo.essentialYn = 'N'
        selectors.bizPicMbpBsnNo.essentialYn = 'N'
      }

      const selector = frm.querySelector('#' + id)
      const telFirstYn = selectors[id].telFirstYn
      this.$ekmtcCommon.hideErrorTooltip(selector)

      const essentialYn = selectors[id].essentialYn
      if (
        saveFlag === 'SAVE' &&
        essentialYn === 'Y' &&
        this.$ekmtcCommon.isEmpty(selector.value)
      ) {
        this.$ekmtcCommon.showErrorTooltip(
          selector,
          this.$t('msg.CSBK100.147')
        )
        isOk = false
      }

      if (this.$ekmtcCommon.isNotEmpty(selector.value)) {
        const firstChr = selector.value.substring(0, 1)
        if (telFirstYn === 'Y' && firstChr !== '0' && this.lang === 'KOR') {
          this.$ekmtcCommon.showErrorTooltip(
            selector,
            this.$t('msg.CSBK100.180')
          ) // 지역번호, FAX번호, 휴대폰번호는 0으로 시작합니다.
          isOk = false
        }

        const emailYn = selectors[id].emailYn
        if (emailYn === 'Y') {
          if (!this.checkValidationEmail(id)) {
            isOk = false
          }
        }
      }

      return isOk
    },
    checkValidationEmail (id) {
      const frm = document.querySelector('#frm')
      const selector = frm.querySelector('#' + id)
      let isOk = true
      if (
        selector !== undefined &&
        selector !== null &&
        this.$ekmtcCommon.isNotEmpty(selector.value)
      ) {
        if (selector.value.indexOf(',') > -1) {
          if (!this.$ekmtcCommon.multiCheckEmail(selector.value, ',')) {
            isOk = false
          }
        } else {
          if (!this.$ekmtcCommon.checkEmail(selector.value)) {
            isOk = false
          }
        }
      }
      this.$ekmtcCommon.hideErrorTooltip(selector)
      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(
          selector,
          this.$t('msg.VOSD100.043')
        ) // 이메일 체크
      }

      return isOk
    },
    checkValidationCargoInfo (id) {
      const frm = document.querySelector('#frm')
      let isOk = true
      const selector = frm.querySelector('#' + id)

      // if ((id === 'actShprCstEnm' && this.regParams.consYn === 'Y') || this.usrCtrCd === 'VN') {
      if (id === 'actShprCstEnm' && this.regParams.consYn === 'Y') {
        // 2022.06.13 베트남 출발, Ocean Freight가 collect 일때 SC.No 필수값 추가
        return true
      }

      /*
      if ((id === 'cmdtDsc' || id === 'cneCstEnm') && this.usrCtrCd !== 'ID' && this.lang !== 'KOR' && this.regParams.polCtrCd !== 'MY') {
        return true
      }
      */

      if (id === 'cmdtDsc' && this.regParams.emptyFlag === 'E') {
        return true
      }

      if (id === 'fwdEdiCd' && this.regParams.polPortCd !== 'PGU') {
        return true
      }

      this.$ekmtcCommon.hideErrorTooltip(selector)

      if (id === 'cneCstEnm') {
        if (this.regParams.frtPncCd === 'C') {
          if (this.$ekmtcCommon.isEmpty(selector.value)) {
            //비딩이 있는 경우에는 필수 해제.
            if (this.$ekmtcCommon.isEmpty(this.regParams.cstBidgMgrNo)) {
              this.$ekmtcCommon.showErrorTooltip(
                selector,
                this.$t('msg.CSBK100.147')
            )
            isOk = false
            }
          } else {
            const scNo = selector.value

            if (scNo.length !== 9) {
              this.$ekmtcCommon.showErrorTooltip(
                selector,
                this.$t('msg.CSBK100.386')
              )
              isOk = false
            } else {
              const firstCheck = scNo.substring(0, 2)
              const lastCheck = scNo.substring(2, 9)

              const firstResult = firstCheck.replace(/[A-Z]/g, '')
              const lastResult = lastCheck.replace(/[0-9]/g, '')

              if (firstResult !== '' || lastResult !== '') {
                this.$ekmtcCommon.showErrorTooltip(
                  selector,
                  this.$t('msg.CSBK100.386')
                )
                isOk = false
              }
            }
          }
        }
      } else if (id === 'cmdtDsc') {
        const cmdtDsc = document.querySelector(
          '#cmdtDsc #autocomplete-form-input'
        )
        if (
          this.$ekmtcCommon.isEmpty(this.regParams.cmdtDsc) ||
          this.$ekmtcCommon.isEmpty(cmdtDsc.value)
        ) {
          this.$ekmtcCommon.showErrorTooltip(
            selector,
            this.$t('msg.CSBK100.147')
          )
          isOk = false
        }
      } else if (id === 'actShprCstEnm') {
        if (this.$ekmtcCommon.isEmpty(this.regParams.actShprCstEnm)) {
          this.$ekmtcCommon.showErrorTooltip(
            selector,
            this.$t('msg.CSBK100.147')
          )
          isOk = false
        } else {
          if (this.regParams.actShprCstEnmYn !== 'Y') {
            this.$ekmtcCommon.showErrorTooltip(
              selector,
              this.$t('msg.CSBK100.389')
            )
            isOk = false
          }
        }
      } else {
        if (this.$ekmtcCommon.isEmpty(selector.value)) {
          this.$ekmtcCommon.showErrorTooltip(
            selector,
            this.$t('msg.CSBK100.147')
          )
          isOk = false
        }
      }

      return isOk
    },
    checkValidationWeight () {
      let isOk = true
      const frm = document.querySelector('#frm')

      if (this.lang !== 'KOR' && this.regParams.polCtrCd !== 'MY') {
        return true
      }

      const containerList = this.regParams.containerList
      const elemTon = frm.querySelector('#grsWtTon')
      const elemKg = frm.querySelector('#grsWtKg')
      const valTon = this.grsWtTon
      const valKg = this.grsWtKg
      let calWgt

      this.$ekmtcCommon.hideErrorTooltip(elemTon)
      this.$ekmtcCommon.hideErrorTooltip(elemKg)

      /* if (valTon === '' && valKg === '') {
        this.$ekmtcCommon.showErrorTooltip(elemTon, this.$t('msg.CSBK100.181')) // 중량을 ton 혹은 kg 으로 입력해 주세요.
        isOk = false
      } */

      return isOk
    },
    checkValidationRemark (event, maxlength) {
      let isOk = true
      const id = event.target.id
      const frm = document.querySelector('#frm')
      const elem = frm.querySelector('#' + id)

      this.$ekmtcCommon.hideErrorTooltip(elem)

      if (this.$ekmtcCommon.fnKeyupByteCheck(event, maxlength) > maxlength) {
        this.$ekmtcCommon.showErrorTooltip(elem, this.$t('msg.CSBK100.182')) // 내용이 너무 많습니다.
        isOk = false
      }

      return isOk
    },
    checkValidationRemarkAll () {
      let isOk = true

      if (this.lang !== 'KOR') {
        let byte = document.querySelector('#byte_dorOdrRmk').innerHTML
        if (Number(byte.replace(/,/g, '')) > 2000) {
          this.$ekmtcCommon.showErrorTooltip(
            document.querySelector('#dorOdrRmk'),
            this.$t('msg.CSBK100.182')
          ) // 내용이 너무 많습니다.
          isOk = false
        }

        byte = document.querySelector('#byte_oprRmk').innerHTML
        if (Number(byte.replace(/,/g, '')) > 2000) {
          this.$ekmtcCommon.showErrorTooltip(
            document.querySelector('#oprRmk'),
            this.$t('msg.CSBK100.182')
          ) // 내용이 너무 많습니다.
          isOk = false
        }
      }

      return isOk
    },
    checkValidationConsignee (id) {
      console.log('@@@@ enter checkValidationConsignee: ', id)
      console.log(this.regParams[id])
      let isOk = true
      const frm = document.querySelector('#frm')
      const elem = frm.querySelector('#' + id)

      this.$ekmtcCommon.hideErrorTooltip(elem)

      if (this.$ekmtcCommon.isEmpty(this.regParams[id])) {
        this.$ekmtcCommon.showErrorTooltip(elem, this.$t('msg.CSBK100.147'))
        isOk = false
      }

      return isOk
    },
    checkValidationConsigneeAll () {
      console.log('@@@@ enter checkValidationConsigneeAll')
      let isOk = true

      const prefix = ['cnePicNm', 'cnePicTelNo']
      prefix.forEach((el) => {
        if (!this.checkValidationConsignee(el)) {
          console.log('@@@@ Consignee error')
          isOk = false
        }
      })

      console.log('@@@@ checkValidationConsigneeAll return: ', isOk)
      return isOk
    },
    checkValidationTrans (id) {
      const TH = this
      const regParams = TH.regParams
      let isOk = true
      const frm = document.querySelector('#frm')

      if (
        (regParams.polShtmCd === '01' || regParams.polShtmCd === '03') &&
        regParams.owtrYn === 'N' &&
        this.cstCatCd !== 'Y'
      ) {
        const elemZip1 = frm.querySelector('#zip1')
        const elemFacPicNm = frm.querySelector('#facPicNm')
        const elemFacCtfcNo = frm.querySelector('#facCtfcNo')

        const selector = frm.querySelector('#' + id)
        this.$ekmtcCommon.hideErrorTooltip(selector)

        if (id === 'zip1' && elemZip1.value === '') {
          this.$ekmtcCommon.showErrorTooltip(
            elemZip1,
            this.$t('msg.CSBK100.147')
          )
          isOk = false
        }

        if (id === 'facPicNm' && elemFacPicNm.value.trim() === '') {
          this.$ekmtcCommon.showErrorTooltip(
            elemFacPicNm,
            this.$t('msg.CSBK100.147')
          )
          isOk = false
        }

        if (id === 'facCtfcNo' && elemFacCtfcNo.value.trim() === '') {
          this.$ekmtcCommon.showErrorTooltip(
            elemFacCtfcNo,
            this.$t('msg.CSBK100.147')
          )
          isOk = false
        }
      }

      return isOk
    },
    checkValidationRFAll (idx) {
      let isOk = true
      const prefix = ['rfSetupTmpr', 'cntrRfStsCd']

      prefix.forEach((el) => {
        if (!this.checkValidationRF(el, idx)) {
          isOk = false
        }
      })

      return isOk
    },
    checkValidationRF (prefix, idx, payload) {
      let isOk = true
      const frm = document.querySelector('#frm')
      const id = prefix + '_' + idx
      const selector = frm.querySelector('#' + id)
      // const cntrInfo = this.regParams.containerList[idx]
      const cntrInfo = { ...this._containerList[idx] }
      if (payload) {
        cntrInfo[prefix] = payload
      }

      console.log('@@@@ cntrInfo: ', cntrInfo)

      let _containerList = [...this.$store.state.schedule.containerList]
      this.$store.commit('schedule/defaultAssign', {
        key: 'containerList',
        value: [
          ..._containerList.slice(0, idx),
          {
            ...cntrInfo
          },
          ..._containerList.slice(idx + 1)
        ]
      })

      this.$ekmtcCommon.hideErrorTooltip(selector)

      if (prefix === 'rfSetupTmpr') {
        if (cntrInfo.chkNOR === 'Y') {
          return true
        }

        const tmpr = selector.value.trim()
        if (this.$ekmtcCommon.isEmpty(tmpr)) {
          this.$ekmtcCommon.showErrorTooltip(
            selector,
            this.$t('msg.CSBK100.147')
          )
          isOk = false
        } else if (
          Number(tmpr) !== -40 &&
          this.lang === 'KOR' &&
          (Number(tmpr) > 25 || Number(tmpr) < -25)
        ) {
          this.$ekmtcCommon.showErrorTooltip(
            selector,
            this.$t('js.restriction.002')
          ) // 온도는 -25도 ~ 25도까지 가능합니다. : 확인필요
          isOk = false
        }
      } else if (prefix === 'cntrRfStsCd') {
        const rfTxt =
          cntrInfo.cntrSzCd +
          cntrInfo.cntrTypCd +
          cntrInfo.rfSetupTmpr +
          cntrInfo.rfTmprUnitCd

        if (rfTxt === '40RH-40C' && this.lang === 'KOR') {
          if (cntrInfo.cntrRfStsCd !== '01') {
            this.$ekmtcCommon.showErrorTooltip(selector, $t('msg.CSBK100.183')) // Full Close만 선택 가능합니다.
            isOk = false
          }
        }
      }

      return isOk
    },
    async checkValidationCntrAll () {
      console.log('@@@@ enter checkValidationCntrAll')
      let isOk = true
      let prefix = ['cntrTypCd', 'cntrQty']

      if (this.cstCatCd !== 'Y' && this.usrCtrCd !== 'PH') {
        prefix = [
          'cntrTypCd',
          'cntrQty',
          'pcupReqDt',
          'pcupCyCd',
          'cfsFixdEntrNm'
        ]
      }

      if (this.usrCtrCd === 'AE') {
        prefix = prefix.filter(item => item !== 'pcupCyCd')
      }
      console.log('@@@@ prefix: ', prefix)
      const containerList = [...this._containerList]

      let idx = 0
      for (const c of containerList) {
        for (const el of prefix) {
          const result = await this.checkValidationCntr(el, idx, 'SAVE', c)
          console.log('@@@@ el: ', el)
          console.log('@@@@ result: ', result)

          if (!result) {
            isOk = false
            return false
          }
        }

        idx++
      }

      console.log('@@@@ checkValidationCntrAll isOk: ', isOk)
      return isOk
    },
    async checkValidationCntr (prefix, idx, flag, payload = null) {
      console.log('@@@@ enter checkValidationCntr')
      console.log('@@@@ prefix: ', prefix)
      console.log('@@@@ payload: ', payload)
      let isOk = true
      const frm = document.querySelector('#frm')
      const id = prefix + '_' + idx
      const selector = frm.querySelector('#' + id)
      console.log('@@@@ selector: ', selector)
      const regParams = this.regParams
      let cntrInfo = null

      if (typeof payload === 'object') {
        if (payload === null) {
          cntrInfo = { ...this._containerList[idx] }
        } else {
          cntrInfo = payload
        }
      } else {
        cntrInfo = { ...this._containerList[idx] }
        cntrInfo[prefix] = payload
      }
      //cntrInfo.iotCntrYn = 'Y'

      console.log('@@@@ cntrInfo: ', cntrInfo)
      let _containerList = [...this.$store.state.schedule.containerList]
      this.$store.commit('schedule/defaultAssign', {
        key: 'containerList',
        value: [
          ..._containerList.slice(0, idx),
          {
            ...cntrInfo
          },
          ..._containerList.slice(idx + 1)
        ]
      })

      this.$ekmtcCommon.hideErrorTooltip(selector)
      let errCnt = 0
      if (
        (regParams.polShtmCd === '01' || regParams.polShtmCd === '03') &&
        prefix === 'cfsFixdEntrNm'
      ) {
        return true
      } else if (regParams.polShtmCd === '02' && prefix === 'pcupCyCd') {
        return true
      }

      if (cntrInfo.shprVanYn === 'Y') {
        if (prefix === 'pcupCyCd') {
          return true
        }
      }
      console.log('@@@@ checkValidationCntr 1')

      if (prefix === 'cntrTypCd' && this.$ekmtcCommon.isEmpty(selector.value)) {
        this.$ekmtcCommon.showErrorTooltip(
          selector,
          this.$t('msg.CSBK100.147')
        )
        isOk = false
        errCnt++
      }

      // 프로모션 유입인 경우 컨테이너 타입은 GP, HC만 선택 가능하도록 변경 처리
      if (prefix === 'cntrTypCd' && this.promotionChk === 'Y') {
        if (selector.value !== 'GP' && selector.value !== 'HC') {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBL200.277'))
          isOk = false
          errCnt++
        }
      }

      // KMTC SPOT 유입인 경우 컨테이너 타입은 GP, HC만 선택 가능하도록 변경 처리
      if (prefix === 'cntrTypCd' && this.regParams.kmtcSpotYn === 'Y') {
        if (selector.value !== 'GP' && selector.value !== 'HC') {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBL200.277'))
          isOk = false
          errCnt++
        }
      }

      // 스케줄에서 RF 선택 후 부킹하는 경우 컨테이너 타입은 RF만 선택 가능하도록 변경 처리
      if (prefix === 'cntrTypCd' && this.regParams.schRfYn === 'Y') {
        if (selector.value !== 'RF' && selector.value !== 'RH') {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBL200.280'))
          isOk = false
          errCnt++
        }
      }

      if (
        prefix === 'cntrQty' &&
        (this.$ekmtcCommon.isEmpty(selector.value.trim()) ||
          Number(selector.value) < 1)
      ) {
        this.$ekmtcCommon.showErrorTooltip(
          selector,
          this.$t('msg.CSBK100.147')
        )
        isOk = false
        errCnt++

        if (!this.fnPickupChk()) return false
      }

      console.log('@@@@ checkValidationCntr 1: ', isOk)

      if (
        prefix === 'pcupReqDt' &&
        ((this.$ekmtcCommon.isEmpty(selector.value) &&
          cntrInfo.shprVanYn !== 'Y') ||
          this.$ekmtcCommon.isNotEmpty(selector.value))
      ) {
        console.log('@@@@ checkValidationCntr 3')
        const pcupReqDt = cntrInfo.pcupReqDt
        const pcupReqDate = this.$ekmtcCommon.getStrToDate(pcupReqDt)
        const today = new Date(
          Number(this.serverYear),
          Number(this.serverMonth) - 1,
          Number(this.serverDay),
          Number(this.serverHour),
          Number(this.serverMin),
          Number(this.serverSecond)
        )
        const today1 = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate()
        )

        /* if (this.$ekmtcCommon.isNotEmpty(cntrInfo.pcupReqDtOld)) {
          if (pcupReqDt.length === 8 && cntrInfo.pcupReqDtOld.length === 8 && pcupReqDt === cntrInfo.pcupReqDtOld) {
            return true
          }
        } */
        if (
          this.$ekmtcCommon.isEmpty(cntrInfo.pcupReqDtOld) ||
          cntrInfo.pcupReqDtOld !== pcupReqDt
        ) {
          // 수정시 픽업일자가 변경된 경우만 체크
          console.log('@@@@ enter pcupReqDt empty')
          // empty
          if (this.lang === 'KOR') {
            if (pcupReqDate < today1) {
              if (
                regParams.polShtmCd === '01' ||
                regParams.polShtmCd === '03'
              ) {
                console.log('@@@@ pickup error')
                this.$ekmtcCommon.showErrorTooltip(
                  selector,
                  this.$t('js.restriction.011')
                )
                this.isPickupError = true

                document.querySelector('#containerHeader').scrollIntoView({
                  behavior: 'smooth'
                })

                if (this.$ekmtcCommon.isEmpty(cntrInfo.pcupCyCd)) {
                  if (
                    cntrInfo.cntrTypCd !== 'TK' &&
                    cntrInfo.shprVanYn !== 'Y'
                  ) {
                    if (
                      regParams.polShtmCd === '01' ||
                      regParams.polShtmCd === '03'
                    ) {
                      const id = 'pcupCyCd_' + idx
                      const _selector = frm.querySelector('#' + id)

                      this.$ekmtcCommon.showErrorTooltip(
                        _selector,
                        this.$t('js.bookingValidation.010')
                      )
                    }
                  }
                }
              } else {
                this.$ekmtcCommon.showErrorTooltip(
                  selector,
                  this.$t('js.restriction.010')
                )
              }
              return false
            }
          }
        }

        if (regParams.polShtmCd === '01' || regParams.polShtmCd === '03') {
          if (
            this.$ekmtcCommon.isEmpty(selector.value) &&
            cntrInfo.cntrTypCd !== 'TK' &&
            cntrInfo.shprVanYn !== 'Y'
          ) {
            if (regParams.polCtrCd === 'PH') {
              return true
            }

            if (
              regParams.polCtrCd === 'SG' ||
              regParams.polCtrCd === 'MY' ||
              regParams.polCtrCd === 'JP'
            ) {
              cntrInfo.pcupReqDt = this.pickDateSgMy
              return true
            }

            this.$ekmtcCommon.showErrorTooltip(
              selector,
              this.$t('msg.CSBK100.147')
            )
            return false
          } else if (cntrInfo.pcupReqDt.length < 8) {
            this.$ekmtcCommon.showErrorTooltip(
              selector,
              this.$t('js.bookingValidation.009')
            )
            return false
          } else {
            if (this.usrCtrCd === 'KR') {
              const numDay = this.$ekmtcCommon.getDateDayOfWeek(selector.value)
              if (cntrInfo.cntrTypCd === 'RH' || cntrInfo.cntrTypCd === 'RF') {
                // if (numDay === 6) {
                //   if (
                //     cntrInfo.pcupCyCd !== 'HBCT' &&
                //     cntrInfo.pcupCyCd !== 'ICT'
                //   ) {
                //     // RF booking : Pick Up일자가 토요일일때 HBCT, ICT 인 경우만 저장 가능 / 그 외 저장 불가
                //     this.$ekmtcCommon.showErrorTooltip(
                //       selector,
                //       this.$t('msg.CSBK100.184')
                //     )
                //     return false
                //   }
                // //} else if (numDay === 0) {
                // } else {
                  let hldYn = 'N' // 휴일여부
                  await schedule.getHolidayYn(pcupReqDt).then(async (res) => {
                    hldYn = res.data
                  })

                  if (hldYn === 'Y') {
                    this.$ekmtcCommon.showErrorTooltip(
                      selector,
                      'RF booking : Pick Up 일자가 토요일, 일요일, 공휴일일 때 저장 불가'
                      //this.$t('msg.CSBK100.185')
                    ) // RF booking : Pick Up일자가 일요일일때 저장 불가
                    return false
                  }
                //}
              }
            }

            if (!this.$ekmtcCommon.isValidDate(pcupReqDt, 8)) {
              this.$ekmtcCommon.showErrorTooltip(
                selector,
                this.$t('js.bookingValidation.022')
              )
              return false
            }

            console.log('@@@@ regParams.polEta: ', regParams.polEta)

            if (
              regParams.polCtrCd === 'KR' &&
              regParams.mtyPkupUsagYn !== 'Y' &&
              regParams.polEta
            ) {
              const eta = regParams.polEta.substring(0, 8)
              const etaDate = new Date(
                eta.substring(0, 4),
                Number(eta.substring(4, 6)),
                Number(eta.substring(6, 8)) - 3
              )
              const pickDate = new Date(
                pcupReqDt.substring(0, 4),
                Number(pcupReqDt.substring(4, 6)),
                Number(pcupReqDt.substring(6, 8))
              )
              etaDate.setDate(etaDate.getDate() - 14)

              if (this.$ekmtcCommon.isNotEmpty(cntrInfo.pcupReqDtOld)) {
                if (pcupReqDt !== cntrInfo.pcupReqDtOld) {
                  if (etaDate > pickDate) {
                    // PICK UP DATE는 입항일로부터 14일 이내로만 변경이 가능합니다. 14일 이전으로 변경을 원하시는 경우 담당 영업사원에게 개별 문의를 부탁드립니다.
                    //this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.186'))
                    //return false
                  }
                }
              } else {
                if (etaDate > pickDate) {
                  // PICK UP DATE는 입항일로부터 14일 이내로만 변경이 가능합니다. 14일 이전으로 변경을 원하시는 경우 담당 영업사원에게 개별 문의를 부탁드립니다.
                  //this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.186'))
                  //return false
                }
              }
            }
          }
        } else {
          if (
            this.$ekmtcCommon.isEmpty(selector.value) &&
            cntrInfo.cntrTypCd !== 'TK' &&
            cntrInfo.shprVanYn !== 'Y'
          ) {
            this.$ekmtcCommon.showErrorTooltip(
              selector,
              this.$t('msg.CSBK100.147')
            )
            return false
          } else if (cntrInfo.pcupReqDt.length < 8) {
            this.$ekmtcCommon.showErrorTooltip(
              selector,
              this.$t('js.bookingValidation.024')
            )
            return false
          } else {
            if (!this.$ekmtcCommon.isValidDate(cntrInfo.pcupReqDt, 8)) {
              this.$ekmtcCommon.showErrorTooltip(
                selector,
                this.$t('js.bookingValidation.024')
              )
              return false
            }
          }
        }

        if (
          this.regParams.mtyPkupUsagYn !== 'Y' &&
          !(await this.fnPickupDateChk(
            cntrInfo,
            document.querySelector('#pcupReqDt_' + idx),
            flag
          ))
        ) {
          return false
        }
      }

      console.log('@@@@ checkValidationCntr 4: ', isOk)
      if (
        prefix === 'pcupCyCd' &&
        this.$ekmtcCommon.isEmpty(cntrInfo.pcupCyCd)
      ) {
        console.log('@@@@ cntrInfo.cntrTypCd: ', cntrInfo.cntrTypCd)
        console.log('@@@@ cntrInfo.shprVanYn: ', cntrInfo.shprVanYn)
        console.log('@@@@ regParams.polShtmCd: ', regParams.polShtmCd)
        console.log('@@@@ regParams.polShtmCd: ', regParams.polShtmCd)

        if (cntrInfo.cntrTypCd !== 'TK' && cntrInfo.shprVanYn !== 'Y') {
          if (regParams.polShtmCd === '01' || regParams.polShtmCd === '03') {
            this.$ekmtcCommon.showErrorTooltip(
              selector,
              this.$t('js.bookingValidation.010')
            )
            isOk = false
            errCnt++
          }
        }
      }
      console.log('@@@@ isOk: ', isOk)
      console.log('@@@@ prefix: ', prefix)
      console.log('@@@@ cntrInfo.pcupCyCd: ', cntrInfo.pcupCyCd)

      if (
        prefix === 'pcupCyCd' &&
        this.$ekmtcCommon.isNotEmpty(cntrInfo.pcupCyCd)
      ) {
        if (idx !== 0) {
          const firstCntr = this._containerList[0]

          if (this.$ekmtcCommon.isNotEmpty(firstCntr.pcupCyCd)) {
            const selector1 = frm.querySelector('#pcupCyCd_' + idx)
            this.$ekmtcCommon.hideErrorTooltip(selector1)
            if (
              cntrInfo.shprVanYn !== 'Y' &&
              firstCntr.pcupCyCd !== cntrInfo.pcupCyCd
            ) {
              this.$ekmtcCommon.showErrorTooltip(
                selector1,
                this.$t('msg.CSBK100.188')
              )
              isOk = false
              errCnt++
            }
          }
        }
      }
      console.log('@@@@ checkValidationCntr 5: ', isOk)
      console.log('#####################################', this.regParams.cfsFixdEntrNm)
      if (
        prefix === 'cfsFixdEntrNm' &&
        this.regParams.cfsFixdTypCd !== '03' &&
        this.$ekmtcCommon.isEmpty(this.regParams.cfsFixdEntrNm)
      ) {
        if (cntrInfo.cntrTypCd !== 'TK' && cntrInfo.shprVanYn !== 'Y') {
          if (regParams.polShtmCd === '02') {
            this.$ekmtcCommon.showErrorTooltip(
              selector,
              this.$t('js.bookingValidation.028')
            )
            isOk = false
            errCnt++
          }
        }
      }

      // if (prefix === 'cntrTypCd') {
      //   this.$store.commit('schedule/setContainerList', {
      //     key: prefix,
      //     value: payload,
      //     index: idx
      //   })
      // } else {
      //   let _containerList = [...this.$store.state.schedule.containerList]
      //   this.$store.commit('schedule/defaultAssign', {
      //     key: 'containerList',
      //     value: [
      //       ..._containerList.slice(0, idx),
      //       {
      //         ...cntrInfo
      //       },
      //       ..._containerList.slice(idx + 1)
      //     ]
      //   })
      // }
      _containerList = [...this.$store.state.schedule.containerList]
      this.$store.commit('schedule/defaultAssign', {
        key: 'containerList',
        value: [
          ..._containerList.slice(0, idx),
          {
            ...cntrInfo
          },
          ..._containerList.slice(idx + 1)
        ]
      })

      console.log('@@@@ checkValidationCntr: ', isOk)
      console.log('@@@@ errCnt: ', errCnt)
      return errCnt > 0 ? false : isOk
    },
    async fnPickupDateChk (cvo, selector, flag) {
      // flag가 save일 경우에만 확인창 띄움.
      console.log('@@@@ enter fnPickupDateChk')
      console.log('@@@@ cvo: ', cvo)
      console.log('@@@@ selector: ', selector)
      console.log('@@@@ flag: ', flag)
      const regParams = this.regParams
      const pcupReqDt = cvo.pcupReqDt
      const polEta = regParams.polEta
      if (this.$ekmtcCommon.isEmpty(pcupReqDt)) return false

      // 픽업일이 변경되지 않은 경우 체크하지 않게 처리
      if (
        this.$ekmtcCommon.isNotEmpty(cvo.pcupReqDtOld) &&
        cvo.pcupReqDtOld === pcupReqDt
      ) {
        return true
      }

      let pcupReqTm = ''
      if (regParams.polShtmCd === '01' || regParams.polShtmCd === '03') {
        if (this.$ekmtcCommon.isEmpty(cvo.pcupReqTm)) {
          pcupReqTm = '09'
        }
      } else {
        pcupReqTm = '09'
      }

      const dayNum = this.$ekmtcCommon.getDateDayOfWeek(pcupReqDt)

      //if (this.lang === 'KOR' || this.usrCtrCd === 'IN') {
      const pcupReqDate = this.$ekmtcCommon.getStrToDate(pcupReqDt)
      let bkgDate = new Date(
        this.bkgDt.substring(0, 4),
        Number(this.bkgDt.substring(4, 6)) - 1,
        Number(this.bkgDt.substring(6, 8)) + 1
      )

      const today = new Date(
        Number(this.serverYear),
        Number(this.serverMonth) - 1,
        Number(this.serverDay),
        Number(this.serverHour),
        Number(this.serverMin),
        Number(this.serverSecond)
      )
      const today1 = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      )
      const today2 = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + 1
      )

      //if (this.usrCtrCd === 'IN') {
      if (this.lang !== 'KOR') {
        // 인도 로직을 국내외 에 적용
        let pcupDtChkExcCtrCd = ['SG', 'MY'] // 픽업일 체크 제외국가 (싱가폴, 말레이시아). 해당 국가의 경우 부킹 시 픽업일이 강제 지정되어 픽업일이 부킹일(오늘) 이전의 날짜여도 부킹 저장/수정이 가능해야 합니다
        // 픽업일 체크 제외국가가 아닌경우 체크
        if (pcupDtChkExcCtrCd.indexOf(this.usrCtrCd) === -1) {
          let podEtd = new Date(
            Number(this.etd.substring(0, 4)),
            Number(this.etd.substring(4, 6)) - 1,
            Number(this.etd.substring(6, 8))
          )

          if (this.$ekmtcCommon.isNotEmpty(regParams.railData)) {
            podEtd = new Date(
              Number(this.etd.substring(0, 4)),
              Number(this.etd.substring(4, 6)) - 1,
              Number(this.etd.substring(6, 8)) + 3
            )
          }

          if (pcupReqDate < today1 && pcupReqDt.length === 8) {
            this.$ekmtcCommon.showErrorTooltip(
              selector,
              this.$t('js.booking.017')
            ) // 입력하고자 하는 픽업일은 현재일자 이후로만 가능합니다.
            return false
          }
          if (podEtd <= pcupReqDate) {
            this.$ekmtcCommon.showErrorTooltip(
              selector,
              this.$t('js.booking.018')
            )
            this.$ekmtcCommon.alertDefault(this.$t('js.booking.018'))
            return false
          }
        }
      } else if (this.lang === 'KOR') {
        let cgoDate = ''
        if (
          this.$ekmtcCommon.isNotEmpty(this.cgoRest) &&
          this.cgoRest.length >= 8
        ) {
          cgoDate = this.cgoRest.substring(0, 8)
        } else {
          if (this.$ekmtcCommon.isNotEmpty(polEta) && polEta.length >= 8) {
            cgoDate = polEta.substring(0, 8)
          }
        }

        console.log('>>>>> this.cgoRest : ' + this.cgoRest)
        console.log('>>>>> polEta : ' + polEta)
        console.log('>>>>> cgoDate : ' + cgoDate)

        if (regParams.polShtmCd === '01' || regParams.polShtmCd === '03') {
          const cgo = this.$ekmtcCommon.getStrToDate(cgoDate)
          const todayValue = this.$ekmtcCommon.getDateToStrDelim(today2, '')
          if (regParams.owtrYn === 'N') {
            const bkgDtValue = this.$ekmtcCommon.getDateToStrDelim(bkgDate, '')

            if (pcupReqDate < today2) {
              this.$ekmtcCommon.showErrorTooltip(
                selector,
                this.$t('js.restriction.009')
              )
              return false
            }

            const toDate = this.$ekmtcCommon.getDateToStrDelim(today2, '')
            if (pcupReqDt === toDate && Number(today1.getHours()) >= 15) {
              this.$ekmtcCommon.alertDefault(
                this.$ekmtcCommon.changeDatePattern(pcupReqDt, 'KO') +
                  this.$t('js.booking.015') +
                  '\n\n' +
                  this.$t('js.booking.016')
              )
              return false
            }

            if (
              (cvo.cntrTypCd === 'RF' || cvo.cntrTypCd === 'RH') &&
              pcupReqDate < today2
            ) {
              // if (flag === 'SAVE') {
              //   this.$ekmtcCommon.alertDefault(cvo.cntrTypCd + this.$t('msg.CSBK100.189')) //컨테이너는 당일 픽업이 불가합니다.
              // }
              this.$ekmtcCommon.showErrorTooltip(
                selector,
                cvo.cntrTypCd + this.$t('msg.CSBK100.189')
              )
              return false
            }
          } else {
            if (pcupReqDate < today1 && pcupReqDt.length === 8) {
              this.$ekmtcCommon.showErrorTooltip(
                selector,
                this.$t('js.booking.017')
              )
              return false
            }

            if (
              (cvo.cntrTypCd === 'RF' || cvo.cntrTypCd === 'RH') &&
              pcupReqDate < today2
            ) {
              // if (flag === 'SAVE') {
              //   this.$ekmtcCommon.alertDefault(cvo.cntrTypCd + this.$t('msg.CSBK100.189')) //컨테이너는 당일 픽업이 불가합니다.
              // }
              this.$ekmtcCommon.showErrorTooltip(
                selector,
                cvo.cntrTypCd + this.$t('msg.CSBK100.189')
              )
              return false
            }
          }

          if (
            cvo.cntrTypCd === 'RH' ||
            cvo.cntrTypCd === 'RF' ||
            cvo.cntrTypCd === 'OT' ||
            cvo.cntrTypCd === 'FR' ||
            cvo.cntrTypCd === 'SR'
          ) {
            let today3 = new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate() + 2
            )

            if (cgo < today3) {
              this.$ekmtcCommon.showErrorTooltip(
                selector,
                this.$t('js.booking.018')
              )
              this.$ekmtcCommon.alertDefault(this.$t('js.booking.018'))
              return false
            }
          }
          if (this.$ekmtcCommon.isNotEmpty(pcupReqDt)) {
            if (
              (this.$ekmtcCommon.isNotEmpty(this.cgoRest) &&
              this.cgoRest.length >= 8) || (this.$ekmtcCommon.isNotEmpty(this.docCls) &&
              this.docCls.length >= 8)
            ) {
              const cgoDate = this.$ekmtcCommon.getStrToDate(
                this.$ekmtcCommon.isEmpty(this.cgoRest) ? this.docCls.substring(0, 8) : this.cgoRest.substring(0, 8)
              )
              if (cvo.cntrTypCd === 'RF' || cvo.cntrTypCd === 'RH') {
                if (
                  this.$ekmtcCommon.isNotEmpty(cgoDate) &&
                  cgoDate <= pcupReqDate
                ) {
                  this.$ekmtcCommon.alertDefault(
                    cvo.cntrTypCd + this.$t('msg.CSBK100.190')
                  ) // 컨테이너는 서류마감일 하루 전까지 입력 가능합니다.
                  this.$ekmtcCommon.showErrorTooltip(
                    selector,
                    cvo.cntrTypCd + this.$t('msg.CSBK100.190')
                  )
                  return false
                }
              } else {
                if (
                  this.$ekmtcCommon.isNotEmpty(cgoDate) &&
                  cgoDate < pcupReqDate
                ) {
                  this.$ekmtcCommon.showErrorTooltip(
                    selector,
                    this.$t('msg.CSBK100.190')
                  )
                  return false
                }
              }
            } else {
              const etaDate = this.$ekmtcCommon.getStrToDate(
                this.regParams.polEta
              )

              if (
                this.$ekmtcCommon.isNotEmpty(etaDate) &&
                etaDate < pcupReqDate &&
                pcupReqDt.length >= 8
              ) {
                this.$ekmtcCommon.showErrorTooltip(
                  selector,
                  this.$t('js.restriction.006')
                )
                return false
              }
            }
          }

          if (regParams.owtrYn === 'N') {
            if (this.$ekmtcCommon.isNotEmpty(cgo) && cgo < today2) {
              this.$ekmtcCommon.showErrorTooltip(
                selector,
                this.$t('js.booking.018')
              )
              this.$ekmtcCommon.alertDefault(this.$t('js.booking.018'))
              return false
            } else if (
              this.$ekmtcCommon.isNotEmpty(cgoDate) &&
              todayValue > cgoDate
            ) {
              this.$ekmtcCommon.showErrorTooltip(
                selector,
                this.$t('js.booking.018')
              )
              this.$ekmtcCommon.alertDefault(this.$t('js.booking.018'))
              return false
            } else if (
              this.$ekmtcCommon.isNotEmpty(cgoDate) &&
              todayValue === cgoDate
            ) {
              if (Number(today.getHours()) >= 15) {
                this.$ekmtcCommon.showErrorTooltip(
                  selector,
                  this.$t('js.booking.018')
                )
                this.$ekmtcCommon.alertDefault(this.$t('js.booking.018'))
                return false
              }
            }
          } else {
            if (cgo < today1) {
              this.$ekmtcCommon.showErrorTooltip(
                selector,
                this.$t('js.booking.018')
              )
              this.$ekmtcCommon.alertDefault(this.$t('js.booking.018'))
              return false
            }
          }
        } else if (regParams.polShtmCd === '02') {
          const etaDate = this.$ekmtcCommon.getStrToDate(this.regParams.polEta)
          const pcupReqDate = this.$ekmtcCommon.getStrToDate(
            pcupReqDt + pcupReqTm
          )
          //const cgo = this.$ekmtcCommon.getStrToDate(cgoDate)

          if (this.$ekmtcCommon.isNotEmpty(etaDate) && etaDate < pcupReqDate) {
            this.$ekmtcCommon.showErrorTooltip(
              selector,
              this.$t('js.restriction.020')
            )
            return false
          }

          /*
            if (cgo < pcupReqDate) {
              this.$ekmtcCommon.showErrorTooltip(selector, this.$t('js.booking.018'))
              this.$ekmtcCommon.alertDefault(this.$t('js.booking.018'))
              return false
            }
            */
        }
      }

      if (this.lang === 'KOR' && flag === 'SAVE') {
        if (Number(dayNum) === 6 || Number(dayNum) === 0) {
          console.log('@@@@ 008 if')
          await this.$ekmtcCommon.asyncAlertMessage({
            message: this.$t('js.restriction.008')
          })
        } else {
          console.log('@@@@ 008 else')
          await schedule.getHolidayYn(pcupReqDt).then(async (res) => {
            const result = res.data
            if (result !== undefined && result !== null) {
              if (result === 'Y') {
                await this.$ekmtcCommon.asyncAlertMessage({
                  message: this.$t('js.restriction.008')
                })
              }
            }
          })
        }
      }
      //}

      return true
    },
    fnPickupChk () {
      if (this.lang !== 'KOR') return true
      const regParams = this.regParams
      const cntrList = regParams.containerList

      let flag = true

      if (regParams.polShtmCd === '02' || regParams.owtrYn !== 'Y') return true

      cntrList.forEach(async (c) => {
        if (this.$ekmtcCommon.isNotEmpty(c.cntrTypCd)) {
          const pcupReqDtBak = c.pcupReqDtBak
          const pcupReqDt = c.pcupReqDt

          if (
            this.$ekmtcCommon.isNotEmpty(pcupReqDtBak) &&
            pcupReqDt !== pcupReqDtBak
          ) {
            const onConfirm = () => {
              c.pickUpPlace = ''
              c.pickUpMan = ''
              c.pickUpTel = ''
              c.pcupCyCd = ''
              c.pcupReqDtBak = ''
            }

            const onClose = () => {
              if (pcupReqDt.length !== 8) {
                c.pickUpPlace = ''
                c.pickUpMan = ''
                c.pickUpTel = ''
                c.pcupCyCd = ''
                c.pcupReqDtBak = ''
              } else {
                c.pcupReqDt = pcupReqDtBak
              }
            }
            this.$ekmtcCommon.confirmCallBack(
              this.$t('js.booking.020'),
              onConfirm,
              onClose
            )

            flag = false
            return flag
          }
        }
      })

      return flag
    },
    openRfHpmgSite (rfHmpgUrl) {
      if (this.$ekmtcCommon.isEmpty(rfHmpgUrl)) {
        this.$ekmtcCommon.alertDefault(this.$t('js.booking.033'))
        return
      }

      window.open(rfHmpgUrl)
    },
    setCharcoalInfo (obj) {
      if (obj.cnt1361 === 'Y' || obj.cnt1362 === 'Y') {
        this.vanningYn = 'Y'
      }
      let imdgNo = ''
      if (obj.cnt1361 !== 'Y' && obj.cnt1362 === 'Y') {
        this.vanningSurvYn = 'N'
        imdgNo = '1362'
      } else {
        this.vanningSurvYn = 'Y'
        imdgNo = '1361'
      }

      const params = {
        polPortCd: this.regParams.polPortCd,
        etd: this.etd,
        imdgNo: imdgNo
      }

      trans.getChclAct(params).then((res) => {
        this.chclActList = res.data
      })
    },
    iranInputCheck (flag, idx) {
      const params = {
        kndCd: '',
        hsCd: '',
        cont: ''
      }

      const frm = document.querySelector('#frm')
      const iranInfo = this.regParams.iranBookingInfo
      let selector = frm.querySelector('#ir_' + flag)

      console.log('iranInputCheck flag @@@@@@ ', flag)
      console.log('iranInputCheck idx @@@@@@ ', idx)

      if (flag === 'endUsrNm') {
        selector = frm.querySelector('#ir_' + flag + '_' + idx)
      }

      if (
        flag === 'actShprCstNm' ||
        flag === 'cneCstNm' ||
        flag === 'endUsrNm' ||
        flag === 'ntifCstNm'
      ) {
        params.kndCd = '1'
        params.cont = selector.value
      } else if (flag === 'cmdtNm') {
        params.kndCd = 2
        params.cont = selector.value
      } else if (flag === 'hsCd') {
        params.kndCd = 3
        params.hsCd = selector.value
      }
      transCommon.getIranCheck(params).then((res) => {
        const result = res.data

        if (res.headers.respcode === 'C0000' && result !== undefined) {
          let clsNm = result === 'Y' ? 'font-size:12px; color:red;font-weight: 700; display:' : 'font-size:12px; color: black;font-weight: 700;display:'

          if (result === 'R') {
            clsNm = ''
          }

          if (flag === 'actShprCstNm') {
            iranInfo.actShprCstNmC = result
          } else if (flag === 'cneCstNm') {
            iranInfo.cneCstNmC = result
          } else if (flag === 'endUsrNm') {
            iranInfo.endUsrNmCList[idx] = result
          } else if (flag === 'cmdtNm') {
            this.cmdtNmC = result
          } else if (flag === 'hsCd') {
            this.hsCdC = result
          } else if (flag === 'ntifCstNm') {
            iranInfo.ntifCstNmC = result
          }
          if (result === 'N') {
              clsNm = 'display: none'
          }
          let msgSelector = frm.querySelector('#ir_' + flag + 'C')
          if (flag === 'endUsrNm') {
            msgSelector = frm.querySelector('#ir_' + flag + 'C' + '_' + idx)
          }
        console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@', msgSelector.innerHTML)
        // msgSelectorr.innerHTML = '33333333333333333333333'
        if (result === 'A' && this.lang === 'KOR') {
          msgSelector.innerHTML = 'SANCTION LIST 포함 업체로 의심되오니 주의 및 정확한 정보 확인 부탁드립니다.'
        }
        msgSelector.classList.remove(...msgSelector.classList)
        msgSelector.style = clsNm
        }
      })
    },
    async myHsCdInputCheck () {
      const TH = this
      const params = {
        hsCd: ''
      }

      if (this.$ekmtcCommon.isNotEmpty(this.regParams.hsCd)) {
        params.hsCd = this.regParams.hsCd

        await bookings.checkMyHsCd(params).then((res) => {
          const result = res.data
          TH.myHsCdYn = result

          // console.log('myHsCdInputCheck result @@@@@@ ', result)
        })

        if (this.myHsCdYn === 'N') {
            TH.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.175'))
            this.regParams.hsCd = ''
        }
      }
    },
    searchSchedule () {
      // 프로모션 부킹인 경우 스케줄 변경 불가
      if (this.promotionChk === 'Y') {
        this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.278'))
        return
      }

      // KMTC SPOT 부킹인 경우 스케줄 변경 불가
      if (this.regParams.kmtcSpotYn === 'Y') {
        this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.278'))
        return
      }

      if (
        this.regParams.bkgStsCd === '03' ||
        this.statusClass === 'decide' ||
        this.statusClass === 'past'
      ) { return false }
      this.selectFunc = this.callbackSearchSchedule

      // JamSin DSM 대안항로 URL로 들어온 경우 스케줄 pop 을 호출할때 url param 전달
      if (this.urlOrNot && this.$ekmtcCommon.isNotEmpty(this.urlParam)) {
        this.popParams = {
          ScheduleLegPop: 'Y',
          podCtrCd: this.urlParam.podCtrCd,
          podPlcCd: this.urlParam.podPlcCd,
          polCtrCd: this.urlParam.polCtrCd,
          polPlcCd: this.urlParam.polPlcCd,
          rfYn: '',
          urlParam: this.urlParam
        }
      } else {
        //console.log('this.regParams=======================>', this.regParams)
        const podPlc = this.$ekmtcCommon.isNotEmpty(this.regParams.dlyPlcCd) ? this.regParams.dlyPlcCd : this.regParams.podPortCd
        const podCtr = this.$ekmtcCommon.isNotEmpty(this.regParams.dlyCtrCd) ? this.regParams.dlyCtrCd : this.regParams.podCtrCd
        this.popParams = {
          ScheduleLegPop: 'Y',
          podCtrCd: podCtr, //this.regParams.podCtrCd,
          podPlcCd: podPlc === 'PNC' ? 'PUS' : podPlc,
            //this.regParams.podPortCd === 'PNC' ? 'PUS' : this.regParams.podPortCd,
          polCtrCd: this.regParams.polCtrCd,
          polPlcCd:
            this.regParams.polPortCd === 'PNC' ? 'PUS' : this.regParams.polPortCd,
          rfYn: this.regParams.schRfYn
        }
      }
      //console.log('<<<<<<<   this.urlParam   >>>>>>>>>>', this.urlParam)
      this.openPopup('schedule-leg-pop')
    },
    async callbackSearchSchedule (obj) {
      console.log('@@@ enter callbackSearchSchedule')
      if (Object.keys(this.$route.query).length > 0) {
        this.$router.replace({ query: {} })
      }

      // 스케줄 팝업 창에서 KMTC ON 구간 선택 및 프리미엄 운임 선택시 KMTC ON 부킹 처리
      // obj.schedule.kmtcPremiumLineYn은 스케줄 화면의 리스트 타입에서 넘어오는 것을 체크하므로 빼면 안됨
      if (obj.detail.kmtcSpotLineYn === 'Y' && obj.detail.kmtcSpotClosYn !== 'Y' && obj.schedule.kmtcPremiumLineYn === 'Y') {
        this.regParams.kmtcSpotYn = 'Y'
        this.regParams.frtAppNo = obj.params.frtAppNo // KMTC ON 구간인 경우 신규 생성된 운임번호 세팅함
        // 부킹메뉴인 경우 BookingMain.vue의 KMTC PREMIUM 변수 반영
        if (this.$route.path === '/booking-new') {
          this.$parent.kmtcPremiumChk(obj.detail.kmtcSpotLineYn)
        }
      }

      // 스케줄에서 운임이 없는데 Collect Term을 클릭하여 부킹으로 들어온 경우
      if (obj.isCollectTermBkg === true) {
        this.regParams.frtPncCd = 'C' // Collect를 선택
      }

      let isOk = true

      if (this.saveFlag === 'EDIT' && this.regParams.scheduleEditYn !== 'Y') {
        if (this.regParams.legTrmlFixdYn === 'Y') {
          const bfPodTrmlCd = this.oldPodTrmlCd
          const afPodTrmlCd = obj.params.podTrmlCd
          isOk = await this.changeLegTrmlFunc(bfPodTrmlCd, afPodTrmlCd)
        }

        const bfPolTrmlCd = this.oldPolTrmlCd
        const afPolTrmlCd = obj.params.polTrmlCd

        if (bfPolTrmlCd !== afPolTrmlCd) {
          //this.shuttlePopYn = 'Y'
        }
      }

      if (!isOk) {
        return
      }

      obj.params.rteCd = obj.detail.rteCd
      obj.params.podEta = obj.params.finalEta
      this.setLegFixShow(obj.params)

      if (obj.params !== undefined) {
        if (this.lang === 'KOR') {
          await this.setScheduleInfoKOR(obj.params)
        } else {
          await this.setScheduleInfo(obj.params)
        }

        this.setVslCloseInfo(obj.params, obj.detail.rteCd)
      } else {
        this.$ekmtcCommon.alertDefault(this.$t('js.common.005')) // 조회에 실패했습니다. 관리자에게 문의해주세요.
      }

      if (obj.detail !== undefined) {
        const schdInfo = this.$refs.schdInfo
        schdInfo.setScheduleLegInfo(obj.detail)

        await this.fnCheckLoiDownEnable(schdInfo.schdLegList)
      }

      const oldRfYn = this.regParams.schRfYn
      const rfYn = obj.params.rfYn
      this.regParams.schRfYn = rfYn
      this.regParams.mcOpenYn = obj.params.mcOpenYn
      if (rfYn === 'Y') {
        // this.regParams.containerList.forEach((cvo) => {
        //     this.setCntrTypList(cvo)
        //   })
        this.regParams.containerList = []
        let _containerList = [...this._containerList]

        this.$store.commit('schedule/defaultAssign', {
          key: 'containerList',
          value: _containerList,
          test: 'callbackSearchSchedule'
        })

        if (_containerList.length < 1) this.addContainer()

        this.cntSzList = this.commonCodes['01031'].filter(
          (vo) => vo.cd !== '45'
        )
      } else {
        this.cntSzList = this.commonCodes['01031']

        if (oldRfYn !== rfYn) {
          this.regParams.containerList.forEach((cvo) => {
            this.setCntrTypList(cvo)
          })
          // this.regParams.containerList = []
          // let _containerList = [...this._containerList]

          // this.$store.commit('schedule/defaultAssign', {
          //   key: 'containerList',
          //   value: _containerList,
          //   test: 'callbackSearchSchedule'
          // })
        }

        // 스케줄 변경 시  kmtc vsl 에 맞게 초기값 변경
        this._containerList.forEach((c, i) => {
          c.underDckCd = ''
          //this.setCntrPickInfo(c, i)
          this.$store.commit('schedule/setContainerListOnlySent', {
            index: i,
            value: c,
            test: 'changeCntrTyp'
          })
        })
      }

      if (this.saveFlag === 'EDIT') {
        this.changeScheduleInput()
      }
    },
    changeScheduleInput () {
      const frm = document.querySelector('#frm')

      const inputEl = frm.getElementsByTagName('input')
      for (const el of inputEl) {
        const id = el.getAttribute('id')

        if (
          id !== null &&
          id.indexOf('pcupReqDt') === -1 &&
          id.indexOf('mtyPkupUsagYn') === -1 &&
          id.indexOf('grsWtTon') === -1 &&
          id.indexOf('grsWtKg') === -1
        ) {
          el.disabled = false
        }
      }

      if ('SG|MY|PH'.indexOf(this.usrCtrCd) === -1) {
        this.pcupDtDisabled = false
      } else {
        this.pcupDtDisabled = true
      }

      const selectEl = frm.getElementsByTagName('select')
      for (const el of selectEl) {
        const id = el.getAttribute('id')
        if (id !== null && id.indexOf('podShtmCd') === -1) {
          el.disabled = false
        }
      }

      const textEl = frm.getElementsByTagName('textarea')
      for (const el of textEl) {
        el.disabled = false
      }
    },
    async setVslCloseInfo (obj, rteCd) {
      /*
    let slotExchCstNo = ''

      if (this.$ekmtcCommon.isEmpty(obj.slotExchCstNo)) {
        await trans.getSlotExchCstNo(obj.vslCd).then(res => {
          slotExchCstNo = res.data
        })
      } else {
        slotExchCstNo = obj.slotExchCstNo
      }
    */
      const closeInfo = {
        polCtrCd: obj.polCtrCd,
        polPortCd: obj.polPortCd,
        polTrmlCd: obj.polTrmlCd,
        cgoPic: obj.cgoPic,
        bkgDocCls: this.$ekmtcCommon.changeDatePattern(obj.docCls, '.'),
        vgmDocCls: this.$ekmtcCommon.changeDatePattern(obj.vgmCls, '.'),
        bkgCgoCls: this.$ekmtcCommon.changeDatePattern(obj.cgoCls, '.'),
        srPic: obj.srPic,
        bkgMfCls: this.$ekmtcCommon.changeDatePattern(obj.mfCls, '.'),
        cfsCls: this.$ekmtcCommon.changeDatePattern(obj.cfsCls, '.'),
        vslCd: obj.vslCd,
        voyNo: obj.voyNo,
        rteCd: rteCd,
        menuCd: '02',
        bound: 'O',
        // Booking 수정시 조회하는 경우에는 etaTm, etdTm변수가 없고 eta, etd에 값이 모두 있음
        polEtaDt: obj.eta + (obj.etaTm ? obj.etaTm : ''), // 선적항 Arrival(접안) 일시
        polEtdDt: obj.etd + (obj.etdTm ? obj.etdTm : ''), // 선적항 Departure 일시,
        callSign: obj.callSign,
        mrnNo: obj.mrnNo,
        apoTcnt: obj.apoTcnt,
        polShtmCd: this.regParams.polShtmCd,
        txtCfsPolTrmlInfo: this.txtCfsPolTrmlInfo // CFS 반입지 / 담당자
      }
      console.log('>>>>> this.eta : ', this.eta)
      console.log('>>>>> this.etd : ', this.etd)
      console.log('>>>>> obj : ', obj)
      console.log('>>>>> closeInfo : ', closeInfo)

      // TODO: 서류마감시간으로 PickUp일자 validation을 하기 위해서 regParams.docCls에 서류마감시간을 가져올지 고민
      // this.regParams.docCls = obj.docCls

      this.bkgCloseInfo = { ...closeInfo }
      //this.bkgCloseVslInfo = { ...closeVslInfo }
    },
    scheduleCloseInfo (obj, loadFlag) {
      console.log('@@@@ enter scheduleCloseInfo: ', obj)
      if (this.$ekmtcCommon.isNotEmpty(obj.cgoCls)) {
        this.cgoCls = obj.cgoCls
      }

      this.cfsCls = obj.cfsCls

      this.docPic = obj.docPic
      this.srRest = obj.srRest
      this.localDtm = obj.localDtm
      this.vslRest = obj.vslRest
      this.cgoRest = obj.cgoRest
      this.docCls = obj.docCls
      this.eta = obj.eta + (obj.etaTm !== undefined ? obj.etaTm : '')
      this.etd = obj.etd + (obj.etdTm !== undefined ? obj.etdTm : '')

      const regParams = this.regParams

      if (loadFlag === 'EDIT') {
        if (this.$ekmtcCommon.isNotEmpty(obj.eta)) {
          regParams.podEta =
            obj.eta.length > 8 ? obj.eta.substring(0, 8) : obj.eta
        }
        regParams.bkgDt = obj.bkgDt

        this.setLegFixShow(regParams)
      } else {
        if (this.$ekmtcCommon.isNotEmpty(obj.finalEta)) {
          regParams.podEta =
            obj.finalEta.length > 8
              ? obj.finalEta.substring(0, 8)
              : obj.finalEta
        }
        regParams.bkgDt =
          obj.localDtm.length > 8 ? obj.localDtm.substring(0, 8) : obj.localDtm
      }

      regParams.polEtd = obj.etd > 8 ? obj.etd.substring(0, 8) : obj.etd

      this.bkgCloseYn = obj.bkgCloseYn === 'Y' ? 'CHECK' : ''
    },
    async setScheduleCommon (obj) {
      console.log('@@@@ enter setScheduleCommon: ', obj)

      // DEM/DET 계산기 활성화 여부 조회
      const response = await trans.isDetCombined({
        polCtrCd: obj.polCtrCd,
        polPortCd: obj.polPortCd
      })
      // Y 로 오면 비활성화
      this.isDetCombined = response.data === 'Y'
      console.log('@@@@ isDetCombined: ', this.isDetCombined)

      const regParams = this.regParams
      const polShtmCd = regParams.polShtmCd
      regParams.vslCd = obj.vslCd
      regParams.polPortCd = obj.polPortCd
      regParams.podPortCd = obj.podPortCd
      regParams.polCtrCd = obj.polCtrCd
      regParams.podCtrCd = obj.podCtrCd
      regParams.polTrmlCd = obj.polTrmlCd
      regParams.podTrmlCd = obj.podTrmlCd
      regParams.voyNo = obj.voyNo
      regParams.hidCS008I = obj.hidCS008I
      regParams.scheduleEditYn = 'Y'
      regParams.schFrtAppNo = obj.frtAppNo
      regParams.schLogDtm = obj.localDtm
      //regParams.expcYn = obj.bkgCloseYn === 'Y' ? 'Y' : 'N'
      regParams.expcYn = 'N'

      obj.cyEnm = obj.polTrmlNm

      this.scheduleCloseInfo(obj, 'NEW')

      if (this.lang !== 'KOR' && this.usrCtrCd !== 'IN') {
        this.pickDateSgMy = obj.prrmSgEtd
      }

      //오늘 날짜 구하기
      // RF 부킹체크
      let rfCheck = 'N'
      regParams.containerList.forEach((c) => {
        if (c.cntrTypCd === 'RF' || c.cntrTypCd === 'RH') {
          rfCheck = 'Y'
        }
      })
      if (rfCheck === 'Y') {
        this.setRfTeuCount()
      }
      if (polShtmCd === '01' || polShtmCd === '03') {
        if (regParams.owtrYn === 'N') {
          regParams.zip1 = ''
        }
        let containerList = [...this._containerList]
        containerList = containerList.map((b, idx) =>
          Object.assign({ index: idx }, b)
        )
        containerList.forEach((c) => {
          c.pickUpPlace = ''
          c.pcupCyCd = ''
          c.pcupReqDt = ''
          c.pickUpMan = ''
          c.pickUpTel = ''
        })

        this.$store.commit('schedule/defaultAssign', {
          key: 'containerList',
          value: containerList,
          test: 'setScheduleCommon'
        })
      } else if (polShtmCd === '02') {
        this.setCfsCodeInfo(this._containerList)
      }

      this.fnDgShipCheck()

      if (regParams.hidCS008I.indexOf('SHA') > -1) {
        this.fnShaTsMsg()
      }
    },
    async setScheduleInfo (obj) {
      console.log('@@@@ enter setScheduleInfo')
      if (obj !== null && obj !== undefined) {
        const regParams = this.regParams
        regParams.polEtd = obj.etd
        regParams.polEta = obj.eta + obj.etaTm

        // 운송 구분 CY, SOC 화주가 아닐 경우
        this.$nextTick(() => {
          // 스케줄에서 넘어오는 경우 readonly 처리를 위해
          if (regParams.polShtmCd === '01' && this.cstCatCd !== 'Y') {
            if (
              this.$ekmtcCommon.isNotEmpty(obj.podCtrCd) &&
              'IN|PK|BD|RU|IR|LK|AE|TW'.indexOf(obj.podCtrCd) > -1
            ) {
              regParams.dorOdrRmk = 'BOLT SEAL CHECK'
              document.querySelector('#dorOdrRmk').readonly = true
            } else {
              //regParams.dorOdrRmk = ''
              document.querySelector('#dorOdrRmk').readonly = false
            }
          }
        })

        await this.setScheduleCommon(obj)
        console.log('@@@@ regParams:', regParams)
        const params = {
          polCtrCd: regParams.polCtrCd,
          polPortCd: regParams.polPortCd
        }
        await trans.getPickUpSet(params).then((res) => {
          const data = res.data
          this.pickPlaceCd = data.cyCd
          this.pickPlaceNm = data.cyNm

          this.setPickPlaceInfo()
        })

        this.vanningChk()

        //this.termCheckHPNT()

        if (obj.polPortCd !== 'PGU') {
          regParams.fwdEdiCd = ''
        }

        /*
        if (obj.podCtrCd === 'TH') {
          const params = {
            polCtrCd: regParams.polCtrCd,
            polPortCd: regParams.polPortCd,
            polTrmlCd: regParams.polTrmlCd,
            hidCS008I: regParams.hidCS008I
          }

          trans.getGateInfoSet(params).then(res => {
            const rtn = res.data

            if (rtn.result !== 'N') {
              this.txtPolTrmlInfo = rtn.result
            }
          })
        }
        */

        transCommon.getCasNoPortCheck(regParams.hidCS008I).then((res) => {
          const rtnStr = res.data
          this.casNoPortYn = rtnStr === 'Y' ? 'Y' : 'N'
        })
        this.chemicalChk()
        this.findIotCntrYn('S')
      }
    },
    vanningChk () {
      const TH = this
      const dg = TH.$refs.dg

      let cnt1361 = 'N'
      let cnt1362 = 'N'
      if (dg !== undefined) {
        dg.forEach((c) => {
          const dgInfo = c.dgInfo.containerDGList

          dgInfo.forEach((d) => {
            if (d.imdunNo === '1361') {
              cnt1361 = 'Y'
            }

            if (d.imdunNo === '1362') {
              cnt1362 = 'Y'
            }
          })
        })
      }

      if (cnt1361 === 'Y' || cnt1362 === 'Y') {
        const obj = {
          cnt1361: cnt1361,
          cnt1362: cnt1362
        }

        this.setCharcoalInfo(obj)
      }
    },
    async setScheduleInfoKOR (obj) {
      if (obj !== null && obj !== undefined) {
        const TH = this
        const regParams = this.regParams

        regParams.polEtd = obj.etd
        regParams.polEta = obj.eta + obj.etaTm

        // 운송 구분 CY, SOC 화주가 아닐 경우
        this.$nextTick(() => {
          if (regParams.polShtmCd === '01' && this.cstCatCd !== 'Y') {
            if (
              this.$ekmtcCommon.isNotEmpty(obj.podCtrCd) &&
              'IN|PK|BD|RU|IR|LK|AE|TW'.indexOf(obj.podCtrCd) > -1
            ) {
              regParams.dorOdrRmk = 'BOLT SEAL CHECK'
              document.querySelector('#docmRmk').readonly = true
            } else {
              regParams.dorOdrRmk = ''
              document.querySelector('#docmRmk').readonly = false
            }
          }
        })
        await this.setScheduleCommon(obj)

        await trans.getKmdVslCheck(regParams.hidCS008I).then((res) => {
          const rtnStr = res.data
          this.kmdVslCheck = rtnStr

          let oogYn = 'N'
          regParams.containerList.forEach((c, idx) => {
            if (
              c.cntrTypCd === 'OT' ||
              c.cntrTypCd === 'FR' ||
              c.cntrTypCd === 'SR'
            ) {
              oogYn = 'Y'
            }
          })

          if (oogYn === 'Y') {
            this.oogLoiPop(rtnStr)
          }
        })

        await transCommon.getCasNoPortCheck(regParams.hidCS008I).then((res) => {
          const rtnStr = res.data
          this.casNoPortYn = rtnStr === 'Y' ? 'Y' : 'N'
        })
        this.chemicalChk()
        this.findIotCntrYn('S')
      }
    },
    clearSchedule () {
      let node = null
      if (this.lang === 'KOR') {
        node = document.querySelector('#docmRmk')
        if (node) {
          node.readonly = false
        }
      } else {
        document.querySelector('#dorOdrRmk').readonly = false
      }

      this.pickDateSgMy = ''
      this.cfsRmkYn = ''
      this.srRest = ''
      this.localDtm = ''
      this.vslRest = ''
      this.cgoRest = ''
      this.eta = ''
      this.etd = ''
      this.cfsCls = ''
      this.bkgCloseYn = ''
      this.$refs.schdInfo.schdLegList = []
    },
    termCheckHPNT () {
      const hidCS008I = this.regParams.hidCS008I
      console.log('@@@@ hidCS008I: ', hidCS008I)

      let pod = hidCS008I.split('@_@')
      for (let i = 1; i < pod.length; i++) {
        const legSplit = pod[i].split(':')
        if (
          (this.$ekmtcCommon.isNotEmpty(legSplit[11]) &&
            'HPNT|HJNC'.indexOf(legSplit[11]) > -1) ||
          (this.$ekmtcCommon.isNotEmpty(legSplit[12]) &&
            'HPNT|HJNC'.indexOf(legSplit[12]) > -1)
        ) {
          this.legTermHPNT = 'Y'
          this.$ekmtcCommon.alertDefault(
            this.$t('msg.CSBK100.089') + '\n' + this.$t('msg.CSBK100.090')
          )
        }

        if (legSplit[1] === 'HKM' || legSplit[3] === 'HKM') {
          this.legTermHKM = 'Y'
          this.$ekmtcCommon.alertDefault(
            this.$t('msg.CSBK100.092') + '\n' + this.$t('msg.CSBK100.093')
          )
        }
      }
    },
    setPickPlaceInfo () {
      console.log('@@@@ setPickPlaceInfo')
      const containerList = this.regParams.containerList
      let _containerList = [...this._containerList]
      _containerList = _containerList.map((b, idx) =>
        Object.assign({ index: idx }, b)
      )
      const frm = document.querySelector('#frm')
      console.log('@@@@ this.pickPlaceNm: ', this.pickPlaceNm)

      _containerList.forEach((c, i) => {
        // c.pickUpPlace = this.pickPlaceNm
        // c.pcupCyCd = this.pickPlaceCd

        // if (this.$ekmtcCommon.isNotEmpty(this.usrCtrCd) && 'SG|MY|JP'.indexOf(this.usrCtrCd) > -1 && this.regParams.polShtmCd === '01') {
        //   c.pcupReqDt = this.pickDateSgMy
        //   if ('SG|MY'.indexOf(this.usrCtrCd) > -1) {
        //     this.pcupDtDisabled = true
        //   }
        // } else {
        //   c.pcupReqDt = ''
        //   this.pcupDtDisabled = false
        // }
        this.setCntrPcupDtAndPlace(c) // 픽업일자 및 픽업장소 설정
      })

      console.log('@@@@ _containerList: ', _containerList)

      this.$store.commit('schedule/defaultAssign', {
        key: 'containerList',
        value: [..._containerList],
        test: 'setPickPlaceInfo'
      })
    },
    setCntrPcupDtAndPlace (cntr) {
      // 스케쥴 선택 시, POL 포트가 JKT, SUB, SRG 인 경우,  픽업지 자동 설정 로직 해제.
      if (['JKT', 'SUB', 'SRG'].indexOf(this.regParams.polPortCd) > -1) {
        cntr.pickUpPlace = ''
        cntr.pcupCyCd = ''
      } else {
        cntr.pickUpPlace = this.pickPlaceNm
        cntr.pcupCyCd = this.pickPlaceCd
      }

      if (
        this.$ekmtcCommon.isNotEmpty(this.usrCtrCd) &&
        'SG|MY|JP'.indexOf(this.usrCtrCd) > -1 &&
        this.regParams.polShtmCd === '01'
      ) {
        cntr.pcupReqDt = this.pickDateSgMy
        if ('SG|MY'.indexOf(this.usrCtrCd) > -1) {
          this.pcupDtDisabled = true
        }
      } else {
        cntr.pcupReqDt = ''
        this.pcupDtDisabled = false
      }
    },
    async setCfsCodeInfo (cntrList) {
      const regParams = this.regParams
      const arrCntrTypCd = []
      if (this.$ekmtcCommon.isNotEmpty(cntrList)) {
        cntrList.forEach((c) => {
          arrCntrTypCd.push(c.cntrTypCd)
        })
      } else {
        regParams.containerList.forEach((c) => {
          arrCntrTypCd.push(c.cntrTypCd)
        })
      }
      const params = {
        polPortCd: regParams.polPortCd,
        podPortCd: regParams.podPortCd,
        podCtrCd: regParams.podCtrCd,
        vslCd: regParams.vslCd,
        voyNo: regParams.voyNo,
        polTrmlCd: regParams.polTrmlCd,
        arrCntrTypCd: arrCntrTypCd.join(','),
        conCode: 'Y'
      }
      await trans.getCfsInfo(params).then((res) => {
        const data = res.data
        if (data.cfsRmkYn === 'Y') {
          this.txtCfsPolTrmlInfo =
            this.$t('msg.CSBK100.128') +
            ' : ' +
            (data.cfsNm ? data.cfsNm : '') +
            ' /\n' +
            this.$t('msg.CSBK100.129') +
            ' : ' +
            (data.picNm ? data.picNm : '') +
            ' / TEL : ' +
            (data.telNo ? data.telNo : '') +
            ' / FAX : ' +
            (data.faxNo ? data.faxNo : '') +
            '\n' +
            data.cfsRmk
          this.cfsRmkYn = 'Y'
          this.cfsRmkMsg = data.cfsRmk
        } else {
          this.txtCfsPolTrmlInfo =
            this.$t('msg.CSBK100.128') +
            ' : ' +
            (data.cfsNm ? data.cfsNm : '') +
            ' /\n' +
            this.$t('msg.CSBK100.129') +
            ' : ' +
            (data.picNm ? data.picNm : '') +
            ' / TEL : ' +
            (data.telNo ? data.telNo : '') +
            ' / FAX : ' +
            (data.faxNo ? data.faxNo : '')
          this.cfsRmkYn = ''
        }
        if (this.lang === 'KOR' && this.$ekmtcCommon.isNotEmpty(data.cfsCd)) {
          let cfsCd = data.cfsCd
          if (cfsCd === 'KECS') {
            cfsCd = 'IKEC'
          } else if (cfsCd === 'KNCY') {
            cfsCd = 'UAM'
          }
          regParams.cfsCy = cfsCd
        }

        // 마감정보에 반입지/담당자 정보 반영
        if (this.$ekmtcCommon.isNotEmpty(this.bkgCloseInfo)) {
          this.bkgCloseInfo.txtCfsPolTrmlInfo = this.txtCfsPolTrmlInfo
        }
      })
    },
    oogLoiPop (kmdVslCheck) {
      if (this.lang === 'KOR') {
        this.popParams = {
          kmdVslCheck: kmdVslCheck
        }

        this.selectFunc = ''

        this.openPopup('oog-loi-pop')
      }
    },
    setRfTeuCount () {
      const regParams = this.regParams
      const params = {
        vslCd: regParams.vslCd,
        voyNo: regParams.voyNo,
        polCtrCd: regParams.polCtrCd,
        hidCS008I: regParams.hidCS008I,
        scheduleEditYn: regParams.scheduleEditYn
      }

      trans.getReeferYnCheck(params).then((res) => {
        const result = res.data

        let rfTeuCount = ''
        const len = result.length
        result.forEach((c, idx) => {
          rfTeuCount += c

          if (idx !== len - 1) {
            rfTeuCount += ':'
          }
        })

        this.rfTeuCount = rfTeuCount
      })
    },
    fnDgShipCheck (__containerList = null) {
      // 닝보, 대련, 상해항 consignee 입력창 노출 및 surabaya 안내 메시지 출력. MSDS 필수 체크
      console.log('@@@@ enter fnDgShipCheck')
      const regParams = this.regParams
      let dgCnt = 0
      let _containerList =
        __containerList !== null && Array.isArray(__containerList)
          ? __containerList
          : [...this.$store.state.schedule.containerList]
      _containerList.forEach((c) => {
        if (c.cgoTypCd === '01') {
          dgCnt++
        }
      })

      // this.$store.commit('schedule/defaultAssign', {
      //   key: 'containerList',
      //   value: [
      //     ..._containerList
      //   ],
      //   test: 'fnDgShipCheck'
      // })

      if (
        (regParams.polPortCd === 'SUB' || regParams.podPortCd === 'SUB') &&
        dgCnt > 0
      ) {
        this.$ekmtcCommon.alertDefault(this.$t('js.booking.024'))
      }

      const msdsCheck = this.fnMSDSCheck()
      this.consigneeYn = 'N'
      this.msdsCheck = 'N'
      console.log('@@@@ dgCnt: ', dgCnt)
      if (dgCnt > 0) {
        if (
          this.$ekmtcCommon.isNotEmpty(regParams.podPortCd) &&
          'DLC|NBO|SHA'.indexOf(regParams.podPortCd) > -1
        ) {
          this.consigneeYn = 'Y'
        }

        if (msdsCheck === 'Y') {
          this.msdsCheck = 'Y'
        }
      }
    },
    fnMSDSCheck () {
      let msdsCheck = 'N'
      const regParams = this.regParams

      if (
        this.$ekmtcCommon.isNotEmpty(regParams.hidCS008I) &&
        this.$ekmtcCommon.isNotEmpty(regParams.podPortCd)
      ) {
        if (
          regParams.hidCS008I.indexOf('FDR:HKG') > -1 ||
          regParams.podPortCd === 'NBO' ||
          regParams.podPortCd === 'SHA'
        ) {
          if (regParams.hidCS008I.indexOf('FDR:HKG') > -1) {
            this.cn4List.forEach((c) => {
              if (c.plcCd === regParams.podPortCd) {
                msdsCheck = 'Y'
              }
            })
          } else {
            msdsCheck = 'Y'
          }
        }
      }

      return msdsCheck
    },
    fnShaTsMsg () {
      const podPortCd = this.regParams.podPortCd
      const hidCS008I = this.regParams.hidCS008I
      if (podPortCd === 'SHA') {
        this.$ekmtcCommon.alertDefault(this.$t('js.booking.034'))
        return
      }
      let cnt = 0
      let podSha = hidCS008I.split('@_@')
      for (let i = 0; i < podSha.length - 1; i++) {
        if (cnt > 0) break

        const podShats = podSha[i].split(':')
        if (podShats[3] === 'SHA') {
          this.$ekmtcCommon.alertDefault(this.$t('js.booking.034'))
          cnt++
        }
      }
    },
    checkRtnCgoYn () {
      const rtnCgoYn = this.regParams.rtnCgoYn

      if (rtnCgoYn !== 'Y') {
        this.regParams.impBlNo = ''
      }
    },
    checkVanningInfo (cvo) {
      const TH = this
      const regParams = TH.regParams
      let isOk = true
      const dgList = cvo.containerDGList
      const frm = document.querySelector('#frm')

      if (cvo.vanningDtYn === 'Y') {
        const selector = frm.querySelector('#vaniDt_' + cvo.cntrSeq)
        TH.$ekmtcCommon.hideErrorTooltip(selector)
        if (regParams.polCtrCd !== 'JP') {
          if (TH.$ekmtcCommon.isEmpty(cvo.vaniDt)) {
            TH.$ekmtcCommon.showErrorTooltip(
              selector,
              this.$t('msg.CSBK100.147')
            )
            isOk = false
          }
        }
      }

      return isOk
    },
    async rtnPhbtChrCheck () {
      const regParams = this.regParams
      const etd = this.$ekmtcCommon.isNotEmpty(regParams.etd)
        ? regParams.etd.substring(0, 8)
        : ''
      const eta = this.$ekmtcCommon.isNotEmpty(regParams.eta)
        ? regParams.eta.substring(0, 8)
        : ''
      const params = {
        bkgNo: regParams.bkgNo,
        etd: etd,
        eta: eta,
        porCtrCd: regParams.polCtrCd,
        porPortCd: regParams.polPortCd,
        polCtrCd: regParams.polCtrCd,
        polPortCd: regParams.polPortCd,
        podCtrCd: regParams.podCtrCd,
        podPortCd: regParams.podPortCd,
        podTrmlCd: regParams.podTrmlCd,
        dlyCtrCd: regParams.podCtrCd,
        dlyPortCd: regParams.podPortCd,
        hidCS008I: regParams.hidCS008I,
        cmdtDsc: regParams.cmdtDsc,
        scheduleEditYn: regParams.scheduleEditYn,
        uiFlag: this.saveFlag
      }

      let isOk = true

      await trans.getRtnPhbtChrChk(params).then(async (res) => {
        const result = res.data
        if (result.rtnCode === 'Y') {
          this.$ekmtcCommon.alertDefault(result.rtnMessage)
          isOk = false
        } else if (result.rtnCode === 'A') {
          // if (
          //   !(await this.$ekmtcCommon.asyncAlertMessage({
          //     message: result.rtnMessage,
          //     useConfirmBtn: true
          //   }))
          // ) {
          //   isOk = false
          // }
          let forOk = true
          for (var item of result.rtnPhbtList) {
              if (
                !(await this.$ekmtcCommon.asyncAlertMessage({
                  message: item.rmk + '\n' + this.$t('msg.CSBK100.114'),
                  useConfirmBtn: true,
                  onCloseSelf: (resolve, $Simplert) => {
                    resolve(false)
                    $Simplert.close()

                    forOk = false
                  }
                }))
              ) {
                isOk = false
              }

              if (!forOk) {
                return isOk
              }
            }
        }
      })

      return isOk
    },
    async pickupDateRestJp () {
      const regParams = this.regParams
      const cntrInfo = regParams.containerList
      const arrPcupReqDt = []

      cntrInfo.forEach((c) => {
        if (this.$ekmtcCommon.isNotEmpty(c.pcupReqDt)) {
          arrPcupReqDt.push(c.pcupReqDt)
        }
      })

      if (arrPcupReqDt.length > 0) {
        await trans.pickupDateRestJp(arrPcupReqDt).then(async (res) => {
          const result = res.data
          if (result === 'Y') {
            await this.$ekmtcCommon.asyncAlertMessage({
              message:
                '日祭日はゲートクローズしております。\nコンテナピックアップは平日に設定願います。<br>＊ 土曜日の受入体制につきましては、お手数ですが\n各港代理店にお問合わせ下さい。'
            })
          }
        })
      }
    },
    async savePickupBooking () {
      const TH = this
      const regParams = TH.regParams
      let isOk = true
      let isOkCharger = true
      let isOkCntr = true

      const arrChargeEl = [
        'bizPicNm',
        'bizPicTelPlcNo',
        'bizPicTelOfcNo',
        'bizPicFaxPlcNo',
        'bizPicFaxOfcNo',
        'bizPicMbpBsnNo',
        'bizPicMbpOfcNo',
        'bizPicEmlAddr',
        'docPicNm',
        'docPicTelPlcNo',
        'docPicTelNo',
        'docPicFaxPlcNo',
        'docPicFaxNo',
        'docPicMbpBsnNo',
        'docPicMbpNo',
        'docPicEmlAddr'
      ]

      if (regParams.polCtrCd !== 'VN') {
        arrChargeEl.forEach((el) => {
          if (!TH.checkValidationCharger(el, 'SAVE')) {
            isOkCharger = false
          }
        })
      }

      isOkCntr = await TH.checkValidationCntrAll()

      if (!isOkCharger || !isOkCntr) {
        isOk = false
      }

      if (isOk) {
        regParams.scheduleEditYn = 'N'
        regParams.hidCS008I = ''
        regParams.vslCd = 'TBN'
        regParams.voyNo = '9999'
        regParams.polPortCd = ''
        regParams.polCtrCd = ''
        regParams.podPortCd = ''
        regParams.podCtrCd = ''
        regParams.polTrmlCd = ''
        regParams.podTrmlCd = ''

        TH.$delete(TH.regParams, 'iranBookingInfo')
        regParams.containerList.forEach((c) => {
          TH.$delete(c, 'containerDGList')
        })

        const arrResetKey = [
          'actShprCstEnm',
          'consYn',
          'grsWt',
          'frtPncCd',
          'cneCstEnm',
          'cmdtDsc',
          'emptyFlag',
          'tfcoCstNm',
          'dpstAcCd',
          'stuffPlceCd',
          'bkgShprCstNo',
          'fwdrCstNo',
          'fwdrCstEnm',
          'bkgShprCstEnm',
          'rtnCgoYn',
          'impBlNo',
          'cstBidgMgrNo',
          'tfcoCstNm',
          'zip1',
          'zip2',
          'facNm',
          'facPicNm',
          'facCtfcNo',
          'facPicEmlAddr',
          'dorOdrRmk'
        ]

        arrResetKey.forEach((el) => {
          regParams[el] = ''
        })

        let fnBkg = ''
        if (isOk) {
          if (this.saveFlag === 'ADD') {
            fnBkg = bookings.postBooking(TH.regParams)
          } else {
            fnBkg = bookings.putBooking(TH.regParams)
          }

          let successFlag = true
          let bkgNo = ''
          if (
            await this.$ekmtcCommon.asyncAlertMessage({
              message: this.$t('msg.CSBK100.215'),
              useConfirmBtn: true
            })
          ) {
            await fnBkg
              .then((res) => {
                const result = res.data

                if (result !== undefined) {
                  if (result.rtnCode !== 'C0000') {
                    successFlag = false
                  } else {
                    bkgNo =
                      TH.saveFlag === 'ADD' ? result.bkgNo : TH.regParams.bkgNo
                  }
                } else {
                  successFlag = false
                }
              })
              .catch((err) => {
                console.log(err)
                successFlag = false
              })
              .finally(() => {
                if (!successFlag) {
                  this.$ekmtcCommon.screenToPdfUpload(
                    this.regParams,
                    this.saveFlag === 'ADD' ? 'postBooking3' : 'putBooking1'
                  )
                  this.$ekmtcCommon.alertDefault(this.$t('js.common.004'))
                } else {
                  this.$ekmtcCommon.alertCallback(
                    this.$t('art.CMATK383'),
                    () => {
                      TH.$router
                        .push({
                          name: 'booking-edit',
                          params: { bkgNo: bkgNo }
                        })
                        .catch(() => {})
                    }
                  )
                }
              })
          }
        }
      }
    },
    async checkValidationDGVol () {
      const regParams = this.regParams
      //const containerList = this.regParams.containerList
      const containerList = [...this._containerList]
      const len = containerList.length
      const params = {
        vslCd: regParams.vslCd,
        voyNo: regParams.voyNo,
        polPortCd: regParams.polPortCd,
        podPortCd: regParams.podPortCd,
        slotExchCsNo: 'KMD',
        cntrQty: '',
        cntrSzCd: '',
        imdun: '',
        cls: '',
        cgoTypCd: '',
        hidCS008I: regParams.hidCS008I,
        scheduleEditYn: regParams.scheduleEditYn,
        saveFlag: this.saveFlag,
        bkgNo: regParams.bkgNo,
        cntrTypCd: ''
      }

      let dgVolErrYn = 'N'
      let c, dgList, dgLen
      let dgArr = []

      let cgoFbCnt = 0
      let cgoOogCnt = 0
      let cgoHzCnt = 0
      let strCgoTypCdNm = ''

      for (let i = 0; i < len; i++) {
        c = containerList[i]
        if (c.cgoTypCd !== '01' && c.cgoTypCd !== '02' && c.cgoTypCd !== '06') {
          continue
        }

        params.cntrQty = c.cntrQty
        params.cntrSzCd = c.cntrSzCd
        params.cgoTypCd = c.cgoTypCd
        params.cntrTypCd = c.cntrTypCd
        dgList = c.containerDGList

        if (c.cgoTypCd === '01') {
          dgLen = c.containerDGList.length
          for (let j = 0; j < dgLen; j++) {
            params.imdu = dgList[j].imdunNo
            params.cls = dgList[j].imoClasCd

            await transCommon.getDgVolumnCheck(params).then(async (res) => {
              dgVolErrYn = res.data
            })

            if (dgVolErrYn === 'Y') {
              break
            }
          }
        } else {
          await transCommon.getDgVolumnCheck(params).then(async (res) => {
              dgVolErrYn = res.data
            })
        }

        if (dgVolErrYn === 'Y') {
          if (c.cgoTypCd === '06' || c.cgoTypCd === '03') {
            cgoFbCnt += 1
          } else if (c.cgoTypCd === '02') {
            cgoOogCnt += 1
          } else if (c.cgoTypCd === '01') {
            cgoHzCnt += 1
          }

          if (cgoFbCnt > 0) {
            if (strCgoTypCdNm !== '' && strCgoTypCdNm.indexOf('Flexi Bag') === -1) {
              strCgoTypCdNm += ', Flexi Bag'
            } else {
              strCgoTypCdNm = 'Flexi Bag'
            }
          }

          if (cgoOogCnt > 0) {
            if (strCgoTypCdNm !== '' && strCgoTypCdNm.indexOf('OOG') === -1) {
              strCgoTypCdNm += ', OOG'
            } else {
              strCgoTypCdNm = 'OOG'
            }
          }

          if (cgoHzCnt > 0) {
            if (strCgoTypCdNm !== '' && strCgoTypCdNm.indexOf('HZ') === -1) {
              strCgoTypCdNm += ', HZ'
            } else {
              strCgoTypCdNm = 'HZ'
            }
          }
        }
      }

      const rntValue = {
        dgVolErrYn,
        strCgoTypCdNm
      }

      return rntValue
    },
    async saveBooking () {
      console.log('@@@@ call saveBooking')
      const regParams = this.regParams
      const mtyPkupUsagYn = regParams.mtyPkupUsagYn // pickup booking
      const that = this

      if (this.regParams.chemicalFileList) {
        this.regParams.chemicalFileList.map(obj => {
          if (isNaN(obj.fileId)) {
            obj.fileId = 0
          }
        })
      }

      if (mtyPkupUsagYn === 'N' && this.$ekmtcCommon.isEmpty(regParams.vslCd)) {
        this.$ekmtcCommon.alertCallback(this.$t('js.booking.003'), function () { that.searchSchedule() })
        return
      }

      if (mtyPkupUsagYn === 'Y') {
        this.savePickupBooking()
        return
      }

      if (this.bkgDocOver === 'Y' && regParams.podCtrCd === 'IR') {
        this.$ekmtcCommon.alertDefault(this.$t('js.booking.036'))
        return
      }

      const arrChargeEl = [
        'bizPicNm',
        'bizPicTelPlcNo',
        'bizPicTelOfcNo',
        'bizPicFaxPlcNo',
        'bizPicFaxOfcNo',
        'bizPicMbpBsnNo',
        'bizPicMbpOfcNo',
        'bizPicEmlAddr',
        'docPicNm',
        'docPicTelPlcNo',
        'docPicTelNo',
        'docPicFaxPlcNo',
        'docPicFaxNo',
        'docPicMbpBsnNo',
        'docPicMbpNo',
        'docPicEmlAddr'
      ]
      const arrCargoEl = ['actShprCstEnm', 'cmdtDsc', 'cneCstEnm', 'fwdEdiCd']
      const arrTransEl = ['zip1', 'facPicNm', 'facCtfcNo']
      const arrVanningEl = ['chclActShprCstNo', 'vaniSurvCstNo']
      const arrEmailEl = ['facPicEmlAddr', 'cnePicEmlAddr', 'ir_endUsrEmlAddr']

      let isOk = true
      let isOkRtnPhbt = true

      if (regParams.polCtrCd !== 'VN') {
        arrChargeEl.forEach((el) => {
          if (!this.checkValidationCharger(el, 'SAVE')) {
            isOk = false
          }
        })
      }

      arrCargoEl.forEach((el) => {
        if (!this.checkValidationCargoInfo(el)) {
          isOk = false
        }
      })

      if (!this.checkValidationWeight()) {
        isOk = false
      }

      if (this.lang === 'KOR' && this.cstCatCd !== 'Y') {
        arrTransEl.forEach((el) => {
          if (!this.checkValidationTrans(el)) {
            isOk = false
          }
        })
      }

      if (this.vanningYn === 'Y') {
        arrVanningEl.forEach((el) => {
          if (!this.checkValidationVanningInfo(el)) {
            isOk = false
          }
        })
      }

      arrEmailEl.forEach((el) => {
        if (!this.checkValidationEmail(el)) {
          isOk = false
        }
      })

      if ((regParams.podCtrCd === 'IR' || regParams.podCtrCd === 'RU') && !this.checkIranValidationAll()) {
        isOk = false
      }

      // 말레이시아 hs code 체크
      if ((regParams.polCtrCd === 'MY') && !this.checkMyValidation()) {
        isOk = false
      }

      //if (this.lang !== 'KOR') {
        if (this.$ekmtcCommon.isNotEmpty(regParams.cmdtDsc)) {
          isOkRtnPhbt = await this.rtnPhbtChrCheck()
        }

        if (!isOkRtnPhbt) {
          //isOk = false
          return
        }

        if (this.lang === 'JPN') {
          await this.pickupDateRestJp()
        }

        // KMTC ON 구간인 경우 해당 메세지 노출 안함
        if (this.bkgCloseYn === 'CHECK' && this.regParams.kmtcSpotYn !== 'Y') {
          // 선복 초과 안내
          //await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.CSBK100.191') })
        }
      //}

      console.log('@@@@ isOk: ', isOk)

      const containerList = [...this._containerList]
      const TH = this
      const dg = TH.$refs.dg
      const oog = TH.$refs.oog
      const flexi = TH.$refs.flexi

      let dgCnt = 0
      let oogCnt = 0
      let rfCnt = 0
      let flexiCnt = 0
      let rfMsg = this.$t('msg.CSBK100.132')
      let cntrSumValue = 0
      let cntrQty = 0
      let teuCount = 0

      let rfIncMsg = 'N'
      let megnumYn = 'N'
      let rfDgMsgYn = 'N'

      let oogBreakBulkYn = 'N'
      let oogLimitYn = 'N'

      let errCnt = 0
      let rfPlugCount = 0

      if (!(await this.checkValidationCntrAll())) {
        isOk = false
        console.log('@@@@ error 1')
        errCnt++
      }

      if (!this.checkValidationRemarkAll()) {
        isOk = false
        console.log('@@@@ error 2')
        errCnt++
      }

      if (this.consigneeYn === 'Y') {
        isOk = this.checkValidationConsigneeAll()
        console.log('@@@@ error 3: ', isOk)
        isOk || errCnt++
      }

      if (this.cfsRmkYn === 'Y') {
        this.$ekmtcCommon.alertDefault(this.cfsRmkMsg)
        isOk = false
        console.log('@@@@ error 4')
        errCnt++
      }
      console.log('@@@@ errCnt: ', errCnt)
      console.log('@@@@ isOk1: ', isOk)

      let vaniAertYn = 'N'
      let idx = 0
      let phCntrChk = false

      // KMTC ON PREMIUM 부킹 컨테이너 정보 체크용도
      let msg = ''
      let msgArr = []
      let kmtcIdx = 0
      // 출항일 기준 날짜 체크
      let cntrMinusChk = this.chkKmtcOnCntrInfoMinus() // false : 출항일 7일 이전, true : 출항일 7일 이후

      // KMTC ON PREMIUM 부킹인 경우 컨테이너 수량 변경 조건에 맞게 체크함
      // _containerList의 값을 서버로 전달하도록 되어 있어 직접 컨트롤해야함
      for (const c of this._containerList) {
        if (this.regParams.kmtcSpotYn === 'Y' && this.saveFlag === 'EDIT' && this.oldContainerList.length > 0 && kmtcIdx === 0) {
          this.oldContainerList.forEach((old) => {
            this._containerList.forEach((c) => {
              if (old.cntrSeq === c.cntrSeq) {
                if (Number(old.cntrQty) < Number(c.cntrQty)) { // 증량인 경우
                  msg = this.$t('msg.CSBK100.513')
                } else if (Number(old.cntrQty) > Number(c.cntrQty) && !cntrMinusChk) { // 감소이면서 출항일 7일 이전
                  msg = this.$t('msg.CSBK100.514')
                }

                if (msg !== '') {
                  msgArr.push(msg)
                }

                // 컨테이너 수량을 증량하거나, 출항일 7일 이전인 경우 화면 수정 값을 원복시킴
                if (Number(old.cntrQty) < Number(c.cntrQty) || !cntrMinusChk) {
                  const frm = document.querySelector('#frm')
                  const id = 'cntrQty_' + kmtcIdx
                  const selector = frm.querySelector('#' + id)
                  selector.value = old.cntrQty
                  this._containerList[kmtcIdx].cntrQty = old.cntrQty
                  kmtcIdx++
                }
              }
            })
          })
        }
      }

      if (this.regParams.kmtcSpotYn === 'Y' && msgArr.length > 0) {
        for (const m of msgArr) {
          await this.$ekmtcCommon.asyncAlertMessage({ message: m })
        }
      }

      let calWgt
      let totSum = 0
      let calWgtYn = 'Y'

      for (const c of containerList) {
        if (this.memberDetail.userCtrCd === 'JP') {
          let cntrSpec = c.cntrSzCd + c.cntrTypCd

          // 컨테이너별 제한무게 계산후 전체허용 무게 체크
          if (this.regParams.wtCvsCd === 'T') {
            const ton = this.regParams.grsWt
            calWgt = Number(ton) * 1000
          } else {
            calWgt = this.regParams.grsWt
          }

          if ((c.cntrSzCd === '20' || c.cntrSzCd === '40') && (c.cntrTypCd === 'GP' || c.cntrTypCd === 'RF' || c.cntrTypCd === 'RH' || c.cntrTypCd === 'HC')) {
            calWgtYn = 'Y'

            // 수량에 따른 제한무게 계산
            switch (cntrSpec) {
              // 20GP = 21.7 TON 21700kg
              // 20'RF = 22.0 TON 22000kg
              // 40'GP/HC = 26.5 TON 26500kg
              // 40'RH = 25.7 TON 25700kg

              case '20GP' :
                  totSum += Number(c.cntrQty) * 21700
                  break
              case '20RF' :
                  totSum += Number(c.cntrQty) * 22000
                  break
              case '40GP' || '40HC' :
                  totSum += Number(c.cntrQty) * 26500
                  break
              case '40RH' :
                  totSum += Number(c.cntrQty) * 25700
                  break
            }
          } else {
            calWgtYn = 'N'
          }
        }

        // KMTC ON 구간이면서 카고타입 선택시 리턴 처리함
        if (c.cgoTypCd !== '' && this.regParams.kmtcSpotYn === 'Y') {
          isOk = false
          this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.281'))
          return
        }

        // 필리핀 40GP 선택한 경우 확인 메세지와 함께 CY REMARK에 문구 추가함
        if (this.usrCtrCd === 'PH' && c.cntrSzCd === '40' && c.cntrTypCd === 'GP' && !phCntrChk) {
          let msg = 'Please confirm if 40HC equipment sub is acceptable in case 40GP unit is not available?'
          if (await this.$ekmtcCommon.asyncAlertMessage({ message: msg, useConfirmBtn: true })) {
            if (this.regParams.dorOdrRmk.length > 0) {
              this.regParams.dorOdrRmk += '\n40HC EQ SUB'
            } else {
              this.regParams.dorOdrRmk = '40HC EQ SUB\n'
            }
          }
          phCntrChk = true
        }
        cntrSumValue += Number(c.cntrSzCd) * Number(c.cntrQty)
        cntrQty += Number(c.cntrQty)
        if (c.cgoTypCd === '01') {
          for (const d of dg) {
            if (c.cntrSeq === d.cntrSeq) {
              //d.setCasNoList() // casNoPortYn = 'Y'인 경우는 casNoList에 셋팅, 아닌 경우 casNoList 삭제
              c.containerDGList = d.dgInfo.containerDGList
              c.reqConts = d.dgInfo.reqConts
              c.reqRno = d.dgInfo.reqRno

              if (this.$ekmtcCommon.isEmpty(c.reqRno)) {
                this.dgOogFileYn = 'N'
              } else {
                this.dgOogFileYn = ''
              }

              if (this.lang !== 'KOR') {
                c.vaniDt = d.dgInfo.vaniDt
                c.vanningDtYn = d.vanningDtYn

                if ((this.$ekmtcCommon.isEmpty(c.vaniDt) || this.$ekmtcCommon.isEmpty(c.reqRno)) && this.lang === 'ENG') {
                  vaniAertYn = 'Y'
                }

                if (!this.checkVanningInfo(c)) {
                  isOk = false
                  console.log('@@@@ isOk checkVanningInfo: ', isOk)
                }
              }

              if (!(await d.checkValidationDGAll())) {
                isOk = false
                console.log('@@@@ isOk checkValidationDGAll: ', isOk)

                if (d.alertShow === 'N') {
                  return
                }
              }
            }
          }
          dgCnt++

          // if (c.cntrTypCd === 'RF' || c.cntrTypCd === 'RH') {
          //   if (this.$ekmtcCommon.isEmpty(c.apvNo)) {
          //     rfDgMsgYn = 'Y'
          //   } else {
          //     c.reqConts = c.apvNo + '|||' + c.reqConts
          //   }
          // }
        } else if (c.cgoTypCd === '02' || (c.cgoTypCd === '03' && c.cntrSzCd === '45' && c.cntrTypCd === 'HC')) {
          oog.forEach((o) => {
            if (c.cntrSeq === o.cntrSeq) {
              const oogObj = o.oogInfo
              c.orgLength = oogObj.orgLength
              c.orgWidth = oogObj.orgWidth
              c.orgHeight = oogObj.orgHeight
              c.itemQty = oogObj.itemQty
              c.underDckCd = oogObj.underDckCd
              c.bulkYn = oogObj.bulkYn
              c.cmdtCd = oogObj.cmdtCd
              c.cmdtDsc = oogObj.cmdtDsc
              c.frgtWt = oogObj.frgtWt
              c.tareWtYn = oogObj.tareWtYn
              c.itemCont = oogObj.itemCont
              c.reqRno = oogObj.reqRno
            }

            if (!o.checkValidationOOGAll()) {
              isOk = false
              console.log('@@@@ isOk checkValidationOOGAll: ', isOk)
            }

            const confirmFlagObj = this.oogConfirmInfo(c) // oog 사이즈에 따른 confirm 로직 추가
            if (confirmFlagObj.breakBulkYn === 'Y') {
              oogBreakBulkYn = 'Y'
            }

            if (confirmFlagObj.limitYn === 'Y') {
              oogLimitYn = 'Y'
            }

            if (this.$ekmtcCommon.isEmpty(c.frgtWt)) {
              c.frgtWt = '0'
            }
          })

          if (c.cgoTypCd === '02') {
            oogCnt++
          }
        } else if (c.cgoTypCd === '06') {
          flexi.forEach((f) => {
            if (c.cntrSeq === f.cntrSeq) {
              const flexiObj = f.flexiInfo

              c.cmdtDsc = flexiObj.cmdtDsc
              c.mfcoNm = flexiObj.mfcoNm
              c.dgYn = flexiObj.dgYn
              c.mpltYn = flexiObj.mpltYn
              c.newFbYn = flexiObj.newFbYn
              c.flsTmpr = flexiObj.flsTmpr
              c.fbCapa = flexiObj.fbCapa
              c.perMax = flexiObj.perMax
              c.fbGrsWt = flexiObj.fbGrsWt
              c.flexiCont = flexiObj.flexiCont
              c.reqRno = flexiObj.reqRno
              c.spcfcGrvt = flexiObj.spcfcGrvt
            }

            if (!f.checkValidationFlexiAll()) {
              isOk = false
              console.log('@@@@ isOk checkValidationFlexiAll: ', isOk)
            }
          })
          flexiCnt++
        }

        if (c.cntrTypCd === 'RF' || c.cntrTypCd === 'RH') {
          if (!this.checkValidationRFAll(idx)) {
            isOk = false
          }

          rfCnt++

          if (this.lang === 'KOR') {
            const rfTmprUnitCd = c.rfTmprUnitCd === 'C' ? '℃' : '℉'
            const cntrRfStsList = this.cntrRfStsList.filter((vo) => vo.cd === c.cntrRfStsCd)

            // console.log('@@@@ c.cntrRfStsCd: ', c.cntrRfStsCd)
            // console.log('@@@@ cntrRfStsList: ', cntrRfStsList)

            if (c.chkNOR === 'Y') {
              rfMsg += c.cntrSzCd + ' / ' + c.cntrTypCd + ' * NOR' + '<br/>'
            } else {
              rfMsg += c.cntrSzCd + ' / ' + c.cntrTypCd + ' * ' + c.rfSetupTmpr + rfTmprUnitCd + ' / ' + cntrRfStsList[0].cdNm + '<br/>'
            }

            if (c.pcupCyCd === 'ICT' || c.pcupCyCd === 'HJIT') {
              rfIncMsg = 'Y'
            }

            const rfTxt = c.cntrSzCd + c.cntrTypCd + c.rfSetupTmpr + c.rfTmprUnitCd
            if (rfTxt === '40RH-40C') {
              if (c.cntrRfStsCd === '01') {
                megnumYn = 'Y'
              }
            }
            regParams.megNumYn = megnumYn
          }
        }

        if (c.chkNOR === 'Y') {
          c.cgoTypCd = '05'
        }

        if (isOk) {
          if (c.cntrSzCd === '20') {
            teuCount += Number(c.cntyQty)
          } else {
            teuCount += Number(c.cntyQty) * 2
          }
          rfPlugCount += Number(cntrQty)
        }
        idx++
      }

      // JP, 컨테이너 수량 정보 변경시, Cargo weight 변경 알림 팝업 추가
      let oldCntrCnt = 0
      let cntrCnt = 0

      // if (this.memberDetail.userCtrCd === 'JP') {
        for (const c of this._containerList) {
          cntrCnt += Number(c.cntrQty)
        }

        for (const old of this.oldContainerList) {
          oldCntrCnt += Number(old.cntrQty)
        }

        if (cntrCnt !== 0 && oldCntrCnt !== 0) {
          if (Number(cntrCnt) !== Number(oldCntrCnt)) {
            // console.log('oldContainerList @@@@@@', (cntrCnt))
            // console.log('oldContainerList @@@@@@', (oldCntrCnt))

            let unit
            let msg

            if (this.regParams.wtCvsCd === 'T') {
              unit = 'ton'
            } else {
              unit = 'kg'
            }

            if (this.memberDetail.userCtrCd === 'JP') {
              msg = '重量 / '
            } else {
              msg = 'Cargo Weight / '
            }

            if (!await this.$ekmtcCommon.asyncAlertMessage({
                // 컨테이너 개수가 변경되었습니다. Cargo Weight 확인 부탁드립니다.
                message: this.$t('js.booking.045') + '\n' + msg + this.regParams.grsWt + unit,
                useConfirmBtn: true
                  })) {
              return
            }
          }
        }
      // }

      // console.log('@@@@ checkValidationWeight containerList totSum', totSum)
      // console.log('@@@@ checkValidationWeight containerList calWgt', calWgt)

      if (calWgtYn === 'Y' && totSum < Number(calWgt)) {
        if (!await this.$ekmtcCommon.asyncAlertMessage({
              message: `<strong><font color='red'>Upper limit of weight</font> of KMTC regulation
                                                      20'GP = 21.7 TON 21700kg
                                                      20'RF = 22.0 TON 22000kg
                                                      40'GP/HC = 26.5 TON 26500kg
                                                      40'RH = 25.7 TON 25700kg </strong>
                                                      `,
              useConfirmBtn: true
              })) {
          return
        }
      }

      console.log('@@@@ isOk3: ', isOk)
      console.log('@@@@ dg: ', dg)
      console.log('@@@@ oog: ', oog)
      console.log('@@@@ flexi: ', flexi)

      if (dg !== undefined) {
        for (const d of dg) {
          const isDgMaxOk = await d.checkValidationDGMax()
          if (!isDgMaxOk) {
            isOk = false
            return
          }
        }
      }

      if (dg !== undefined || oog !== undefined || flexi !== undefined) {
        let dgVolChk
        try {
          dgVolChk = await this.checkValidationDGVol()

          if (dgVolChk.dgVolErrYn === 'Y') {
            let dgVolMessage = ''
            if (this.lang === 'KOR') {
              // dgVolMessage = '이 스케줄은 위험물 제한이 있습니다.\n이미 제한 수량을 초과하였으니 다른 스케줄로 진행 바랍니다.'
              dgVolMessage = `이 스케줄은 ${dgVolChk.strCgoTypCdNm} 수량 제한이 있습니다.\n이미 제한 수량을 초과하였으니 다른 스케줄로 진행 바랍니다.`
            } else {
              dgVolMessage = `This schedule have a limited number of ${dgVolChk.strCgoTypCdNm}.\nExceeded the Volume limit now\nSo please change target vessel schedule.`
            }
            this.$ekmtcCommon.alertDefault(dgVolMessage)
            return
          }
        } catch (e) {
          console.log('@@@@ dgVolChk: ', dgVolChk)
        }
      }
      console.log('@@@@ errCnt: ', errCnt)

      if (isOk && errCnt === 0) {
        if (this.saveFlag === 'EDIT' && Number(this.vslRest) > 0) {
          if (teuCount > Number(this.regParams.dgHcTeuCount)) {
            this.$ekmtcCommon.alertDefault(this.$t('js.booking.037'))
            return
          }
        }

        if (dgCnt > 0) {
          if (regParams.polTrmlCd === 'CONV') {
            const convMessage = this.$t('msg.CSBK100.103') + '\n' + this.$t('msg.CSBK100.104')
            await this.$ekmtcCommon.asyncAlertMessage({ message: convMessage })
          }

          if (this.msdsCheck === 'Y' && this.dgOogFileYn === 'N') {
            const message = this.regParams.podPortCd === 'SHA' ? 'A MSDS is required. Please upload the file.' : 'A MSDS in CHINESE is required. Please upload the file.'
            await this.$ekmtcCommon.asyncAlertMessage({ message: message })
          }
        }

        if (flexiCnt > 0) {
          const kmdVslCheck = this.kmdVslCheck
          let flexiFileMsg = this.$t('msg.CSBK100.250')
          let flexiMsgYn = 'N'

          if (kmdVslCheck === 'Y') {
            if (this.fbCat01 !== 'Y' || this.fbCat02 !== 'Y' || this.fbCat03 !== 'Y') {
              flexiMsgYn = 'Y'
            }
          } else {
            if (this.fbCat01 !== 'Y' || this.fbCat02 !== 'Y' || this.fbCat03 !== 'Y') {
              flexiMsgYn = 'Y'
            }
          }

          if (flexiMsgYn === 'Y' && !(await this.$ekmtcCommon.asyncAlertMessage({ message: flexiFileMsg, useConfirmBtn: true }))) {
            return
          }
        }

        if (rfIncMsg === 'Y') {
          // 리퍼컨테이너 PTI 완료 여부 및 홀딩 해제
          await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.CSBK100.192') })
        }

        if (rfCnt > 0) {
          if (regParams.polShtmCd === '02') {
            this.$ekmtcCommon.alertDefault(this.$t('js.booking.023'))
            return
          }

          if (this.oldContainerList.length > 0) {
            this.oldContainerList.forEach((old) => {
              containerList.forEach((c) => {
                if (old.cntrSeq === c.cntrSeq) {
                  if (old.cntrSzCd !== c.cntrSzCd || old.cntrTypCd !== c.cntrTypCd || old.cntrQty !== c.cntrQty || old.pcupReqDt !== c.pcupReqDt ||
                    old.pcupCyCd !== c.pcupCyCd || old.rfSetupTmpr !== c.rfSetupTmpr || old.rfTmprUnitCd !== c.rfTmprUnitCd) {
                    this.cntrEditYn = 'Y'
                  }
                }
              })
            })
          }
        }

        let feederYn = 'N'
        let plugOverCnt = 0
        if (this.$ekmtcCommon.isNotEmpty(this.rfTeuCount) && this.lang === 'KOR') {
          const rftTemp = this.rfTeuCount.split(':')

          rftTemp.forEach((r) => {
            if (r === 'FDR' && this.lang === 'KOR') {
              feederYn = 'Y'
              //return
            }
          //console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@', Number(r))
          //console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@', rfPlugCount)
          //console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@', this.regParams.cntTeuCount)
          //console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@', this.saveFlag)
          //console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@', this.regParams.scheduleEditYn)
            if (this.saveFlag === 'EDIT' && this.regParams.scheduleEditYn !== 'Y' && (rfPlugCount <= this.regParams.cntTeuCount)) {
              //
            } else {
              if (Number(r) - rfPlugCount < 0) {
                plugOverCnt++
              }
            }
          })
        }

        if (feederYn === 'Y') {
          // FEEDER VSL은 RF 부킹 불가합니다. 부킹 가능한 다른 모선으로 부킹 후, 고객서비스팀으로 문의 부탁 드립니다.(311 6114)
          //this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.193'))
          //return
        }

        if (plugOverCnt > 0) {
          TH.rfPlugOverYn = 'Y'
          //this.$ekmtcCommon.alertDefault('해당 모선의 PLUG 개수가 부족해 부킹이 불가합니다. 다른 모선으로 부킹 부탁 드립니다.')
          //return
        }

        let sizeTypeQty = 'N'
        if (this.lang !== 'KOR' || this.cstCatCd === 'Y') {
          sizeTypeQty = this.fnVslStsCdCheck()
        }

        if (megnumYn === 'Y' && this.$ekmtcCommon.isNotEmpty(regParams.podPortCd) && 'TYO|SMZ|YAT|TMK|ONA|ISI'.indexOf(regParams.podPortCd) === -1) {
          if (regParams.podPortCd === 'SHA' && regParams.polPortCd === 'INC') {
            //
          } else {
            this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.187')) // 매그넘 리퍼 부킹이 불가능한 지역입니다.
            megnumYn = 'N'
            regParams.megNumYn = 'N'
            return
          }
        }

        if (this.$ekmtcCommon.isNotEmpty(TH.vslRest)) {
          if (Number(TH.vslRest) > 0 && this.$ekmtcCommon.isNotEmpty(regParams.sumValue) && Number(regParams.sumValue) < cntrSumValue && this.cntrCpEditYn !== 'Y') {
            this.$ekmtcCommon.alertDefault(this.$t('js.bookingValidation.002')) // 컨테이너 합이 일치하지 않습니다.
            return
          }
        }

        if (regParams.polShtmCd === '02' && this.lang === 'KOR') {
          const localDateTime = new Date(Number(this.serverYear), Number(this.serverMonth) - 1, Number(this.serverDay), Number(this.serverHour), Number(this.serverMin), Number(this.serverSecond))
          const cfsDateTime = this.$ekmtcCommon.getStrToDate(this.cfsCls)

          if (this.$ekmtcCommon.isNotEmpty(this.cfsCls)) {
            if (localDateTime > cfsDateTime) {
              this.$ekmtcCommon.alertDefault('CFS 반입마감 이후에는 CFS Booking을 할 수 없습니다.')
              return
            }
          }
        }

        if (oogBreakBulkYn === 'Y') {
          if (!(await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.CSBK100.251'), useConfirmBtn: true }))) {
            return
          }
        }

        if (oogLimitYn === 'Y') {
          if (!(await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.CSBK100.252'), useConfirmBtn: true }))) {
            return
          }
        }

        let isOkChk = true
        let isOkDG = true
        let isOkOOG = true
        let isOkRF = true
        let isOkMax = true

        if (dgCnt > 0) {
          isOkDG = await this.checkBookingDG()
          if (!isOkDG) {
            return
          }
        }

        if (oogCnt > 0) {
          isOkOOG = await this.checkBookingOOG()
          if (!isOkOOG) {
            return
          }
        }

        if (this.lang === 'KOR') {
          if (rfCnt > 0) {
            isOkRF = await this.checkBookingRF()
            if (!isOkRF) {
              return
            }

            let rfConfirmYn = 'Y'
            if (this.saveFlag === 'EDIT' && this.cntrEditYn !== 'Y') {
              rfConfirmYn = 'N'
            }

            if (rfConfirmYn === 'Y') {
              if (!(await this.$ekmtcCommon.asyncAlertMessage({ message: rfMsg + this.$t('msg.CSBK100.133'), useConfirmBtn: true }))) {
                return
              }
            }
          }

          if (this.$ekmtcCommon.isNotEmpty(TH.regParams.podCtrCd) && 'IN|PK|BD|RU|AE|IR|LK'.indexOf(TH.regParams.podCtrCd) > -1 && this.lang === 'KOR') {
            let message = this.$t('msg.CSBK100.130')
            if (TH.regParams.podCtrCd === 'IN') {
              message += this.$t('msg.CSBK100.130_1')
            }
            if (TH.regParams.podPortCd === 'JEA') {
              message += this.$t('msg.CSBK100.130_2')
            }
            await this.$ekmtcCommon.asyncAlertMessage({ message: message })
          }
        }

        if (cntrQty > 0 && isOk) {
          let avgWT = 0
          if (Number(regParams.grsWt) > 0) {
            if (regParams.wtCvsCd === 'T') {
              avgWT = (Number(regParams.grsWt) * 1000) / cntrQty
            } else {
              avgWT = Number(regParams.grsWt) / cntrQty
            }
          }

          if (avgWT > 40000) {
            this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.144'))
            isOk = false
          } else if (Number(regParams.grsWt) > 0 && avgWT < 10000) {
            if (!(await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.CSBK100.145'), useConfirmBtn: true }))) {
              isOk = false
            }
          } else if (avgWT > 25000) {
            if (!(await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.CSBK100.146'), useConfirmBtn: true }))) {
              isOk = false
            }
          }
        }

        this.$ekmtcCommon.hideErrorTooltip(frm.querySelector('#chemicalY'))
        if (this.regParams.chemicalYn === 'Y' && this.regParams.chemicalFileList.length === 0) {
          this.showErrorTooltipBk(frm.querySelector('#chemicalY'), 'Please Upload the Chemical.')
          if (!await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.CSBK100.507'), useConfirmBtn: true })) {
            return
          }
          //this.$ekmtcCommon.alertDefault(this.$t('Please Upload the Chemical.'))
        }

        if (!isOk) {
          return
        }

        isOkChk = await this.checkBooking()
        if (!isOkChk) {
          return
        }

        isOkMax = await this.fnBkgCstMaxControl(this.saveFlag)
        if (!isOkMax) {
          return
        }

        if (isOk) {
          let successFlag = true
          let saveMessage = this.$t('msg.CSBK100.215')

          if (megnumYn === 'Y') {
            saveMessage = this.$t('js.booking.038')
          } else if (sizeTypeQty === 'Y' && Number(this.vslRest) > 0) {
            saveMessage = this.$t('js.booking.039')
          } else if (this.saveFlag === 'EDIT') {
            if (this.regParams.expcYn === 'Y') {
              saveMessage = this.$t('js.booking.040')
            } else {
              saveMessage = this.$t('tem.CMAT025')
            }
          } else if (this.urlOrNot) { // JamSin DSM URL로 진입한 경우 Booking 저장시 트래킹 코드 삽입
            $('#e-alert-set2-btn1').addClass('conversion')
          }

          if (await this.$ekmtcCommon.asyncAlertMessage({ message: saveMessage, useConfirmBtn: true })) {
            let fnBkg = null
            if (this.urlOrNot) { // JamSin DSM
              $('#e-alert-set2-btn1').removeClass('conversion')
            }

            if (TH.regParams.podCtrCd !== 'IR' && TH.regParams.podCtrCd !== 'RU') {
              // 이란 부킹 아닐경우 iranBookingInfo 삭제
              this.$delete(this.regParams, 'iranBookingInfo')
            }

            if (this.$ekmtcCommon.isNotEmpty(TH.regParams.podPortCd) && 'DLC|NBO|SHA'.indexOf(TH.regParams.podPortCd) === -1) {
              // 상해,닝보,대련 아닌경우 consingnee 정보 삭제
              TH.regParams.cnePicNm = ''
              TH.regParams.cnePicTelNo = ''
              TH.regParams.cnePicFaxNo = ''
              TH.regParams.cnePicEmlAddr = ''
            }

            const schdInfo = TH.$refs.schdInfo
            TH.regParams.legTrmlFixdYn = schdInfo.legTrmlFx

            if (this.lang !== 'KOR') {
              this.regParams.tfcoCstNo = ''

              if (this.usrCtrCd !== 'PH') {
                // 필리핀일 경우 Haulier 저장
                this.regParams.tfcoCstNm = ''
              }
            }

            // 이런,러시아 부킹 저장일때, END_USER 넘기기
            if (this.regParams.podCtrCd === 'IR' || this.regParams.podCtrCd === 'RU') {
              this.regParams.iranBookingInfo.endUserList = this.endUserList
            }

            let o = { ...this.regParams }
            o.containerList = [...this._containerList]
            o.containerList = o.containerList.map((b, idx) => Object.assign({ index: idx }, b))

            //  Container Pick up > 특수화물구분=위험물 Flash Point(온도) 값이 필수가 아니면 NULL 값 들어가도록 함.
            let isOkTmpr = true
            const frm = document.querySelector('#frm')

            o.containerList.forEach((item, index) => {
              if (item.cgoTypCd === '01') {
                item.containerDGList.forEach((_item, _index) => {
                  const selectorTmprUnitCd = frm.querySelector('#tmprUnitCd_' + item.cntrSeq + '_' + _index)
                  this.$ekmtcCommon.hideErrorTooltip(selectorTmprUnitCd)

                  if (this.$ekmtcCommon.isEmpty(_item.flsTmpr)) {
                    _item.tmprUnitCd = ''
                  } else {
                    if (this.$ekmtcCommon.isEmpty(_item.tmprUnitCd)) {
                      // 초기화때, $ekmtcCommon.hideErrorTooltipAll 로 인해 가려진 에러메시지 박스까지 show 처리
                      const $elem = $(selectorTmprUnitCd)
                      $elem.parents('td').eq(0).find('.tooltip_essen_box').show()
                      this.$ekmtcCommon.showErrorTooltip(selectorTmprUnitCd, this.$t('msg.CSBK100.147'))
                      isOkTmpr = false
                    }
                  }
                })
              }

              if (item.cgoTypCd === '06' || item.cgoTypCd === '02' || item.cgoTypCd === '03' || item.cgoTypCd === '') {
                item.containerDGList = []
              }

              if (item.cgoTypCd === '02' || (item.cgoTypCd === '03' && item.cntrTypCd !== 'HC' && item.cntrTypCd !== '45')) {
                // nothing
              } else {
                item.underDckCd = ''
              }

              if (item.chkNOR === 'Y') {
                  item.cgoTypCd = '05'
                  item.cntrRfStsCd = ''
                  item.rfTmprUnitCd = ''
               }
              // v-if="vo.cgoTypCd === '02' || (vo.cgoTypCd === '03' && (vo.cntrTypCd !== 'HC' && vo.cntrTypCd !== '45'))"

              if (item.cntrTypCd === 'RF' || item.cntrTypCd === 'RH') {
                if (this.$ekmtcCommon.isEmpty(item.apvNo)) {
                  item.rfDgMsgYn = 'Y'
                } else {
                  item.reqConts = item.apvNo + '\n' + item.reqConts
                  console.log('item.cntrTypCd item.reqConts else >>>>> ', item.reqConts)
                }
              }
            })

            if (!isOkTmpr) {
              return
            }

            console.log('@@@@ before post o: ', o)

            if (this.saveFlag === 'ADD') {
              fnBkg = bookings.postBooking(o)
            } else {
              fnBkg = bookings.putBooking(o)
            }

            let bkgNo = ''
            let bkgMdlTargetYn = ''
            let bkgStsCd = ''
            let returnCode = ''
            fnBkg.then((res) => {
              const result = res.data
              if (result !== undefined) {
                if (result.rtnCode !== 'C0000') {
                  successFlag = false
                } else {
                  bkgNo = TH.saveFlag === 'ADD' ? result.bkgNo : TH.regParams.bkgNo
                  bkgMdlTargetYn = result.bkgMdlTargetYn
                  bkgStsCd = result.bkgStsCd
                }
                returnCode = result.result
              } else {
                successFlag = false
              }
            }).catch(() => {
              successFlag = false
            }).finally(async () => {
              if (!successFlag) {
                this.$ekmtcCommon.screenToPdfUpload(this.regParams, this.saveFlag === 'ADD' ? 'postBooking1' : 'putBooking1')
                this.$ekmtcCommon.alertDefault(this.$t('js.common.004'))
              } else {
                if (this.vanningSurvYn === 'Y' && vaniAertYn === 'Y') {
                  await this.$ekmtcCommon.asyncAlertMessage({ message: "Please upload 'Vanning Certificate' and type-in 'Vanning Date' before submitting S/R" })
                }

                if (this.$ekmtcCommon.isNotEmpty(bkgNo)) {
                  this.fnVocPop(bkgNo)
                }

                let isCntrGTI = false
                /*
                let cntrPickInfo = await bookings.getContainerLog({ bkgNo: bkgNo, cntrStsCd: 'GTI' })
                if (cntrPickInfo !== undefined && cntrPickInfo.data !== undefined && cntrPickInfo.data.length > 0) {
                  isCntrGTI = true
                }
                */

                if (this.saveFlag === 'ADD') {
                  this.regParams.bkgNo = bkgNo
                }

                let returnSts = await this.callBkgSaveCp('B101')
                if (this.$ekmtcCommon.isNotEmpty(returnSts)) {
                  bkgStsCd = returnSts
                }

                if (this.shuttlePopYn === 'Y' && isCntrGTI) {
                  if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.CSBK100.379'), useConfirmBtn: true })) {
                    TH.shuttleReqPop()
                  } else {
                    TH.initEdit(bkgNo)
                  }
                } else if (rfDgMsgYn === 'Y') {
                  if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.CSBK100.216'), useConfirmBtn: true })) {
                    // 선택하신 화물은 ‘RF 위험물 사전승인번호’가 없습니다. 지금 신청하겠습니까?
                    TH.$router.push({ path: '/dg', name: 'specialRfDg', params: { bkgNo: bkgNo } }).catch(() => {})
                  } else {
                    TH.$router.push({ name: 'booking-edit', params: { bkgNo: bkgNo } }).catch(() => {})
                  }
                } else {
                  /*
                  if (this.$ekmtcCommon.isEmpty(regParams.schFrtAppNo)) {
                    if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.CSBK100.217'), useConfirmBtn: true })) { // 등록된 운임이 없습니다. '운임문의를 하시겠습니까?'
                      TH.$router.push({ path: '/shipping-cost', name: 'inquiry' }).catch(() => {})
                    } else {
                      TH.$router.push({ name: 'booking-edit', params: { bkgNo: bkgNo } }).catch(() => {})
                    }
                  } else {
                  }
                  */

                  // as-is 로직 메시지
                  let callbackMsg = ''
                  if (returnCode === 'SUCC') {
                    if (bkgStsCd === '02') {
                      if (this.lang === 'KOR' && rfCnt > 0) {
                        callbackMsg = this.$t('msg.CSBK100.239')
                      } else {
                        if (this.lang === 'KOR' && bkgMdlTargetYn === 'Y') {
                          callbackMsg = this.$t('msg.CSBK100.240')
                        } else {
                          callbackMsg = this.$t('art.CMATK399')
                        }
                      }
                    } else {
                      if (this.lang === 'KOR' && bkgStsCd === '05' && rfCnt > 0) {
                        callbackMsg = this.$t('msg.CSBK100.241')
                      } else if (this.lang === 'KOR' && bkgStsCd === '05') {
                        callbackMsg = this.$t('msg.CSBK100.242')
                      } else {
                        callbackMsg = this.$t('tem.CMAT013')
                      }
                    }

                    // JamSin DSM 부킹완료 후 URL로 진입한 케이스에 acsid, bkgNo 를 로그테이블에 저장한다.
                    if (this.dsmYn) {
                      if (this.urlOrNot && this.$ekmtcCommon.isNotEmpty(this.urlParam)) {
                        // JamSin DSM acsid가 있을때만 저장한다.
                        if (this.$ekmtcCommon.isNotEmpty(this.urlParam.acsid)) {
                          // JamSin DSM 대안항로는 bkgNo가 있지만 선호항로, 선복보장은 없기 때문에 저장된 bkgNo 를 param에 추가
                          this.urlParam.bkgNo = bkgNo
                          bookings.setMCBookingLog(this.urlParam)
                        }
                      }
                    }
                  } else if (returnCode === 'SHA') {
                    callbackMsg = this.$t('art.CMATK398')
                  } else if (returnCode === 'FAIL') {
                    callbackMsg = this.$t('art.CMATK186')
                    this.$ekmtcCommon.screenToPdfUpload(this.regParams, this.saveFlag === 'ADD' ? 'postBooking2' : 'putBooking2')
                  }
                  /*
                  if (bkgStsCd === '02' && bkgMdlTargetYn === 'Y') {
                    let gbn = returnCode === 'SUCC' ? this.saveFlag.toLowerCase() : (returnCode === 'FAIL' ? 'fail' : 'SHA')
                    const ediParams = {
                      bkgNo: bkgNo,
                      gbn: gbn
                    }
                    trans.fnCheckBkgModelSendEdi(ediParams)
                  }
                  */
                  this.$ekmtcCommon.alertCallback(callbackMsg, () => {
                    if (this.saveFlag === 'ADD') {
                      TH.$router.push({ name: 'booking-edit', params: { bkgNo: bkgNo } }).catch(() => {})
                    } else {
                      TH.regParams.bkgNo = bkgNo
                      console.log('####################################################################refresh')
                      TH.refresh()
                    }
                  })
                }
              }
            })
          }

          if (this.urlOrNot) { // JamSin DSM
            $('#e-alert-set2-btn1').removeClass('conversion')
          }
        }
      } else {
        console.log('@@@@ here else')
        this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.238'))
        if (this.isPickupError === false) {
          document.querySelector('html').scrollTop = 0
        } else {
          this.isPickupError = false
        }
      }
    },
    async checkBooking () {
      let isOk = true

      let o = {
        ...this.regParams
      }
      o.containerList = [...this._containerList]
      const regParams = this.regParams

      let preSetLOIRequired = false
      let existLOI
      o.containerList.forEach((item, index) => {
        if (item.cntrTypCd === 'RF' || item.cntrTypCd === 'RH') {
          if (item.rfPreSetCd === '01') {
            preSetLOIRequired = true
          }
        }
      })

      if (preSetLOIRequired) {
        await this.getCertiFileListForBooking().then(() => {
          existLOI = this.uploadFileList?.find((item) => item.category === '08')
        })

        if (['SG'].includes(this.memberDetail.userCtrCd) && !existLOI) {
          this.$ekmtcCommon.alertDefault(this.$t('msg.ONEX070G090.050'))
          return
        }
      }

      await bookings.validationBooking(o).then(async (res) => {
        const result = res.data

        if (result !== undefined) {
          const apcl = result.apcl
          const remark = result.remark
          const restList = result.restList

          if (apcl === 'Y' && this.$ekmtcCommon.isEmpty(remark)) {
            this.$ekmtcCommon.alertDefault(
              this.$t('art.CMATK186') + this.$t('art.CMATK185')
            )
            isOk = false
          } else if (apcl === 'Y' && this.$ekmtcCommon.isNotEmpty(remark)) {
            this.$ekmtcCommon.alertDefault(remark)
            isOk = false
          } else if (apcl === 'A' && this.$ekmtcCommon.isEmpty(remark)) {
            this.$ekmtcCommon.alertDefault(
              this.$t('art.CMATK186') + this.$t('art.CMATK185')
            )
            isOk = false
          } else if (apcl === 'A' && this.$ekmtcCommon.isNotEmpty(remark)) {
            let forOk = true

            // 부킹 제약 메시지 중복 추가
            for (var item of restList) {
              if (
                !(await this.$ekmtcCommon.asyncAlertMessage({
                  message: item.rmk + '\n' + this.$t('msg.CSBK100.114'),
                  useConfirmBtn: true,
                  onCloseSelf: (resolve, $Simplert) => {
                    resolve(false)
                    $Simplert.close()

                    forOk = false
                  }
                }))
              ) {
                isOk = false
              }

              if (!forOk) {
                return
              }
            }
          }
        } else {
          this.$ekmtcCommon.alertDefault(
            this.$t('art.CMATK186') + this.$t('art.CMATK185')
          )
          isOk = false
        }
      })

      return isOk
    },
    async checkBookingDG () {
      const TH = this
      let isOk = false

      let o = {
        ...this.regParams
      }
      o.containerList = [...this._containerList]
      console.log('@@@@ o: ', o)

      await bookings.validationBookingDG(o).then((res2) => {
        const result = res2.data

        if (result !== undefined) {
          if (
            result.rtnCode === 'Y' &&
            this.$ekmtcCommon.isNotEmpty(result.rtnMessage)
          ) {
            this.$ekmtcCommon.alertDefault(result.rtnMessage)
            isOk = false
          } else {
            const containerInfo = result.containerInfo

            containerInfo.forEach((c) => {
              TH.regParams.containerList.forEach((o) => {
                if (c.cntrSeq === o.cntrSeq) {
                  o.scgRno = c.scgRno
                }
              })
            })

            isOk = true
          }
        }
      })

      return isOk
    },
    async checkBookingOOG () {
      const TH = this
      let isOk = true

      let o = {
        ...this.regParams
      }
      o.containerList = [...this._containerList]

      await bookings.validationBookingOOG(o).then((res) => {
        const result = res.data

        if (result !== undefined) {
          if (result.rtnCode === 'Y') {
            this.$ekmtcCommon.alertDefault(result.rtnMessage)
            isOk = false
          }
        }
      })

      return isOk
    },
    async checkBookingRF (__containerList = null) {
      const TH = this
      let isOk = true
      let messageShow = ''
      let _containerList =
        __containerList !== null && Array.isArray(__containerList)
          ? __containerList
          : [...this.$store.state.schedule.containerList]

      _containerList.forEach((c) => {
        console.log('@@@@ c.cntrTypCd: ', c.cntrTypCd)
        if (
          (c.cntrTypCd === 'RF' || c.cntrTypCd === 'RH') &&
          c.cgoTypCd === '01'
        ) {
          messageShow = 'Y'
        }
      })
      console.log('@@@@ messageShow:', messageShow)

      this.$store.commit('schedule/defaultAssign', {
        key: 'containerList',
        value: [..._containerList]
      })

      await bookings.validationBookingRF(TH.regParams.hidCS008I).then((res) => {
        const result = res.data

        if (result.rtnCode === 'Y' && messageShow === 'Y') {
          this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.131'))
          isOk = false
        }
      })

      return isOk
    },
    async fnBkgCstMaxControl (flag) {
      const regParams = this.regParams
      // const cntrList = regParams.containerList
      const cntrList = [...this._containerList]
      let sumQty20 = 0
      let sumQty40 = 0
      let sumTeu = 0
      let cgoYn = 'N'
      let isOk = true
      cntrList.forEach((c) => {
        if (flag === 'ADD') {
          if (c.cntrSzCd === '20') {
            sumQty20 += Number(c.cntrQty)
            sumTeu += Number(c.cntrQty)
          } else if (c.cntrSzCd === '40') {
            sumQty40 += Number(c.cntrQty)
            sumTeu += Number(c.cntrQty) * 2
          }

          if (
            c.cntrSzCd === '45' ||
            (c.cntrTypCd !== 'GP' && c.cntrTypCd !== 'HC')
          ) {
            cgoYn = 'Y'
          }
        } else if (flag === 'EDIT') {
          if (
            c.cntrSzCd !== '45' &&
            (c.cntrTypCd === 'GP' || c.cntrTypCd === 'HC')
          ) {
            if (c.cntrSzCd === '20') {
              sumQty20 += Number(c.cntrQty)
              sumTeu += Number(c.cntrQty)
            } else if (c.cntrSzCd === '40') {
              sumQty40 += Number(c.cntrQty)
              sumTeu += Number(c.cntrQty) * 2
            }
          }
        }
      })

      if (cgoYn === 'Y') {
        return isOk
      }

      const params = {
        vslCd: regParams.vslCd,
        voyNo: regParams.voyNo,
        porCtrCd: regParams.polCtrCd,
        dlyCtrCd: regParams.podCtrCd,
        porPlcCd: regParams.polPortCd,
        dlyPlcCd: regParams.podPortCd,
        hidCS008I: regParams.hidCS008I,
        scheduleEditYn: regParams.scheduleEditYn,
        bkgNo: regParams.bkgNo,
        uiFlag: flag,
        sumQty20: sumQty20,
        sumQty40: sumQty40,
        sumTeu: sumTeu,
        fnlActShprCstCd: regParams.fnlActShprCstCd,
        mcOpenYn: regParams.mcOpenYn
      }

      await bookings.bkgCstMaxControlChk(params).then((res) => {
        const result = res.data

        if (result !== undefined && result.rtnCode === 'Y') {
          this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.194')) // 담당영업사원에게 확인바랍니다.
          isOk = false
        }
      })

      return isOk
    },
    oogConfirmInfo (cvo) {
      let breakBulkYn = 'N'
      let limitYn = 'N'

      const cntrSzCd = cvo.cntrSzCd
      const cntrTypCd = cvo.cntrTypCd

      if (cntrTypCd === 'FR') {
        if (cntrSzCd === '20') {
          if (
            (Number(cvo.orgLength) <= 100 && Number(cvo.frgtWt) > 21000) ||
            (Number(cvo.orgLength) <= 150 && Number(cvo.frgtWt) > 21400) ||
            (Number(cvo.orgLength) <= 200 && Number(cvo.frgtWt) > 21900) ||
            (Number(cvo.orgLength) <= 250 && Number(cvo.frgtWt) > 22500) ||
            (Number(cvo.orgLength) <= 300 && Number(cvo.frgtWt) > 23300) ||
            (Number(cvo.orgLength) <= 350 && Number(cvo.frgtWt) > 24200) ||
            (Number(cvo.orgLength) <= 400 && Number(cvo.frgtWt) > 24500) ||
            (Number(cvo.orgLength) <= 450 && Number(cvo.frgtWt) > 26800) ||
            (Number(cvo.orgLength) <= 500 && Number(cvo.frgtWt) > 28500) ||
            (Number(cvo.orgLength) > 500 && Number(cvo.frgtWt) > 31100)
          ) {
            breakBulkYn = 'Y'
          }
        } else if (cntrSzCd === '40') {
          if (
            (Number(cvo.orgLength) <= 100 && Number(cvo.frgtWt) > 25400) ||
            (Number(cvo.orgLength) <= 200 && Number(cvo.frgtWt) > 25700) ||
            (Number(cvo.orgLength) <= 300 && Number(cvo.frgtWt) > 26100) ||
            (Number(cvo.orgLength) <= 400 && Number(cvo.frgtWt) > 26700) ||
            (Number(cvo.orgLength) <= 500 && Number(cvo.frgtWt) > 27400) ||
            (Number(cvo.orgLength) <= 600 && Number(cvo.frgtWt) > 28400) ||
            (Number(cvo.orgLength) <= 700 && Number(cvo.frgtWt) > 29500) ||
            (Number(cvo.orgLength) <= 800 && Number(cvo.frgtWt) > 30900) ||
            (Number(cvo.orgLength) <= 900 && Number(cvo.frgtWt) > 32600) ||
            (Number(cvo.orgLength) <= 1000 && Number(cvo.frgtWt) > 34800) ||
            (Number(cvo.orgLength) <= 1100 && Number(cvo.frgtWt) > 37000) ||
            (Number(cvo.orgLength) > 1100 && Number(cvo.frgtWt) > 39900)
          ) {
            breakBulkYn = 'Y'
          }
        }
      }

      if (
        Math.round(
          (cvo.frgtWt / 1000 / ((cvo.orgLength / 100) * (cvo.orgWidth / 100))) *
            100
        ) /
          100 >
        3.0
      ) {
        limitYn = 'Y'
      }

      return {
        breakBulkYn: breakBulkYn,
        limitYn: limitYn
      }
    },
    openPopup (compNm) {
      this.customComponent = compNm

      this.$ekmtcCommon.layerOpen('.booking_new_area > .popup_dim')
    },
    closePopup () {
      this.customComponent = null
      this.selectFunc = null
      this.$ekmtcCommon.layerClose('.booking_new_area > .popup_dim')
    },
    openNotice (title, msg) {
      let obj = {
        alertType: 'normal',
        showXclose: true,
        customCloseBtnClass: 'button blue lg',
        customCloseBtnText: this.$t('msg.CSBK100.137'),
        title: title,
        message: msg,
        type: 'success'
      }
      this.$ekmtcCommon.alertDefault(obj)
    },
    checkIranValidationAll () {
      let isOk = true
      const prefix = [
        'actShprCstNm',
        'cneCstNm',
        // 'endUsrNm',
        'cmdtNm',
        'hsCd',
        'useDestCont',
        'actShprAddr',
        'cneCstAddr',
        'ntifCstNm',
        'ntifCstAddr'
      ]

      prefix.forEach((el) => {
        if (!this.checkIranValidation(el)) {
          isOk = false
        }
      })

      this.endUserList.forEach((el, idx) => {
        if (!this.checkIranValidation('endUsrNm', idx)) {
          isOk = false
        }

        if (!this.checkValidationEmail(`ir_endUsrEmlAddr_${idx}`)) {
          isOk = false
        }
      })

      return isOk
    },
    checkIranValidation (flag, idx) {
      let isOk = true
      const frm = document.querySelector('#frm')
      const elemActShprCstNm = frm.querySelector('#ir_actShprCstNm')
      const elemCneCstNm = frm.querySelector('#ir_cneCstNm')
      const elemEndUsrNm = frm.querySelector('#ir_endUsrNm_' + idx)
      const elemEndUsrAddr = frm.querySelector('#ir_endUsrAddr_' + idx)
      const elemHsCd = frm.querySelector('#ir_hsCd')
      const elemCmdtNm = frm.querySelector('#ir_cmdtNm')
      const elemNtifCstNm = frm.querySelector('#ir_ntifCstNm')
      let selector = frm.querySelector('#ir_' + flag)

      if (flag === 'endUsrNm' || flag === 'endUsrAddr') {
        selector = frm.querySelector('#ir_' + flag + '_' + idx)
      }

      this.$ekmtcCommon.hideErrorTooltip(selector)

     const selectorInfo = {
        actShprCstNm: { message: 'B/L SHIPPER.', upperCase: 'Y' },
        actShprAddr: { message: 'B/L SHIPPER ADDRESS.', upperCase: 'Y' },
        cneCstNm: { message: 'B/L CONSIGNEE.', upperCase: 'Y' },
        cneCstAddr: { message: 'B/L CONSIGNEE ADDRESS.', upperCase: 'Y' },
        ntifCstNm: { message: 'B/L NOTIFY.', upperCase: 'Y' },
        ntifCstAddr: { message: 'B/L NOTIFY ADDRESS.', upperCase: 'Y' },
        endUsrNm: { message: 'END USER.', upperCase: 'Y' },
        //endUsrAddr: { message: 'END USER ADDRESS.' },
        cmdtNm: { message: 'NAME OF COMMODITY.', upperCase: 'Y' },
        hsCd: { message: 'HS CODE.' },
        useDestCont: { message: 'PURPOSE OF USE.' }
      }

      if (this.$ekmtcCommon.isEmpty(selector.value.trim())) {
        this.$ekmtcCommon.showErrorTooltip(
          selector,
          'Please input the ' + selectorInfo[flag].message
        )
        isOk = false
      }

      const elemActShprCstNmC = document.querySelector('#ir_actShprCstNmC')
      const elemCneCstNmC = document.querySelector('#ir_cneCstNmC')

      let elemEndUsrNmC = document.querySelector('#ir_actShprCstNmC')

      if (flag === 'endUsrNm') {
        elemEndUsrNmC = document.querySelector('#ir_endUsrNmC_' + idx)
      }

      const elemHsCdC = document.querySelector('#ir_hsCdC')

      const elemCmdtNmC = document.querySelector('#ir_cmdtNmC')

      const elemNtifCstNmC = document.querySelector('#ir_ntifCstNmC')

      const iranInfo = this.regParams.iranBookingInfo
      if (
        elemActShprCstNmC.style.display !== 'none' &&
        (iranInfo.actShprCstNmC === 'Y' || iranInfo.actShprCstNmC === 'A')
      ) {
        this.$ekmtcCommon.showErrorTooltip(
          elemActShprCstNm,
          'Please Check the B/L SHIPPER.'
        )
        isOk = false
      }

      if (
        elemCneCstNmC.style.display !== 'none' &&
        (iranInfo.cneCstNmC === 'Y' || iranInfo.cneCstNmC === 'A')
      ) {
        this.$ekmtcCommon.showErrorTooltip(
          elemCneCstNm,
          'Please Check the NAME OF CONSIGNEE.'
        )
        isOk = false
      }

      if (
        elemNtifCstNmC.style.display !== 'none' &&
        (iranInfo.ntifCstNmC === 'Y' || iranInfo.ntifCstNmC === 'A')
      ) {
        this.$ekmtcCommon.showErrorTooltip(
          elemNtifCstNm,
          'Please Check the B/L NOTIFY.'
        )
        isOk = false
      }

      if (
        elemEndUsrNmC.style.display !== 'none' && flag === 'endUsrNm' &&
        iranInfo.endUsrNmCList && (iranInfo.endUsrNmCList[idx] === 'Y' || iranInfo.endUsrNmCList[idx] === 'A')
      ) {
        this.$ekmtcCommon.showErrorTooltip(
          elemEndUsrNm,
          'Please Check the END USER.'
        )
        isOk = false
      }

      if (elemHsCdC.style.display !== 'none' && this.hsCdC === 'Y') {
        this.$ekmtcCommon.showErrorTooltip(elemHsCd, 'Please check HS Code.')
        isOk = false
      }

      if (elemCmdtNmC.style.display !== 'none' && this.cmdtNmC === 'Y') {
        this.$ekmtcCommon.showErrorTooltip(elemCmdtNm, 'Please check NAME OF COMMODITY.')
        isOk = false
      }
      if (isOk) {
        if (
          (elemActShprCstNmC.style.display !== 'none' &&
            iranInfo.actShprCstNmC === 'A') ||
          (elemCneCstNmC.style.display !== 'none' &&
            iranInfo.cneCstNmC === 'A') ||
          (elemEndUsrNmC.style.display !== 'none' && iranInfo.endUsrNmC === 'A' && flag === 'endUsrNm')
        ) {
          const onConfirm = () => {
            isOk = false
          }

          // Saction List에 포함된 업체가 존재합니다.\n계속 진행하시겠습니까?
          // this.$ekmtcCommon.confirmCallBack(
          //   this.$t('msg.CSBK100.195'),
          //   onConfirm,
          //   undefined
          // )
        }
      }

      return isOk
    },
    checkMyValidation () {
      let isOk = true
      const frm = document.querySelector('#frm')
      let selector = frm.querySelector('#my_hsCd')

      this.$ekmtcCommon.hideErrorTooltip(selector)

      if (this.$ekmtcCommon.isEmpty(selector.value.trim())) {
        this.$ekmtcCommon.showErrorTooltip(
          selector,
          'Please input the HS CODE.'
        )
        isOk = false
      }

      return isOk
    },
    sameConsigneeName (e) {
      const iranInfo = this.regParams.iranBookingInfo
      if (e.target.checked) {
        iranInfo.endUsrNm = iranInfo.cneCstNm
      } else {
        iranInfo.endUsrNm = ''
      }
    },
    changeActShprCstNm (e) {
      this.$ekmtcCommon.onlyEngValue(e)
      this.regParams.iranBookingInfo.actShprCstNm =
        this.regParams.iranBookingInfo.actShprCstNm.toUpperCase()
    },
    fnVslStsCdCheck () {
      const TH = this
      let sizeTypeQty = 'N'
      const regParams = TH.regParams

      let oldSize = 0
      let oldType = 0
      let oldQty = 0
      regParams.containerList.forEach((c, idx) => {
        const oldCntr = TH.oldContainerList[idx]

        if (oldCntr !== undefined) {
          oldSize = oldCntr.cntrSzCd
          oldType = oldCntr.cntrTypCd
          oldQty = oldCntr.cntrQty

          if (Number(c.cntrSzCd) > Number(oldSize)) {
            sizeTypeQty = 'Y'
          } else if (
            c.cntrTypCd !== oldType &&
            (c.cntrTypCd !== 'GP' || c.cntrTypCd !== 'HC')
          ) {
            sizeTypeQty = 'Y'
          } else if (Number(c.cntrQty) > Number(oldQty)) {
            sizeTypeQty = 'Y'
          }
        }
      })

      return sizeTypeQty
    },
    changeFwdrCstNo (e) {
      const fwdrList = this.jpFwdrList
      const cstNo = e.target.value
      const regParams = this.regParams

      let fwdrCstEnm = ''
      if (cstNo !== '') {
        if (fwdrList !== undefined && fwdrList.length > 0) {
          const fwdrInfo = fwdrList.filter((vo) => vo.fwdrCstNo === cstNo)
          if (fwdrInfo !== undefined && fwdrInfo !== null) {
            fwdrCstEnm = fwdrInfo[0].fwdrCstEnm
          }
        }
      }

      regParams.fwdrCstEnm = fwdrCstEnm
    },
    changeBkgShprCstNo (e) {
      const bkgShprList = this.jpBkgShprList
      const cstNo = e.target.value
      const regParams = this.regParams

      let bkgShprCstEnm = ''
      if (cstNo !== '') {
        if (bkgShprList !== undefined && bkgShprList.length > 0) {
          const bkgShprInfo = bkgShprList.filter(
            (vo) => vo.bkgShprCstNo === cstNo
          )
          if (bkgShprInfo !== undefined && bkgShprInfo !== null) {
            bkgShprCstEnm = bkgShprInfo[0].bkgShprCstEnm
          }
        }
      }

      regParams.bkgShprCstEnm = bkgShprCstEnm
    },
    showCancelBtn () {
      // 선적 취소 요청서 여부와 인보이스 생성 여부가 조회되면 Y 또는 N 으로 두 값이 셋팅됨.
      // 버튼이 먼저 노출되어 사용자가 클릭하는 것을 방지하기 위하여 조건으로 제어함.
      if (
        this.$ekmtcCommon.isNotEmpty(this.cancelReqYn) &&
        this.$ekmtcCommon.isNotEmpty(this.invoiceReqYn)
      ) {
        if (this.cancelReqYn === 'Y' || this.invoiceReqYn === 'Y') {
          return false
        } else {
          return true
        }
      } else {
        return false
      }
    },
    async cancelBooking () {
      // 출항일 기준 날짜 체크
      let cntrMinusChk = this.chkKmtcOnCntrInfoMinus() // false : 출항일 7일 이내, true : 출항일 7일 이전

      if (this.regParams.kmtcSpotYn === 'Y' && this.saveFlag === 'EDIT' && !cntrMinusChk) {
        this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.514'))
        return
      }

      const TH = this
      const regParams = TH.regParams
      const cgoRest = this.cgoRest
      let msg
      let isAlert = false
      let serverDt =
        this.serverYear +
        this.serverMonth +
        this.serverDay +
        this.serverHour +
        this.serverMin

      // 싱가폴 DG 부킹이면 부킹캔슬 조건 체크
      const sgDgParam = {
        bkgNo: regParams.bkgNo
      }

      let sgBookingDg = 'N'

      if (this.regParams.polCtrCd === 'SG') {
        await bookings.getSgBookingDgCheck(sgDgParam).then((res) => {
          if (res.data !== undefined) {
            console.log('getSgBookingDgCheck res @@@@ ', res)

            sgBookingDg = res.data
          }
        })
      }

      if (sgBookingDg === 'Y') {
        this.$ekmtcCommon.asyncAlertMessage({
            message: TH.$t('js.booking.040'), // 부킹 상태가 '미확인' 또는 '확인 중' 상태이므로 취소가 불가능합니다.
            useConfirmBtn: false
        })

        return
      }

      await commons.getServerTime().then((res) => {
        if (res.data !== undefined) {
          serverDt = res.data.length > 12 ? res.data.substring(0, 12) : res.data
        }
      })

      if (TH.lang === 'KOR') {
        const localDtm = TH.localDtm
        const localDate = TH.$ekmtcCommon.getStrToDate(localDtm)
        regParams.containerList.forEach((c) => {
          if (
            (c.cntrTypCd === 'RF' || c.cntrTypCd === 'RH') &&
            TH.$ekmtcCommon.isNotEmpty(c.pcupReqDt) &&
            c.pcupReqDt.length === 8
          ) {
            const getDate = TH.$ekmtcCommon.getStrToDate(c.pcupReqDt)

            if (getDate <= localDate) {
              msg =
                '부킹 취소는 부킹사무소별 담당자와 확인 후 처리 부탁드립니다.'
              if (TH.bkgPlcCd === 'SEL') {
                msg += '(서울: 02-311-6114 내선 1번)'
              } else if (TH.bkgPlcCd === 'PUS') {
                msg += '(부산: 051-461-2800 내선 1번)'
              }
              isAlert = true
              // TH.$ekmtcCommon.alertDefault(msg)
              // return true
            }
          }
        })
      }

      if (isAlert) {
        await this.$ekmtcCommon.asyncAlertMessage({
          message: msg,
          useConfirmBtn: false
        })
        return false
      }

      let delegate = true
      // 서류마감 여부 서류마감일이 오늘날짜보다 작을때로 변경해야함
      if (
        this.$ekmtcCommon.isNotEmpty(cgoRest) &&
        Number(cgoRest) < Number(serverDt)
      ) {
        const reqParam = {
          bkgNo: this.regParams.bkgNo,
          reqCatCd: '01'
        }

        let succFlag = true
        await docs
          .findReqChk(reqParam)
          .then((res) => {
            const result = res.data
            console.log('findReqChk result @@@@@@ ', result)

            if (this.$ekmtcCommon.isNotEmpty(result)) {
              const arrResult = result.split('@@')
              const flag = arrResult[0]

              if (flag === 'Y') {
                this.fnCancelRequestPop(delegate)
              } else {
                this.$ekmtcCommon.alertDefault(arrResult[1])
              }
            } else {
              succFlag = false
            }
          })
          .catch(() => {
            succFlag = false
          })
          .finally(() => {
            if (!succFlag) {
              this.$ekmtcCommon.alertDefault(this.$t('js.common.005'))
            }
          })
      } else {
        let emptyShip = false

        await bookings
          .getContainerLog({ bkgNo: regParams.bkgNo, cntrStsCd: 'GTO' })
          .then((res) => {
            if (
              res.headers.respcode === 'C0000' &&
              res.data !== undefined &&
              res.data.length > 0
            ) {
              emptyShip = true
            }
          })
          .catch(() => { })
          .finally(async () => {
            if (emptyShip) {
              if (this.lang !== 'JPN') {
                this.cancelFeePop()
              } else {
                this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.405'))
              }
            } else {
              await this.fnCancel()
            }
          })
        }
    },
    async fnCancel () {
      const TH = this
      const regParams = TH.regParams

      if (this.lang === 'KOR') {
        await trans.findCnclClosePopYn(regParams.bkgNo).then(async (res) => {
          const result = res.data

          if (result === 'Y') {
            TH.popParams = {
              bkgNo: regParams.bkgNo,
              cboTerm: regParams.polShtmCd
            }

            TH.selectFunc = TH.refresh
            TH.openPopup('booking-cancel-pop')
          } else {
            await this.saveCancel()
          }
        })
      } else {
        let isCheck = false
        if (TH.usrCtrCd === 'TH') {
          await trans.findCnclChargeYn(regParams.bkgNo).then(async (res) => {
            const result = res.data

            if (result === 'Y') {
              if (
                await TH.$ekmtcCommon.asyncAlertMessage({
                  message: TH.$t('msg.CSBK100.246'),
                  useConfirmBtn: true
                })
              ) {
                isCheck = true
              }
            } else {
              isCheck = true
            }
          })
        } else {
          isCheck = true
        }

        if (isCheck) {
            //await TH.saveCancel()
            TH.popParams = {
                bkgNo: regParams.bkgNo,
                cboTerm: regParams.polShtmCd
              }

            TH.selectFunc = TH.refresh
            TH.openPopup('booking-cancel-pop')
        }
      }
    },
    async saveCancel () {
      let successFlag = true
      const regParams = this.regParams
      if (
        await this.$ekmtcCommon.asyncAlertMessage({
          message: 'Booking ' + this.$t('tem.CMAT030'),
          useConfirmBtn: true
        })
      ) {
        await bookings
          .bookingCancel({
            bkgNo: regParams.bkgNo,
            cboTerm: regParams.polShtmCd
          })
          .then((res) => {
            const result = res.data
            if (result === undefined || Number(result.result) !== 1) {
              successFlag = false
            }
          })
          .catch(() => {
            successFlag = false
          })
          .finally(() => {
            let returnMsg = ''
            const TH = this
            returnMsg = successFlag
              ? this.$t('msg.CSBK100.384')
              : this.$t('msg.CSBK100.385')
            this.$ekmtcCommon.alertCallback(returnMsg, function () {
              TH.refresh()
            })
          })
      }
    },
    async cancelReqDocCancel () {
      const TH = this
      const bkgNo = this.regParams.bkgNo
      if (TH.cancelReqYn === 'Y') {
        if (
          await this.$ekmtcCommon.asyncAlertMessage({
            message: this.$t('msg.CSBK100.254'),
            useConfirmBtn: true
          })
        ) {
          let successFlag = true
          trans
            .updateCancelReqDocStatus(bkgNo)
            .then((res) => {
              if (res.headers.respcode !== 'C0000') {
                successFlag = false
              }

              console.log('updateCancelReqDocStatus @@@@@ ', res)
            })
            .catch(() => {
              successFlag = false
            })
            .finally(() => {
              let message = successFlag
                ? this.$t('msg.CSBK100.255')
                : this.$t('msg.NEWB020P030.010')
              this.$ekmtcCommon.alertCallback(message, () => {
                TH.initEdit(bkgNo)
              })
            })
        }
      } else if (this.invoiceReqYn === 'Y') {
        console.log('인보이스 생성 취소')
      }
    },
    refresh () {
      this.initEdit()
      this.closePopup()
      this.closeUploadPopup()
      document.querySelector('html').scrollTop = 0
    },
    fnVocPop (bkgNo) {
      if (this.vocYn === 'N') {
        const url = '#/open-popup/voc-pop?docNo=' + bkgNo
        const method = 'GET'

        let form = $('<form>')
          .attr({ target: 'vocPop', method: method, action: url })
          .appendTo(document.body)

        let p = window.open(
          '',
          'vocPop',
          'width=480,height=343,menubar=no,status=status,scrollbars=yes resizable=yes'
        )

        form.submit()
      }
    },
    fnCancelRequestPop (delegate) {
      const regParams = this.regParams

      this.popParams = {
        bkgNo: regParams.bkgNo,
        blNo: regParams.blNo
      }
      this.selectFunc = this.refresh
      this.openPopup('booking-cancel-request-pop')
    },
    inputConsignee (key, flag) {
      const regParams = this.regParams
      const value = regParams[key]
      console.log('@@@@ value: ', value)

      if (key === 'cnePicTelNo') {
        regParams[key] = value.replace(/[^a-z|A-Z|0-9|*]/gi, '')
      } else {
        regParams[key] = value.replace(/[^a-z|A-Z|0-9]/gi, '')
      }

      if (flag === 'ESSENTIAL') {
        this.checkValidationConsignee(key)
      }
    },
    demDetInfoPop () {
      if (this.isDetCombined) return false
      let isOk = true
      const prefix = ['cntrTypCd', 'cntrQty', 'pcupReqDt']
      const regParams = this.regParams
      // const containerList = regParams.containerList
      const containerList = this._containerList

      containerList.forEach((c, idx) => {
        prefix.forEach((el) => {
          const result = this.checkValidationCntr(el, idx, 'KEYUP')
          if (!result) {
            isOk = false
          }
        })
      })

      if (isOk) {
        const arrParamCntr = []
        containerList.forEach((c, idx) => {
          const obj = {
            cgoTypCd: c.cgoTypCd,
            cntrQty: c.cntrQty,
            cntrTypCd: c.cntrTypCd,
            cntrSzCd: c.cntrSzCd,
            pcupReqDt: this.$ekmtcCommon.changeDatePattern(c.pcupReqDt, '-')
          }
          arrParamCntr.push(obj)
        })

        const etd = this.$ekmtcCommon.isNotEmpty(this.etd)
          ? this.etd.substring(0, 8)
          : ''

        this.popParams = {
          containerList: arrParamCntr,
          etd: this.$ekmtcCommon.changeDatePattern(etd, '-'),
          ctrCd: regParams.polCtrCd,
          portCd: regParams.polPortCd
        }

        this.selectFunc = ''
        this.openPopup('dem-det-calculator-pop')
      }
    },
    cancelFeePop () {
      const regParams = this.regParams
      this.popParams = {
        bkgNo: regParams.bkgNo
      }

      this.selectFunc = ''
      this.openPopup('booking-cancel-fee-pop')
    },
    shuttleReqPop () {
      const regParams = this.regParams

      this.popParams = {
        bkgNo: regParams.bkgNo
      }

      this.selectFunc = ''
      this.openPopup('container-shuttle-pop')
    },
    callFbCatInfo (returnVo) {
      this.fbCat01 = returnVo.fbCat01
      this.fbCat02 = returnVo.fbCat02
      this.fbCat03 = returnVo.fbCat03
      this.fbCat04 = returnVo.fbCat04
      this.fbCat05 = returnVo.fbCat05
    },
    async fbBkgSplitCombine (flag) {
      let successFlag = true
      let result = ''

      // KMTC ON 부킹인 경우 SPLIT은 출항일자 -7 이후인 경우만 동작함
      // this.chkKmtcOnCntrInfoMinus() - false : 출항일 7일 이전, true : 출항일 7일 이후
      if (this.regParams.kmtcSpotYn === 'Y' && flag === 'SPLIT' && !this.chkKmtcOnCntrInfoMinus()) {
        await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.CSBK100.514') })
        return
      }

      await trans.findBkgSplitStatus(this.regParams.bkgNo).then((res) => {
        if (res.headers.respcode !== 'C0000') {
          successFlag = false
        } else {
          result = res.data
        }
      }).catch(() => {
        successFlag = false
      }).finally(async () => {
        if (successFlag && this.$ekmtcCommon.isNotEmpty(result)) {
          if (flag === 'SPLIT') {
            await this.splitBooking(result)
          } else {
            await this.combineBooking(result)
          }
        } else {
          this.$ekmtcCommon.alertDefault(this.$t('js.common.005'))
        }
      })
    },
    async splitBooking (result) {
      result = String(result)
      if (result !== '99' && result !== '09') {
        let failMsg = this.regParams.bkgNo + this.$t('msg.CSBK100.341')
        failMsg += this.$t('msg.CSBK100.split_fail_' + result)
        this.$ekmtcCommon.alertDefault(failMsg)
      } else {
        if (
          await this.$ekmtcCommon.asyncAlertMessage({
            message: this.regParams.bkgNo + ' ' + this.$t('msg.CSBK100.342'),
            useConfirmBtn: true
          })
        ) {
          this.openSplitCombinePop('SPLIT')
        }
      }
    },
    async combineBooking (result) {
      result = String(result)
      const bkgStsCd = this.regParams.bkgStsCd

      let failMsg = this.regParams.bkgNo + this.$t('msg.CSBK100.343')
      let isFail = false

      if (bkgStsCd !== '01' && this.lang === 'KOR') {
        failMsg += this.$t('msg.CSBK100.combine_fail_00')
        isFail = true
      }

      if (
        result === '03' ||
        result === '05' ||
        result === '06' ||
        result === '01'
      ) {
        failMsg += this.$t('msg.CSBK100.combine_fail_' + result)
        isFail = true
      }

      if (isFail) {
        this.$ekmtcCommon.alertDefault(failMsg)
        return
      }

      if (
        await this.$ekmtcCommon.asyncAlertMessage({
          message: this.regParams.bkgNo + this.$t('msg.CSBK100.344'),
          useConfirmBtn: true
        })
      ) {
        this.openSplitCombinePop('COMBINE')
      }
    },
    openSplitCombinePop (flag) {
      const compNm =
        flag === 'SPLIT'
          ? 'booking-split-detail-pop'
          : 'booking-combine-detail-pop'
      this.popParams = {
        bkgNo: this.regParams.bkgNo,
        page: 'DETAIL'
      }

      this.selectFunc = this.setSplitCombineResult
      this.openPopup(compNm)
    },
    async setSplitCombineResult (bkgNo) {
      await this.initEdit(bkgNo)
      if (this.lang === 'JPN') {
        this.initFwdrInfo()
      }
    },
    async changeLegTrmlFunc (bfPodTrmlCd, afPodTrmlCd) {
      let isOk = true
      if (bfPodTrmlCd !== afPodTrmlCd) {
        const message =
          this.$t('msg.CSBK100.346') +
          '<br><br>' +
          this.$t('msg.CSBK100.347') +
          bfPodTrmlCd +
          ' → ' +
          this.$t('msg.CSBK100.348') +
          afPodTrmlCd
        if (
          !(await this.$ekmtcCommon.asyncAlertMessage({
            message: message,
            useConfirmBtn: true
          }))
        ) {
          isOk = false
        } else {
          this.regParams.legTrmlFixdYn = 'N'
        }
      }

      return isOk
    },
    fnIranHsCdMulti () {
      const bkgNo = this.regParams.bkgNo

      this.popParams = {
        bkgNo: bkgNo
      }

      this.selectFunc = ''
      this.openPopup('hs-code-ir-pop')
    },
    changeOnlyUpperEng (e, object, key) {
      e.target.value = e.target.value
        .replace(/[^a-zA-Z0-9^\s]/gi, '')
        .toUpperCase()
      let value = e.target.value
      this.$set(object, key, value)
    },
    async createSR () {
      const regParams = this.regParams
      const bkgNo = regParams.bkgNo

      let srOk = true
      let message = this.$t('js.common.005')
      await trans
        .findBkgBlInfo(bkgNo)
        .then((res) => {
          if (res.headers.respcode !== 'C0000') {
            srOk = false
          } else {
            const result = res.data
            if (result !== undefined) {
              if (
                this.$ekmtcCommon.isNotEmpty(result.blNo) &&
                this.$ekmtcCommon.isNotEmpty(result.blNo) &&
                regParams.polShtmCd === '02'
              ) {
                srOk = false
                message =
                  this.$t('msg.CSBL200.088') + this.$t('msg.CSBL200.089')
              }
            } else {
              srOk = false
            }
          }
        })
        .catch(() => {
          srOk = false
        })

      if (!srOk) {
        this.$ekmtcCommon.alertDefault(message)
        return
      }

      const bkgStsCd = regParams.bkgStsCd

      if (bkgStsCd !== '01' && bkgStsCd !== '04') {
        this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.085'))
        return
      }

      if (regParams.cllYn === 'Y') {
        this.$ekmtcCommon.alertDefault(this.$t('art.CSBK005'))
        return
      }

      if (Number(regParams.srExt) > 99) {
        this.$ekmtcCommon.alertDefault(this.$t('art.CSBK007'))
        return
      }

      if (Number(regParams.srExt) > 0) {
        this.$ekmtcCommon.alertDefault(this.$t('art.CSBK006'))
        return
      }

      const cntrDgList = regParams.containerList.filter(
        (vo) => vo.cgoTypCd === '01'
      )

      if (
        cntrDgList !== undefined &&
        cntrDgList.length > 0 &&
        regParams.polTrmlCd === 'CONV'
      ) {
        this.$ekmtcCommon.alertDefault(
          this.$t('msg.CSBK100.103') + '\n' + this.$t('msg.CSBK100.104')
        )
      }

      this.$router
        .push({ name: 'bl', params: { bkgNo: bkgNo, srCnclYn: this.srCnclYn } })
        .catch(() => {})
    },
    resetRfSetup (e, idx) {
      console.log('@@@@ enter resetRfSetup')
      console.log(e.target.value)
      // const cntrInfo = this.regParams.containerList[idx]
      const cntrInfo = { ...this._containerList[idx] }
      const frm = document.querySelector('#frm')
      if (e.target.checked) {
        cntrInfo.rfSetupTmpr = ''
        cntrInfo.cntrRfStsCd = '01'
        frm.querySelector('#rfSetupTmpr_' + idx).disabled = true
      } else {
        cntrInfo.rfSetupTmpr = ''
        cntrInfo.rfTmprUnitCd = 'C'
        frm.querySelector('#rfSetupTmpr_' + idx).disabled = false
      }

      let _containerList = [...this.$store.state.schedule.containerList]
      this.$store.commit('schedule/defaultAssign', {
        key: 'containerList',
        value: [
          ..._containerList.slice(0, idx),
          {
            ...cntrInfo
          },
          ..._containerList.slice(idx + 1)
        ],
        test: 'resetRfSetup'
      })
    },
    isDefaultCgoValue (vo) {
      let isVisible = true

      if (
        vo.cntrTypCd === 'OT' ||
        vo.cntrTypCd === 'FR' ||
        vo.cntrTypCd === 'SR' ||
        (vo.cntrSzCd === '45' && vo.cntrTypCd === 'HC')
      ) {
        isVisible = false
      }

      return isVisible
    },
    async resetBooking () {
      const TH = this
      if (!(await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.CSBK100.382'), useConfirmBtn: true }))) {
        return
      } else { // 확인을 누른 경우 프로모션 및 KMTC SPOT 유입인 경우 메인으로 이동함
        if (this.promotionChk === 'Y' || this.regParams.kmtcSpotYn === 'Y') {
          this.$router.push('/main')
          return
        }
      }
      this.$router.go() // 일반 유입시 확인 누르면 새로고침 됨
    },
    goList () {
      this.$router.push({ name: 'progress' }).catch(() => {})
    },
    setScheduleBtnClass () {
      return this.$ekmtcCommon.isEmpty(this.regParams.hidCS008I)
        ? 'button sm blue'
        : 'button sm gray'
    },
    dgDeclarationPop () {
      this.popParams = {
        items: [{ bkgNo: this.regParams.bkgNo }]
      }

      this.selectFunc = ''
      this.openPopup('dg-itext-pop')
    },
    shipmentFinishPop () {
      this.popParams = {
        items: [{ bkgNo: this.regParams.bkgNo }]
      }

      this.selectFunc = ''
      this.openPopup('shipment-finish-pop')
    },
    delayNoticePop () {
      this.popParams = {
        inOut: 'O',
        items: [
          {
            docNo: '01',
            prtGubun: '28',
            parm1: this.regParams.bkgNo
          }
        ]
      }

      this.selectFunc = ''
      this.openPopup('delay-notice-pop')
    },
    vesselChangePop () {
      this.popParams = {
        inOut: 'O',
        items: [
          {
            docNo: '19',
            prtGubun: '29',
            parm1: this.regParams.bkgNo
          }
        ]
      }

      this.selectFunc = ''
      this.openPopup('vessel-change-pop')
    },
    fnIranUploadPop (type) {
      this.popParams = {
        reqRno: this.regParams.bkgNo,
        count: 0,
        type: type
      }

      this.selectFunc = ''
      this.openPopup('iran-upload-pop')
    },
    templateSavePop () {
      this.popParams = {
        tplRno: this.regParams.bkgNo,
        pol: this.regParams.polPortCd,
        pod: this.regParams.podPortCd,
        tplCatCd: '01'
      }

      this.selectFunc = ''
      this.openPopup('template-save-pop')
    },
    async fnIranLOIDown () {
      //const fileName = 'Letter_of_Indemnity_IR.docx'
      const fileName = '(kmtc) Sanction LOI form.xlsx'
      const result = await bookings.excelDownload(fileName)
      const blob = new Blob([result.data], {
        type: result.headers['content-type']
      })

      if (window.navigator.msSaveOrOpenBlob) {
        //browser Check - IE
        window.navigator.msSaveOrOpenBlob(blob, fileName)
      } else {
        //browser Check - 그 외
        //<a>태그에 URL화 시킨 blob객체를 연결시킨 후 다운로드
        const link = document.createElement('a')
        const url = window.URL.createObjectURL(blob)
        link.href = url
        link.target = '_self'
        link.download = fileName
        link.click()

        //url 사용 후 메모리에서 제거
        window.URL.revokeObjectURL(url)
      }
    },
    setLegFixShow (obj) {
      const polEtd = this.$ekmtcCommon.isNotEmpty(obj.etd)
        ? obj.etd.substring(0, 8)
        : ''
      const podEta = this.$ekmtcCommon.isNotEmpty(obj.podEta)
        ? obj.podEta.substring(0, 8)
        : ''

      if (polEtd !== '' && podEta !== '') {
        const params = {
          actionCd: 'A101',
          etdDate: polEtd,
          etaDate: podEta,
          porPlc: obj.polPortCd,
          dlyPlc: obj.podPortCd,
          route: obj.rteCd,
          vessel: obj.vslCd,
          voyageNo: obj.voyNo
        }

        cp.getCallActionHandler(params)
          .then((res) => {
            const result = res.data

            if (this.$ekmtcCommon.isNotEmpty(result)) {
              const flag = result.result
              if (flag === 'Y') {
                this.isLegFixShow = true
              } else {
                this.isLegFixShow = false
              }
            }
          })
          .catch(() => {})
      }
    },
    async callBkgSaveCp (actionCd) {
      const schdInfo = this.$refs.schdInfo

      if (schdInfo !== null && schdInfo !== undefined) {
        const schdLegList = schdInfo.schdLegList

        let arrRoute = ''
        let arrVslCd = ''
        let arrVoyNo = ''

        schdLegList.forEach((vo, i) => {
          if (this.$ekmtcCommon.isNotEmpty(vo.rteCd)) {
            arrRoute += vo.rteCd
          }

          if (this.$ekmtcCommon.isNotEmpty(vo.vslCd)) {
            arrVslCd += vo.vslCd
          }

          if (this.$ekmtcCommon.isNotEmpty(vo.voyNo)) {
            arrVoyNo += vo.voyNo
          }

          if (i !== schdLegList.length - 1) {
            arrRoute += ','
            arrVslCd += ','
            arrVoyNo += ','
          }

          if (i !== 0 && this.$ekmtcCommon.isNotEmpty(vo.pol)) {
            this.cpParam['ts' + i] = vo.pol
          }

          if (i === 0) {
            this.cpParam.fstPorPod = vo.pod
            this.cpParam.fstVessel = vo.vslCd
            this.cpParam.fstVoyageNo = vo.voyNo
          }
        })

        await this.fnCheckLoiDownEnable(schdLegList)

        this.cpParam.route = arrRoute
        this.cpParam.vessel = arrVslCd
        this.cpParam.voyageNo = arrVoyNo
      }

      let arrCntrTypCd = ''
      let arrCntrSzCd = ''
      let arrCgoTypCd = ''
      this.regParams.containerList.forEach((vo, i) => {
        arrCntrTypCd += vo.cntrTypCd
        arrCntrSzCd += vo.cntrSzCd

        if (vo.cgoTypCd === '01') {
          arrCgoTypCd += 'HZ'
        } else if (vo.cgoTypCd === '02') {
          arrCgoTypCd += 'OOG'
        } else if (vo.cgoTypCd === '03') {
          arrCgoTypCd += 'ING'
        } else if (vo.cgoTypCd === '06') {
          arrCgoTypCd += 'FB'
        } else if (vo.cgoTypCd === '05') {
          arrCgoTypCd += 'NOR'
        } else if (vo.cgoTypCd === '') {
          arrCgoTypCd += ''
        }

        if (i !== this.regParams.containerList.length - 1) {
          arrCntrTypCd += ','
          arrCntrSzCd += ','
          arrCgoTypCd += ','
        }
      })

      this.cpParam.etdDate = this.regParams.polEtd
      this.cpParam.etaDate = this.regParams.podEta
      this.cpParam.porPlc = this.regParams.polPortCd
      this.cpParam.dlyPlc = this.regParams.podPortCd
      this.cpParam.fstPorPlc = this.regParams.polPortCd
      this.cpParam.cntrSize = arrCntrSzCd
      this.cpParam.cntrType = arrCntrTypCd
      this.cpParam.dgoog = arrCgoTypCd
      this.cpParam.actionCd = actionCd
      let returnVal = ''

      if (actionCd === 'B101') {
        returnVal = await this.cpActionAfterSave()
      } else if (actionCd === 'A201') {
        returnVal = await this.cpActionEditCntr()
      }

      return returnVal
    },
    async cpActionAfterSave () {
      let returnVal = ''

      if (this.saveFlag !== 'ADD' && this.regParams.bkgStsCd === '01') {
        return
      }

      await cp
        .getCallActionHandler(this.cpParam)
        .then(async (res) => {
          const result = res.data

          if (this.$ekmtcCommon.isNotEmpty(result)) {
            const flag = result.result

            if (flag === 'Y') {
              const updateParam = {
                bkgNo: this.regParams.bkgNo,
                bkgStsCd: '02'
              }
              await trans.updateBookingStatus(updateParam).then((res1) => {
                if (res1.headers.respcode === 'C0000' && res1.data === 'SUCC') {
                  returnVal = '02'
                }
              })
            }
          }
        })
        .catch(() => {})

      return returnVal
    },
    async cpActionEditCntr () {
      let returnVal = ''

      await cp
        .getCallActionHandler(this.cpParam)
        .then((res) => {
          const result = res.data

          if (this.$ekmtcCommon.isNotEmpty(result)) {
            returnVal = result.result
          }
        })
        .catch(() => {})

      return returnVal
    },
    initCntrInput () {
      this.cntrCpEditYn = 'Y'
      this.rfDisabled = false

      const arrId = [
        'cntrQty',
        'pcupReqDt',
        'pcupReqTm',
        'pcupCyCd',
        'pickUpMan',
        'pickUpTel',
        'cntrTypCd',
        'cntrSzCd',
        'cgoTypCd'
      ]
      const frm = document.querySelector('#frm')

      this.regParams.containerList.forEach((c, i) => {
        arrId.forEach((el) => {
          const selector = frm.querySelector('#' + el + '_' + i)

          if (selector !== null && selector !== undefined) {
            selector.disabled = false
            this.pcupDtDisabled = false
          }
        })
      })
    },
    fnCommonBtnAction () {
      this.commonBLParams = {
        action: 'LOG',
        srRno: '',
        bkgNo: this.regParams.bkgNo
      }
      this.$nextTick(() => {
        this.$refs.commonBL.doAction()
      })
    },
    fnCopy () {
      const inp = document.createElement('input')
      document.body.appendChild(inp)
      inp.value = this.regParams.bkgNo
      inp.select()
      document.execCommand('copy')
      document.body.removeChild(inp)
      this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.090'))
    },
    isRfDg (vo) {
      let isVisible = false

      if (
        (vo.cntrTypCd === 'RF' || vo.cntrTypCd === 'RH') &&
        vo.cgoTypCd === '01' &&
        !this.rfDisabled
      ) {
        isVisible = true
      }

      return isVisible
    },
    compareCheck () {
      let isChange = false
      const res = this.$ekmtcCommon.compareObject(
        this.regParams,
        this.originParams
      )

      if (res.length > 0) {
        isChange = true
      }

      return isChange
    },
    changeFrtPncCd (e) {
      const regParams = this.regParams

      if (e.target.value === 'P') {
        const frm = document.querySelector('#frm')
        this.$ekmtcCommon.hideErrorTooltip(frm.querySelector('#cneCstEnm'))
        this.regParams.cneCstEnm = ''
      }
      this.regParams.frtPncCd = e.target.value
      this.findIotCntrYn('S')
    },
    isSplitCombine (flag) {
      let isVisible = false

      if (this.btnAreaVisible && this.btnAreaVisibleJpn && this.regParams.mtyPkupUsagYn !== 'Y') {
        isVisible = true
      }

      // KMTC ON 부킹인 경우 COMBINE은 아예 비노출 처리함
      if (this.regParams.kmtcSpotYn === 'Y' && flag === 'COMBINE') {
        isVisible = false
      }

      return isVisible
    },
    setContainer (key, payload, index) {
      console.log('@@@@ key: ', key)
      console.log('@@@@ payload: ', payload)
      console.log('@@@@ index: ', index)

      let _containerList = [...this.$store.state.schedule.containerList]

      this.$store.commit('schedule/defaultAssign', {
        key: 'containerList',
        value: [
          ..._containerList.slice(0, index),
          {
            ..._containerList[index],
            [key]: payload
          },
          ..._containerList.slice(index + 1)
        ]
      })
    },
    deleteTemplate () {
      if (this.regParams.templateCd === 'B' && this.$ekmtcCommon.isNotEmpty(this.templateNo_2)) {
        this.$ekmtcCommon.confirmCallBack(this.$t('msg.CMBA660.013'), () => {
          this.regParams.tplRno = this.templateNo_2
          this.regParams.tplCatCd = '01'
          this.templateNo_2 = ''

          trans.deleteeTemplate(this.regParams).then(res => {
            if (res.data === 'SUCC') {
                this.$ekmtcCommon.alertDefault(this.$t('tem.CMAT012'))
                trans.getTemplateList(this.regParams).then((res) => {
                  this.templateList = res.data
                })
              } else {
                this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.078'))
              }
          }).catch(err => {
            console.log(err)
          })
        })
      } else {
        this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.078'))
      }
    },
    addEndUser () {
      const endUserList = this.endUserList
      const seq = endUserList.length === 0 ? 1 : Number(endUserList[endUserList.length - 1].seq) + 1
      const obj = {
        seq: seq,
        hsCd: ''
      }

      this.endUserList.push(obj)
    },
    removeEndUser (idx) {
      const endUserList = this.endUserList

      endUserList.splice(idx, 1)

      // this.endUserList.forEach((h, i) => {
      //   this.checkValidation(i)
      // })
    },
    isChemicalFileUpload () {
      if (this.regParams.chemicalFileList !== undefined && this.regParams.chemicalFileList.length > 0) {
        return true
      }
      return false
    },
    openUploadPopup (type) {
      const THIS = this
      this.uploadType = type
      if (this.uploadType === 'chemical') {
        if (!this.regParams.chemicalFileList) {
          this.regParams.chemicalFileList = []
        }
        this.uploadParam = [...[], ...this.regParams.chemicalFileList]
        if (this.regParams.chemicalFileList.length === 0) {
          this.uploadCallbackFunc = null
          this.uploadParam = []
        }
      }

      this.uploadCallbackFunc = (fileList) => {
        if (THIS.uploadType === 'chemical') {
          THIS.regParams.chemicalFileList = [...[], ...fileList]
        }
      }
      this.uploadComponent = 'doc-upload-pop'
      this.$ekmtcCommon.layerOpen('.doc_req_pop .popup_dim')
    },
    closeUploadPopup () {
      if (this.regParams.chemicalFileList !== undefined && this.regParams.chemicalFileList.length > 0) {
        this.toolTipHide('chemicalY', 'N')
      }
      this.uploadCallbackFunc = null
      this.uploadComponent = null
      this.$ekmtcCommon.layerClose('.doc_req_pop .popup_dim')
    },
    chemicalChk () {
      const podPortCd = this.regParams.podPortCd
      const hidCS008I = this.regParams.hidCS008I
      const shaTsYn = this.regParams.shaTsYn
      if (podPortCd === 'SHA' || shaTsYn === 'Y') {
        this.regParams.chemicalUploadYn = 'Y'
        if (this.regParams.chemicalYn === 'N') {
          this.regParams.chemicalYn = 'N'
        } else {
          this.regParams.chemicalYn = 'Y'
        }
        return
      }
      let cnt = 0
      let podSha = (hidCS008I) ? hidCS008I.split('@_@') : ''
      for (let i = 0; i < podSha.length - 1; i++) {
        if (cnt > 0) break

        const podShats = podSha[i].split(':')
        if (podShats[3] === 'SHA') {
           this.regParams.chemicalUploadYn = 'Y'
          if (this.regParams.chemicalYn === 'N') {
            this.regParams.chemicalYn = 'N'
          } else {
            this.regParams.chemicalYn = 'Y'
          }
        return
        }
      }
       this.regParams.chemicalUploadYn = 'N'
       this.regParams.chemicalYn = 'N'
       this.regParams.chemicalFileList = []
    },
    toolTipHide (id, flag) {
      const frm = document.querySelector('#frm')
      if (flag === 'Y' && (!this.regParams.chemicalFileList || this.regParams.chemicalFileList.length === 0)) {
        this.showErrorTooltipBk(document.querySelector('#' + id), 'Please Upload the Chemical.')
      } else {
        this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#' + id))
      }
    },
    showErrorTooltipBk (elem, message) {
    const $elem = $(elem)
    const $parent = $elem.parents('td, div').eq(0)
    let $msgBox = $parent.find('.tooltip_essen_box').remove()
    $elem.addClass('input_error')
    $parent.addClass('position_relative')
    if ($msgBox.length >= 0) {
      $msgBox = $('<p class=\'tooltip_essen_box\'><span class=\'tooltip_essen\'></span></p> ')
      $msgBox.appendTo($parent)
    }
    $msgBox.find('.tooltip_essen').eq(0).text(message).show()
  },
  async findIotCntrYn (flag, idx, _cvo) {
    let cntrTypCd = ''
    let cntrSzCd = ''
    let cgoTypCd = ''
    if (flag === 'C') {
      const params = {
        bkgCtrCd: this.regParams.vslCd,
        polCtrCd: this.regParams.polCtrCd,
        podCtrCd: this.regParams.podCtrCd,
        polPortCd: this.regParams.polPortCd,
        podPortCd: this.regParams.podPortCd,
        cntrTypCd: _cvo.cntrTypCd,
        cntrSzCd: _cvo.cntrSzCd,
        cgoTypCd: _cvo.cgoTypCd,
        frtPncCd: this.regParams.frtPncCd,
        frtAppNo: this.regParams.cneCstEnm
      }
      await bookings.bkgIotYn(params).then((res) => {
        const result = res.data
        if (result !== undefined && result === 'Y') {
          _cvo.iotCntrYn = 'Y'
        } else {
          _cvo.iotCntrYn = 'N'
          _cvo.iotEqpmYn = 'N'
        }
      })
      this.$store.commit('schedule/setContainerListOnlySent', {
        index: idx,
        value: _cvo
      })
    } else {
     this._containerList.forEach((c, index) => {
        const params = {
          bkgCtrCd: this.regParams.vslCd,
          polCtrCd: this.regParams.polCtrCd,
          podCtrCd: this.regParams.podCtrCd,
          polPortCd: this.regParams.polPortCd,
          podPortCd: this.regParams.podPortCd,
          cntrTypCd: this._containerList[index].cntrTypCd,
          cntrSzCd: this._containerList[index].cntrSzCd,
          cgoTypCd: this._containerList[index].cgoTypCd,
          frtPncCd: this.regParams.frtPncCd,
          frtAppNo: this.regParams.cneCstEnm
        }
        if (this._containerList[index].cntrTypCd === 'RF' || this._containerList[index].cntrTypCd === 'RH') {
           bookings.bkgIotYn(params).then((res) => {
            const result = res.data
            if (result !== undefined && result === 'Y') {
              this._containerList[index].iotCntrYn = 'Y'
            } else {
              this._containerList[index].iotCntrYn = 'N'
              this._containerList[index].iotEqpmYn = 'N'
            }
          })
         }
      })
    }
  },
  async checkIotYn (e, vo, idx) {
      const params = {
        bkgCtrCd: this.regParams.vslCd,
        polCtrCd: this.regParams.polCtrCd,
        podCtrCd: this.regParams.podCtrCd,
        polPortCd: this.regParams.polPortCd,
        podPortCd: this.regParams.podPortCd,
        cntrTypCd: vo.cntrTypCd,
        cntrSzCd: vo.cntrSzCd,
        cgoTypCd: vo.cgoTypCd,
        frtPncCd: this.regParams.frtPncCd,
        frtAppNo: this.regParams.cneCstEnm
      }

      await bookings.getIotUseYn(params).then((res) => {
        const result = res.data
        if (result !== undefined && result === 'N') {
          this.$ekmtcCommon.alertCallback(this.$t('js.booking.041'), function () {
            e.target.disabled = true
            e.target.checked = false
          })
        } else {
          e.target.disabled = false
        }
      })

      let _cvo = {}
      if ('SG|MY|JP'.indexOf(this.usrCtrCd) > -1) {
        const params = {
          polCtrCd: this.regParams.polCtrCd,
          polPortCd: this.regParams.polPortCd,
          cntrSeq: idx,
          iotCntrYn: e.target.checked ? 'Y' : 'N'
        }
       await trans.getPickUpSet(params).then((res) => {
          const data = res.data
          _cvo.pcupCyCd = data.cyCd
          _cvo.pickUpPlace = data.cyNm
        })
        this.$store.commit('schedule/setContainerListOnlySent', {
        index: idx,
        value: _cvo
      })
      } else {
        if (this.regParams.owtrYn !== 'N') {
          _cvo.pcupReqDt = ''
          _cvo.pickUpPlace = ''
          _cvo.pickUpMan = ''
          _cvo.pickUpTel = ''
          _cvo.pcupCyCd = ''
          _cvo.pcupReqDtBak = ''
          _cvo.rfHmpgUrl = ''
          this.$store.commit('schedule/setContainerListOnlySent', {
            index: idx,
            value: _cvo
          })
        }

        //1376616_[eKMTC] IoT 컨테이너 선택시 'Pick Up 일시/장소' 선택 관련
        //pickup 일시/장소 초기화 시 다시 선택하세요 메시지 노출
        this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.515'))
      }
    },
    getToday () {
      let today = new Date()
      let year = today.getFullYear() //연도
      let month = ('0' + (today.getMonth() + 1)).slice(-2) //월
      let date = ('0' + today.getDate()).slice(-2) //날짜

      return year + month + date
    },
    // KMTC ON 컨테이너 수량 적게 변경시 출항일자 7일 이내인 경우 불가, 7일 이전인 경우 가능 체크
    chkKmtcOnCntrInfoMinus () {
      let toDay = this.getToday()

      if (toDay.length < 8 || toDay === undefined || this.regParams.polEtd < 8 || this.regParams.polEtd === undefined) {
        return false
      }

      let minusDate = this.$ekmtcCommon.getStrToDate(toDay) - this.$ekmtcCommon.getStrToDate(this.regParams.polEtd)
      minusDate = Math.abs(minusDate / (1000 * 3600 * 24))

      // 오늘 날짜가 출항일자보다 크면 두 날짜 차이는 마이너스 이므로 - 붙여줌
      if (this.$ekmtcCommon.getStrToDate(toDay) > this.$ekmtcCommon.getStrToDate(this.regParams.polEtd)) {
        minusDate = Number(minusDate) * -1
      }

      if (Number(minusDate) < 7) {
        return false
      }
      return true
    },
    async fnCheckLoiReuse () {
      const params = {
        bkgShprCstNo: this.cstNo
      }
      let rtn = 'N'
      await bookings.checkLoiReuseYn(params).then((res) => {
          const result = res.data
          rtn = result
        })
        return rtn
    },
    async fnCheckLoiDownEnable (params) {
      let count = 0

      for (let i = 0; i < params.length; i++) {
        const param = {
          polPortCd: params[i].pol,
          podPortCd: params[i].pod,
          vslCd: params[i].vslCd,
          voyNo: params[i].voyNo
        }

        await bookings.checkLoiDownEnable(param).then((res) => {
          const result = res.data
          if (result !== undefined) {
            count = count + Number(result)
          }
        })
      }

      if (count > 0) {
        this.loiDownYn = 'N'
      } else {
        this.loiDownYn = 'Y'
      }
    },
    // 국가코드  POR : JP, POD : VN 부킹 시 DG,OOG 아닌 케이스에도 서류 첨부 기능 추가
    etcDocUploadPopup () {
      this.etcUploadParam = {
        taskCatCd: 'B',
        type: 'E',
        bkgNo: this.regParams.bkgNo !== undefined ? this.regParams.bkgNo : '',
        blNo: this.regParams.blNo !== undefined ? this.regParams.blNo : this.regParams.bkgNo,
        reqRno: this.regParams.tempReqRno !== undefined ? this.regParams.tempReqRno : this.regParams.bkgNo,
        etcUploadFlag: this.saveFlag,
        count: this.cntrSeq
      }

      this.selectFunc = this.uploadComplete
      this.openEtcUploadPopup('etc-doc-upload-pop')
    },
    uploadComplete (returnVo) {
      if (returnVo.uploadEtcFileInfos.length > 0) {
          this.etcDeleteAllYn = 'Y'
          this.regParams.tempReqRno = returnVo.reqRno
      } else {
        this.etcDeleteAllYn = 'N'
      }

      this.closeEtcUploadPopup()
    },
    openEtcUploadPopup (type) {
      const THIS = this
      this.uploadType = type

      this.etcUploadComponent = 'etc-doc-upload-pop'
      this.$ekmtcCommon.layerOpen('.etc_doc_pop .popup_dim')
    },
    closeEtcUploadPopup () {
      this.etcUploadCallbackFunc = null
      this.etcUploadComponent = null
      this.$ekmtcCommon.layerClose('.etc_doc_pop .popup_dim')
    },
    async templateDownloadForPH () {
      const filenm = 'VEHICLE CHECKLIST.xlsx'
      const result = await bookings.excelDownload(filenm)
      //blob 생성
      const blob = new Blob([result.data], { type: result.headers['content-type'] })

      if (window.navigator.msSaveOrOpenBlob) { //browser Check - IE
        window.navigator.msSaveOrOpenBlob(blob, filenm)
      } else { //browser Check - 그 외
        //<a>태그에 URL화 시킨 blob객체를 연결시킨 후 다운로드
        const link = document.createElement('a')
        const url = window.URL.createObjectURL(blob)
        link.href = url
        link.target = '_self'
        link.download = filenm
        link.click()

        //url 사용 후 메모리에서 제거
        window.URL.revokeObjectURL(url)
      }
    },
    // 파일 조회(Booking 등록)
    async getCertiFileListForBooking (etcUploadParam) {
      this.uploadFileList = []
      etcUploadParam = etcUploadParam ?? {
        taskCatCd: 'B',
        type: 'E',
        bkgNo: this.regParams.bkgNo !== undefined ? this.regParams.bkgNo : '',
        blNo: this.regParams.blNo !== undefined ? this.regParams.blNo : this.regParams.bkgNo,
        reqRno: this.regParams.tempReqRno !== undefined ? this.regParams.tempReqRno : this.regParams.bkgNo,
        etcUploadFlag: this.saveFlag,
        count: this.cntrSeq
      }

      await DgOogCerti.getEtcDocFileForBooking(etcUploadParam).then(response => {
        let uri = 'http://trans.ekmtc:9000/trans/trans/file-download'
        let paramType = 'ETC_DOC'

        if (process.env.VUE_APP_MODE === 'LOCAL') {
          uri = 'http://localhost:9006/trans/file-download'
        }

        if (response.headers.respcode === 'C0000') {
          let fileList = response.data.dgOogCertiFileInfoList

          if (fileList !== undefined) {
            fileList.forEach(file => {
              const category = file.reqCatCd

              this.uploadFileList.push(
                {
                  guid: file.reqRno + '_' + file.fileSeq,
                  originalName: file.fileNm,
                  size: file.fileSz,
                  fileId: uri + '?reqRno=' + file.reqRno + '&fileSeq=' + file.fileSeq + '&type=' + paramType,
                  category: category,
                  fileSeq: file.fileSeq
                }
              )
            })
          }
        }
      })
    },
    async oldFileCheck () {
      const TH = this
      const params = {
        taskCatCd: 'B',
        type: 'E',
        bkgNo: TH.regParams.bkgNo !== undefined ? TH.regParams.bkgNo : '',
        blNo: TH.regParams.blNo !== undefined ? TH.regParams.blNo : TH.regParams.bkgNo,
        reqRno: TH.regParams.tempReqRno !== undefined ? TH.regParams.tempReqRno : TH.regParams.bkgNo,
        etcUploadFlag: TH.saveFlag,
        count: TH.cntrSeq
      }
      if (this.saveFlag === 'EDIT') {
        await this.getCertiFileListForBooking(params).then(() => {
          this.etcDeleteAllYn = this.uploadFileList.length > 0 ? 'Y' : 'N'
        })
      }
    }
  }
}
</script>

<style scoped>
.span_grs {
  display: inline-block;
  width: 30px;
  text-align: center;
}
.span_rmk {
  margin-left: 23px;
}
.div_len_ck {
  position: absolute;
  top: 3px;
  right: 125px;
  width: 100px;
}
.auto-area {
  display: inline-block;
  width: 60%;
}
.scd_btn_area > span:first-child {
  margin-right: 0px !important;
}
.scd_btn_area > span + span {
  margin-left: 50px !important;
}
.div_req_area textarea {
  min-height: 70px;
}
.ir_red {
  color: red;
  font-weight: 700;
}
.ir_black {
  color: black;
  font-weight: 700;
}
.txt_jp {
  margin-top: 5px;
  margin-left: 0px !important;
}
.jp_notice {
  font-size: 12px;
  color: #f2084f;
}
.txt_desc {
  background: inherit;
  position: relative;
}
.txt_desc:before {
  content: "※";
  position: absolute;
  top: 0;
  left: 0;
}
.tooltip_wrap {
  min-width: 250px;
  right: 0;
  top: 24px;
  display: none;
  text-align: left;
}
.tbl_bkg_charger input[type="text"] + input[type="text"] {
  margin-left: 3px !important;
}
.tbl_bkg_charger select + input[type="text"] {
  margin-left: 3px !important;
}
.ir_t_red {
  margin-top: 15px;
  color: red;
  font-weight: 300;
}
</style>
