var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "div_booking_leg" },
    [
      _c(
        "table",
        { staticClass: "tbl_col tal" },
        [
          _vm._m(0),
          _c("thead", [
            _c("tr", [
              _c("th", { attrs: { scope: "col" } }, [
                _vm._v(_vm._s(_vm.$t("msg.CSBK100.014"))),
              ]),
              _c("th", { attrs: { scope: "col" } }, [
                _vm._v(_vm._s(_vm.$t("msg.CSBK100.012"))),
              ]),
              _c("th", { attrs: { scope: "col" } }, [
                _vm._v(_vm._s(_vm.$t("msg.CSBK100.013"))),
              ]),
              _c("th", { attrs: { scope: "col" } }, [
                _vm._v(_vm._s(_vm.$t("msg.CSBK100.155"))),
              ]),
            ]),
          ]),
          _vm._l(_vm.schdLegList, function (vo, idx) {
            return _c("tbody", { key: "schdLeg_" + idx }, [
              _vm.$ekmtcCommon.isNotEmpty(vo.transitTSTime)
                ? _c("tr", [
                    _c(
                      "td",
                      { staticClass: "text_center", attrs: { colspan: "3" } },
                      [_vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.069")))]
                    ),
                    _c("td", { staticClass: "text_center" }, [
                      _vm._v(_vm._s(vo.transitTSTime)),
                    ]),
                  ])
                : _vm._e(),
              _c("tr", [
                _c("td", { attrs: { rowspan: "2" } }, [
                  vo.vslNm !== "Rail" && vo.vslNm !== "Truck"
                    ? _c(
                        "a",
                        {
                          class: _vm.urlOrNot
                            ? "td_underline dsm_info"
                            : "td_underline dsm_info",
                          on: {
                            click: function ($event) {
                              return _vm.vesselPop(vo, idx)
                            },
                          },
                        },
                        [_vm._v(_vm._s(vo.vslNm) + " " + _vm._s(vo.voyNo))]
                      )
                    : _vm._e(),
                  vo.vslNm === "Rail" || vo.vslNm === "Truck"
                    ? _c(
                        "a",
                        { class: _vm.urlOrNot ? "dsm_info" : "dsm_info" },
                        [_vm._v(_vm._s(vo.vslNm) + " " + _vm._s(vo.voyNo))]
                      )
                    : _vm._e(),
                  _c("br"),
                  _vm.$ekmtcCommon.isNotEmpty(vo.rteCd)
                    ? _c(
                        "a",
                        {
                          class: _vm.urlOrNot
                            ? "td_underline dsm_info"
                            : "td_underline dsm_info",
                          on: {
                            click: function ($event) {
                              return _vm.routePop(vo)
                            },
                          },
                        },
                        [_vm._v("(Route: " + _vm._s(vo.rteCd) + ")")]
                      )
                    : _vm._e(),
                  idx === 0 && _vm.fdrInfo.yzrYn === "Y"
                    ? _c(
                        "a",
                        {
                          staticClass: "button sm ml2",
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.searchSchedule.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("btn.CMBTK004")))]
                      )
                    : _vm._e(),
                ]),
                _c("td", [
                  _c(
                    "a",
                    {
                      staticClass: "td_underline",
                      on: {
                        click: function ($event) {
                          return _vm.portTerminalCyPop(vo.pol, vo.polTrmlCd)
                        },
                      },
                    },
                    [
                      _vm._v(_vm._s(vo.polNm)),
                      idx !== 0
                        ? _c("span", [
                            _vm._v("("),
                            _c("span", { staticClass: "color_red" }, [
                              _vm._v("T/S"),
                            ]),
                            _vm._v(")"),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ]),
                _c("td", [
                  _c(
                    "a",
                    {
                      staticClass: "td_underline",
                      on: {
                        click: function ($event) {
                          return _vm.portTerminalCyPop(vo.pod, vo.podTrmlCd)
                        },
                      },
                    },
                    [
                      _vm._v(_vm._s(vo.podNm)),
                      idx !== _vm.schdLegList.length - 1
                        ? _c("span", [
                            _vm._v("("),
                            _c("span", { staticClass: "color_red" }, [
                              _vm._v("T/S"),
                            ]),
                            _vm._v(")"),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ]),
                _c(
                  "td",
                  { staticClass: "text_center", attrs: { rowspan: "2" } },
                  [_vm._v(_vm._s(vo.transitTime))]
                ),
              ]),
              _c("tr", [
                _c("td", { staticClass: "bor-left" }, [
                  _c("ul", [
                    _c("li", [
                      _vm._v(
                        _vm._s(
                          idx === 0
                            ? _vm.$t("msg.CSBK100.157")
                            : _vm.$t("msg.CSBK100.156")
                        ) +
                          " : " +
                          _vm._s(vo.polEtbDT)
                      ),
                    ]),
                    idx === 0
                      ? _c("li", [
                          _vm._v(
                            _vm._s(_vm.$t("msg.CSBK100.156")) +
                              " : " +
                              _vm._s(vo.etdDT)
                          ),
                        ])
                      : _vm._e(),
                    _c("li", [
                      _c(
                        "a",
                        {
                          staticClass: "td_underline",
                          on: {
                            click: function ($event) {
                              return _vm.portTerminalCyPop(vo.pol, vo.polTrmlCd)
                            },
                          },
                        },
                        [_vm._v(_vm._s(vo.polTml))]
                      ),
                    ]),
                  ]),
                ]),
                _c("td", [
                  _c("ul", [
                    _c("li", [
                      _vm._v(
                        _vm._s(_vm.$t("msg.CSBK100.157")) +
                          " : " +
                          _vm._s(vo.etaDT)
                      ),
                    ]),
                    _c("li", [
                      _c(
                        "a",
                        {
                          staticClass: "td_underline",
                          on: {
                            click: function ($event) {
                              return _vm.portTerminalCyPop(vo.pod, vo.podTrmlCd)
                            },
                          },
                        },
                        [_vm._v(_vm._s(vo.podTml))]
                      ),
                    ]),
                    idx === _vm.schdLegList.length - 1 &&
                    _vm.saveFlag !== "PICKUP" &&
                    _vm.legFixShow
                      ? _c("li", { staticClass: "mt5" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.legTrmlFx,
                                expression: "legTrmlFx",
                              },
                            ],
                            attrs: {
                              type: "checkbox",
                              id: "legTrmlFx",
                              "true-value": "Y",
                              "false-value": "N",
                              disabled: _vm.disabledYn === "Y",
                            },
                            domProps: {
                              checked: Array.isArray(_vm.legTrmlFx)
                                ? _vm._i(_vm.legTrmlFx, null) > -1
                                : _vm._q(_vm.legTrmlFx, "Y"),
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.legTrmlFx,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? "Y" : "N"
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.legTrmlFx = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.legTrmlFx = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.legTrmlFx = $$c
                                }
                              },
                            },
                          }),
                          _c("label", { attrs: { for: "legTrmlFx" } }, [
                            _vm._v("Terminal Fix"),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ]),
              ]),
            ])
          }),
          _vm.schdLegList.length === 0
            ? _c("tbody", [
                _c("tr", [
                  _c(
                    "td",
                    {
                      staticClass: "text_center",
                      staticStyle: { cursor: "default" },
                      attrs: { colspan: "4" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.isPickup
                              ? _vm.$t("msg.CSBK100.392")
                              : _vm.$t("msg.CSBK100.219")
                          ) +
                          " "
                      ),
                    ]
                  ),
                ]),
              ])
            : _vm._e(),
        ],
        2
      ),
      _c(
        "win-layer-pop",
        [
          _vm.customComponent
            ? _c(_vm.customComponent, {
                tag: "component",
                attrs: { "parent-info": _vm.popParams },
                on: { close: _vm.closePopup, selectFunc: _vm.selectFunc },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "20%" } }),
      _c("col", { staticStyle: { width: "32%" } }),
      _c("col", { staticStyle: { width: "auto" } }),
      _c("col", { staticStyle: { width: "15%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }